import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect } from "formik";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import ActionsTable from "components/ActionsTable/TableList";
import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core'

const getFieldValues = (fields) => {
    const values = Object.values(fields)?.reduce((obj, item) => {
        return { ...obj, [item.key]: item.value };
    }, {});
    return values;
}

const ValetForm = connect((props) => {
    console.log('valet-ValetForm-props', props);
    useEffect(() => {
        if (props.valet) {
            const values = getFieldValues(Object.values(props.valet));
            if (!_.isEqual(values, props.formik.values)) {
                props.formik.setValues(values);
            }
        }
    }, [props.valet]);

    useEffect(() => {
        if (props.formik.values.area_id) {
            const values = _.cloneDeep(props.formik.values);
            let configs = _.cloneDeep(props.config);
            // props.formik.setFieldValue('service_location_id', "");
            configs.fields.service_location_id.options = values.area_id.serviceLocations;
            props.setConfig(configs);
        }
    }, [props.formik.values.area_id])

    return (
        <div className="row">
            <CardBox styleName="col-12">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {Object.values(props.config.fields)?.map((field) => (
                            field && <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{ ...field, ...{ name: field.key } }} />
                            </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset">
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    );
});

const ValetStaff = (props) => {
    const [config, setConfig] = useState();
    const [initialValues, setInitialValues] = useState();
    const [valets, setValets] = useState();
    const [valet, setValet] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        msg: "",
        type: "error",
    });
    const [valetAction, setValetAction] = useState({
        isShowing: false,
        type:'',
        headingVariant:'',
        heading:'',
        text: '',
        user:{}
    });
    const validations = Yup.object().shape({
        first_name: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        area_id: Yup.object().required("Required"),
        service_location_id: Yup.object().required("Required"),
        role_id: Yup.string().required("Required")
    });

    useEffect(() => {
        API.get("valetStaff")
            .then((res) => {
                const values = getFieldValues(res.data.data.config.fields);
                setInitialValues(values);
                setConfig(res.data.data.config);
                setValets(res.data.data.valets);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    const handleSubmit = (data, form) => {
        API.post("valetStaff", JSON.stringify(data))
            .then((res) => {
                setValets(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    };

    const handleEdit = (clickedLoc) => {
        setValet(clickedLoc);
        // scroll to top
        document.body.style.height = "auto";
        setTimeout(function () {
            document.body.style.height = "100%";
        }, 50);
    };

    const handleStaffStatus = (user) => {
        let heading= `${user.active.value ==1 ? 'Deactivate' : 'Activate'} Valet`;
        let text = `Are you sure you want to ${(user.active.value == 1 ? 'deactivate' : 'activate')} ${user.first_name.value}  ${user.last_name.value}?`;
        setValetAction({ isShowing: true, type:'staffStatus', headingVariant:user.active.value == 1? 'error':'secondary', heading, text, user })
    }

    const handleResetMobile = (user) => {
        let heading='Reset Valet';
        let text = `Are you sure you want to reset ${user.first_name.value} ${user.last_name.value}?`;
        setValetAction({ isShowing: true, type:'resetMobile', headingVariant:'error', heading, text, user })
    }

    const confirmAction = (type, user) =>{
        if(type === 'staffStatus'){
            onEnableConfirm(user)
        } else if(type === 'resetMobile'){
            resetMobile(user)
        }
    }

    const handleDialogClose = () => {
        setValetAction({isShowing: false, type:'', heading:'', text:'', user:{} })
    }
    const onEnableConfirm = (user) => {
        let valetActive = user.active.value == 0 ? 1 : 0;
        API.put("valetStatus", JSON.stringify({ id: user.id.value, active: valetActive }))
            .then((res) => {
                let tempValet = valets;
                const index = tempValet.body.findIndex(valet => valet.id.value === user.id.value);
                if (index !== -1) {
                    const selectedValet = tempValet.body[index];
                    tempValet.body[index] = { ...selectedValet, active:{...selectedValet.active, value: valetActive}};
                    setValets(tempValet);
                }
                handleDialogClose()
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                handleDialogClose()
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            })
    }

    const resetMobile = (user) => {
        API.post("resetMobile", JSON.stringify({ 'guard_id': user.user_id.value }))
            .then((res) => {
                handleDialogClose()
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                handleDialogClose()
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            })
    }

    return (
        config && initialValues &&
        <>
            <ContainerHeader title={config?.title} match={props.match} />
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validations}
            >
                <ValetForm config={config} setConfig={setConfig} valet={valet} />
            </Formik>
            {valets && (
                <ActionsTable
                    tableHeading={valets.title}
                    table={valets}
                    onEdit={handleEdit}
                    staffStatus={handleStaffStatus}
                    resetMobile={handleResetMobile}
                    actions={true}
                />
            )}
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar(prevState => ({...prevState, open: false}));
                }}
            />
            <Dialog open={valetAction.isShowing} onClose={handleDialogClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle disableTypography>
                    <Typography variant={"h6"} color={valetAction.headingVariant}>
                        {valetAction.heading}
                    </Typography>
                </DialogTitle>

                <DialogContent color="secondary">
                    <Typography variant={"body1"}>
                        {valetAction.text}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Box display="inline-block">
                        <Button onClick={()=>confirmAction(valetAction.type, valetAction.user)} variant="contained" color="primary">
                            Yes
                        </Button>
                    </Box>
                    <Box display="inline-block">
                        <Button onClick={handleDialogClose} variant="outlined" color="secondary">
                            No
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default ValetStaff;