import React, {Component} from 'react';
import ContainerHeader from 'components/ContainerHeader';
import API from 'util/Api';
import _ from 'lodash'
import { connect } from 'react-redux';
import ReportCounts from 'components/Cards/ReportCounts'
import QrScanner from 'components/ActionsTable/QrScanner'
import ActionsTable from 'components/ActionsTable'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/AspectRatio';

class ScanAppointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeAppointments: [],
            upcomingAppointments: [],
            completedAppointments:[],
            loading:false,
            isShowing: false,
            appointmentCounts:[],
            message:'',
            errorMessage:''
        }
        this.updateAppointment = this.updateAppointment.bind(this)
    }
    componentDidMount(){
      if (this.props.reportFlags===undefined) {
            localStorage.removeItem("token")
       }
      API.get('appointments')
      .then((res) => {
          // received data        
          this.setState({ activeAppointments: res.data.data.ongoingAppointments, upcomingAppointments: res.data.data.upcomingAppointments,completedAppointments:res.data.data.completedAppointments,appointmentCounts:res.data.data.appointmentCounts, errorMessage: ""})
      })
      .catch((err) => {
          // got an error
          this.setState({errorMessage: "Please try again"});
      })
      
  }
    
    updateAppointment = (data) => {
        this.setState({loading:true})
        API.post('appointments', JSON.stringify(data))
            .then((res)=> {
                console.log(res)
            if(res.data.error){
                this.setState({loading:false,errorMessage: res.data.msg});
            } else {   
                this.setState({message: res.data.msg,loading:false});
                var self = this
                    API.get('appointments')
                        .then((res) => {
                            // received data        
                            self.setState({ activeAppointments: res.data.data.ongoingAppointments, upcomingAppointments: res.data.data.upcomingAppointments,appointmentCounts:res.data.data.appointmentCounts,errorMessage: "",isShowing:false})

                        })
                        .catch((err) => {
                            // got an error
                            self.setState({errorMessage: "Please try again",loading:false});
                        })
                }          
            })
            .catch((err)=> {
                this.setState({errorMessage: err.response.data.msg,loading:false})
            })
    }
   
    render() {
        return(
            <div>
                <ContainerHeader title="Scan Appointments" match={this.props.match}/>
                {this.state.appointmentCounts.length !=0 && <ReportCounts data={this.state.appointmentCounts} /> }
                {this.state.errorMessage !='' && <Typography paragraph={true} variant="h3"  color="secondary">{this.state.errorMessage}</Typography>}

                <div className="row">
                    {this.state.activeAppointments.length !=0 && <ActionsTable tableHeading ="Active Appointments" table={this.state.activeAppointments} />}
                </div>
                <div className="row">
                    {this.state.upcomingAppointments.length !=0 &&  <ActionsTable tableHeading ="Booked Appointments" table={this.state.upcomingAppointments} />}
                </div>
                <div className="row">
                    {this.state.completedAppointments.length !=0 &&  <ActionsTable tableHeading ="Completed Appointments" table={this.state.completedAppointments} />}
                </div>
                <Fab style={{position:'fixed',right:5,bottom:5}}onClick={() =>this.setState({isShowing:true})} color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
               
                {this.state.isShowing  && 
                <QrScanner isShowing = {this.state.isShowing} 
                           handleClose={()=>{this.setState({isShowing:false,errorMessage:''})}} 
                           message={this.state.message}
                           errorMessage={this.state.errorMessage}
                           loading={this.state.loading}
                           handleScan={this.updateAppointment}
                />
                                        
                }                  
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    try {
        return {reportFlags : state.auth.authUser.appointments}
    } catch (err) {
    }
}
  
export default connect(mapStateToProps)(ScanAppointments);