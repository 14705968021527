import React from 'react';
import {createRoot, render} from 'react-dom/client';
import registerServiceWorker from 'Notifications/registerServiceWorker';
import { initializeFirebase} from 'Notifications/push-notification'
const rootEl = document.getElementById('app-site');
const root = createRoot(rootEl);
// Create a reusable render method that we can call more than once
let renderEl = () => {
  // Dynamically import our main App component, and render it
  const MainApp = require('./MainApp').default;
  root.render(
    <MainApp/>
  );
};

if (module.hot) {
  module.hot.accept('./MainApp', () => {
    const MainApp = require('./MainApp').default;
    root.render(
      <MainApp/>
    );
  });
}

renderEl();
initializeFirebase()
registerServiceWorker()

