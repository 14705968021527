import React,{ useEffect} from 'react'
import { Form,connect } from 'formik'
import { Button,Box } from '@material-ui/core'
import FormField from 'components/FormField'
import _ from 'lodash'
import CardBox from 'components/CardBox';

const InvoiceDetails = (props) => {

    console.log("InvoiceDetails",props.formik.values);
    
    useEffect(()=>{
        if(props.invoice){
            if(!_.isEqual(props.invoice,props.formik.values)){
                props.formik.setValues(props.invoice)
            }
        }
    },[props.invoice])

    useEffect(()=>{
        if(props.resetFormik){
            props.formik.resetForm();
            props.setFalse();
        }
    },[props.resetFormik])
                  
    return(
        <>
        { Object.keys(props.config).length >0 &&
            <CardBox styleName="col-12" heading='Invoice Details'>
            
                <Form>
                    {/* FIRST ROW */}            
                    <div className="row">
                        { props.config.map(field => (
                            <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{...field,...{name:field.key}}}/>
                            </div>
                        ))
                        }                 
                    </div>
                    {/* BUTTONS */}
                    <div className="col-lg-6 col-sm-12 col-12">                    
                        <Box mt="20px">
                            <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" > Submit </Button></Box>
                            <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset">Reset</Button></Box>
                        </Box>
                    </div>
                </Form>
            </CardBox>
        }
        </>
    )
};

export default connect(InvoiceDetails)