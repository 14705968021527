import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart,Area,BarChart, Bar } from 'recharts';

const data = [
  {
    name: 'Sun',
    'This week': 4000,
    'Last week': 2400,
  },
  {
    name: 'Mon',
    'This week': 3000,
    'Last week': 1398,
  },
  {
    name: 'Tue',
    'This week': 2000,
    'Last week': 9800
  },
  {
    name: 'Wed',
    'This week': 2780,
    'Last week': 3908,
  },
  {
    name: 'Thru',
    'This week': 1890,
    'Last week': 4800,
  },
  {
    name: 'Fri',
    'This week': 2390,
    'Last week': 3800
  },
  {
    name: 'Sat',
    'This week': 3490,
    'Last week': 4300
  },
];

export default class LineGraphComp extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  render() {
    return (
      <LineChart
          width={400}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="This week" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Last week" stroke="#82ca9d" />
        </LineChart>
    );
  }
}
export const AreaChartComp = (props) =>{
    console.log("AreaChartComp",props.data)
    return(
    <AreaChart width={410} height={200} data={props.data}
    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={props.colorCode} stopOpacity={0.8}/>
            <stop offset="95%" stopColor={props.colorCode} stopOpacity={0}/>
            </linearGradient>
            {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
            </linearGradient> */}
        </defs>
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="This week" stroke={props.colorCode}fillOpacity={1} fill="url(#colorUv)" />
        <Area type="monotone" dataKey="Last week" stroke="grey" fillOpacity={1} fill="url(#colorPv)" />
        <Legend />
    </AreaChart>
    )
}
export const BarGraphComp = (props) =>{
  console.log("BarGraphComp",props)
    return(
        <BarChart width={410} height={200} data={props.data}>
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="This week" fill={props.colorCode.thisWeek} />
            <Bar dataKey="Last week" fill={props.colorCode.lastWeek} />
        </BarChart>
    )
}
