import React, { useEffect, useState } from "react";
import { connect, Form } from "formik";
import { Button, Box } from "@material-ui/core";
import _ from "lodash";
import FormField from "components/FormField";
import CardBox from "components/CardBox";
import { Formik } from "formik";

const getFieldValues = (fields) => {
    const values = fields.reduce((obj, item) => {
        return { ...obj, [item.key]: item.value };
    }, {});
    return values;
}

const EZFields = connect((props) => {
    useEffect(() => {
        if (props.values) {
            if (!_.isEqual(props.values, props.formik.values)) {
                props.formik.setValues(props.values);
            }
        }
    }, [props.values]);

    useEffect(() => {
        if (props.handleChange) {
            props.handleChange(props.formik);
        }
    }, [props.formik.values])

    return (
        <div className="row">
            <CardBox styleName="col-12">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {props.fields?.map((field) => (
                            field && <div className={`col-md-${field.size == "lg" ? '3' : field.size} col-12`} key={`field-${field.key}`}>
                                <FormField field={{ ...field, ...{ name: field.key } }} />
                            </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset">
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    )
});

const EZForm = (props) => {
    const [initValues, setInitValues] = useState();

    useEffect(() => {
        if (props.fields) {
            setInitValues(getFieldValues(props.fields));
        }
    }, [props.fields]);

    return (
        initValues &&
        <Formik
            onSubmit={props.onSubmit}
            initialValues={initValues}
            validationSchema={props.validationSchema}
        >
            <EZFields
                fields={props.fields}
                values={props.values}
                handleChange={props.handleChange}
            />
        </Formik>
    )
}
export default EZForm;
export { getFieldValues };