import React, {Component} from 'react';
import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/CardBox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import API from 'util/Api';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button'
import OrderDetails from './OrderDetails.js'
import _ from 'lodash'
import MsgPopover from 'components/MsgPopover'
import {SwapHoriz} from '@material-ui/icons'
import Box from '@material-ui/core/Box'
import SearchBox from 'components/ActionsTable/SearchBox';

class ReadyForPickUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table:[],
            orderDetails: {},
            isShowing: false,
            onView: false,
            loading:false,
            page: 0,
            rowsPerPage: 10
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleOrderStatus = this.handleOrderStatus.bind(this)

    }
    componentDidMount(){
      let status = 'ready for pickup'
      API.get('orders', {
          params: {
              status: status
          }
      })
      .then((res) => {
          // received data        
          console.log('rcvd data',res.data.data)
          this.setState({ table: res.data.data.orders ,appState : 'active', errorMessage: "",tableData:res.data.data.orders})
      })
      .catch((err) => {
          // got an error
          this.setState({errorMessage: "Please try again"});
      })
      
  }
    onView = (order) => {
        //console.log("view clicked",order)
        this.setState({orderDetails: order, isShowing: true, onView: true,errorMessage:'',message:'',loading:false})
        console.log("view clicked", this.state.orderDetails)
    }

    handleClickOpen = () => {
        this.setState({isShowing: true})
    }

    handleClose = () => {
        this.setState({isShowing: false})
    }
    handleOrderStatus = (action,orderID,vendorID,otp=null) => {
        this.setState({loading:true})
        API.post('order_status', JSON.stringify({'orderID':orderID,'action':action,'vendorID':vendorID,'otp':otp}))
        .then((res)=> {
           if(res.data.error){
            this.setState({errorMessage: res.msg.msg});
           } else {
            this.setState({message: 'Status successfully updated'});
            API.get('orders')
                .then((res) => {
                    // received data        
                    console.log('rcvd data',res.data.data)
                    this.setState({ table: res.data.data.orders ,isShowing:false,loading:false})
                })
                .catch((err) => {
                    // got an error
                    this.setState({errorMessage: "Please try again",loading:false});
                })    
            }          
        })
        .catch((err,res)=> {
            this.setState({errorMessage: err.response.data.msg.msg,loading:false})
        })                                                      
    }
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage})
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0})
    };
//filter table according to search
handleSearch = (searchValue)=>{
    console.log("handleSearch",this.state.table,searchValue)
    if(searchValue){
        var filter = [];
        this.state.tableData.map(o=>{
            console.log("loopkey",o);
            for(var key in o){
                
                if(!key.includes('hidden') && o[key] !=null && !Array.isArray(o[key]) && o[key].toString().toLowerCase().includes(searchValue.toLowerCase())){
                    filter.push(o);
                    break;
                }
            }
        });
        console.log("filterdedArray",filter)
        this.setState({table:filter});
    }else{
        this.setState({table:this.state.tableData});
    }
}
    render() {
        return(
            <div>
                <ContainerHeader title="Manage Orders" match={this.props.match}/>  
                <div className="row">
                     <CardBox styleName="col-12" headerOutside heading={
                    <React.Fragment>
                    {"Order Listings"}
                    <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                    <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                    </React.Fragment>}>
                        <SearchBox handleSearch={this.handleSearch.bind(this)}/>
                        <div className="table-responsive-material">
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Order Id</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Phone</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Time</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Status</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { (this.state.rowsPerPage > 0
                                    ? this.state.table.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    : this.state.table
                                    ).map(order => {
                                        return (
                                            <TableRow key={order.order_id}>
                                                <TableCell>{order.order_id}</TableCell>
                                                <TableCell>{order.mobile}</TableCell>
                                                <TableCell>{order.placed_at}</TableCell>
                                                <TableCell style={{color:order.color}}>{order.status}</TableCell>
                                                <TableCell>
                                                        <Button name="view" variant="outlined"  color="secondary" onClick={() => this.onView(order)}>View</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination 
                                  rowsPerPageOptions={[10, 25, 50]}
                                  component="div"
                                  count={this.state.table.length}
                                  rowsPerPage={this.state.rowsPerPage}
                                  page={this.state.page}
                                  onPageChange={this.handleChangePage}
                                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />   
                        </div>
                          
                    </CardBox>
                </div>
                <OrderDetails loading = {this.state.loading} message = {this.state.message} errorMessage = {this.state.errorMessage} isShowing={this.state.isShowing} orderDetails={this.state.orderDetails} handleOrderStatus = {this.handleOrderStatus} handleClose={this.handleClose} />                    
            </div>
        )
    }
}
  
export default ReadyForPickUp;