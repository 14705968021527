import React, { useState,useEffect } from 'react'
import CardBox from 'components/CardBox'
import {Box,Button,InputLabel,TextField,FormControl,Select,MenuItem, Typography,FormHelperText,LinearProgress,InputAdornment} from '@material-ui/core'
import {DateTimePicker} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles} from '@material-ui/core/styles'
import _ from 'lodash'
import moment from "moment"

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }
))

const Form =(props)=>{
    console.log("Form-props",props)
    const classes = useStyles()
    const [location,setLocation] = useState([])
    const [controllers,setControllers] = useState([])
    const [inputs,setInputs] = useState({
        type:'',
        location:null,
        controller:null
    })
    const [dateRange,setDateRange] = useState({
        startDateTime : null,
        endDateTime :  null
    })
    const handleChange = (e)=>{
        const {name,value} = e.target
        console.log("handleChange",name,value)
        if(name==='type'){
            if(value==='evCharging'){
                setLocation(props.chargingLoc)
            } else if(value==='nfc_access'){
                setLocation(props.accessLoc)
            }else{
                setLocation(props.parkingLoc)
            }
        }
        setInputs({...inputs,[name]:value,location:null,controller:null})
    }
    //filler controller according to parking locations or charging locations
    useEffect(()=>{
        if(inputs.location && inputs.type.length>0){
            let newController = []
            if(inputs.type==='evCharging'){
                const cpIds = inputs.location.cp_ids.split(",")
                newController = _.filter(props.controllersList,c=>cpIds.includes(c.cp_id) && c.type===inputs.type)
                console.log("cpIds are",cpIds,newController)
            }else if(inputs.type==='nfc_access'){
                newController = _.filter(props.controllersList,c=>c.al_id==inputs.location.id)
            }else{
                newController = _.filter(props.controllersList,c=>c.pl_id==inputs.location.id && c.type===inputs.type)
            }
            setControllers(newController)
        }
    },[inputs.location])
    console.log()
    return(
        <>
            {
                props.formData===undefined &&
                <CardBox styleName="">
                    <div>
                        {/* 1st row */}
                        <div className={`row`} >
                            <div className="col-lg-5 col-xl-3">
                                <FormControl fullWidth>
                                    <FormHelperText className={classes.label}>{'Type'}</FormHelperText>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={inputs.type}
                                        label="Type"
                                        name={'type'}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'main'}>Main</MenuItem>
                                        <MenuItem value={'rfid'}>Lite</MenuItem>
                                        <MenuItem value={'evCharging'}>EV Charging</MenuItem>
                                        <MenuItem value={'led'}>LED</MenuItem>
                                        <MenuItem value={'nfc'}>NFC</MenuItem>
                                        <MenuItem value={'nfc_access'}>NFC Access</MenuItem>
                                        <MenuItem value={'loop'}>Loop</MenuItem>
                                        <MenuItem value={'slot-sensor'}>Slot Sensor</MenuItem>
                                        <MenuItem value={'slot-detector'}>Slot Detector</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                <Autocomplete
                                    value = {inputs.location}
                                    id = "location"
                                    name = "location"
                                    style={{marginTop:0}}
                                    onChange={(event,value)=>{

                                        setInputs({...inputs,location:value,controller:null})
                                    }}
                                    disabled={location.length===0?true:false}
                                    options = {location}
                                    getOptionLabel={(option) => option.name?option.name:''}
                                    renderInput={(params) => (
                                        <React.Fragment>
                                            <InputLabel shrink={true} htmlFor="location" className={classes.label}>Location</InputLabel>
                                            <TextField name="location" {...params} margin="none" />
                                        </React.Fragment> 
                                    )}
                                />
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                <Autocomplete
                                    value = {inputs.controller}
                                    id = "controllerName"
                                    name = "controllerName"
                                    style={{marginTop:0}}
                                    onChange={(event,value)=>{
                                        setInputs({...inputs,controller:value})
                                    }}
                                    options = {controllers}
                                    disabled={controllers.length===0?true:false}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <React.Fragment>
                                                <InputLabel shrink={true} htmlFor="location" className={classes.label}>Controller Name</InputLabel>
                                                <TextField name="controllerName" {...params} margin="none" />
                                        </React.Fragment> 
                                    )}
                                />
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                    <FormHelperText className={classes.label}>{'Start Time'}</FormHelperText>
                                    <DateTimePicker
                                        fullWidth
                                        format="DD MMM YY - hh:mm a"
                                        value={dateRange.startDateTime} 
                                        onChange={(date)=>{
                                            setDateRange({...dateRange,startDateTime:date['_d']})
                                        }}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="zmdi zmdi-alarm"/>
                                            </InputAdornment>
                                        ),
                                        }}
                                        disableFuture
                                        minDate={new Date(moment(). subtract(15, 'days'))}
                                    />
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                <FormHelperText className={classes.label}>{'End Time'}</FormHelperText>
                                <DateTimePicker
                                    fullWidth
                                    disableFuture
                                    format="DD MMM YY - hh:mm a"
                                    value={dateRange.endDateTime} 
                                    onChange={(date)=>{
                                        setDateRange({...dateRange,endDateTime:date['_d']})
                                    }}
                                    minDate={dateRange.startDateTime?dateRange.startDateTime:new Date()}
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="zmdi zmdi-alarm"/>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-6 col-sm-12 col-12">
                                <Box mt="20px">
                                    <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{
                                        
                                        props.handleSubmit('submit',{...inputs,...dateRange})
                                        
                                    }}> Submit </Button></Box>
                                    <Box mr="10px" display="inline"><Button variant="contained" color="secondary" mr="5px" onClick={()=>{
                                        setInputs({
                                            type:'',
                                            location:null,
                                            controller:null
                                        })
                                        setDateRange({
                                            startDateTime : null,
                                            endDateTime :  null
                                        })
                                        setLocation([])
                                        setControllers([])
                                        props.handleReset()
                                    }}> Reset </Button></Box>
                                </Box>
                            </div> 
                        </div>
                    </div>
                </CardBox>
            }
        </>
    )
}
export default Form