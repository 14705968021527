import React from 'react';
// import {getReports} from './data';
import ContainerHeader from 'components/ContainerHeader';
import ReportTable from 'components/ReportTable/index';
import API from 'util/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';


class AttendantStatus extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            match: props.match,
            staffTable:{},
            ledTable:{},
            errorMessageStaff: '',
            errorMessageLed: '',
            ledState:'waiting',
            staffState:'waiting',
            appState:'loading'
        }
    }  

    componentDidMount(){        
        this.staffStatus()
        this.ledStatus()
        this.interval = setInterval(()=>{
            this.staffStatus()
        }, 60000*5); // runs every 5 min interval
        this.ledInterval = setInterval(()=>{
            this.ledStatus()
        }, 60000*3); // runs every 3 min interval
    }
    componentWillUnmount(){
        clearInterval(this.interval);
        clearInterval(this.ledInterval);
    }
    staffStatus = () =>{
        API.get('staffStatus')
        .then((res) => {
            this.setState({ staffTable: res.data.data ? res.data.data : [] , errorMessage: "",staffState:"active",appState:'active'})
        })            
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please try again"});
        })
    }
    ledStatus = () =>{
        API.get('ledStatus')
        .then((res) => {
            this.setState({ ledTable: res.data.data ? res.data.data : [] , errorMessage: "",ledState:"active",appState:'active'})
        })            
        .catch((err) => {
            // got an error
            //this.setState({errorMessage: "Please try again"});
        })
    }
    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<div> {this.renderPage()} </div>)                
        }
    }
    render() {
        return (<div> {this.renderState(this.state.appState)} </div>)
    }
    
    renderPage(){
        console.log("this.state.staffTable",this.state.staffTable)
        return(
            <div>
                
                <ContainerHeader title={'Online Status'} match={this.props.match}/>    
                { 
                    this.state.errorMessageStaff!=='' &&
                     <h3 className="row"> { this.state.errorMessageStaff } </h3> 
                }
                {
                    this.state.staffState==='waiting' 
                    ? <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div>
                    : <React.Fragment>
                        {
                            Object.keys(this.state.staffTable).length >0 &&
                            <ReportTable data = {this.state.staffTable}/>
                        }
                      </React.Fragment>
                }
                { 
                    this.state.errorMessageLed!=='' &&
                    <h3 className="row"> { this.state.errorMessageLed } </h3> 
                }
                {
                    this.state.ledState==='waiting' 
                    ? <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div>
                    : <React.Fragment>
                        {
                        Object.keys(this.state.ledTable).length >0 &&
                        <ReportTable data = {this.state.ledTable}/>
                        }</React.Fragment>
                }
            </div>
        )
    }
}
  
export default AttendantStatus;
