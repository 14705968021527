import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";


export const EZDoughnut = (props)=>{
    if(props.legend){
        ChartJS.register(ArcElement, Tooltip,Legend);

    }else{
        ChartJS.register(ArcElement, Tooltip);
    }
    return(<Doughnut data={props.data} style={props?.style} />)
}

