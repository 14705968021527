/*
 * File: index.js
 * Project: parkezdashboard
 * File Created: Monday, 03th Apr 2023 6:01:52 pm
 * Author: Binod (binod@valetez.com)
 * -----
 * Copyright - 2023 , ValetEZ Services Pvt Ltd
 */
import React, { useEffect, useState } from 'react'
import API from 'util/Api'
import SimpleTable from 'components/ActionsTable/SimpleTable'
import CardBox from 'components/CardBox'
import MsgPopover from 'components/MsgPopover'
import {Box} from '@material-ui/core'
import {SwapHoriz} from '@material-ui/icons'



const APIThrottle = (props) => {

    const [guard,setGuard] = useState(null)
    
    useEffect(()=>{
        API.get("getGuardApiThrottle")
        .then((res) => {
            console.log("parking_staff_api_throttle", res)
            setGuard(res.data.data.table)
        })
        .catch((err) => {
            console.log(err)
        })
    },[])
    console.log("guard-state",guard)
    return(
        <>
            { guard &&
            <div className="row">
                <CardBox styleName="col-12" headerOutside 
                    heading={
                        <React.Fragment>
                            {'Guard Api throttle'}
                            <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                            <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                        </React.Fragment>
                    }>                        
                       <SimpleTable type={'guardApiThrottle'} table={guard} />
                </CardBox>
            </div>
            }
        </>
    )
}

export default APIThrottle