
import React, { useState } from "react"
import GoogleMapReact from 'google-map-react'
import { makeStyles,Badge,Tooltip } from "@material-ui/core"
import mapStyles from "./mapStyles.json"

const useStyles = makeStyles( (theme) =>(
    {
        mapWrapper:{
            height: 350, 
            width: '100%'
        }
    }
))
const createMapOptions = (maps) => {
    console.log('maps',maps)
    return {
    //   panControl: false,
    //   mapTypeControl: false,
      fullscreenControl:false,
    //   scrollwheel: false,
    //   styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
        styles: mapStyles
    };
  }
const EZMarker = (props)=>{
 return(
     <div style={{
        position: 'absolute',
        top:-14,
        transform: 'translate(-50%, -50%)'
     }}>
         <Tooltip title={props.text}>
            <Badge badgeContent={props.counts}  color="secondary">
                <img {...props}/>
            </Badge>
        </Tooltip>
     </div>
    
    
 )
}
const NormalMap = (props) => {

    const classes = useStyles()   
    console.log("NormalMap",props)
    const showLocDetails = (e,i)=>{
        console.log("showLocDetails",e,i)
        
    }
    const handleApiLoaded = (map,maps) => {
        console.log("api loaded",maps.places)
        // setMaps(true)
    }
    return (
        <div className={classes.mapWrapper}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_KEY}}
             defaultCenter={(props.data.length>0)?{lng:parseFloat(props.data[0].long),lat:parseFloat(props.data[0].lat)}:{}}
             center={(props.data.length>0)?{lng:parseFloat(props.data[0].long),lat:parseFloat(props.data[0].lat)}:{}}
            defaultZoom={10}
            options={createMapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
            {
                props.data.map((item,index)=>(
                <EZMarker 
                    onClick={(e)=>showLocDetails(e,index)}
                    key={`marker-${index}`}
                    counts={item.counts}
                    text={item.name}
                    lat={parseFloat(item.lat)}
                    lng={parseFloat(item.long)} style={{width:28,height:42}} src={'/EZcharge-min.png'}
                />
                ))
            }
        </GoogleMapReact>
        </div>
      );
};

export default NormalMap