import React, { useState, useEffect} from 'react'
import {Grid,Accordion,AccordionSummary,AccordionDetails,Typography,Button,Box} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {makeStyles} from '@material-ui/core/styles'
import ActionsTable from 'components/ActionsTable'

import _ from 'lodash';
const useStyles = makeStyles( (theme) =>(
    {
        section:{
            flexBasis: '100%',
            flexShrink: 0,
            flexWrap:'wrap',
            borderBottomWidth: 1,
            borderBottomColor:'rgba(0, 0, 0, 0.2)',
            borderBottomStyle:"solid",
            paddingBottom:15,
            paddingTop:15,
            '&:nth-last-child(1)':{
                borderBottomWidth: 0,
            }
        },
        field:{
            paddingTop:10,
            paddingBottom:10
        },
        panelHeading : {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.secondary.main,
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        panelSubheading : {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
            flexBasis: '33.33%',

        },
        newOrder : {
            position:'absolute',
            fontWeight:500,
            color:'rgb(255, 0, 0)',
            right:'5%',
            top:'10px'
        },
        
        expanded : {
            backgroundColor:'#efefef'
        },
        panelRoot : {
            flexWrap:'wrap'
        },
        panelAction : {
            position: 'absolute',
            right: '5%'
        },
        addButton:{
            margin:'10px 0'
        },
        mr5:{
            float:'right',
            marginRight:'5px'
        },
        padding5:{
            padding:'0.5rem'
        }
    }
))


const DiningTables = (props) => {
    const classes = useStyles()
    const [selectedTable, setSelectedTable] = useState(props.selectedTable)
    const handleAccordian = (table) =>{
        table = selectedTable === table ? '' : table
        setSelectedTable(table)
    }
    return(
        Object.keys(props.table).map((item, key) => (
            <Accordion  expanded={selectedTable === item} onChange={() => handleAccordian(item)}>
                
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} 
                classes={{expanded:classes.expanded}} >
                    <Typography className={classes.panelHeading}>Table #{item}</Typography>
                    <Typography className={classes.panelSubheading}>  Order #{props.table[item].details.orderID}</Typography>
                    <Typography className={classes.panelSubheading}>  Order Total - {props.table[item].details.amount}</Typography>
                    {props.table[item].details.status === 'pending' && 
                    <Typography className={classes.newOrder}>
                        New
                    </Typography> }
                    {props.table[item].details.status === 'new items' && 
                    <Typography className={classes.newOrder}>
                        New Items
                    </Typography> }
                </AccordionSummary>
                <AccordionDetails classes={{root:classes.panelRoot}}>  
                    <Box className={classes.padding5} display={{xs:'none',md:'block'}}> </Box>
                    <Grid xs={12} item>

                        {props.table[item].details.status === 'pending' &&               
                            <Button className={classes.mr5}  variant="contained" onClick={(event)=>{
                                event.stopPropagation();props.updateOrderStatus('accept',{'orderID':props.table[item].details.orderID},item)
                                }} color="primary" >
                                Accept
                            </Button>
                        }
                        {
                            (props.table[item].details.status === 'active' || props.table[item].details.status === 'new items') &&
                            <Button className={classes.mr5} variant="contained" onClick={(event)=>{
                                event.stopPropagation();props.addProducts(props.table[item].details.orderID,item)
                                }} color="primary">
                                Add Product
                            </Button>
                        }
                        {props.table[item].details.allowComplete &&   
                            <Button className={classes.mr5}  variant="contained" onClick={(event)=>{
                                event.stopPropagation();props.updateOrderStatus('order_complete',props.table[item])
                                }} color="primary">
                                Complete
                            </Button>
                        }
                    </Grid>
                    <ActionsTable dineIn={true} table={props.table[item]} updateOrderStatus={props.updateOrderStatus} tableHeading={item}/>
                        
                </AccordionDetails>
                
            </Accordion> 
        ))
        
    )    
}

export default DiningTables

