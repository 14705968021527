import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik, connect } from "formik";
import { Form } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import SnackBar from "components/SnackBar";
import ReportTable from 'components/ReportTable/index';

const PassForm = connect((props) => {
    console.log('PassForm-props', props);
    useEffect(() => {
        if (props.formik.values.location) {
            const config = _.cloneDeep(props.config);
            config.fields.client.options = props.formik.values.location.clients;
            props.setConfig(config);
            props.formik.setFieldValue('client', '');
        }
    }, [props.formik.values.location])

    return (
        <div className="row">
            <CardBox styleName="col-12">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {props.config.fields && Object.values(props.config.fields).map((field) => (
                            field && !field.hidden && <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{ ...field, ...{ name: field.key } }} />
                            </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset">
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    );
})

const VisitorPass = (props) => {
    const [config, setConfig] = useState();
    const [initialValues, setInitialValues] = useState();
    const [passes, setPasses] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        message: "",
        type: "error",
    });

    const validations = Yup.object().shape({
        location: Yup.object().required("Required"),
        client: Yup.object().required("Required"),
        vehicleType: Yup.string().required("Required"),
        vehicleNo: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        name: Yup.string().required("Required"),
        startDate: Yup.string().required("Required"),
        endDate: Yup.string().required("Required")
    });

    const getFieldValues = (fields) => {
        const values = fields.reduce((obj, item) => {
            return { ...obj, [item.key]: item.value };
        }, {});
        return values;
    }

    useEffect(() => {
        API.get("visitorPass")
            .then((res) => {
                const values = getFieldValues(Object.values(res.data.data.config.fields));
                setInitialValues(values);
                setConfig(res.data.data.config);
                setPasses(res.data.data.passes);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ?? "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    const handleSubmit = (data, form) => {
        API.post("visitorPass", JSON.stringify(data))
            .then((res) => {
                setPasses(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err.response?.data?.msg ?? "Something went wrong",
                    type: "error",
                });
            });
    }

    return (
        <>
            <ContainerHeader title="Visitor Pass" match={props.match} />
            {initialValues && config &&
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validations}
                >
                    <PassForm config={config} setConfig={setConfig} />
                </Formik>}
            {passes && <ReportTable data={passes} />}
            <SnackBar
                open={snackBar.open}
                handleClose={() => {
                    setSnackBar({ open: false });
                }}
                message={snackBar.message}
                type={snackBar.type}
            />
        </>
    )
}

export default VisitorPass;