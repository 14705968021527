import React, { useState } from 'react'
import CardBox from 'components/CardBox'
import { makeStyles} from '@material-ui/core/styles'
import {Typography,FormControlLabel,TextField} from '@material-ui/core'
import JsonViewer from 'searchable-react-json-view'
const useStyles = makeStyles( (theme) =>(
    {
        headerStyle:{
            textAlign:'end',
            marginBottom:0
        },
        cardStyle:{
            padding:0
        }
    }
))

const JsonView=(props)=>{
    const [searchValue,setSearchValue] = useState('')
    const classes = useStyles()
    
    return(
        <>
        <CardBox styleName={` ${classes.cardStyle}`} headingStyle={classes.headerStyle} heading={
                    <React.Fragment>
                        <Typography style={{float:'left',textAlign:'left'}} variant={'h6'} component={'h6'}>{`${props.name}`}</Typography>
                        <FormControlLabel
                            control={<TextField autoComplete={'off'} value={searchValue} onChange={(e)=>setSearchValue(e.target.value)}  label="Search" color="primary" />}
                        />
                    </React.Fragment>}>
                        <JsonViewer highlightSearchColor={'#033e9e'} highlightSearch={searchValue} sortKeys={true} collapsed={1} collapseStringsAfterLength={15} theme={'ocean'} src={props.jsonObject} />
                    </CardBox>
        </>
        
    )
}
export default JsonView
