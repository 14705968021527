let dummyData = {
  msg: "Success",
  data: {
    config: {
      title: "Upload Build",
      key: "uploadBuild",
      required: true,
      type: "fields",
      fields: [
        {
          value: "",
          title: "Lite Controller Type",
          key: "controller_id",
          help: "",
          type: "select",
          size: "lg",
          hidden: false,
          multiSelect: false,
          options: [
            { title: "Entry Sync Controller", value: "1" },
            { title: "Exit Sync Controller", value: "2" },
            { title: "Entry Sync", value: "3" },
            { title: "Entry Sync Controller", value: "5" },
            { title: "Entry Controller", value: "7" },
            { title: "Entry Controller", value: "12" },
            { title: "Exit Controller", value: "13" },
            { title: "Entry Controller", value: "15" },
            { title: "Entry Controller", value: "16" },
            { title: "Entry Controller", value: "17" },
            { title: "Exit Controller", value: "18" },
            { title: "Exit Controller", value: "19" },
            { title: "BIN-TESt1", value: "28" },
          ],
        },
        {
          value: "",
          title: "Version",
          key: "version",
          help: "",
          type: "input",
          size: "lg",
          hidden: false,
        },
        {
          value: "",
          title: "Upload File",
          key: "build",
          help: "",
          type: "file",
          accept: ".bin",
          hidden: false,
        },
      ],
    },
  },
};
export default dummyData;