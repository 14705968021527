import React from "react";
import { Route, Switch } from "react-router-dom";
import BasicReports from "../dashboard/routes/BasicReports";
import MeetingSlot from './routes/MeetingSlot';
import AccessLocations from "./routes/AccessLocations";
import SubLocations from "./routes/AccessLocations/SubLocations";
import ConsoleUsers from "./routes/ConsoleUsers";
import RenewSubscription from "./routes/Subscription/RenewSubscription";
import UploadUsers from "./routes/Subscription/UploadUsers/index";
import UpdateSubscription from './routes/Subscription/UpdateSubscription'

const Access = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Route path={`${match.url}/renew`} component={RenewSubscription}/>
      <Route path={`${match.url}/create`} component={UploadUsers}/>
      <Route path={`${match.url}/update/:id/:type`} component={UpdateSubscription}/>
      <Route path={`${match.url}/access-meeting`} component={MeetingSlot}/>
      <Route path={`${match.url}/locations/sub-locations/:name`} component={SubLocations}/>
      <Route path={`${match.url}/locations`} component={AccessLocations}/>
      <Route path={`${match.url}/console-users`} component={ConsoleUsers}/>
      <Route path={`${match.url}/:report`} component={BasicReports} />
      {/* <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
    </Switch>
  </div>
);

export default Access;
