import React from 'react';
import {Route, Switch} from 'react-router-dom';
import RenewSubscriptions from "./routes/index";
import CreateSubscriptions from "./routes/CreateSubscriptions";
import UpdateFasTag from './routes/UpdateFasTag';
import UpdateSubscription from "./routes/EditSubscription"
import ClientsActiveBookings from "./routes/ClientsActiveBookings"
import VisitorPass from './routes/VisitorPass';

const Subscriptions = ({match}) => (
	  <div className="app-wrapper">
	    <Switch>
	    	<Route path={`${match.url}/renew-subscriptions/`} component={RenewSubscriptions}/>
			<Route path={`${match.url}/create-subscriptions/`} component={CreateSubscriptions}/>
			<Route path={`${match.url}/update-subscription/:subscrcriptionId/:subsType/:locName`} component={UpdateSubscription}/>
			<Route path={`${match.url}/update-fastag/`} component={UpdateFasTag}/>
			<Route path={`${match.url}/clients-active-bookings/`} component={ClientsActiveBookings}/>
			<Route path={`${match.url}/visitor-pass/`} component={VisitorPass}/>
	    </Switch>

	     </div>
);

export default Subscriptions;
