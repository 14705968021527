import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import ContainerHeader from "components/ContainerHeader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import API from "util/Api";
import { Box, AccordionActions, Button } from "@material-ui/core";
import { Formik, Form, FieldArray, connect } from "formik";
import PlusOneIcon from "@material-ui/icons/PlusOne";
import FormField from "components/FormField";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Yup from "yup";
import _ from "lodash";
import ToolTipIcon from "components/ToolTipIcon";
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    section: {
        flexBasis: "100%",
        flexShrink: 0,
        flexWrap: "wrap",
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.2)",
        borderBottomStyle: "solid",
        paddingBottom: 15,
        paddingTop: 15,
        "&:nth-last-child(1)": {
            borderBottomWidth: 0,
        },
    },
    field: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    row: {
        padding: "10px 0",
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    panelHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.secondary.main,
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    panelSubheading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        textAlign: "right",
    },
    expanded: {
        backgroundColor: "#efefef",
    },
    panelRoot: {
        flexWrap: "wrap",
        display: "block!important",
    },
    panelAction: {
        flex: 1,
        justifyContent: "flex-end",
    },
    addButton: {
        margin: "10px 0",
    },
}));

const getFieldValues = (fields) => {
    const values = fields.reduce((obj, item) => {
        return { ...obj, [item.key]: item.value };
    }, {});
    return values;
}

const EZField = (props) => {
    const classes = useStyles();
    return (
        props.field.type == 'group'
            ? <FieldArray name={props.field.name}
                render={arrayHelpers => (
                    <>
                        {props.value.length == 0 &&
                            <div className="col-12">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.addButton}
                                    startIcon={<PlusOneIcon />}
                                    onClick={(e) => { arrayHelpers.push(getFieldValues(props.field.fields)) }}
                                >
                                    {props.field.title}
                                </Button>
                            </div>}
                        <div className={`col-12`}>
                            <div className="row">
                                {props.value.map((value, index) => (
                                    <div className={`${classes.row}`}>
                                        {props.field.fields.map(field => (
                                            <div className={`${classes.field} col-sm-2 col-lg-1`}>
                                                <FormField field={{ ...field, ...{ name: `${props.field.name}.${index}.${field.key}` } }} />
                                            </div>))}
                                        <div className={`${classes.field} col-sm-3 col-lg-1`}>
                                            <ToolTipIcon
                                                title={`Remove ${props.field.title}`}
                                                icon={<RemoveIcon color="error" />}
                                                onClick={(e) => { arrayHelpers.remove(index) }}
                                            />
                                            {index === props.value.length - 1 &&
                                                <ToolTipIcon
                                                    title={`Add ${props.field.title}`}
                                                    icon={<AddIcon color="primary" />}
                                                    onClick={(e) => { arrayHelpers.push(getFieldValues(props.field.fields)) }}
                                                />}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}>
            </FieldArray>
            : <div className={`${classes.field} col-sm-4 col-lg-3 col-xl-${props.field.size}`}>
                <FormField field={props.field} />
            </div>
    )
}

const FormSection = connect((props) => {
    console.log('valet-FormSection-props', props);
    const values = _.cloneDeep(props.formik.values);
    const classes = useStyles();

    return (
        props.section.multiValue ?
            <FieldArray
                name={props.section.key}
                render={arrayHelpers => (
                    <>
                        {values[props.section.key].length == 0 &&
                            <div className="row">
                                <div className="col-12">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.addButton}
                                        startIcon={<PlusOneIcon />}
                                        onClick={(e) => { arrayHelpers.push(getFieldValues(props.section.fields)) }}
                                    >
                                        {props.section.title}
                                    </Button>
                                </div>
                            </div>}
                        {values[props.section.key].map((value, index) => (
                            <>
                                <div className="row" >
                                    <div className="col-12">
                                        <Typography variant="subtitle1" color="primary">{`${props.section.title} `}
                                            <ToolTipIcon
                                                title={`Remove ${props.section.title}`}
                                                icon={<RemoveIcon color="error" />}
                                                onClick={(e) => { arrayHelpers.remove(index) }}
                                            />
                                            {index === values[props.section.key].length - 1 &&
                                                <ToolTipIcon
                                                    title={`Add ${props.section.title}`}
                                                    icon={<AddIcon color="primary" />}
                                                    onClick={(e) => { arrayHelpers.push(getFieldValues(props.section.fields)) }}
                                                />}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="row">
                                    {props.section.fields.map((field) => (
                                        <EZField field={{ ...field, ...{ name: `${props.section.key}.${index}.${field.key}` } }} value={value[field.key]} />
                                    ))}
                                </div>
                                <Divider className="my-4" />
                            </>
                        ))}
                    </>
                )}>
            </FieldArray> :
            <>
                <div className="row" >
                    <div className="col-12">
                        <Typography variant="subtitle1" color="primary">{props.section.title}</Typography>
                    </div>
                </div>
                <div className="row">
                    {props.section.fields.map((field) => (
                        <EZField field={{ ...field, ...{ name: field.key } }} />
                    ))}
                </div>
                <Divider className="my-4" />
            </>
    )
})

const ModelForm = (props) => {
    console.log('ModelForm-props', props)
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [errorMessage, setErrorMessage] = useState("");
    const [isShowing, setisShowing] = useState(false);
    const [loading, setisLoading] = useState(false);

    const validations = Yup.object().shape({
        name: Yup.string().required("Required"),
        vehicle_type_id: Yup.string().required("Required"),
        subRateModel: Yup.array().of(
            Yup.object().shape({
                start_time: Yup.string().required("Required"),
                end_time: Yup.string().required("Required"),
                tarrif: Yup.array().of(
                    Yup.object().shape({
                        upfront: Yup.string().required("Required"),
                        unit_in_minutes: Yup.string().required("Required"),
                        rate_per_unit: Yup.string().required("Required"),
                        start_minutes: Yup.string().required("Required"),
                        end_minutes: Yup.string().required("Required"),
                    })
                )
            })
        )
    });

    const saveConfig = (e, { setSubmitting }) => {
        setSubmitting(true);
        setisLoading(true);

        API.post("valetRateModelsV2", { ...e, vl_id: props.locationId })
            .then((res) => {
                props.setRateModels(res.data.data);
                setErrorMessage("Configuration saved");
                setisShowing(true);
                setSubmitting(false);
                setisLoading(false);
            })
            .catch((err) => {
                setErrorMessage("Request Failed. Please Try Again!!");
                setisShowing(true);
                setSubmitting(false);
                setisLoading(false);
            });
    };

    const handleClose = () => {
        setisShowing(false);
        setErrorMessage("");
    };

    const handleCloseReject = () => {
        setisShowing(false);
        setErrorMessage("");
    };

    return (
        <>
            {loading && (
                <div>
                    <LinearProgress color="primary" />
                    <LinearProgress color="primary" />
                </div>
            )}
            {!loading && (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{ expanded: classes.expanded }}
                    >
                        <Typography className={classes.panelHeading}>
                            {props.title ? props.title : "New Rate Model"}
                        </Typography>
                        <Typography className={classes.panelSubheading}></Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.panelRoot }}>
                        <Formik
                            initialValues={props.rateModel}
                            validationSchema={validations}
                            onSubmit={saveConfig}
                        >
                            <Form>
                                {props.config.sections.map((section, index) => (
                                    <FormSection section={section} />
                                ))}
                                <div className={classes.panelAction}>
                                    <AccordionActions>
                                        <Button size="small" type="reset">Cancel</Button>
                                        <Button size="small" color="primary" type="submit">Save</Button>
                                    </AccordionActions>
                                </div>
                            </Form>
                        </Formik>
                    </AccordionDetails>
                </Accordion>
            )}
            {errorMessage !== "" && (
                <Dialog
                    open={isShowing}
                    onClose={handleClose}
                    fullWidth={true}
                    fullScreen={fullScreen}
                >
                    <DialogContent color="secondary">
                        <Typography variant={"body1"}>{errorMessage}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Box display="inline-block">
                            <Button
                                onClick={handleCloseReject}
                                variant="outlined"
                                className={classes.button}
                            >
                                OK
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

const RateModels = (props) => {
    const [config, setConfig] = useState();
    const [rateModels, setRateModels] = useState();
    const classes = useStyles();
    const locationId = props.location.state.id.value;

    useEffect(() => {
        API.get("valetRateModelsV2", {
            params: {
                vl_id: locationId,
            },
        })
            .then((res) => {
                setRateModels(res.data.data.rateModels);
                setConfig(res.data.data.config);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const addConfig = () => {
        let newRateModel = {};
        for (var index in config.sections) {
            const section = config.sections[index];
            const values = getFieldValues(section.fields);
            const newValues = section.multiValue ? { [section.key]: [values] } : values;
            newRateModel = { ...newRateModel, ...newValues };
        }
        setRateModels([...rateModels, { ...newRateModel }]);
    };

    return (
        <div>
            <ContainerHeader
                title={"Valet Rate Model - " + props.location.state.name.value}
                match={props.match}
            />
            <div className="row">
                {
                    <div className="col-12">
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.addButton}
                            startIcon={<PlusOneIcon />}
                            onClick={addConfig}
                        >
                            Rate Model
                        </Button>
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-12">
                    {rateModels && config && rateModels.map((model, index) => {
                        return (
                            <ModelForm
                                key={`sub-model-${index}`}
                                config={config}
                                index={index}
                                rateModel={model}
                                locationId={locationId}
                                title={model.name}
                                setRateModels={setRateModels}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
export default RateModels;