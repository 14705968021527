import React, {useState,useEffect} from 'react'
import {Typography,LinearProgress,Button,Box,IconButton,Dialog,DialogContent,DialogActions,DialogTitle,FormHelperText,TextField,Select,MenuItem} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles,useTheme } from '@material-ui/core/styles'
import moment from 'moment'
import CustomDateTimePicker from 'components/customDateTimePicker/CustomDateTimePicker'
import CustomDatePicker from 'components/customDateTimePicker/CustomDatePicker'

import CircularProgress from '@material-ui/core/CircularProgress'


const useStyles = makeStyles( (theme) =>(
    {
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        right10: {
            right:'10%'
        },  
       }

))
const RenewDialog =(props)=> {
    console.log("RenewDialog",props.subscription)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(props.loading);
    const [error,setErrorMessage] = useState(props.errorMessage)
    const [message,setMessage] = useState(props.message)
    const [dateChanged,setDateChanged] = useState(false)
    const [input, setInput] = useState(props.subscription)
    useEffect(() => { 
        console.log('use state',props)
        setErrorMessage(props.errorMessage)
        setMessage(props.message)
        setIsLoading(props.loading)
        setInput(props.subscription)
        setDateChanged(false)
    
     }, [props]);
     const handleInputChange = (e,val) => { 
        console.log('usdatee state')

        const {name, value} = e.target
        console.log("name - "+name+ " value - "+value+"-"+moment(value).format('DD-MM-YYYY HH:mm'))
        if(name == 'start' || name == 'end')
            setInput({...input, [name]:moment(value,'DD-MM-YYYY HH:mm').toDate()})
        else
            setInput({...input, [name]: value})
        console.log('input',input)
        // if((name === 'start' || name === 'end') && !dateChanged)
        //     setDateChanged(true)
     }
    return(
            <Dialog open={props.isShowing} onClose={props.handleClose} 
                    fullWidth={true}
                    fullScreen={fullScreen}
                    aria-labelledby="max-width-dialog-title"
                >
                <DialogTitle id="max-width-dialog-title" color="secondary"> 
                    <Box>
                        {props.header?props.header:props.dialogType==='updateVehicle'?'Update Vehicle':'Renew Subscription'}
                        <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>                   
                </DialogTitle>
                <DialogContent>
                {error !='' && <Typography paragraph={true} variant="h5"  color="secondary">{error}</Typography>}
                {message !='' && <Typography paragraph={true} variant="h5"  color="primary">{message}</Typography>}
                <div>{isLoading && <LinearProgress size={24}  />}</div>
                {!isLoading && 
                    <div>            
                        <div className="row">
                        {
                            props.dialogType=='renew' &&
                            <>
                            {/* START DATE */}
                            <div className="col-lg-5 col-sm-12 col-12">            
                                <FormHelperText className={classes.label}>Start Date</FormHelperText>
                                <CustomDateTimePicker selectedDate={input.start} minDate={input.minStart} enableFuture={true} maxDate={input.end} handleDateChange={handleInputChange} name='start' minutes={15} />                
                            </div>   
                            
                            {/* END DATE */}
                            <div className="col-lg-5 col-sm-12 col-12">
                                <FormHelperText className={classes.label}>End Date</FormHelperText>
                                <CustomDateTimePicker selectedDate={input.end} minDate={input.start} enableFuture={true} maxDate={new Date('2027-01-01')} handleDateChange={handleInputChange} name='end' minutes={15} />
                            </div>
                            {(input.amount || input.amount == 0) && <div className="col-lg-2 col-sm-12 col-12"> 
                                <FormHelperText className={classes.label}>Amount</FormHelperText>
                                <TextField name="amount" margin="none" onChange={handleInputChange} value={input.amount} fullWidth/> 
                            </div>}
                            </>
                        }
                        {
                            props.dialogType==='updateVehicle' &&
                            <>
                                <div className="col-lg-6 col-sm-12 col-12"> 
                                    <FormHelperText className={classes.label}>Vehicle No</FormHelperText>
                                    <TextField name="vehicleNo" margin="none" onChange={handleInputChange} value={input.vehicleNo} fullWidth/> 
                                </div>
                            </>
                        }
                        {
                            props.dialogType==='updateAmount' &&
                            <>
                                <div className="col-lg-4 col-sm-12 col-12"> 
                                    <FormHelperText className={classes.label}>Enter Amount</FormHelperText>
                                    <TextField name="amount" margin="none" onChange={handleInputChange} value={input.amount} fullWidth/> 
                                </div>
                            </>
                        }
                        {
                            props.dialogType==='update_client_subscription' &&
                            <>
                                <div className="col-lg-4 col-sm-12 col-12"> 
                                    <FormHelperText className={classes.label}>Client Name</FormHelperText>
                                    <TextField name="client_name" margin="none" onChange={handleInputChange} value={input.client_name} fullWidth/> 
                                </div>
                                <div className="col-lg-4 col-sm-12 col-12"> 
                                    <FormHelperText className={classes.label}>Vehicle Limit</FormHelperText>
                                    <TextField name="vehicle_limit" margin="none" onChange={handleInputChange} value={input.vehicle_limit} fullWidth/> 
                                </div>
                            </>
                        }
                        {
                            props.dialogType==='access_renew' &&
                            <>
                                {/* START DATE */}
                                <div className="col-lg-5 col-sm-12 col-12">            
                                    <FormHelperText className={classes.label}>Start Date</FormHelperText>
                                    <CustomDatePicker selectedDate={input.start} minDate={input.minStart} enableFuture={true} maxDate={input.end} handleDateChange={handleInputChange} name='start' />                
                                </div>   
                                
                                {/* END DATE */}
                                <div className="col-lg-5 col-sm-12 col-12">
                                    <FormHelperText className={classes.label}>End Date</FormHelperText>
                                    <CustomDatePicker selectedDate={input.end} minDate={input.start} enableFuture={true} maxDate={new Date('2027-01-01')} handleDateChange={handleInputChange} name='end' />
                                </div>
                            </>
                        }
                        </div>
                    </div>
                } 
            </DialogContent>
            <DialogActions>
                    {!isLoading && <div><Button onClick={props.handleClose} name="cancel" variant="outlined"  color="secondary" className={classes.right10}>
                        Cancel    
                    </Button>
                        <Button onClick={()=>{
                            setIsLoading(true) ;setErrorMessage('');setMessage('');
                            if(props.dialogType==='renew'){
                                props.renewSubscriptionData(input)
                            }else if(props.dialogType==='updateVehicle'){
                                props.updateVehicleData(input)
                            }else if(props.dialogType==='update_client_subscription'){
                                props.updateClientSubscription(input)
                            }else if(props.dialogType==='access_renew'){
                                props.renewAccess(input)
                            }else{
                                props.handleDialogCallback(input,props.dialogType)
                            }
                        }} variant="contained" color="primary" disabled={isLoading}>
                                {props.dialogType==='renew'?'Renew':props.dialogType==='access_new_client'?'Submit':'Update'}
                        </Button>
                    </div>}
                    <Box display="inline-block">
                        
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Box>
                </DialogActions>
        </Dialog>     
     )

}
export default RenewDialog

