export const DataFormater=(number)=>{
    console.log('DataFormater',number)
    if(number > 1000000000){
      return (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return (number/1000).toString() + 'K';
    }else{
      return number.toString();
    }
  }
  export const getRoundedDate = (minutes, d=new Date(),low=true)=>{  
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date((Math.round(d.getTime() / ms) - (low ? 0 :-1)) * ms);

    return roundedDate
}