import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import API from "util/Api";
import ContainerHeader from "components/ContainerHeader";
import SnackBar from "components/SnackBar";
import EZForm from "components/EZForm";
import {saveAs} from "file-saver"

const FormatReport = (props) => {
    const [config, setConfig] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        msg: "",
        type: "error",
    });
    const validations = Yup.object().shape({
        report: Yup.string().required("Required"),
        type: Yup.string().required("Required"),
    });

    const handleSubmit = (data, form) => {
        API.post("reportFormat", JSON.stringify(data))
            .then((res) => {
                form.setSubmitting(false);
                form.resetForm();
                saveAs(res.data.data);
            })
            .catch((err) => {
                console.error(err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ?? "Something went wrong",
                    type: "error",
                });
            });
    };

    useEffect(() => {
        API.get("reportFormat")
            .then((res) => {
                setConfig(res.data.data);
            })
            .catch((err) => {
                console.log("error", err);
            });
    }, [])

    return (
        <>
            <ContainerHeader title={config?.title} match={props.match} />
            {config &&
                <EZForm
                    fields={config.fields}
                    onSubmit={handleSubmit}
                    validationSchema={validations}
                />}
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false });
                }}
            />
        </>
    )
}
export default FormatReport;