/*
 * File: index.js
 * Project: parkezdashboard
 * File Created: Wednesday, 29th April 2020 4:55:14 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Wednesday, 3rd June 2020 8:56:52 am
 * Modified By: Smit (smit@valetez.com)
 * -----
 * component for mapbox maps
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import axios from 'axios';

import MapGL,{Marker} from 'react-map-gl'
import Geocoder from 'react-map-gl-geocoder'


const useStyles = makeStyles( (theme) =>(
    {
        mapWrapper :{
            height:'100%'
        },
        searchBox:{
            backgroundColor:'white'
        }
    }
))

const MapBox = (props) => {
    const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_KEY
    const mapRef = React.useRef()
    const classes = useStyles();
    const viewport = props.viewport
    const marker = props.marker

    const handleViewportChange = (newPort) => {
        console.log("handleViewportChange",viewport,newPort)
        if(newPort.fromSearch && newPort.zoom !== viewport.zoom){
            console.log("zoom changed")
            props.onMarkerChange([newPort.longitude,newPort.latitude])
        }else{
            console.log("zoom is same")
        }
        props.onViewChange({...viewport,...newPort})
        
    }
    
    // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
    const handleGeocoderViewportChange = (newPort) => {
        const geocoderDefaultOverrides = { transitionDuration: 1000,fromSearch:true }
        console.log("handleGeocoderViewportChange")
        props.onMarkerChange([newPort.longitude,newPort.latitude])
        return handleViewportChange({
        ...newPort,
        ...geocoderDefaultOverrides
        })
    }

    const onLocationChange = (e) =>{
        console.log(e.lngLat)
        props.onMarkerChange(e.lngLat)
    }

    return (
        <MapGL
          ref={mapRef}
          {...viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapStyle={"mapbox://styles/mapbox/satellite-v9"}
          >
          <Geocoder
            className={classes.searchBox}
            mapRef={mapRef}
            onViewportChange={handleGeocoderViewportChange}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            countries="IN,MY" // returns only results from india and malayasia
          />
          <Marker latitude={marker[1]} longitude={marker[0]} 
          draggable={true}
          onDragEnd={onLocationChange}
        //   offsetTop={-17.5}
          >
            <LocationOnIcon fontSize="large" color="primary"/>
            </Marker>
        </MapGL>
      )

}

const MapAPI =  axios.create({
    baseURL: `https://api.mapbox.com/geocoding/v5/`,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  

export  {MapBox,MapAPI};
