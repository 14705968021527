import React, { useState,useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader'
import CardBox from 'components/CardBox'
import {Box,Button,InputLabel,TextField,Typography,FormHelperText,LinearProgress,InputAdornment} from '@material-ui/core'
import {DateTimePicker} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles} from '@material-ui/core/styles'
import CircularProgress from 'components/Loader'
import API from '../../../../util/Api'
import _ from 'lodash'
import TerminalBox from 'components/TerminalBox'
import moment from "moment"

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }
))

const FastTag = (props) =>{
    const classes = useStyles()
    const [loc,setLoc] = useState(null)
    const [selectedLoc,setSelectedLoc] = useState(null)
    const [logs,setLogs] = useState(null)
    const [callBackLogs,setCallBackLogs] = useState(null)
    const [olderlogs,setOlderlogs] = useState({
        shouldFetch:false,
        startDateTimeFastag : '',
        startDateTimeCallback : '',
        endDateTime : '',
        type:null
    })
    const [dateRange,setDateRange] = useState({
        startDateTime : null,
        endDateTime :  null
    })
    const [intervalRange,setIntervalRange] = useState({
        startDateTime : null
    })
    const [callbackRange,setCallbackRange] = useState({
        startDateTime : null
    })
    const [showProgress,setShowProgress] = useState(false)

    useEffect(()=>{
        API.get("location").then(response=>{
            setLoc(_.filter(response.data.data,data=>data.orgId!=''))
        }).catch(error=>{})
    },[])
    const handleSubmit =(from,type=null)=>{

        let subUrl = ''
        
        if(olderlogs.shouldFetch){
            let startDateTime = olderlogs.type==='fasttag'?olderlogs.startDateTimeFastag:olderlogs.startDateTimeCallback
            subUrl = `&startTime=${startDateTime}&endTime=${olderlogs.endDateTime}`
            setOlderlogs({...olderlogs,shouldFetch:false})
        }else if(dateRange.startDateTime && dateRange.endDateTime){
            let dateRangeStartTime = moment(dateRange.startDateTime).format("x")
            let dateRamgeEndTime = moment(dateRange.endDateTime).format("x")
            setOlderlogs({...olderlogs,
                    startDateTimeFastag:dateRangeStartTime,
                    startDateTimeCallback:dateRangeStartTime
                })
            subUrl = `&startTime=${dateRangeStartTime}&endTime=${dateRamgeEndTime}`
        }

        if(type==null || type=='fasttag')
        {
            // if condition will be called every interval
            if(intervalRange.startDateTime){
                subUrl = `&startTime=${intervalRange.startDateTime}`
            }
            API.get("findlogs?type=fasttag&filter="+selectedLoc.orgId+subUrl).then(response=>{
                setShowProgress(false)
                //reset logs if submit clicked again (from!=='submit')
                if(from!=='submit' && logs && logs.length>0){
                    let serverLogs = response.data.data.data
                    let newData = _.unionBy(serverLogs,logs,'token')
                    newData = _.orderBy(newData,['timeStamp'],['asc'])
                    setLogs(newData)
                    console.log("newData",newData,logs)
                }else{
                    setLogs(response.data.data.data)
                    let min_obj = _.minBy(response.data.data.data, function(o) { return o.timeStamp; });
                    setOlderlogs({...olderlogs,
                        startDateTime:min_obj.timeStamp
                    })
                }
                //setting start time for interval or when refresh is clicked
                if(response.data.data.data && response.data.data.data.length>0){
                    let max_obj = _.maxBy(response.data.data.data, function(o) { return o.timeStamp; });
                    setIntervalRange({
                        startDateTime:max_obj.timeStamp
                    })
                } 

            }).catch(error=>{
                console.log(error)
            })
        }
            
        if(type==null || type=='callback'){
            // if condition will be called every interval
            if(callbackRange.startDateTime){
                subUrl = `&startTime=${callbackRange.startDateTime}`
            }
            API.get("findlogs?type=callback"+subUrl).then(response=>{
                setShowProgress(false)
                //reset logs if submit clicked again (from!=='submit')
                if(from!=='submit' && callBackLogs && callBackLogs.length>0){
                    let serverLogs = response.data.data.data
                    let newData = _.unionBy(serverLogs,callBackLogs,'token')
                    newData = _.orderBy(newData,['timeStamp'],['asc'])
                    setCallBackLogs(newData)
                    console.log("callBackLogsnewData",newData,callBackLogs)
                }else{
                    setCallBackLogs(response.data.data.data)
                    let min_obj = _.minBy(response.data.data.data, function(o) { return o.timeStamp; })
                    setOlderlogs({...olderlogs,
                        startDateTimeCallback:min_obj.timeStamp
                    })
                }
                //setting start time for interval or when refresh is clicked
                if(response.data.data.data && response.data.data.data.length>0){
                    let max_obj = _.maxBy(response.data.data.data, function(o) { return o.timeStamp; });
                    setCallbackRange({startDateTime:max_obj.timeStamp})
                } 

            }).catch(error=>{
                console.log(error)
            })
        }
    }
    useEffect(()=>{
        //if date range is selected , don't update logs in intervals
        if(!(dateRange.startDateTime && dateRange.endDateTime)){
            const timer = setInterval(() => {
                if(selectedLoc)
                    handleSubmit()
            }, 1000*30);
            return () => clearInterval(timer);
        }
        
    })
    //will be called when getolder logs btn is clicked from terminal
    useEffect(()=>{
        if(olderlogs.shouldFetch==true)
            handleSubmit(null,olderlogs.type)
    },[olderlogs.shouldFetch])
    const getOlderLogs = (e)=>{
        setOlderlogs({
            shouldFetch:true,
            startDateTimeFastag:e==='fasttag'?olderlogs.startDateTimeFastag-(15*60000):olderlogs.startDateTimeFastag,
            startDateTimeCallback:e==='callback'?olderlogs.startDateTimeCallback-(15*60000):olderlogs.startDateTimeCallback,
            endDateTime:e==='fasttag'?olderlogs.startDateTimeFastag:olderlogs.startDateTimeCallback,
            type:e
        })
        console.log("getOlderLogs",e)
    }
    const handleReset = () =>{
        console.log("handleReset")
        setSelectedLoc(null)
        setDateRange({
            startDateTime : null,
            endDateTime :  null
        })
        setLogs(null)
        setCallBackLogs(null)
        setCallbackRange({
            startDateTime : null
        })
        setIntervalRange({
            startDateTime : null
        })
    }
    console.log("Logs",loc,selectedLoc)
    return(
        <div>
            <ContainerHeader title={'Fastag Logs'} match={props.match}/>
            {
                loc && loc.length>0 &&
                <CardBox styleName="">
                    <div>
                        {/* 1st row */}
                        <div className={`row`} >
                            <div className="col-lg-5 col-xl-3">
                                <Autocomplete
                                    value = {selectedLoc}
                                    id = "location"
                                    name = "location"
                                    style={{marginTop:0}}
                                    onChange={(event,value)=>{
                                        if(value){
                                            setSelectedLoc(value)
                                        }else{
                                            setSelectedLoc(null)
                                        }
                                        setLogs(null)
                                        setCallBackLogs(null)
                                        setCallbackRange({
                                            startDateTime : null
                                        })
                                        setIntervalRange({
                                            startDateTime : null
                                        })
                                    }}
                                    options = {loc}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <React.Fragment>
                                                <InputLabel shrink={true} htmlFor="location" className={classes.label}>Location</InputLabel>
                                                <TextField name="location" {...params} margin="none" />
                                        </React.Fragment> 
                                    )}
                                />
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                    <FormHelperText className={classes.label}>{'Start Time'}</FormHelperText>
                                    <DateTimePicker
                                        fullWidth
                                        format="DD MMM YY - hh:mm a"
                                        value={dateRange.startDateTime} 
                                        onChange={(date)=>{
                                            setDateRange({...dateRange,startDateTime:date['_d']})
                                        }}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="zmdi zmdi-alarm"/>
                                            </InputAdornment>
                                        ),
                                        }}
                                        disableFuture
                                        minDate={new Date(moment(). subtract(15, 'days'))}
                                    />
                                </div>
                                <div className="col-lg-5 col-xl-3">
                                    <FormHelperText className={classes.label}>{'End Time'}</FormHelperText>
                                    <DateTimePicker
                                        fullWidth
                                        format="DD MMM YY - hh:mm a"
                                        value={dateRange.endDateTime} 
                                        onChange={(date)=>{
                                            setDateRange({...dateRange,endDateTime:date['_d']})
                                        }}
                                        minDate={dateRange.startDateTime}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="zmdi zmdi-alarm"/>
                                            </InputAdornment>
                                        ),
                                        }}
                                    />
                                </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-6 col-sm-12 col-12">
                                <Box mt="20px">
                                    <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{

                                        handleSubmit("submit")
                                        setShowProgress(true)
                                        setLogs(null)
                                        setCallBackLogs(null)
                                        setCallbackRange({
                                            startDateTime : null
                                        })
                                        setIntervalRange({
                                            startDateTime : null
                                        })
                                    }}> Submit </Button></Box>
                                    <Box mr="10px" display="inline"><Button variant="contained" color="secondary" mr="5px" onClick={handleReset}> Reset </Button></Box>
                                </Box>
                            </div> 
                        </div>
                    </div>
                </CardBox>
            }
            {
                showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary"/>
                    <LinearProgress color="primary"/>
                </div>
            }
            {
                loc === null &&
                    <CircularProgress />
            }
            {
              logs && logs.length>0 &&
                <TerminalBox
                    logs={logs}
                    type={'Fastag'}
                    name= {selectedLoc?selectedLoc.name:''}
                    handleRefresh={!(dateRange.startDateTime && dateRange.endDateTime)?()=>handleSubmit(null,'fasttag'):undefined}
                    getOlderLogs = {()=>getOlderLogs('fasttag')}
                    allowScroll={!olderlogs.shouldFetch}
                />
            }
            {
              logs && logs.length==0 &&
                <Typography style={{padding:15}}>{'No Fastag logs for '+selectedLoc.name}</Typography>
            }
            {
              callBackLogs && callBackLogs.length>0 &&
                <TerminalBox
                    logs={callBackLogs}
                    type={'Callback'}
                    name= {selectedLoc?selectedLoc.name:''}
                    handleRefresh={!(dateRange.startDateTime && dateRange.endDateTime)?()=>handleSubmit(null,'callback'):undefined}
                    getOlderLogs = {()=>getOlderLogs('callback')}
                    allowScroll={!olderlogs.shouldFetch}
                />
            }
            {
                callBackLogs && callBackLogs.length==0 &&
                <Typography style={{padding:15}}>{'No Callback logs for '+selectedLoc.name}</Typography>
            }
        </div>
    )
};

export default FastTag;
