
import React,{useEffect,useState} from 'react';
import { Button,InputLabel,Box,Select,MenuItem } from '@material-ui/core';
import API from 'util/Api';
import _ from "lodash";
import {saveAs} from "file-saver"

const Actions = (props) => {
    console.log("upload-subsc-props", props);
    const [uploadType,setUploadType]=useState(null);
    const [selectedClient,setSelectedClient]=useState(null);
    const [file,setFile] = useState(undefined);
    const [value,setValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(()=>{
        setFile(undefined);
        setValue(null);
        setUploadType(null)
    },[props.selectedLoc])
    const uploadExcel = (e)=>{
        
        if(file){
            const data = new FormData();
            data.append('file', file);
            data.append('location_id', props.selectedLoc.location_id);
            data.append('type',uploadType);
            data.append('client_id',selectedClient);
            setIsSubmitting(true);
            API.post('upload_access_users',data)
            .then((res) => {
                // received data        
                console.log('rcvd data',res.data)
                props.renderSnack({open:true,msg:res.data.msg,type:'success'});
                setFile(undefined);
                setValue(null);
                setUploadType(null)
                setIsSubmitting(false);
                props.updateList();
            })
            .catch((err) => {
                try{
                    props.renderSnack({open:true,msg:err.response.data.msg,type:'error'})
                }catch{
                    props.renderSnack({open:true,msg:"Something went wrong",type:'error'})
                }
                // got an error
                console.log('err',err.response)
                setIsSubmitting(false);
            })
        }else{
            props.renderSnack({open:true,msg:"Choose File",type:'error'})
        }
        console.log("uploadExcel",file)
    }
    const dwnloadSamples =(key)=>{
        var data={
            "location_id":props.selectedLoc.location_id,
            "subscription_type":key
        }
        API.get('subscriptionSampleExcel?data='+JSON.stringify(data))
        .then((res) => {
            saveAs(res.data.data);
        })
        .catch((err) => {
            // got an error
        })
    }
    return(
          <>
            <div className="row" style={{marginTop:20}}>
            <div className="col-12" style={{display:"flex",flexWrap:"wrap",marginBottom:40}}>
                <Box ><Button variant="contained" color="primary" onClick={()=>dwnloadSamples('access_clients_users')}> Download Client's User upload sample  </Button>
                </Box>
                {
                  props.selectedLoc.role=='master'&&
                    <Box ml="15px" ><Button variant="contained" color="primary" onClick={()=>dwnloadSamples('access_users')}> Download User upload sample  </Button>
                    </Box>
                }
                
            </div>
            {/* Access Type */}
                <div className={'col-sm-4 col-md-4 col-12 '}  >
                    <InputLabel id="demo-simple-select-helper-label">Select Upload Type</InputLabel>
                    <Select
                        value={uploadType}
                        onChange={(e)=>{
                            setUploadType(e.target.value);
                            setSelectedClient(null);
                            setFile(null);
                            setValue('');
                        }}
                        displayEmpty
                        fullWidth
                        inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={"client_users"}>{"Client Users"}</MenuItem>
                            {
                              props.selectedLoc.role=='master'&&
                                <MenuItem value={"users"}>{"Location Users "}</MenuItem>
                            }
                    </Select>
                </div>
            {/* Select Client */}
            {
                (uploadType && uploadType=='client_users')&&
                <div className={'col-sm-4 col-md-4 col-12 '}  >
                <InputLabel id="demo-simple-select-helper-label">Select Client</InputLabel>
                <Select
                    value={selectedClient}
                    onChange={(e)=>{
                        setSelectedClient(e.target.value);
                        setFile(null);
                        setValue('');
                    }}
                    displayEmpty
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {
                            props.selectedLoc.clients.map(client=><MenuItem value={client.id}>{client.name}</MenuItem>)
                        }
                        
                </Select>
                </div>
                
            }
            {
            (uploadType && (uploadType=='client_users' && selectedClient)||uploadType=='users')&&

                <div className={'col-sm-4 col-md-4 col-12'} style={{padding:15}} >
                    <input style={{display:'flex'}} value={value} type="file" accept=".xls,.xlsx" name="myfile" onChange={e=>{setValue(e.target.value);setFile(e.target.files[0])}}/>
                </div> 
            }
            </div>
            <div className="row" style={{marginTop:20}}>
                {(uploadType && (uploadType=='client_users' && selectedClient)||uploadType=='users')&&
                   <div className={'col-sm-4 col-md-4 col-12'}  >
                        <Button  variant="contained" color="primary" mr="5px" disabled={isSubmitting} onClick={()=>{uploadExcel()}}> Upload</Button>
                    </div> 
                }
            </div>
            
            
        </> 
    )
}

export default Actions;