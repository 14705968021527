import React, { useState, useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader'
import TerminalBox from 'components/TerminalBox'
import { Typography, LinearProgress } from '@material-ui/core'
import API from '../../../../util/Api'
import _ from 'lodash'
import Form from './Form'
import moment from "moment"


const LiteController = (props) => {

    const [response, setResponse] = useState(undefined)
    const [showProgress, setShowProgress] = useState(false)
    const [logs, setLogs] = useState(null)
    const [fetchOlderlogs, setFetchOlderlogs] = useState(false)
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [form, setForm] = useState(undefined)
    const [olderlogs, setOlderlogs] = useState({
        shouldFetch: false,
        startDateTime: '',
        endDateTime: ''
    })

    useEffect(() => {
        API.get("liteController").then(response => {
            console.log('response.data.data', response.data.data)
            setResponse(response.data.data)
        }).catch(error => {
        })

    }, [])

    const handleReset = () => {
        setLogs(null)
    }
    const fetchlogs = (macAddress, url = '', reset = false) => {
        API.get(`findLedlogs?&type=lite-controller&filter=${macAddress}${url}`).then(response => {
            setShowProgress(false)
            console.log("findlogsResponse", response.data.data)
            let serverLogs = response.data.data.data
            if (!reset && logs && logs.length > 0) {
                let newData = _.unionBy(serverLogs, logs, 'timeStamp')
                serverLogs = _.orderBy(newData, ['timeStamp'], ['asc'])
            }

            //setting start time to fetch older logs
            let min_obj = _.minBy(serverLogs, function (o) { return o.timeStamp; });
            setStartTime(min_obj?.timeStamp)
            //setting start time to fetch latest logs
            let max_obj = _.maxBy(serverLogs, function (o) { return o.timeStamp; });
            setEndTime(max_obj?.timeStamp)
            setLogs(serverLogs)

        }).catch(error => {
            setShowProgress(false)
        })
    }
    const handleSubmit = (from, data) => {
        if (data.controller) {
            let url = ''
            if (data.startDateTime && data.endDateTime) { // if date range is selected
                let dateRangeStartTime = moment(data.startDateTime).format("X")
                let dateRamgeEndTime = moment(data.endDateTime).format("X")
                url = `&startTime=${dateRangeStartTime}&endTime=${dateRamgeEndTime}`
            }
            setForm(data)
            setShowProgress(true)
            fetchlogs(data.controller.mac_address, url, true)
            console.log("handleSubmit2", url, from, data)

        } else {
            console.log("please select led")
        }

    }
    const handleRefresh = () => {
        console.log('handleRefresh')
        let url = `&startTime=${endTime}`
        setLogs(['Refreshing...']);
        fetchlogs(form.controller.mac_address, '', true);
    }
    const getOlderLogs = () => {
        let start = startTime - (30 * 60)//taking 30 min before from start time
        let url = `&startTime=${start}&endTime=${startTime}`
        setFetchOlderlogs(true)
        fetchlogs(form.controller.mac_address, url)
    }
    useEffect(() => {
        // if date range is selected, don't allow auto update
        if (logs && !(form?.startDateTime && form?.endDateTime)) {
            console.log("setIntervalll", logs)
            const timer = setInterval(() => {
                if (form.controller.mac_address)
                    fetchlogs(form.controller.mac_address);
            }, 1000 * 30);
            return () => clearInterval(timer);
        }

    }, [logs])
    console.log("LiteController", logs, form)
    return (
        <>
            <ContainerHeader title={'Lite Controller Logs'} match={props.match} />
            {
                response ?
                    <Form
                        parkingLoc={response.parkingLoc}
                        chargingLoc={response.chargingLoc}
                        accessLoc={response.accessLoc}
                        controllersList={response.controllers}
                        handleReset={handleReset}
                        handleSubmit={handleSubmit}
                    /> : <></>
            }
            {
                showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary" />
                    <LinearProgress color="primary" />
                </div>
            }
            {
                (form && logs && logs.length > 0) &&
                <TerminalBox
                    logs={logs}
                    type={'Lite Controller'}
                    name={`${form.location.name} - ${form.controller.name} (${form.controller.mac_address})`}
                    handleRefresh={() => {
                        if (!(form?.startDateTime && form?.endDateTime)) {
                            handleRefresh()
                        }
                    }} // if date range is selected then hide refresh
                    getOlderLogs={() => getOlderLogs()}
                    allowScroll={!fetchOlderlogs}
                />
            }
            {
                (form && logs && logs.length == 0) &&
                <Typography style={{ padding: 15 }} >{`${'No logs found'} for ${form.location.name} - ${form.controller.name} (${form.controller.mac_address})`}</Typography>
            }

        </>
    )
}
export default LiteController