import React from "react";

import Widget from "components/Widget/index";
const numFormatter = new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 6 })
const amountFormatter = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2,maximumFractionDigits: 2 })
const IconWithTextCard = ({data}) => {
  const {title, count, color, formatter} = data;
  return (
    <Widget styleName={`p-2 text-white`} bg={color}>
      <div className="media align-items-center flex-nowrap py-lg-2">
        <div className="media-body">
          <p className="mb-0 jr-fs-cp">{title}</p>
          <h1 className="jr-fs-xxl jr-font-weight-black mb-1 text-white">{formatter === 'amount' ? amountFormatter.format((count)) : numFormatter.format((count))}</h1>
        </div>
      </div>
    </Widget>
  );
};

export default IconWithTextCard;
