import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'util/IntlMessages';
import API from 'util/Api';
import ActionsTable from 'components/ActionsTable'

import ImageDialog from 'components/ReportTable/imageDialog'
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

class ApproveBills extends React.Component {
  constructor(props) {
      super(props);
      this.state={
          errorMessage: '',
             appState:'loading',
             open:false,
            image:[],
            selectedBill:[],
            approvedBills:[],
            pendingBills:[],
            credits:1,
            message:'',
            allowAction:false

      } 
      this.handleImageClose = this.handleImageClose.bind(this)
      this.addEZcredits = this.addEZcredits.bind(this)
      this.handleImageDialog = this.handleImageDialog.bind(this)
  }
  componentDidMount(){
        if (this.props.reportFlags===undefined) {
            localStorage.removeItem("token")
        }
        
        API.get('bills')
        .then((res) => {
            // received data        
            console.log('Bills api',res.data.data)
            
            this.setState({ approvedBills: res.data.data.approved,pendingBills: res.data.data.pending, errorMessage: "",appState : 'active'})

        })
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please Try Again"});
        })
    }
    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<div> {this.renderPage()} </div>)                
        }
    }
    render() {
        return (<div> {this.renderState(this.state.appState)} </div>)
    }
    handleImageDialog(bills){
      var allowAction = bills.ezcredits ? false : true; //Need to hide actions in image dialog for approved bills
      this.setState({open:true,image:bills.images.link,selectedBill:bills.hidden_billid,allowAction:allowAction})
    }
    addEZcredits(credits,status){
      var params = {'type':status,'bill':this.state.selectedBill,'credits':credits};
      this.setState({appState:'waiting', errorMessage: ""})
      API.post('addEzCredits', JSON.stringify(params))
        .then((res)=> {
           console.log('res',res)
            this.setState({'message':res.data.msg.msg,appState:'active'})
            if(res.data.msg.success){
              var self = this;
              API.get('bills')
              .then((res) => {
                  // received data        
                  this.setState({ approvedBills: res.data.data.approved,pendingBills: res.data.data.pending, errorMessage: "",appState : 'active'})

              })
              .catch((err) => {
                  // got an error
                  this.setState({errorMessage: "Please Try Again"});
              })
              setTimeout(function(){ self.setState({errorMessage: "",open:false,image:[],selectedBill:[],message:''}); }, 3000);

            }
          })
        .catch((err)=> {
            this.setState({'message':err.msg})

        })
    }
  handleImageClose(){
    this.setState({open:false,image:[],selectedBill:[]})
  }
    billsList() {

      return(
        <div>
          <div className="row">
              {this.state.pendingBills.length !=0 && <ActionsTable tableHeading ="Pending Bills" table={this.state.pendingBills} handleImageDialog = {this.handleImageDialog} />}
          </div>
          <div className="row">
              {this.state.approvedBills.length !=0 && <ActionsTable tableHeading ="Approved Bills" table={this.state.approvedBills} handleImageDialog = {this.handleImageDialog} />}
          </div>
         
        </div>
      )
  }
    renderPage() {
    return (
      <div className="app-wrapper">
        <ContainerHeader match={this.props.match} title={<IntlMessages id="dashboard.ApproveBills"/>}/>
        <div>
        { this.state.errorMessage!=='' &&
                  <h3 className="row"> { this.state.errorMessage } </h3> }
                {this.state.appState==='waiting' 
                ? <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div>
                : this.billsList()
                }
                <ImageDialog open={this.state.open} image={this.state.image} handleImageClose={this.handleImageClose} addCredits={this.addEZcredits} allowAction={this.state.allowAction} message={this.state.message} appState={this.state.appState} />

        </div>

      </div>
    );
  }
}

const mapStateToProps = function(state) {
    try {
        return {reportFlags : state.auth.authUser.ezcredits}
    } catch (err) {
    }
}
  
export default connect(mapStateToProps)(ApproveBills);