import React from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip, ResponsiveContainer } from 'recharts';
const numFormatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 6 })

const PieData = ({ data, legends, xLabel, yLabel }) => {
  console.log('PieData props - ', { data, legends, xLabel, yLabel })

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Tooltip formatter={(value) => numFormatter.format(value)} />
        <Pie data={data} dataKey="value" nameKey="name">
          {data.map((item, index) => (
            <Cell key={index} stroke={'#FFFFFF'} strokeWidth={data.length > 1 ? 1 : 0} fill={legends[index].color} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  )
};

export default PieData;
