import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ScanAppointments from "./routes/ScanAppointments";

const Appointments = ({match}) => (
	  <div className="app-wrapper">
	    <Switch>
	        <Route path={`${match.url}/ScanAppointments`} component={ScanAppointments}/> 
	    </Switch>

	     </div>
);

export default Appointments;