import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ViewBookings from "./routes/ViewBookings";
import ValetLocations from './routes/ValetLocations';
import ServiceLocations from './routes/ValetLocations/ServiceLocations';
import ParkingLocations from './routes/ValetLocations/ParkingLocations';
import ValetStaff from './routes/ValetStaff';
import RateModels from './routes/ValetLocations/RateModels';

const Valet = ({ match }) => (
	<div className="app-wrapper">
		<Switch>
			<Route path={`${match.url}/Reimbursements`} component={ViewBookings} />
			<Route path={`${match.url}/locations/service/rate-model`} component={RateModels} />
			<Route path={`${match.url}/locations/service`} component={ServiceLocations} />
			<Route path={`${match.url}/locations/parking`} component={ParkingLocations} />
			<Route path={`${match.url}/locations`} component={ValetLocations} />
			<Route path={`${match.url}/staff`} component={ValetStaff} />
		</Switch>
	</div>
);

export default Valet;