import React from 'react'
import {Popover, PopoverBody} from 'reactstrap';
import { Info } from '@material-ui/icons';

const ReportPopover = function(props){
    return (
        <div>      
          <Info id={props.reference} style={{color:'#f8ac40',position: 'absolute',zIndex: 1,right: '0.75em',top: '0.2em'}} onClick={() => props.togglePopover(props.reference)}
          {...(
            props.onHover ?
            {
              onMouseEnter:() =>props.togglePopover(props.reference),
              onMouseLeave:()=>props.togglePopover(props.reference),
              onTouchStart:() =>props.togglePopover(props.reference),
              onTouchEnd:()=>props.togglePopover(props.reference)
            }
            : {}
        )}
          
          
          />
          <Popover placement="bottom" isOpen={props.open} target={props.reference}>
            <PopoverBody>{props.body}</PopoverBody>
          </Popover>
        </div>
    )
}
export default ReportPopover;