import React, {Component, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Imagecarousal from './Imagecarousel';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useTheme } from '@material-ui/core/styles'
import { Box } from '@material-ui/core';


const ImageDialog =(props)=> {

    const [EZcredits, setEZcredits] = useState(0)

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleInputChange = (e) => {                
        const {name, value} = e.target        
        setEZcredits(value)
    }

    return (
        <div>
            <Dialog             
            fullScreen={fullScreen}
            open={props.open}>

            <DialogTitle>
                {"Images"}
            </DialogTitle>
            <DialogContent>
                <Imagecarousal image={props.image}/>
                {props.appState==='waiting' 
                    ? <div><LinearProgress color="primary"/><LinearProgress color="primary"/></div> 
                    : (props.addCredits && props.allowAction 
                        ? <div className="col-md-9 col-12 mt-5">
                                <InputLabel htmlFor="name">EZcredits</InputLabel>
                                <TextField name="name" margin="none" onChange={handleInputChange} value={EZcredits} fullWidth />                    
                        </div> 
                        : '')}
                    {props.message}
                    
            </DialogContent>
            <DialogActions>
                {props.addCredits && props.allowAction &&
                <React.Fragment>
                    <Button onClick={() => props.addCredits(EZcredits,'reject')} variant="contained" color="secondary">
                        Reject Request
                    </Button>
                    <Button onClick={() => props.addCredits(EZcredits,'accept')} variant="contained" color="primary">
                        Add EZcredits
                    </Button>
                </React.Fragment>
                
                }
                <Button variant="outlined" onClick={props.handleImageClose} color="primary">
                Close
                </Button>
            </DialogActions>
            </Dialog>
        </div>
    );
}

export default ImageDialog;