import React,{useEffect,useState} from 'react'
import {Form,connect } from 'formik'
import { Button,Box } from '@material-ui/core'
import FormField from 'components/FormField'
import _ from 'lodash'
import CardBox from 'components/CardBox';

const NfcAdminDetails = (props) => {

    console.log('NfcAdminDetails',props);
    const [optionList,setOptionList] = useState({})
    var config = props.config
    const getUser = () => {
        let data ={}
        for(let key in props.nfcAdmin){
            data[key]  = props.nfcAdmin[key].value
        }
        console.log("new values",data)
        console.log("old values",props.formik.values)
        return ({isSame:_.isEqual(data,props.formik.values),data:data})
    }
    const addHeaderInRates = ()=>{
        //adding header in rate models
        let selectedVehicleTypeIds = props.formik.values.vehicle_type;
        var selectedVehicleTypes = _.filter(optionList.vehicle_type,o=>selectedVehicleTypeIds.includes(o.value))
        var filteredrateModels = optionList['rate_model'];
        var rateModels = [];
        for(var i = 0; i<selectedVehicleTypes.length;i++){

            rateModels.push({value:'',title:(selectedVehicleTypes[i].title + ' Type').toUpperCase(),disabled:true});
            for(var j=0;j<filteredrateModels.length;j++){
                if(selectedVehicleTypes[i].value==filteredrateModels[j].vehicle_type_id){
                    rateModels.push(filteredrateModels[j]);
                }
            }

        }
        return rateModels;
    }
    const getLocOptions = (key) => {        
        var completeOptions = []
        var locID = props.formik.values.location
        if(locID){
            var allLocationData = _.find(config,{key:"location"})
            var options = _.find(allLocationData.options,{value:locID})[key]            
            var allOptions = _.find(config,{key:key})        
            completeOptions = allOptions.options.filter( o => options?.includes(o.value))
        }        
        console.log('getLocOptions- ',key," - ",completeOptions);
        return completeOptions;
    }
    const getVehicleOptions = (key) => {
        var locID = props.formik.values.location
        var vehIDs = props.formik.values.vehicle_type
        var options = []
        if(locID && vehIDs.length>0){
            var allData = _.find(config,{key:key}).options
            options = allData.filter(data => (data.location_id == locID && vehIDs.includes(data.vehicle_type_id)))
        }        
        return options;
    }
    //setting options for vehicle type,printer on selct of location
    useEffect(()=>{
        // console.log("change in lcoation");
        let user = getUser();
        let keys = ["vehicle_type"]
            let newOptionList = optionList
            keys.map(key => {
                newOptionList[key] = getLocOptions(key)
                if(!user.isSame){
                    props.formik.setFieldValue(key,[])
                }else{
                    props.formik.setFieldValue(key,props.formik.values[key]);
                }
            }); 
        setOptionList(newOptionList)
        
    },[props.formik.values.location]);
    useEffect(()=>{
        // console.log("change in vehicle type");
        let user = getUser();
        console.log("change based on vehicleelse")
            let keys = ["rate_model"];
            let newOptionList = optionList
            keys.map(key => {
                newOptionList[key] = getVehicleOptions(key)
                if(!user.isSame ){
                    props.formik.setFieldValue(key,[])
                }else{
                    
                    props.formik.setFieldValue(key,props.formik.values[key])
                }
            });
            var newRateModelOptions = addHeaderInRates();
            newOptionList['rate_model'] = newRateModelOptions
            setOptionList(newOptionList)
        
            
        },[props.formik.values.vehicle_type]);
    useEffect(()=>{
        if(props.nfcAdmin){
            let data ={}
            for(let key in props.nfcAdmin){
                data[key]  = props.nfcAdmin[key].value
            }
            console.log("new values",data)
            console.log("old values",props.formik.values)
            if(!_.isEqual(data,props.formik.values)){
                props.formik.setValues(data)
            }
        }
        //reset to inital on successfull submit
    },[props.nfcAdmin]) // note: run this only if props.user changes , 
                    // we can't add props.formik in dependency as it will change 
                    // when we chnage the form values
    useEffect(()=>{
        
        if(props.refresh===true){
            props.formik.resetForm();
            props.resetRefresh();
        }
    },[props.refresh])
    return(
        <>
        { Object.keys(props.config).length >0 &&
            <CardBox styleName="col-12" heading='Nfc Admin Details'>
            
                <Form>
                    {/* FIRST ROW */}            
                    <div className="row">
                        { props.config.map(field => (
                            <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField 
                                    field={{
                                        ...field,
                                        ...{name:field.key},
                                        ...{options: optionList[field.key] || field.options },
                                        ...{disabled: (!optionList[field.key] || optionList[field.key].length) ? false : true }
                                    }}
                                />
                            </div>
                        ))
                        }                 
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">   
                    <div className="col-lg-6 col-sm-12 col-12">                    
                        <Box mt="20px">
                            <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" > Submit </Button></Box>
                            <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset">Reset</Button></Box>
                        </Box>
                    </div> 
                    </div>
                </Form>
            </CardBox>
        }
        </>
    )
}

export default connect(NfcAdminDetails)