import React from 'react';
// import {getReports} from './data';
import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/CardBox';
import API from 'util/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetClientsByLocation from './GetClientsByLocation';
import { Typography } from '@material-ui/core';
import UploadSubscription from './UploadSubscription';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import SnackBar from 'components/SnackBar';

class CreateSubscriptions extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            appState:'loading',
            data:[],
            selectedLoc:null,
            snackBar:{open:false,msg:'',type:'error'}
        }
    }  

    getData(){
        API.get("locationClients")
          .then((res) => {
            this.setState({
              appState: "active",
              data: res.data.data,
            });
            // received data
            console.log("rcvd data", res.data.data);
          })
          .catch((err) => {
            // got an error
          });
    }

    componentDidMount(){         
        this.getData();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.data !== this.state.data && prevState.selectedLoc){
            this.setState({
              selectedLoc: _.find(this.state.data, { id: prevState.selectedLoc.id }),
            });
        }
    }

    onSubmit=(loc)=>{
        if(loc){
            this.setState({selectedLoc:_.find(this.state.data,{id:loc.id})})
        }else{
            this.setState({snackBar:{open:true,msg:'Select Location',type:'error'}})
        }
        console.log("onSubmit-clicked",loc)
    }
    reset=()=>{
        this.setState({selectedLoc:null})
    }
    dwnloadSamples =(key)=>{
        var data={
            "location_id":this.state.selectedLoc.id,
            "subscription_type":key
        }
        API.get('subscriptionSampleExcel?data='+JSON.stringify(data))
        .then((res) => {
            saveAs(res.data.data);
            // received data        
            console.log('rcvd data',res.data.data)
        })
        .catch((err) => {
            // got an error
        })
    } 
    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
                return(<React.Fragment> {this.renderPage()} </React.Fragment>)                
        }
    }
    render() {
        return (
            <React.Fragment> 
                {this.renderState(this.state.appState)} 
            </React.Fragment>
        )
    }

    renderPage(){
        console.log("renderPage",this.state);
        return(
            <div>
                <ContainerHeader title="Create Subscriptions" match={this.props.match} />    
                <div className="row">
                    <CardBox styleName="col-12">
                        <GetClientsByLocation
                            reset = {this.reset.bind(this)}
                            data = {this.state.data}
                            onSubmit={this.onSubmit.bind(this)}
                        />
                    </CardBox>
                    {
                    this.state.selectedLoc &&
                        <CardBox styleName="col-12">
                            <>
                            <Typography variant={'h5'} style={{marginBottom:25}}>{this.state.selectedLoc.location_name}</Typography>
                            <UploadSubscription
                                selectedLoc={this.state.selectedLoc}
                                dwnloadSample={this.dwnloadSamples.bind(this)}
                                renderSnack={(snack)=>{this.setState({snackBar:snack})}}
                                updateList={this.getData.bind(this)}
                            />
                            </>
                        </CardBox>
                    }
                    <SnackBar 
                        open={this.state.snackBar.open} 
                        handleClose={()=>{this.setState({snackBar:{open:false,msg:'',type:''}})}}
                        message={this.state.snackBar.msg}
                        type={this.state.snackBar.type}
                    />
                </div>   
            </div>
        )
    }
}
export default CreateSubscriptions;
