import React, { useEffect, useState } from 'react' 
import {Workbox} from 'workbox-window'
import { Button, Box } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import Typography from "@material-ui/core/Typography";
import SnackbarContent from '@material-ui/core/SnackbarContent';

const AppUpdate = (props) => {

    const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
    const actionReload = (
        <Button size="small" 
            onClick={()=>window.location.reload()}
            >Reload</Button>
    )
    const actionComp = (
        <React.Fragment>
            <Box mr={2}>
            <Button size="small" 
            style={{
                color:'black'
            }}
            onClick={()=>setOpen(false)}
            >
                Ignore
            </Button></Box>
            <Button size="small" 
            style={{
                color:'#f9a825',
                border:'solid 1px #f9a825'
            }}
            variant="outlined"
            onClick={()=> {
                setMsg('Waiting for page to reload. If it is taking too long please click below')
                setAction(actionReload)
                console.log('SERVICE-WORKER -- SKIP_WAITING')
                wb.messageSW({type: 'SKIP_WAITING'})
                // ios safari does not send a controlling event
                // we are not able to close the snackbar
                setTimeout(() => {
                    console.log("lets close the popup")
                    setOpen(false)
                }, 2000);
            }}
            >
                Update Now
            </Button>
        </React.Fragment>        
      );

    const [complete,setComplete] = useState(false)  
    const [open,setOpen] = useState(false)
    const [action,setAction] = useState(actionComp)
    const [msg,setMsg] = useState('A new version of this app is available.')
    const wb = new Workbox(swUrl);

    const WBSetup = () =>{
        if ('serviceWorker' in navigator) {
            setComplete(true)
            wb.addEventListener('activated', (event) => {
                console.log("SERVICE-WORKER -- Activated called",event.isUpdate)
                // `event.isUpdate` will be true if another version of the service
                // worker was controlling the page when this version was registered.
                if (!event.isUpdate) {
                  console.log('Service worker activated for the first time!');
              
                  // If your service worker is configured to precache assets, those
                  // assets should all be available now.
                }
            });
            wb.addEventListener('waiting', (event) => {
                console.log("SERVICE-WORKER -- Waiting ",event.isUpdate)
                console.log('A new service worker has installed, and waiting')
                if(event.isUpdate) setOpen(true)                
            });
            wb.addEventListener('controlling', (event) => {
                console.log("SERVICE-WORKER -- Controlling called")
                if(event.isUpdate)
                    window.location.reload();
            });
            console.log("SERVICE-WORKER -- Register the service worker")
            wb.register();
        }
    }

    useEffect(()=>{
        console.log("SERVICE-WORKER -- component loaded")
        if(!complete){
            console.log("SERVICE-WORKER -- call setup")
            WBSetup()
        }
    })    

    return(
        <Snackbar
            open={open}
            style={{
                position:'fixed',
                bottom:20,
                margin:'auto 10px',        
            }}
        >
            <SnackbarContent
                style={{
                    background:'white',
                    borderRadius:4,
                    padding:'10px 20px'
                }}
                message={ <Typography style={{color:'black'}}>{msg}</Typography> }
                action={action}
            />
        </Snackbar>
    )
}

export default AppUpdate
