
import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import CheckinAppUserConfig from './CheckinAppUserConfig'
import ActionsTable from 'components/ActionsTable/TableList'
import CircularProgress from 'components/Loader'
import SnackBar from 'components/SnackBar'
import API from 'util/Api'
import * as Yup from "yup"
import _ from "lodash"
import PopUpDialog from '../../../../../components/PopUpDialog'
import { Select,MenuItem, FormControl,InputLabel } from '@material-ui/core'

const checkVehicleType = (values, config) => {
    var flag = true;
    if (values.rate_model?.length > 0) {
        var conigRateModels = _.find(config, { key: "rate_model" }).options;
        var selectedRatemodels = _.filter(conigRateModels, o => values.rate_model==o.value);
        var vehicleType = values.vehicle_type;
        var is_ratemodel = [];
        for (var w in vehicleType) {
            for (var x in selectedRatemodels) {
                if (vehicleType[w] == selectedRatemodels[x].vehicle_type_id) {
                    if (is_ratemodel.indexOf(selectedRatemodels[x].vehicle_type_id) == -1) {
                        is_ratemodel.push(selectedRatemodels[x].vehicle_type_id);
                    }
                }
            }
        }
        if (is_ratemodel.length != vehicleType.length) {
            flag = false;
        }
        console.log("validationrate_model", vehicleType, is_ratemodel, flag);
    }
    console.log("validationrate_model", flag);
    return flag;
}
const SignupSchema = (config) => {
    return Yup.lazy(values => {
        console.log("SignupSchema", config, values);
        return Yup.object().shape({
            fname: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Required'),
            location_id: Yup.object()
                .required('Required'),
            vehicle_type: Yup.string()
                .required('Required'),
            rate_model: Yup.string()
                .required("Required")
                .test('global-ok', 'should have all vehicle type',
                    function () {
                        return checkVehicleType(values, config);
                    }),
            email: Yup.string()
                .email('Invalid email')
                .required('Required'),
            password: ('id' in values && values.password === undefined) ?
                Yup.string()
                    .notRequired() : Yup.string()
                        .required('Password is required'),
            confirm_password: ('id' in values && values.password === undefined) ? Yup.string()
                .oneOf([Yup.ref('password')], "Passwords do not match") :
                Yup.string()
                    .oneOf([Yup.ref('password')], "Passwords do not match")
                    .required('Confirm password is required'),
        });
    });

}
const GuardDropdown = (props)=>{
    const [value,setvalue] = useState(props.barrierGuard)

    return(
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Guard</InputLabel>
            <Select name={'barrierGuard'} fullWidth value={value} 
                onChange={e=>{
                    console.log("guard select",e)
                    setvalue(e.target.value)
                    props.updateGuard(e.target.value)
                    }} >
                {
                    props.guardList?.map(item=><MenuItem key={`k-${item.value}`} value={item.value} >{item.title}</MenuItem>)
                }
                
            </Select>
        </FormControl>
    )
}
const CheckinAppUser = (props) => {

    const [config, setConfig] = useState(null)
    const [values, setValues] = useState(null)
    const [user, setUser] = useState(null)
    const [staffList, setStaffList] = useState(null)
    const [snackBar, setSnackBar] = useState({ open: false, msg: '', type: 'error' });
    const [resetF, setResetF] = useState(false)
    const [barrierGuard,setBarrierGuard] = useState("")
    const [dialogState,setDialogState] = useState({
        open:false,
        header:'',
        content:'',
        agreeBtnTxt:'',
        disAgreeBtnTxt:'',
        selectedSusbcription:{}
    })
    
    const saveStaff = (e, { setSubmitting }) => {
        console.log("save config", e)
        API.post("checkinAppUsers", JSON.stringify(e))
            .then((res) => {
                console.log("checkinAppUsers", res);
                setSnackBar({ open: true, msg: res.data.msg, type: 'success' });

                setStaffList(res.data.data);
                resetStaff()
                //setUser(null);
                setSubmitting(false)
            })
            .catch((err) => {
                try {
                    setSnackBar({ open: true, msg: err.response.data.msg, type: 'error' });
                } catch {
                    setSnackBar({ open: true, msg: "Something went wrong", type: 'error' });
                }
                console.log("catch", err);
                setSubmitting(false)
            })

    }

    const resetStaff = () => {
        // todo: do we need to do anything here?
        setUser(null);
        setResetF(true);
    }

    useEffect(() => {
        API.get("checkinAppUsers")
            .then((res) => {
                console.log("checkinAppUsers", res, res.data.data.config)
                setConfig(Object.values(res.data.data.config));
                setStaffList(res.data.data.checkinAppUsers);
                let data = {}
                var fields = ["vehicle_type", "rate_model"];
                for (let key in res.data.data.config) {
                    data[key] = res.data.data.config[key].value
                }
                console.log('values', data);
                setValues(data);
            })
            .catch((err) => {
                console.log("catch", err);
            })
    }, [])


    const handleStaffEdit = (clickedUser) => {
        console.log("edit ", clickedUser)
        setUser(clickedUser)
        // scroll to top 
        document.body.style.height = 'auto'
        setTimeout(function () {
            document.body.style.height = '100%'
        }, 50)
    }
    const openGuardDialog = (e) =>{
        console.log("openGuardDialog",e.guard_id.value)
        API.get("getLocationGuard?parking_location_id="+e.location_id.value.value)
        .then((res) => {
            
            let guardList = res.data.data.guard_list
            var header = 'Update Barrier Guard' 
            setBarrierGuard(e.guard_id.value)
            setDialogState({
                open:true,
                header:header,
                content:<GuardDropdown barrierGuard={e.guard_id.value} guardList={guardList} updateGuard={(value)=>setBarrierGuard(value)} />,
                agreeBtnTxt:'Update',
                disAgreeBtnTxt:'Cancel',
                selectedCheckinUser:e
            })
        })
        
    }
    const updateBarrierGuard = () =>{
        const postData = {
            guard_id:barrierGuard,
            checkin_user_id:dialogState.selectedCheckinUser.id.value
        }
        API.post("attachGuardToCheckinUser",postData).then((res) => {
            setBarrierGuard("")
            setSnackBar({ open: true, msg: res.data.msg, type: 'success' });
            setDialogState({selectedCheckinUser:{},open:false,dialogAgreeBtnTxt:'',dialogDisAgreeBtnTxt:'',dialogContent:'',dialogHeader:''})
        })
    }

    return (
        <>
            {config && values &&
                <Formik
                    onSubmit={saveStaff}
                    onReset={resetStaff}
                    initialValues={values}
                    validationSchema={SignupSchema(config)}
                    resetInitalValue={true}
                >
                    <>
                        <CheckinAppUserConfig
                            config={config}
                            user={user}
                            resetFormik={resetF}
                            setFalse={() => { console.log('setResetF'); setResetF(false) }}
                        />
                        {staffList &&
                            <div className="row">
                                <ActionsTable
                                    tableHeading="Checkin App User's"
                                    table={staffList}
                                    onEdit={handleStaffEdit}
                                    attachGuard={openGuardDialog}
                                    actions={true}
                                />
                            </div>
                        }
                    </>
                </Formik>
            }
            {
                !config && !values && <CircularProgress />
            }
            <SnackBar
                open={snackBar.open}
                handleClose={() => { setSnackBar({ open: false, msg: '', type: 'error' }) }}
                message={snackBar.msg}
                type={snackBar.type}
            />
            <PopUpDialog 
                {...dialogState}
                agreeClicked ={updateBarrierGuard}
                handleDialogClose={()=>{
                    setBarrierGuard("")
                    setDialogState({selectedCheckinUser:{},open:false,dialogAgreeBtnTxt:'',dialogDisAgreeBtnTxt:'',dialogContent:'',dialogHeader:''})
                }}
            />
        </>
    )
}

export default CheckinAppUser