import React from 'react';
import ContainerHeader from 'components/ContainerHeader';
import API from 'util/Api';
import _ from 'lodash'
import QrScanner from 'components/ActionsTable/QrScanner'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/AspectRatio';
import ReportCounts from 'components/Cards/ReportCounts'
import ActionsTable from 'components/ActionsTable'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
class ViewBookings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowing: false,
            pointsModel:[],
            errorMessage:'',
            maxCredits:[{
                'title':'Credits Remaining',
                'count':0,
                'color':'#0092dd'
            }],
            creditHistory:[]
        }
        this.reimburseBooking = this.reimburseBooking.bind(this)
        this.redirectPaymentStatus = this.redirectPaymentStatus.bind(this)
        this.rechargeCredits = this.rechargeCredits.bind(this)
    }
    componentDidMount(){
        
         API.get('creditModel')
        .then((res) => {
            // received data        
            this.setState({ pointsModel: res.data.data.creditsModel ? res.data.data.creditsModel : [] ,  errorMessage: "",maxCredits:[{'title':'Credits Remaining','count':res.data.data.totalCredits,'color':'#0092dd'}],creditHistory:res.data.data.creditHistory,appState:'active'})
        })
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please try again"});
        })
    }
    reimburseBooking(booking,creditModel,credits){
        API.post('reimburse', JSON.stringify({'vehicleNo':booking[0],'phone':booking[1],'otp':booking[2],'model':creditModel,'credits':credits}))
        .then((res)=> {
           if(res.data.error){
            this.setState({errorMessage: res.msg.msg});
           } else {   
              this.setState({message: 'Booking Successfully Reimbursed',maxCredits:[{'title':'Credits Remaining','count':res.data.data.totalCredits,'color':'#0092dd'}],loading:false,creditHistory:res.data.data.creditHistory});
              var self=this;
              setTimeout(function(){
                  self.setState({isShowing: false});
              },1000)  
            }          
        })
        .catch((err)=> {
            this.setState({errorMessage: err.response.data.msg,loading:false})
        })
    }
    redirectPaymentStatus(response){
        this.setState({appState:'waiting',creditHistory:[]});
        
        let postData = {
             paymentId:response.razorpay_payment_id,
             orderId:response.razorpay_order_id,
             signature : response.razorpay_signature
         }        
         API.post('paymentCallback',postData
         ).then((body) => {
             if(body.status===200){
                 this.setState({maxCredits:[{'title':'Credits Remaining','count':body.data.data.totalCredits,'color':'#0092dd'}],loading:false,creditHistory:body.data.data.creditHistory,appState:'active'})
             }else{
                 this.setState({errorMessage: body.msg.msg,appState:'active'});
             }
         })
         .catch((error) => {
             this.setState({errorMessage: "please try again",appState:'active'});
         });
     }
    rechargeCredits(credits){
        var self=this;
        this.setState({errorMessage: '',appState:'waiting'});

        API.post('initiatePayment', JSON.stringify({'amount':credits}))
        .then((res)=> {
           if(res.data.error){
            this.setState({errorMessage: res.msg.msg,appState:'active'});
           } else {   
                var options = {
                    "key": "rzp_live_WvzQj6YOenkt1i", // Enter the Key ID generated from the Dashboard
                    "amount": parseFloat(res.data.data.amount)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    "currency": "INR",
                    "name": "ValetEZ",
                    "description":"Smart Parking Solutions",
                    "order_id": res.data.data.orderId,
                    "image": require("assets/images/logo_v.png"),
                    "handler": function (res){
                        console.log("response",res);
                        if(Object.keys(res).length>0 && res.razorpay_payment_id.length>0){
                            self.redirectPaymentStatus(res)
                        } else {
                            this.setState({errorMessage: "please try again",appState:'active'});
                        }
                    },
                    "prefill": {
                        "name": res.data.data.firstName,
                        "email": res.data.data.email,
                        "contact":res.data.data.phone
                    },
                    "notes": {
                        "address": "Razorpay Corporate Office"
                    },
                    "theme": {
                        "color": "#F9a825"
                    }
                };
                console.log("razorpay called",options)
                var rzp1 = window.Razorpay(options);
                rzp1.open();
            }          
        })
        .catch((err)=> {
            this.setState({errorMessage: err.response.data.msg,appState:'active'})
        })
    }
    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<div> {this.renderPage()} </div>)                
        }
    }
    render() {
        return (<div> {this.renderState(this.state.appState)} </div>)
    }
    renderPage() {
        return(
            <div>
                <ContainerHeader title="Scan Bookings" match={this.props.match}/>
                {this.state.maxCredits.length !=0 && <ReportCounts data={this.state.maxCredits} rechargeCredits={this.rechargeCredits} /> }
                {this.state.errorMessage !='' && <Typography paragraph={true} variant="h3"  color="secondary">{this.state.errorMessage}</Typography>}
                <div className="row">
                    {this.state.creditHistory.length !=0 &&  <ActionsTable tableHeading ="Credit History" table={this.state.creditHistory} />}
                </div>
                <Fab style={{position:'fixed',right:5,bottom:5}}onClick={() =>this.setState({isShowing:true})} color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
               
                {this.state.isShowing  && 
                <QrScanner isShowing = {this.state.isShowing} 
                           handleClose={()=>{this.setState({isShowing:false,errorMessage:''})}} 
                           message={this.state.message}
                           errorMessage={this.state.errorMessage}
                           loading={this.state.loading}
                           reimburse={this.reimburseBooking}
                           type = 'reimbursements'
                           pointsModel={this.state.pointsModel}
                />
                                        
                }                  
            </div>
        )
    }
}

  
export default (ViewBookings);