import React, { useEffect, useState } from "react";
import EVChargingPointDetails from "./EVPointDetails";
import API from "util/Api";
import ActionsTable from "components/ActionsTable/TableList";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import * as Yup from "yup";
import _ from "lodash";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import CardBox from "components/CardBox";
// import dummyData from "./dummy";
import { Card, CardHeader, Box, Grid } from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';
import PopUpDialog from 'components/PopUpDialog';

const StatusSummary = (props) => {
  return (
    <div className="col-12">
      <div className="row">
        {props.summary &&
          props.summary.map((item, index) => (
            <CardBox styleName="col-md-4" cardStyle={'py-0 px-2'}>
              <CardHeader
                avatar={<OfflineBoltIcon color="primary" style={item.avatarStyle} />}
                title={item.value}
                subheader={item.title}
                titleTypographyProps={{ variant: 'h6' }}
              />
            </CardBox>
          ))}
      </div>
    </div>
  );
};

const EVChargingPoints = () => {
  const [values, setValues] = useState(null);
  const [config, setConfig] = useState(null);
  const [point, setPoint] = useState(null);
  const [chargingPoints, setChargingPoints] = useState(null);
  const [chargerStatus, setChargerStatus] = useState();
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const [dialog, setDialog] = useState({open:false,content:'',header:'',agreeBtnTxt:'',disAgreeBtnTxt:'', type:'', callback:null});
  const validations = Yup.object().shape({
    name: Yup.string().required("Required"),
    location_id: Yup.object().required("Required"),
    vehicle_type_id: Yup.object().required("Required"),
    charger_code: Yup.string().length(4, "Invalid code!").required("Required"),
    capacity: Yup.string().required("Required").test("global-ok", "Only numbers allowed",(value) => !isNaN(value))
  });

  const saveChargingPoint = (data, form) => {
    API.post("chargingPoints", JSON.stringify(data))
      .then((res) => {
        setPoint(null);
        form.setSubmitting(false);
        setSnackBar({ open: true, msg: res.data.msg, type: "success" });
        setChargingPoints(res.data.data.chargingPoints);
        form.resetForm();
      })
      .catch((err) => {
        console.log("error", err);
        form.setSubmitting(false);
        setPoint(null);
        try {
          setSnackBar({
            open: true,
            msg: err.response.data.msg,
            type: "error",
          });
        } catch {
          setSnackBar({
            open: true,
            msg: "Something went wrong",
            type: "error",
          });
        }
      });
  };
  const getData = () => {
    API.get("chargingPoints")
      .then((res) => {
        // res.data=dummyData;
        if (config === null) {
          setConfig(Object.values(res.data.data.config.sections));
          let data = {};
          for (let section in res.data.data.config.sections) {
            for (let key in res.data.data.config.sections[section].fields) {
              data[res.data.data.config.sections[section].fields[key].key] =
                res.data.data.config.sections[section].fields[key].value;
            }
          }
          setValues(data);
        }
        setChargingPoints(res.data.data.chargingPoints);
        let status = [
          {
            title: "OFFLINE",
            avatarStyle: { color: red[500], fontSize: 50 },
            icon: OfflineBoltIcon,
            value: 0,
          },
          {
            title: "ONLINE",
            avatarStyle: { color: green[500], fontSize: 50 },
            icon: OfflineBoltIcon,
            value: 0,
          },
          {
            title: "TOTAL",
            avatarStyle: { fontSize: 50 },
            icon: OfflineBoltIcon,
            value: res.data.data.chargingPoints.body.length,
          },
        ];
        for (let key in res.data.data.chargingPoints.body) {
          if (
            res.data.data.chargingPoints.body[key].status.value === "Offline"
          ) {
            status[0].value++;
          } else {
            status[1].value++;
          }
        }
        setChargerStatus(status);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 900000);
    return () => clearInterval(interval);
  }, []);

  const handlePointEdit = (clickedPoint) => {
    setPoint(clickedPoint);
    // scroll to top
    document.body.style.height = "auto";
    setTimeout(function () {
      document.body.style.height = "100%";
    }, 50);
  };

  const updateStatus = (id, status, cb) => {
    API.put(
      "chargingPointStatus",
      JSON.stringify({ id: id, active: status })
    )
      .then((res) => {
        setSnackBar({ open: true, msg: res.data.msg, type: "success" });
        cb(status)
      })
      .catch((err) => {
        console.log("error", err);
        try {
          setSnackBar({ open: true, msg: err.response.data.msg, type: "error" });
        } catch {
          setSnackBar({ open: true, msg: "Something went wrong", type: "error" });
        }
      });
    handleDialogClose()
  };

  const updateActiveStatus = (id, checked, cb)=> {
    let chargingPoint = _.find(chargingPoints.body, (chargingPoint) => chargingPoint.id.value === id);

    setDialog({
        open:true,
        header:`${checked == true ? 'Activate': 'Deactivate'} Charging Point`,
        content:`Are you sure you want to ${checked ? 'activate': 'deactivate'} ${chargingPoint.name.value}?`,
        agreeBtnTxt:'Confirm',
        disAgreeBtnTxt:'Cancel',
        type:'updateActiveStatus',
        chargingPoint:{id:id, active:checked},
        callback:cb
    });
}

const confirmDialogSubmit = (type) =>{
    if(type === 'updateActiveStatus'){
      updateStatus(dialog.chargingPoint.id, dialog.chargingPoint.active, dialog.callback);
    }
}

const handleDialogClose = ()=>{
    setDialog({
        open:false,
        content:'',
        header:'',
        agreeBtnTxt:'',
        disAgreeBtnTxt:'',
        type:'',
        callback:null
    });
}

  return (
    <>
      {config && values && (
        <Formik
          onSubmit={saveChargingPoint}
          initialValues={values}
          validationSchema={validations}
        >
          <>
            <EVChargingPointDetails config={config} point={point} />
            <StatusSummary summary={chargerStatus} />
            {chargingPoints && (
              <ActionsTable
                tableHeading="Charging Points"
                table={chargingPoints}
                onEdit={handlePointEdit}
                updateStatus={updateActiveStatus}
                actions={true}
              />
            )}
          </>
        </Formik>
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false, msg: "", type: "" });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
      <PopUpDialog
        fullWidth
        open={dialog.open}
        header={dialog.header}
        content ={dialog.content}
        agreeBtnTxt ={dialog.agreeBtnTxt}
        disAgreeBtnTxt={dialog.disAgreeBtnTxt}
        agreeClicked ={() =>confirmDialogSubmit(dialog.type)}
        handleDialogClose={handleDialogClose}
      />
    </>
  );
};
export default EVChargingPoints;
