import React, { useEffect, useState } from "react"
import _ from "lodash"
import ContainerHeader from "components/ContainerHeader"
import API from "../../../../../util/Api"
import {Box,Button,InputLabel,TextField, Typography,LinearProgress} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SimpleTable from 'components/ActionsTable/SimpleTable'
import CardBox from 'components/CardBox'
import MsgPopover from 'components/MsgPopover'
import {SwapHoriz} from '@material-ui/icons'
import { makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }
))
const FasTagError = (props) => {

  const [parkingLoc,setParkingLoc] = useState([])
  const [selectedLoc,setSelectedLoc] = useState(null)
  const [showProgress,setShowProgress] = useState(false)
  const [data, setData] = useState(undefined)
  const classes = useStyles()

  useEffect(() => {
    //charging locations
    API.get("location")
    .then((res) => {
      console.log("parkingLocations-apiResponse", res);
      setParkingLoc(res.data.data ? res.data.data : [])
    })
    .catch((err) => {
      // got an error
    });

  }, []);
  const handleSubmit = ()=>{
    if(!selectedLoc?.id){
        //add validation here
        console.log("select location")
    }else{
        setShowProgress(true)
        API.get("fastag_error?pl_id="+selectedLoc.id)
        .then((res) => {
            setData(res.data.data)
            setShowProgress(false)
        })
        .catch((err) => {});
    }
    
  }
  return (
    <>
      <ContainerHeader title={`FASTag Error`} match={props.match} />
            <CardBox styleName="">
                <div>
                    {/* 1st row */}
                    <div className={`row`} >
                        <div className="col-lg-4 col-xl-4 col-xs-6">
                            <Autocomplete
                                value = {selectedLoc?selectedLoc:null}
                                id = "location"
                                name = "location"
                                style={{marginTop:0}}
                                onChange={(event,value)=>{
                                    if(value)
                                        setSelectedLoc(value)

                                }}
                                options = {parkingLoc}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <React.Fragment>
                                        <InputLabel shrink={true} htmlFor="location" className={classes.label}>Location</InputLabel>
                                        <TextField name="location" {...params} margin="none" />
                                    </React.Fragment> 
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{
                                    handleSubmit()
                                    setShowProgress(true)
                                }}> Submit </Button></Box>
                            </Box>
                        </div> 
                    </div>
                </div>
            </CardBox>
            {
                showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary"/>
                    <LinearProgress color="primary"/>
                </div>
            }
            {
                data&&
                <div className="row">
                    <CardBox styleName="col-12" headerInside 
                        heading={
                            <React.Fragment>
                                {'Tags'}
                            </React.Fragment>
                        }>                        
                        <SimpleTable type={'fastagError'} table={data.fastag_errors} />
                        
                    </CardBox>
                </div>
            }
            
    </>
  );
};
export default FasTagError;
