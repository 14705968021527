import React from 'react';
import {Route, Switch} from 'react-router-dom';

import BasicReports from "./routes/BasicReports";
import Requests from './routes/RequestReport';
import ParkingOverView from './routes/ParkingOverview';
import ParkingSlots from './routes/ParkingSlots';

const Dashboard = ({match}) => (
  <div className="app-wrapper">
    <Switch>
     <Route path={`${match.url}/requests`} component={Requests}/>
     <Route path={`${match.url}/parking-overview`} component={ParkingOverView}/>
     <Route path={`${match.url}/parking-slots`} component={ParkingSlots}/>
     <Route path={`${match.url}/:report`} component={BasicReports}/>
     {/* <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
    </Switch>

     </div>
);

export default Dashboard;
