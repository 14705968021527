import React, {Component} from 'react';
import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/CardBox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'
import API from 'util/Api';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button'
import OrderDetails from './OrderDetails.js'
import _ from 'lodash'
import { connect } from 'react-redux';
import MsgPopover from 'components/MsgPopover'
import {SwapHoriz} from '@material-ui/icons'
import Box from '@material-ui/core/Box'
import ReportCounts from 'components/Cards/ReportCounts'
import {AudioUrl} from 'constants/AppConstants';
import SearchBox from 'components/ActionsTable/SearchBox';
class ManageOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersTable: [],
            CancelledOrders: [],
            orderDetails: {},
            ordersCount: [],
            isShowing: false,
            onView: false,
            loading:false,
            page: 0,
            rowsPerPage: 10,
            cancelPage: 0,
            cancelRowsPerPage: 10
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleOrderStatus = this.handleOrderStatus.bind(this)
    }
    componentDidMount(){
      if (this.props.reportFlags===undefined) {
            localStorage.removeItem("token")
       }
       this.notificationHandler()
       this.getOrders()
      
  }
  getOrders = () => {
    API.get('orders')
    .then((res) => {
        // received data        
        console.log('rcvd data',res.data.data.orders)
        let cancelledOrders = [];
        let allOrders = [];
        for(var i=0;i<res.data.data.orders.length;i++){
              if(res.data.data.orders[i].status==="cancelled" || res.data.data.orders[i].status==="completed") {
                  cancelledOrders.push(res.data.data.orders[i]);                    
              } else {
                  allOrders.push(res.data.data.orders[i])
              }
         }
        var orders ={
            'CancelledOrders':cancelledOrders,
            'ordersTable':allOrders
        }
        this.setState({ CancelledOrders: cancelledOrders, ordersTable: allOrders, ordersCount: res.data.data.ordersCount, isShowing:false,loading:false, errorMessage: "",orders:orders})
    })
    .catch((err) => {
        // got an error
        this.setState({errorMessage: "Please try again"});
    }) 
  }
    onView = (order) => {
        //console.log("view clicked",order)
        this.setState({orderDetails: order, isShowing: true, onView: true,errorMessage:'',message:'',loading:false})
        console.log("view clicked", this.state.orderDetails)
    }

    handleClickOpen = () => {
        this.setState({isShowing: true})
    }

    handleClose = () => {
        this.setState({isShowing: false})
    }
    handleOrderStatus = (action,orderID,vendorID,otp=null) => {
        this.setState({loading:true})
        API.post('order_status', JSON.stringify({'orderID':orderID,'action':action,'vendorID':vendorID,'otp':otp}))
        .then((res)=> {
           if(res.data.error){
            this.setState({errorMessage: res.msg.msg});
           } else {   
            this.setState({message: 'Status successfully updated'});
            this.getOrders()  
            }          
        })
        .catch((err)=> {
            this.setState({errorMessage: err.response.data.msg.msg,loading:false})
        })                                                      
    }
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage})
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({rowsPerPage: event.target.value, page: 0})
    };

    handleCancelPage = (event, newPage) => {
        this.setState({cancelPage: newPage})
    };

    handleCancelRowsPerPage = (event) => {
        this.setState({cancelRowsPerPage: event.target.value, cancelPage: 0})
    };
    notificationHandler(){
        var self = this;

        navigator.serviceWorker.addEventListener("message", (message) => { 
            var message = message.data['firebase-messaging-msg-data'].data;  

            navigator.serviceWorker.ready.then(function(registration) {
            registration.showNotification(message.title, {
                body: message.body,
                icon: 'https://valetez.app/favicon.ico'
              }); 
              let audio = new Audio(AudioUrl);
              audio.play() 
              self.getOrders()
    
            });
          });
    }
    handleSearch = (check,searchValue,)=>{
        
        var orders = this.state.orders;
        console.log("handleSearch",searchValue,check)
        if(searchValue){
            var filter = [];
            orders[check].map(o=>{
                console.log("loopkey",o);
                for(var key in o){
                    
                    if(o[key] !=null && !Array.isArray(o[key]) && o[key].toString().toLowerCase().includes(searchValue.toLowerCase())){
                        filter.push(o);
                        break;
                    }
                }
            });
            console.log("filterdedArray",filter)
            this.setState({[check]:filter});
        }else{
            this.setState({[check]:this.state[check]});
        }
    }
    render() {
        /** fixme: table needs to come from actions table */
        return(
            <div>
                <ContainerHeader title="Manage Orders" match={this.props.match}/>  
                <ReportCounts data={this.state.ordersCount} />
                {this.state.ordersTable!=='' &&
                <div className="row">
                     <CardBox styleName="col-12" cardStyle="p-0" headerOutside heading={
                        <React.Fragment>
                            {"Active Requests"}
                            <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                            <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                        </React.Fragment>}>
                        <SearchBox handleSearch={this.handleSearch.bind(this,'ordersTable')}/>
                        <div className="table-responsive-material">
                        
                            <Table>
                                <TableHead >
                                    <TableRow>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Order Id</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Phone</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Time</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Status</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { (this.state.rowsPerPage > 0
                                    ? this.state.ordersTable.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    : this.state.ordersTable
                                    ).map(order => {
                                        return (
                                            <TableRow key={order.order_id}>
                                                <TableCell>{order.order_id}</TableCell>
                                                <TableCell>{order.mobile}</TableCell>
                                                <TableCell>{order.placed_at}</TableCell>
                                                <TableCell style={{color:order.color}}>{order.status}</TableCell>
                                                <TableCell>
                                                        <Button name="view" variant="outlined"  color="secondary" onClick={() => this.onView(order)}>View</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination 
                                  rowsPerPageOptions={[10, 25, 50]}
                                  component="div"
                                  count={this.state.ordersTable.length}
                                  rowsPerPage={this.state.rowsPerPage}
                                  page={this.state.page}
                                  onPageChange={this.handleChangePage}
                                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />   
                        </div>
                          
                    </CardBox>
                </div>
                }
                {this.state.cancelledOrders!=='' &&
                <div className="row">
                     <CardBox styleName="col-12" cardStyle="p-0" headerOutside heading={
                    <React.Fragment>
                    {"Completed Orders"}
                        <MsgPopover msg='Big table, scroll horizontally' timeout='1500'>
                        <Box display={{xs:'inline',md:'none'}}><SwapHoriz color="primary" /></Box></MsgPopover>
                        <SearchBox handleSearch={this.handleSearch.bind(this,'CancelledOrders')}/>
                    </React.Fragment>} >
                        <div className="table-responsive-material">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Order Id</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Phone</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Time</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Status</TableCell>
                                        <TableCell style={{backgroundColor:'#1d2232',color:'white',fontFamily: 'Roboto'}}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { (this.state.cancelRowsPerPage > 0
                                    ? this.state.CancelledOrders.slice(this.state.cancelPage * this.state.cancelRowsPerPage, this.state.cancelPage * this.state.cancelRowsPerPage + this.state.cancelRowsPerPage)
                                    : this.state.CancelledOrders
                                    ).map(order => {
                                        return (
                                            <TableRow key={order.order_id}>
                                                <TableCell>{order.order_id}</TableCell>
                                                <TableCell>{order.mobile}</TableCell>
                                                <TableCell>{order.placed_at}</TableCell>
                                                <TableCell style={{color:order.color}}>{order.status}</TableCell>
                                                <TableCell>
                                                        <Button name="view" variant="outlined"  color="secondary" onClick={() => this.onView(order)}>View</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                        })
                                    }
                                </TableBody>
                            </Table>
                            <TablePagination 
                                  rowsPerPageOptions={[10, 25, 50]}
                                  component="div"
                                  count={this.state.CancelledOrders.length}
                                  rowsPerPage={this.state.cancelRowsPerPage}
                                  page={this.state.cancelPage}
                                  onPageChange={this.handleCancelPage}
                                  onRowsPerPageChange={this.handleCancelRowsPerPage}
                            />   
                        </div>
                          
                    </CardBox>
                </div>
                }
                <OrderDetails loading = {this.state.loading} message = {this.state.message} errorMessage = {this.state.errorMessage} isShowing={this.state.isShowing} orderDetails={this.state.orderDetails} handleOrderStatus = {this.handleOrderStatus} handleClose={this.handleClose} />                    
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    try {
        return {reportFlags : state.auth.authUser.manageorders}
    } catch (err) {
    }
}
  
export default connect(mapStateToProps)(ManageOrders);