/*
 * File: index.js
 * Project: parkezdashboard
 * File Created: Friday, 22nd May 2020 11:45:41 am
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Thursday, 2nd July 2020 11:18:27 am
 * Modified By: Smit (smit@valetez.com)
 * -----
 * components for parking configuration page
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

import React from 'react'
import ConfigPage from '../ConfigPage'



/**
 * Parent component for the page
 * 
 * @param {*} props 
 */
const ParkingConfiguration = (props) => {
    
    return(
        <ConfigPage url='configurations' title='Parking' {...props}/>
    ) 
}

export default ParkingConfiguration