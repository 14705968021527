import React, {useState,useEffect} from 'react';
import IconWithTextCard from "components/IconWithTextCard";
import CardBox from 'components/CardBox';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

 const useStyles = makeStyles((theme) =>({
        recharge:{
            display: 'flex',
            width: '100%',
            paddingRight: '15px',
            paddingLeft: '15px',
        }
    }))
const ReportCounts =(props)=> {
   
    const classes = useStyles();

    const [creditsValue, setCreditsValue] = useState(0);
    const handleCredits = (event) => {
        setCreditsValue(event.target.value)
    }
    return(
        <div className="row">
            <CardBox styleName="col-12">
                <div className="row">
                    {props.data.map((data, index) => <div key={index} className={props.data.length > 1 ? "col" : "col-md-4"}>
                    <IconWithTextCard data={data}/>
                    </div>)
                    }
                    {props.rechargeCredits && 
                        <div className={classes.recharge}>
                        <TextField id="custom-credits" label="Enter Amount" onChange={handleCredits} value={creditsValue} />
                        <Button onClick={() => {props.rechargeCredits(creditsValue)}} variant="outlined" >
                            Recharge
                        </Button>
                        </div>                                     
                    }
                </div>
            </CardBox>
                
        </div>
    )

}
export default ReportCounts

