/*
 * File: FormField.jsx
 * Project: parkezdashboard
 * File Created: Tuesday, 25th August 2020 2:23:13 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Tuesday, 22nd September 2020 1:28:04 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import React, { useEffect, useState } from 'react'
import { Tooltip, FormControl, InputLabel, MenuItem, ListSubheader, InputAdornment, Button, Typography } from '@material-ui/core'
import { TextField, Select, CheckboxWithLabel } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Field, ErrorMessage } from 'formik'
import { DatePicker, DateTimePicker } from 'formik-material-ui-pickers';
import { TxtField } from '../components/MUIComponent'
import _ from 'lodash'
import PinDropIcon from '@material-ui/icons/PinDrop';
import CloseIcon from '@material-ui/icons/Close'
import { Dialog, AppBar, IconButton, Toolbar } from '@material-ui/core';
import { MapAPI } from 'components/Map/MapBox'
import GMap from 'components/Map/GMap'
import Axios from 'axios';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import API from "util/Api";

/**
 * Theme the tooltip here
 */
const useStyles = makeStyles({
    errMsg: {
        color: '#f44336',
        fontSize: '0.75rem'
    },
    subHeader: {
        position: 'relative'
    }
});
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.light,
        color: 'rgba(255, 255, 255, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        maxWidth: 200,
        textAlign: "center",

    },
    popper: {
        zIndex: 1100 // select popup has zIndex 1200
    }
}))(Tooltip);

const SimpleFileUpload = (props) => {
    console.log('SimpleFileUpload-props', props);
    const [label, setLabel] = useState(props.label);
    const useStyles = makeStyles((theme) => (
        {
            button: {
                color: theme.palette.secondary.light,
                justifyContent: 'left',
                marginTop: '17px'
            }
        }
    ))
    const classes = useStyles();

    const handleUpload = (event) => {
        if (event.currentTarget?.files?.length) {
            setLabel('Uploading...')
            const formData = new FormData();
            formData.append("file", event.currentTarget.files[0]);
            API.post("fileUpload", formData)
                .then((res) => {
                    setLabel(res.data.data?.fileName)
                    props.form.setFieldValue(props.field.name, res.data.data?.filePath);
                })
                .catch((err) => {
                    console.error(err);
                    setLabel(err.response?.data?.msg ?? 'Upload failed');
                });
        }
    }

    useEffect(() => {
        if (!props.field.value) {
            setLabel(props.label);
        }
    }, [props.field.value])

    return (
        <>
            <Button
                variant="text"
                size='large'
                className={classes.button}
                onClick={() => document.getElementById(props.field.name).click()}
                startIcon={<CloudUploadIcon color='primary' />}
            >
                {label}
            </Button>
            <input id={props.field.name} type="file" accept={props.accept} {...props.field.value === '' ? { value: '' } : {}} style={{ display: 'none' }} onChange={handleUpload} />
        </>
    )
}

const GMapInput = (props) => {
    console.log('gmap-input-props', props)
    const emptyMap = {
        long: 77.5946,
        lat: 12.9716,
        locality: null,
    };
    const [location, setLocation] = useState(emptyMap)
    const [showMap, setShowMap] = useState(false)
    const [viewport, setViewport] = useState({
        latitude: emptyMap.lat,
        longitude: emptyMap.long,
        zoom: 12
    })
    const useStyles = makeStyles((theme) => (
        {
            mapButton: {
                marginTop: '17px',
                color: theme.palette.secondary.light,
            },
            label: {
                color: theme.palette.primary.main,
                marginTop: '0px'
            },
            appBar: {
                position: 'relative',
            },
            title: {
                marginLeft: theme.spacing(2),
                flex: 1,
            },
            imgErr: {
                color: 'red'
            }
        }
    ))
    const classes = useStyles();

    useEffect(() => {
        if (props.field.value === '') {
            props.form.setFieldValue(props.field.name, emptyMap);
        } else {
            if (!_.isEqual(location, props.field.values)) {
                setLocation(props.field.value)
            }
        }
    }, [props.field.value])

    const onMapClose = () => {
        setShowMap(false)
        if (location?.id) {
            setLocation({ ...location, 'long': parseFloat(props.field.value.long), 'lat': parseFloat(props.field.value.lat) })
            setViewport({ ...viewport, 'longitude': parseFloat(props.field.value.long), 'latitude': parseFloat(props.field.value.lat) })
        }
    }

    // set value of isGoogle to change the map usage
    const onMapSave = (isGoogle = true) => {
        if (isGoogle) {
            const keyList = ["neighborhood", "sublocality_level_1", "sublocality_level_2", "locality", "route"]
            let url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='
            url += location.lat + ',' + location.long + '&key=' + process.env.REACT_APP_GMAP_KEY
            Axios.get(url).then((res) => {
                // console.log(res.data.results)    
                let add = null
                for (let i = 0; i < keyList.length; i++) {
                    let address = _.filter(res.data.results, (o) => {
                        return _.includes(o.types, keyList[i])
                    })
                    if (address.length) {
                        add = address[0].address_components
                        break
                    }
                }
                if (add) {
                    let locality = add[0].short_name + ', ' + add[1].short_name
                    // setLocation({ ...location, 'locality': locality })
                    props.form.setFieldValue(props.field.name, { ...location, 'locality': locality });
                    setShowMap(false)
                } else {
                    console.log('unable to find locality ')
                }
            })
                .catch((err) => {
                    console.log("error from google", err)
                })

        } else {
            const keyList = ["locality", "place"]
            // get the data for this marker
            MapAPI.get('mapbox.places/' + location.long + "," + location.lat +
                ".json?access_token=" + process.env.REACT_APP_MAPBOX_KEY).then((res) => {
                    console.log('rcvd', res.data.features)

                    let locality = _.filter(res.data.features, (o) => {
                        return _.some(keyList, el => _.includes(o.id, el))
                    }).map(el => el.text).join(', ')

                    console.log(locality)
                    // setLocation({ ...location, 'locality': locality })
                    props.form.setFieldValue(props.field.name, { ...location, 'locality': locality });
                    setShowMap(false)

                })
                .catch((err) => {
                    // got an error
                    console.log("error from mapbox")
                })
        }
    }

    const onMapMarkerChange = (marker) => {
        setLocation({ ...location, 'long': marker[0], 'lat': marker[1] })
    }

    const onPlacesChanged = (marker) => {
        setLocation({ ...location, 'long': marker[0], 'lat': marker[1] })
        setViewport({ ...viewport, 'longitude': marker[0], 'latitude': marker[1] })
    }

    const onLatChanged = (e) => {
        setLocation({ ...location, lat: e.target.value });
        setViewport({ ...viewport, latitude: e.target.value });
    };

    const onLongChanged = (e) => {
        setLocation({ ...location, long: e.target.value });
        setViewport({ ...viewport, longitude: e.target.value });
    };

    const onMapViewChange = (center) => {
        setViewport({ ...viewport, 'longitude': center[0], 'latitude': center[1] })
    }

    return (
        <>
            <Button
                variant="text"
                color="secondary"
                size='large'
                className={classes.mapButton}
                onClick={() => setShowMap(true)}
                startIcon={<PinDropIcon color='primary' />}
                style={{ justifyContent: 'left' }}
            >
                {location.locality ? location.locality : props.label}

            </Button>
            <Dialog open={showMap}
                fullScreen={true}
                disableEscapeKeyDown
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onMapClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Mark location for <span style={{ textDecoration: "underline" }}>{location.name ? " " + location.name : " <New Location>"}</span>
                        </Typography>
                        <Button autoFocus variant="contained" color="secondary" onClick={onMapSave}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <GMap
                    viewport={viewport}
                    marker={[location.long, location.lat]}
                    onMarkerChange={onMapMarkerChange}
                    onPlacesChanged={onPlacesChanged}
                    onViewChange={onMapViewChange}
                    onLatChanged={onLatChanged}
                    onLongChanged={onLongChanged}
                />
            </Dialog>
        </>
    )
}

const FormField = (props) => {
    console.log("form field - ", props.field.key, " - ", props)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const classes = useStyles();
    const field = _.cloneDeep(props.field)
    if (field.type === 'select' && !field.multiSelect) {
        field.options.splice(0, 0, { title: 'None', value: '' })
    }
    let component = field.type === 'input' || field.type === 'password' ? TextField : field.type === 'select' ? Select : field.type === 'datepicker' ? DatePicker : field.type === 'datetimepicker' ? DateTimePicker : field.type === 'autocomplete' ? Autocomplete : field.type === 'file' ? SimpleFileUpload : field.type === 'map' ? GMapInput : CheckboxWithLabel;

    return (
        <LightTooltip title={(field.help ? field.help : "No help added")}
            {...(field.type === 'select' ? { open: tooltipOpen } : {})} // this adds this property only for select
        >
            <FormControl className="w-100">
                {(field.type === 'select') &&
                    <InputLabel>{field.title}</InputLabel>
                }
                <Field
                    component={component}
                    name={field.name || field.key}
                    type={field.type === 'checkbox' ? 'checkbox' : field.type == 'password' ? 'password' : ''}
                    label={field.title}
                    disabled={"disabled" in field ? field.disabled : false}
                    // helperText={field.helperText}
                    InputProps={field.type === 'input' && field.startAdornment ? { startAdornment: <InputAdornment position="start">{field.startAdornment}</InputAdornment> } : {}}
                    {...(
                        field.type === 'select' ?
                            {
                                onMouseEnter: () => setTooltipOpen(true),
                                onMouseLeave: () => setTooltipOpen(false),
                                onOpen: () => setTooltipOpen(false),
                                onTouchStart: () => setTooltipOpen(true),
                                onTouchEnd: () => setTooltipOpen(false),
                                onClose: () => setTooltipOpen(false),
                                multiple: field.multiSelect ? true : false
                            }
                            : {}
                    )}
                    {...(
                        field.type === 'checkbox' ?
                            {
                                Label: { label: field.title }
                            }
                            : {}
                    )}
                    {...(
                        field.type === 'datepicker' || field.type === 'datetimepicker' ?
                            {
                                Label: { label: '' },
                                format: field.format,
                                minDate: field.minDate,
                                maxDate: field.maxDate,
                                onMouseEnter: () => setTooltipOpen(true),
                                onMouseLeave: () => setTooltipOpen(false),
                                onOpen: () => setTooltipOpen(false),
                                onTouchStart: () => setTooltipOpen(true),
                                onTouchEnd: () => setTooltipOpen(false),
                                onClose: () => setTooltipOpen(false),
                            }
                            : {}
                    )}
                    {...(
                        field.type === 'autocomplete' ?
                            {

                                limitTags: 3,
                                options: field.options,
                                multiple: field.multiSelect ? true : false,
                                getOptionLabel: (option) => { return option.title },
                                renderInput: (params) => (<TxtField
                                    {...params}
                                    label={field.title}
                                />)
                            } : {}

                    )}
                    {...(field.type === 'file' ? { accept: field.accept } : {})}
                >
                    {field.type === 'select' &&
                        field.options.map((opt, i) => (
                            typeof opt == 'object' ?
                                (opt.subHeader ?
                                    <ListSubheader classes={{ root: classes.subHeader }}>{opt.title}</ListSubheader> :
                                    <MenuItem disabled={'disabled' in opt ? opt.disabled : false} key={`${field.name || field.key}-opt-${i}`} value={opt.value} checked={field.value.includes(opt.value)}>
                                        {opt.title}
                                    </MenuItem>)
                                :
                                <MenuItem key={`${field.name || field.key}-opt-${i}`} value={opt} checked={field.value.includes(opt)}>{opt}</MenuItem>
                        ))}
                </Field>
                {
                    (field.type === 'select' || field.type === 'autocomplete' || field.type === 'datepicker') &&
                    <ErrorMessage component={"div"} className={classes.errMsg} name={field.name} />
                }
            </FormControl>
        </LightTooltip>
    )
}

export default FormField