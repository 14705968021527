import React, { useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import ContainerHeader from "components/ContainerHeader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import _ from "lodash";
import API from "util/Api";
// import dummyData from "./dummyRate";
import {
  Tooltip,
  Box,
  AccordionActions,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Formik, Form, Field, connect, FieldArray, getIn } from "formik";
import PlusOneIcon from "@material-ui/icons/PlusOne";
import FormField from "components/FormField";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  section: {
    flexBasis: "100%",
    flexShrink: 0,
    flexWrap: "wrap",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(0, 0, 0, 0.2)",
    borderBottomStyle: "solid",
    paddingBottom: 15,
    paddingTop: 15,
    "&:nth-last-child(1)": {
      borderBottomWidth: 0,
    },
  },
  field: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  panelHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.secondary.main,
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  panelSubheading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textAlign: "right",
  },
  expanded: {
    backgroundColor: "#efefef",
  },
  panelRoot: {
    flexWrap: "wrap",
    display: "block!important",
  },
  panelAction: {
    flex: 1,
    justifyContent: "flex-end",
  },
  addButton: {
    margin: "10px 0",
  },
}));

const Config = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [errorMessage, setErrorMessage] = useState("");
  const [isShowing, setisShowing] = useState(false);
  const [loading, setisLoading] = useState(false);
  const [values, setValues] = useState();
  const validations = Yup.object().shape({
    cost: Yup.string().required("Required"),
    // location_id: Yup.object().required("Required"),
    vehicle_type_id: Yup.object().required("Required"),
  });

  useEffect(() => {
    if (props.model) {
      console.log("props model", props.model);
      let tempVal = {};
      Object.values(props.model).map((field) => {
        tempVal[field.key] = field.value;
      });
      setValues(tempVal);
    }
  }, [props.model]);

  const saveConfig = (e, { setSubmitting }) => {
    console.log("save config", e);
    setSubmitting(true);
    setisLoading(true);

    API.post("chargingRateModels", {
      ...e,
      ...{ location_id: props.locationId },
    })
      .then((res) => {
        props.setRateModels(res.data.data.chargingRateModels);
        console.log("api response", res);
        setErrorMessage("Configuration saved");
        setisShowing(true);
        console.log("post config", res.data);
        setSubmitting(false);
        setisLoading(false);
      })
      .catch((err) => {
        setErrorMessage("Request Failed. Please Try Again!!");
        setisShowing(true);
        console.log("post config error ", err);
        setSubmitting(false);
        setisLoading(false);
      });
  };
  const handleClose = () => {
    setisShowing(false);
    setErrorMessage("");
  };

  const handleCloseReject = () => {
    setisShowing(false);
    setErrorMessage("");
  };
  console.log("initial values", values);
  return (
    <>
      {loading && (
        <div>
          <LinearProgress color="primary" />
          <LinearProgress color="primary" />
        </div>
      )}
      {!loading && values && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ expanded: classes.expanded }}
          >
            <Typography className={classes.panelHeading}>
              {props.title ? props.title : "New Rate Model"}
            </Typography>
            <Typography className={classes.panelSubheading}></Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.panelRoot }}>
            <Formik
              initialValues={values}
              validationSchema={validations}
              onSubmit={saveConfig}
            >
              <Form>
                <div className="row">
                  {props.config.sections[0].fields.map((field) => (
                    <div
                      className={
                        classes.field +
                        " col-sm-4 " +
                        (field.size === "lg" ? "col-lg-4" : "col-lg-3 col-xl-2")
                      }
                    >
                      <FormField field={{ ...field, ...{ name: field.key } }} />
                    </div>
                  ))}
                </div>
                <div className={classes.panelAction}>
                  <AccordionActions>
                    <Button size="small" type="reset">
                      Cancel
                    </Button>
                    <Button size="small" color="primary" type="submit">
                      {" "}
                      Save{" "}
                    </Button>
                  </AccordionActions>
                </div>
              </Form>
            </Formik>
          </AccordionDetails>
        </Accordion>
      )}
      {errorMessage !== "" && (
        <Dialog
          open={isShowing}
          onClose={handleClose}
          fullWidth={true}
          fullScreen={fullScreen}
        >
          <DialogContent color="secondary">
            <Typography variant={"body1"}>{errorMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Box display="inline-block">
              <Button
                onClick={handleCloseReject}
                variant="outlined"
                className={classes.button}
              >
                OK
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

const ChargingTariff = (props) => {
  console.log("chargin tariff props", props);
  const [config, setConfig] = useState();
  // const [values, setValues] = useState();
  const [rateModels, setRateModels] = useState();
  const classes = useStyles();

  useEffect(() => {
    API.get("chargingRateModels", {
      params: {
        location_id: props.location.state.id.value,
      },
    })
      .then((res) => {
        // res.data = dummyData;
        setRateModels(res.data.data.chargingRateModels);
        setConfig(res.data.data.config);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const addConfig = () => {
    let newField = {};
    config.sections[0].fields.map((field) => {
      newField[field.key] = field;
    });
    setRateModels([...rateModels, newField]);
  };
  return (
    <div>
      <ContainerHeader
        title={"tariff - " + props.location.state.name.value}
        match={props.match}
      />
      <div className="row">
        {
          <div className="col-12">
            <Button
              variant="contained"
              color="secondary"
              className={classes.addButton}
              startIcon={<PlusOneIcon />}
              onClick={addConfig}
            >
              Rate Model
            </Button>
          </div>
        }
      </div>
      <div className="row">
        <div className="col-12">
          {rateModels &&
            config &&
            rateModels.map((model, index) => {
              return (
                <Config
                  config={config}
                  index={index}
                  model={model}
                  locationId={props.location.state.id.value}
                  title={model.vehicle_type ? model.vehicle_type.value : ""}
                  setRateModels={setRateModels}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default ChargingTariff;
