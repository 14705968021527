/*
 * File: index.js
 * Project: parkezdashboard
 * File Created: Monday, 20th April 2020 5:41:05 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Monday, 20th April 2020 5:43:49 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

import React from 'react';
import {Route, Switch} from 'react-router-dom';
import MIS from './routes/MIS'
import Reports from './routes/Reports'
import HistoricalAnalytics from './routes/Reports/historical'
import Predictions from './routes/Predictions'
import ParkingSummery from './routes/ParkingSummery';
 
const Analytics = ({match}) => (
    <div className="app-wrapper">
      <Switch>
       <Route path={`${match.url}/MIS`} component={MIS}/> 
       <Route path={`${match.url}/Reports`} component={Reports}/> 
       <Route path={`${match.url}/Historical`} component={HistoricalAnalytics}/> 
       <Route path={`${match.url}/Prediction`} component={Predictions}/>
       <Route path={`${match.url}/parking`} component={ParkingSummery}/>
       {/* <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
      </Switch>
  
       </div>
);
  
  export default Analytics;
  