import React, { useState,useEffect } from 'react'
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,IconButton,Typography} from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { Formik,Form,connect,FieldArray} from 'formik'
import FormField from 'components/FormField'
import * as Yup from "yup"
import API from "../../../../../../util/Api"
import moment from 'moment'

const CreateClientsConfigSchema =()=>{
    return Yup.lazy(values=>{
        return Yup.object().shape({
            clients:Yup.array().of(
                Yup.object().shape({
                    client_name:Yup.string()
                    .required("requried"),
                    start:Yup.string()
                    .required("start date is requried"),
                    end:Yup.string()
                    .required("end date is requried"),
                  })
            ).min(1, "Need at least a row")
          
        });
    });

}
const ClientsForm = (props) => {
   console.log("clientsForm", props);
    useEffect(()=>{
        if(props.resetFormik){
            props.formik.resetForm();
            props.setFalse();
        }
    },[props.resetFormik])

  return (
    <Form>
      <DialogContent>
        <FieldArray
          name={"clients"}
          render={({ insert, remove, push }) => {
            return props.formik.values.clients.map((value, i) => {
              return (
                <div className={"row"}>
                  {props.config.map((field, index) => {
                    return (
                      <div
                        className={`col-md-${field.size} col-12`}
                        key={`rowField-${field.key}`}
                      >
                        <FormField
                          field={{
                            ...field,
                            ...{ name: `clients.${i}.${field.key}` },
                          }}
                        />
                      </div>
                    );
                  })}
                  <div className="col-sm-1">
                    {props.formik.values.clients.length > 1 && (
                      <IconButton
                        aria-label="remove"
                        onClick={(e) => {
                          remove(i);
                        }}
                      >
                        <RemoveIcon color="error" />
                      </IconButton>
                    )}
                    {i === props.formik.values.clients.length - 1 && (
                      <IconButton
                        aria-label="add"
                        onClick={(e) =>
                          push({client_name:'',start:moment().format('DD MMM, YYYY'),end:moment().add(30, 'days').format('DD MMM, YYYY'),sub_location:[],location_id:props.location?.location_id})
                        }
                      >
                        <AddIcon color="primary" />
                      </IconButton>
                    )}
                  </div>
                </div>
              );
            });
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={props.onClose}>
          {"Close"}
        </Button>
        <Button variant="outlined" type="submit" color="primary">
          {"Save"}
        </Button>
      </DialogActions>
    </Form>
  );
};

const EZForm = connect(ClientsForm)
const CreateClients = (props)=>{
    const [message,setMessage] = useState('')
    const [resetF,setResetF] = useState(false);
    const submitClients = (e) =>{
        console.log("submitClients",e)
        API.post('add_access_clients',JSON.stringify(e)).then(response=>{
            console.log("access_subscription",response.data)
            setMessage(response.data.msg)
            setResetF(true)
            setTimeout(()=>{
                props.onClose(true);
            },200)

        }).catch(error=>{
            setMessage('Failed to save')
            console.log(error)
        })
    }
    return(
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={'md'}
            disableBackdropClick
            onClose={props.onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{'Create New Clients '}</DialogTitle>
            <Typography color='primary' variant='h6' style={{marginLeft:30}} >{message}</Typography>
            <Formik
                onSubmit={submitClients}
                initialValues={props.initialValues}
                validationSchema={CreateClientsConfigSchema}
                resetInitalValue={true}
            >
                <EZForm onClose={props.onClose} config={props.config} resetFormik={resetF} setFalse={()=>{console.log('setResetF');setResetF(false)}} />
            </Formik>
      </Dialog>
    )
}
export default CreateClients