import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
const numFormatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 6 })
const maxBarSize = 30;

const TinyBarChart = ({ data, legends, xLabel, yLabel, layout }) => {
  console.log('TinyBarChart props - ', { data, legends, xLabel, yLabel, layout })

  return (
    <ResponsiveContainer width="100%" height={400}>
      {layout == 'horizontal' ? <BarChart data={data}
        margin={{ top: 20, right: 0, left: 17, bottom: 0 }}>
        <XAxis dataKey={xLabel} />
        <YAxis angle={-45} textAnchor="end" label={{ value: yLabel, angle: 0, position: 'top' }}
          tickFormatter={tick => {
            return numFormatter.format(tick);
          }} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={(value) => numFormatter.format(value)} />
        <Legend />
        {legends.map((n, index) => {
          return (
            <Bar dataKey={n.name} fill={n.color} maxBarSize={maxBarSize} />
          )
        })}
      </BarChart>
        :
        <BarChart data={data}
          margin={{ top: 20, right: 0, left: 17, bottom: 0 }} layout={layout}>
          <XAxis type="number" />
          <YAxis type="category" dataKey={xLabel} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip formatter={(value) => numFormatter.format(value)} />
          <Legend />
          {legends.map((n, index) => {
            return (
              <Bar dataKey={n.name} fill={n.color} maxBarSize={maxBarSize} />
            )
          })}
        </BarChart>
      }
    </ResponsiveContainer>
  )
};

export default TinyBarChart;