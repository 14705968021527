import React from "react";
import { DateTimePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";

const CustomDateTimePicker = (props) => {
  return (
    <div key="datetime_custom" className="picker">
      {props.version === 'v3' ? (
        <DateTimePicker
          fullWidth
          // views={["year", "month"]}
          // disableToolbar
          format="DD MMM YY - hh:mm a"
          minDate={props.minDate ? props.minDate : new Date('2016-01-01')}
          maxDate={new Date(moment().subtract(7, "months").endOf("month").add(1, 'days'))}
          showTabs={false}
          disableFuture
          value={props.selectedDate}
          onAccept={handleOnAccept}
          onChange={(date) => {
            props.handleDateChange({
              target: { name: props.name, value: date },
            });
          }}
          leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
          rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
          minutesStep={props.minutes}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i className="zmdi zmdi-alarm" />
              </InputAdornment>
            ),
          }}
        />
      ) : props.enableFuture ? (
        <DateTimePicker
          fullWidth
          format="DD MMM YY - hh:mm a"
          minDate={props.minDate}
          maxDate={props.maxDate}
          showTabs={false}
          value={props.selectedDate}
          onAccept={handleOnAccept}
          onChange={(date) => {
            props.handleDateChange({
              target: { name: props.name, value: date },
            });
          }}
          leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
          rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
          minutesStep={props.minutes}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i className="zmdi zmdi-alarm" />
              </InputAdornment>
            ),
          }}
        />

      ) : (
        <DateTimePicker
          fullWidth
          format="DD MMM YY - hh:mm a"
          minDate={props.minDate}
          maxDate={props.maxDate}
          showTabs={false}
          disableFuture
          value={props.selectedDate}
          onAccept={handleOnAccept}
          onChange={(date) => {
            props.handleDateChange({
              target: { name: props.name, value: date },
            });
          }}
          leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
          rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
          minutesStep={props.minutes}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i className="zmdi zmdi-alarm" />
              </InputAdornment>
            ),
          }}
        />
      )}
    </div>
  );
};

const handleOnAccept = (date) => {
  console.log("inside on accept", date);
};

export default CustomDateTimePicker;
