import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import API from "util/Api";

var crypto = require("crypto");
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  right10: {
    right: "10%",
  },
}));
const QrScanner = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const [loading, setLoading] = useState(props.loading);
  const [error, setErrorMessage] = useState(props.errorMessage);
  const [message, setMessage] = useState(props.message);
  const [scanned, setScanned] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [creditsValue, setCreditsValue] = useState(0);
  const [verificationType, setVerificationType] = useState("phone");
  useEffect(() => {
    setErrorMessage(props.errorMessage);
    setMessage(props.message);
    setLoading(props.loading);
    setCreditsValue(0);
    setScanned(false);
    setCreditsValue("custom");
  }, [props.errorMessage, props.message, props.loading]);
  const handleScan = (data) => {
    setErrorMessage("");

    if (data) {
      setLoading(true);
      setErrorMessage("");
      setMessage("");

      var decryptedData = decrypt(data);
      var order = decryptedData.split("/");
      if (order.length === 3) {
        props.handleScan(order);
      } else {
        setMessage("");
        setErrorMessage("Invalid QR");
        setLoading(false);
      }
    } else {
      setMessage("");

      setErrorMessage("");
    }
  };
  const handleError = (err) => {
    setErrorMessage("");
  };
  const decrypt = (text) => {
    const ivText = "0fcab864f6b182d538f918876e0fda92";
    const key = "e0b1c3e518efc6ba8bbb0d920f40c547";
    const cipherAlgo = "AES-128-CBC";
    const hashKey = "iiDB8ceNBDdBTXLhAPlF1HQW6u13";
    var binaryIV = new Buffer(ivText, "hex");
    var binaryEncryptionKey = new Buffer(key, "hex");
    var decipher = crypto.createDecipheriv(
      cipherAlgo,
      binaryEncryptionKey,
      binaryIV
    );
    var a = decipher.update(text, "base64", "binary");
    var b = decipher.final("binary");
    var decryptedInput = a + b; //Buffer.concat([a,b]);
    //console.log(decryptedInput.toString());

    return decryptedInput.toString();
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleVerifyType = (event) => {
    setVerificationType(event.target.value);
  };
  const handleCredits = (event) => {
    setCreditsValue(event.target.value);
  };
  const handleValetScan = (data) => {
    setErrorMessage("");

    if (data) {
      setLoading(true);
      setErrorMessage("");
      setMessage("");
      var decryptedData = decrypt(data.substr(2, data.length - 4)); // -4 to remove v_ from qr string
      var booking = decryptedData.split("/");

      if (booking.length === 6) {
        setScanned(booking);
        setLoading(false);
        setSelectedValue("custom");
      } else {
        setMessage("");
        setErrorMessage("Invalid QR");
        setLoading(false);
      }
    } else {
      setMessage("");

      setErrorMessage("");
    }
  };
  const verifyBooking = (event) => {
    let phone = event.target.value;  
    setErrorMessage("");

    if (phone.length === 10) {
      setLoading(true);
      setErrorMessage("");
      setMessage("");
      API.post(
        "verify_valet_retailer_booking",
        JSON.stringify({ phone: phone })
      )
        .then((res) => {
          if (res.data.error) {
            setErrorMessage(res.msg.msg);
            setLoading(false);
          } else {
            setScanned(res.data.data);
            setLoading(false);
            setSelectedValue("custom");
          }
        })
        .catch((err) => {
          setErrorMessage(err.response.data.msg);
          setLoading(false);
        });
    }
  };
  return (
    <Dialog
      open={props.isShowing}
      onClose={props.handleClose}
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <DialogTitle color="secondary">
        <Box>
          Scan QR
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      {!props.type && (
        <DialogContent>
          {error != "" && (
            <Typography paragraph={true} variant="h3" color="secondary">
              {error}
            </Typography>
          )}
          {message != "" && (
            <Typography paragraph={true} variant="h3" color="secondary">
              {message}
            </Typography>
          )}
          <div>{loading && <LinearProgress size={24} />}</div>
          {!loading && (
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
              resolution={600}
            />
          )}
        </DialogContent>
      )}
      {props.type == "reimbursements" && (
        <DialogContent>
          {error != "" && (
            <Typography paragraph={true} variant="h3" color="secondary">
              {error}
            </Typography>
          )}
          {message != "" && (
            <Typography paragraph={true} variant="h3" color="secondary">
              {message}
            </Typography>
          )}
          <div>{loading && <LinearProgress size={24} />}</div>
          {scanned && (
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Choose Reimbursement Module
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="custom"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="custom"
                    control={<Radio color="primary" />}
                    label="Custom"
                    labelPlacement="end"
                  />
                  {props.pointsModel.map((model) => {
                    return (
                      <FormControlLabel
                        value={model.type}
                        control={<Radio color="primary" />}
                        label={
                          model.type == "percentage"
                            ? model.discount + "% of bill"
                            : "Flat " + model.discount + " off"
                        }
                        labelPlacement="end"
                      />
                    );
                  })}
                </RadioGroup>
                {selectedValue == "custom" && (
                  <TextField
                    id="custom-credits"
                    label="Enter Amount"
                    onChange={handleCredits}
                  />
                )}
                {selectedValue == "percentage" && (
                  <TextField
                    id="custom-credits"
                    label="Enter Bill Amount"
                    onChange={handleCredits}
                  />
                )}
              </FormControl>
            </div>
          )}
          {!loading && !scanned && (
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="phone"
                onChange={handleVerifyType}
              >
                <FormControlLabel
                  value="phone"
                  control={<Radio color="primary" />}
                  label="Phone Number"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="scan"
                  control={<Radio color="primary" />}
                  label="Scan Qr"
                  labelPlacement="end"
                />
              </RadioGroup>
              
              {verificationType =="phone" && (
                <FormControl component="fieldset">
                  <TextField
                    id="phone"
                    label="10 digit phone no"
                    onChange={verifyBooking}
                  />
                </FormControl>
              )}
            </FormControl>
            
          )}
          {!loading && !scanned && verificationType == "scan" && (
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleValetScan}
                  style={{ width: "100%" }}
                  resolution={600}
                />
              )}
        </DialogContent>
      )}
      {props.type == "reimbursements" && !loading && (
        <DialogActions>
          <Box display="inline-block">
            <Button
              onClick={() => {
                setLoading(true);
                props.reimburse(scanned, selectedValue, creditsValue);
              }}
              variant="contained"
              className={classes.right10}
              color="primary"
            >
              Confirm
            </Button>
          </Box>
          <Box display="inline-block">
            <Button
              onClick={props.handleClose}
              variant="outlined"
              className={classes.button}
            >
              Cancel
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};
export default QrScanner;
