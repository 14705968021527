import React from 'react'
import {  Button, Typography } from '@material-ui/core'
import { Formik, Form, connect } from 'formik'
import FormField from 'components/FormField'
import * as Yup from "yup"
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {  AccordionActions } from "@material-ui/core";
import API from '../../../../../../util/Api'
import useStyles from './style'

const FormConfig = connect((props) => {
    console.log("FormConfig--",props)
    const classes = useStyles();
    return (
        <Form>
            <div className={"row"}>
                {props.controllerConfig.map((field, index) => {
                    return (
                        <div
                            className={`col-md-${field.size} col-12`}
                            key={`rowField-${field.key}`}
                        >
                            <FormField
                                field={{
                                    ...field,
                                    ...{ name: `${field.key}` },
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <div className={`mt-4 ${classes.panelAction}`}>
                <AccordionActions>
                    <Button size="small" type="reset">Cancel</Button>
                    <Button size="small" color="primary" type="submit">Save</Button>
                </AccordionActions>
            </div>
        </Form>
    )
})

const EZTVConfig = (props) => {
    console.log("EZTVConfig--",props,props.eztv,props.ezTvConfig())
    const classes = useStyles();
    const ezTvValidation = Yup.object().shape({
        // eztv_accessory_id: Yup.string().required("Required"),
        // port:Yup.string().required("Required")
    })

    const saveController = (e) => {
        console.log("saveController", e)
        API.post('attachEztvToGuard', JSON.stringify({...e,staff_id:props.staffId})).then(response => {
            //   props.setMessage(response.data.msg)
            props.setSnackBar({ open: true, msg: response.data.msg, type: 'success' });
        }).catch(err => {
            // props.setMessage('Failed to save')
            console.log(err)
            props.setSnackBar({ open: true, msg: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong", type: 'error' });
        })
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ expanded: classes.expanded }}
            >
                <Typography className={classes.panelHeading}>
                    EZTV Config
                </Typography>
                <Typography className={classes.panelSubheading}></Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.panelRoot }}>
                <Formik
                    onSubmit={saveController}
                    initialValues={props.eztv}
                    validationSchema={ezTvValidation}
                    resetInitalValue={true}
                >
                    <FormConfig controllerConfig={props.ezTvConfig()} />
                </Formik>
            </AccordionDetails>
        </Accordion>
    )
}
export default EZTVConfig;