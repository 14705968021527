import React from 'react';

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block">Copyright ValetEZ &copy; {new Date().getFullYear()}</span>
      </footer>
    );
  }
;

export default Footer;
