import React, { useEffect, useState } from 'react'
import API from '../../../../../../util/Api'
import ContainerHeader from '../../../../../../components/ContainerHeader'
import CardBox from 'components/CardBox'
import RenewFilter from '../RenewFilter'
import SnackBar from "components/SnackBar";
import { LinearProgress } from '@material-ui/core'
import ReportTable from 'components/ReportTable/index'
import PopUpDialog from 'components/PopUpDialog'
import RenewDialog from 'app/routes/Subscriptions/routes/RenewDialog'
import _ from 'lodash'
import moment from 'moment'

const RenewSubscriptions = (props) => {
    const [locations,setLocations] = useState(undefined)
    const [subscriptionList,setSubscriptionList] = useState('')
    const [isError,setIsError] = useState('')
    const [renewDialog,setRenewDialog] = useState({
        isShowing:false,
        loading:false,
        errorMessage:"",
        header:'',
        dialogType:'access_renew',
        message:'',
        subscription:{}
    })
    const [dialogState,setDialogState] = useState({
        open:false,
        header:'',
        content:'',
        agreeBtnTxt:'',
        disAgreeBtnTxt:'',
        selectedSusbcription:{}
    })
    const [snackBar, setSnackBar] = useState({
        open: false,
        msg: "",
        type: "error",
    });
    useEffect(()=>{
        API.get('access_location')
        .then((res) => {
            // received data
            setLocations(res.data.data.access_locations)
        })
        .catch((err) => {
            // got an error
        })
    },[])

    const onSubmit = (params) => {
        console.log("access_subscriptions" + JSON.stringify(params))
        setSubscriptionList(null)
        setIsError('')
        API.get("access_subscriptions?data=" + JSON.stringify(params))
            .then((res) => {
                console.log("res.data.data", res.data.data)
                // received data
                setSubscriptionList(res.data.data)
            }).catch((err) => {
                // got an error
                setSubscriptionList('')
                setIsError("Try Again")
            })
    }
    const onEnable = (params,checked) => {
       var text = checked ? `Are you sure you want to activate ${params.update_type_hidden=='client'?'client':'user'} ?` : `Are you sure you want to deactivate ${params.update_type_hidden=='client'?'client':'user'}`
        var header = checked ? 'Activate Access' : 'Deactivate Access'
        console.log('onEnable',params,checked)
        setDialogState({
            open:true,
            header:header,
            content:text,
            agreeBtnTxt:'Update',
            disAgreeBtnTxt:'Cancel',
            selectedSusbcription:params
        })
        
    }
    const renewClicked = (e) => {
        console.log("renewSubscription",e)
        let subscription = _.cloneDeep(e);
        const oneMonthAgo = moment(moment().subtract(1, 'M'), 'DD-MM-YYYY HH:mm');
        const endDate = moment(e.end, 'DD-MM-YYYY HH:mm');
        if (endDate.isBefore(oneMonthAgo)) {
            const dateObj = moment();
            subscription.start = moment(dateObj,'DD-MM-YYYY HH:mm').toDate();
            subscription.end = moment(dateObj,'DD-MM-YYYY HH:mm').add(1,'M').toDate();
            subscription.minStart = moment(dateObj,'DD-MM-YYYY HH:mm').subtract(1,'M').toDate();
        } else {
            subscription.start = moment(e.end,'DD-MM-YYYY HH:mm').toDate();
            subscription.end = moment(e.end,'DD-MM-YYYY HH:mm').add(1,'M').toDate();
            subscription.minStart = moment(e.end,'DD-MM-YYYY HH:mm').subtract(1,'M').toDate();
        }
        setRenewDialog({...renewDialog,isShowing:true,subscription:subscription})
    }
    const renewAccess = (inputs)=>{
        console.log("renewAccess",inputs)
        API.post("access_renew", {...inputs})
        .then((res) => {
            setRenewDialog({...renewDialog,loading:false,subscription:{},isShowing:false})
            setSubscriptionList(res.data.data)
        }).catch((err) => {
            // got an error
            setSnackBar({ open:true, msg:err.response.data.msg, type:"error"})
        })
    }
    const onEditSubscription = (data) => {
        console.log('edit-data', data)
        window.open(`/app/access/update/${data.au_id_hidden}/${data.update_type_hidden}`, '_blank')
    }
    const updateAccess = () =>{
        API.post("access_updateStatus", JSON.stringify(dialogState.selectedSusbcription))
        .then((res)=> {
            setDialogState({selectedSusbcription:{},open:false,dialogAgreeBtnTxt:'',dialogDisAgreeBtnTxt:'',dialogContent:'',dialogHeader:''})
            setSubscriptionList(res.data.data)
        })
        .catch((err)=>{
            // this.setState({errorMessage:err.response.data.msg,open:false});
            console.log("post config error ", err)
  })
    }
    return (
        <div>
            <ContainerHeader title="Renew Access" match={props.match} />
            <div className="row">
                {
                    (locations) ?
                        <CardBox styleName="col-12">
                            <RenewFilter
                                locations={locations}
                                getReportData={onSubmit}
                                subscription_type={["users", "clients"]}
                            />
                        </CardBox> : ''
                }
            </div>
            {(isError.length > 0) && <h3 className="row"> {isError} </h3>}
            {(subscriptionList == undefined) &&
                <div><LinearProgress color="primary" /><LinearProgress color="primary" /></div>
            }
            {
                (subscriptionList) &&
                <React.Fragment>
                    {
                        Object.keys(subscriptionList).length > 0 &&
                        <ReportTable
                            onEnable={onEnable}
                            data={subscriptionList}
                            renewSubscription={renewClicked}
                            onEdit={onEditSubscription}
                        />
                    }
                </React.Fragment>
            }
            {
              renewDialog.isShowing &&
                <RenewDialog 
                    handleClose={()=>{setRenewDialog({
                        isShowing:false,
                        loading:false,
                        errorMessage:"",
                        header:'',
                        dialogType:'access_renew',
                        message:'',
                        subscription:{}
                    })}} 
                    isShowing={renewDialog.isShowing} 
                    loading={renewDialog.loading} 
                    errorMessage={renewDialog.errorMessage} 
                    subscription={renewDialog.subscription}
                    renewAccess={renewAccess} 
                    message={renewDialog.message} 
                    dialogType={renewDialog.dialogType}
                    header={renewDialog.header}
                />
            }
            <PopUpDialog 
                {...dialogState}
                agreeClicked ={updateAccess}
                handleDialogClose={()=>{
                    setDialogState({selectedSusbcription:{},open:false,dialogAgreeBtnTxt:'',dialogDisAgreeBtnTxt:'',dialogContent:'',dialogHeader:''})
                }}
            />
            <SnackBar
                open={snackBar.open}
                handleClose={() => {
                    setSnackBar({...snackBar, open: false, msg: ""});
                }}
                message={snackBar.msg}
                type={snackBar.type}
            />
        </div>
    )
};

export default RenewSubscriptions;
