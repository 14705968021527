import React, { useState,useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/CardBox';
import API from 'util/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography,Box,Button } from '@material-ui/core'
import Actions from '../Component/actions';
import GetClientsByLocation from 'app/routes/Subscriptions/routes/CreateSubscriptions/GetClientsByLocation';
import _ from 'lodash';
import SnackBar from 'components/SnackBar'
import CreateClients from './CreateClients'
import EditClient from './EditClient';
import moment from 'moment'
import {clientConfig,editClientConfig} from "./clientConfig.js"
import SimpleTable from 'components/ActionsTable/SimpleTable'

var UploadUsers = (props)=> {
     
    const [pageState,setPageState] = useState({
            appState:'loading',
            data:[]
        })
    const [selectedLoc,setSelectedLoc] = useState(null)
    const [locations,setLocations] = useState(undefined)
    const [snackBar,setSnackBar] = useState({open:false,msg:'',type:'error'})
    const [dialogProps,setDialogProps] = useState({
        isShowing:false,
        isEditShowing:false
    })

    const getAccessLocation=(updateSelectedLoc)=>{
        API.get('access_location')
        .then((res) => {
            // received data
            setLocations(res.data.data.access_locations)
            setPageState({...pageState,appState:'active'})
            if(updateSelectedLoc){
                onSubmit(_.find(res.data.data.access_locations,{location_id:selectedLoc.location_id}))
            }
        })
        .catch((err) => {
            // got an error
        })
    }

    useEffect(()=>{
        getAccessLocation()
    },[])

    const onSubmit=async (loc)=>{
        
        console.log("onSubmit-clicked",loc)
        let subLocArr = []
        loc.sub_locations.map(sub_loc=>{
            subLocArr.push({title:sub_loc.name,value:sub_loc.id})
        })
        let newClients = loc.clients
        newClients.map(client=>{
            let findSubLocs = []
            let sub_locations_ids_hidden = []
            _.map(loc.sub_locations,sub_loc=>{
                if(sub_loc.client_id.includes(client.id)) {
                    findSubLocs.push(sub_loc.name) 
                    sub_locations_ids_hidden.push(sub_loc.id)
                }
            })
            client.sub_locations = findSubLocs.toString()
            client.sub_locations_ids_hidden = sub_locations_ids_hidden
        })
        setSelectedLoc({...loc,sub_locations:subLocArr,clientSubLocations:newClients})
    }
    const reset=()=>{
        setSelectedLoc(null)
    }
    var renderPage=()=>{
        console.log("renderPage");
        return(
            <div>
                <ContainerHeader title="Create Access" match={props.match} />    
                <div className="row">
                    <CardBox styleName="col-12">
                    <GetClientsByLocation
                            reset = {reset}
                            data = {locations}
                            onSubmit={onSubmit}
                        />
                    </CardBox>
                    {
                      selectedLoc &&
                      <>
                      {
                        selectedLoc.role=='master'&&
                        <div className="row">
                            <div className="col-12" style={{display:"flex",flexWrap:"wrap"}}>
                                <Box ml="15px" mb="25px"><Button variant="contained" color="primary" onClick={()=>{
                                    setDialogProps({
                                        ...dialogProps,
                                        isShowing:true,
                                    })
                                }}> Create New Client  </Button>
                                </Box>
                            </div>
                            <div className="col-12" style={{}}>
                                <CardBox styleName="col-12" 
                                heading={
                                    <Typography variant='h5' >
                                        Clients Sublocations
                                    </Typography>
                                }>                        
                                    <SimpleTable 
                                        type={'accessClients'} 
                                        disableSearch
                                        table={{
                                            headers:['Client Name','Sub locations','Actions'],
                                            tbody:selectedLoc.clientSubLocations
                                        }} 
                                        onUpdate={
                                            (client)=>{
                                                
                                                setDialogProps({
                                                    ...dialogProps,
                                                    isEditShowing:true,
                                                    client:client
                                                })
                                            }
                                        }
                                        onUpdateTitle={'Update Client'}
                                    />
                                </CardBox>
                            </div>
                        </div>
                      }
                      <CardBox styleName="col-12">
                            <>
                            <Typography variant={'h6'} style={{marginBottom:25}}>{selectedLoc.location_name}</Typography>
                            <Actions
                                selectedLoc={selectedLoc}
                                renderSnack={(snack)=>{setSnackBar(snack)}}
                                updateList={getAccessLocation}
                            />
                            </>
                        </CardBox>
                      </>
                        
                    }
                    {
                     (selectedLoc && dialogProps.isShowing) &&
                        <CreateClients
                            initialValues={{
                                clients:[{client_name:'',start:moment(new Date()),end:moment().add(30, 'days').format('DD MMM, YYYY'),sub_location:[],location_id:selectedLoc.location_id}]
                            }}
                            config={[...clientConfig,{
                                "value": [],
                                "title": "Sub location",
                                "key": "sub_location",
                                "help": "",
                                "type": "select",
                                "size": 3,
                                "multiSelect":true,
                                "options": selectedLoc.sub_locations
                            }]}
                            location={selectedLoc}
                            open={dialogProps.isShowing}
                            onClose={(update)=>{
                                getAccessLocation(update)
                                setDialogProps({...dialogProps,isShowing:false})
                            }}
                        />
                    }
                     {
                     (selectedLoc && dialogProps.isEditShowing) &&
                        <EditClient
                            initialValues={dialogProps.client}
                            config={[...editClientConfig,{
                                "value": [],
                                "title": "Sub locations",
                                "key": "sub_locations_ids_hidden",
                                "help": "",
                                "type": "select",
                                "size": 4,
                                "multiSelect":true,
                                "options": selectedLoc.sub_locations
                            }]}
                            open={dialogProps.isEditShowing}
                            onClose={(update)=>{
                                getAccessLocation(update)
                                setDialogProps({...dialogProps,isEditShowing:false})
                            }}
                        />
                    }
                    <SnackBar 
                        open={snackBar.open} 
                        handleClose={()=>{setSnackBar({snackBar:{open:false,msg:'',type:''}})}}
                        message={snackBar.msg}
                        type={snackBar.type}
                    />
                </div>   
            </div>
        )
    }
    var renderState=(state)=>{
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
                return(<React.Fragment> {renderPage()} </React.Fragment>)                
        }
    }
    return( 
        <React.Fragment> 
            {renderState(pageState.appState)} 
        </React.Fragment>
    )
}
export default UploadUsers;