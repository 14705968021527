import React, { useState,useEffect } from 'react';
import ContainerHeader from 'components/ContainerHeader'
import TerminalBox from 'components/TerminalBox'
import CardBox from 'components/CardBox'
import {Box,Button,InputLabel,TextField, Typography,FormHelperText,LinearProgress,InputAdornment} from '@material-ui/core'
import {DateTimePicker} from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles} from '@material-ui/core/styles'
import CircularProgress from 'components/Loader'
import API from '../../../../util/Api'
import _ from 'lodash'
import moment from "moment"

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }
))

const ControllerLogs = (props) =>{
    const classes = useStyles()
    const [loc,setLoc] = useState(null)
    const [guardList,setGuardList] = useState([])
    const [selectedLoc,setSelectedLoc] = useState(null)
    const [guard,selectedGuard] = useState(null)
    const [logs,setLogs] = useState(null)
    const [olderlogs,setOlderlogs] = useState({
        shouldFetch:false,
        startDateTime : '',
        endDateTime : ''
    })
    const [dateRange,setDateRange] = useState({
        startDateTime : null,
        endDateTime :  null
    })
    const [intervalRange,setIntervalRange] = useState({
        startDateTime : null
    })
    const [showProgress,setShowProgress] = useState(false)

    useEffect(()=>{
        API.get("location?guard=true").then(response=>{
            setLoc(response.data.data)
        }).catch(error=>{})
    },[])
    const handleSubmit =(from)=>{
        console.log('handleSubmit',selectedLoc)
        if(selectedLoc){
            
            let url=''
            if(olderlogs.shouldFetch==true){
                url = `&startTime=${olderlogs.startDateTime}&endTime=${olderlogs.endDateTime}`
                setOlderlogs({...olderlogs,shouldFetch:false})
                
            }else if(dateRange.startDateTime && dateRange.endDateTime){
                let dateRangeStartTime = moment(dateRange.startDateTime).format("x")
                let dateRamgeEndTime = moment(dateRange.endDateTime).format("x")
                setOlderlogs({...olderlogs,
                        startDateTime:dateRangeStartTime
                    })
                url = `&startTime=${dateRangeStartTime}&endTime=${dateRamgeEndTime}`
            }else if(intervalRange.startDateTime){
                url = `&startTime=${intervalRange.startDateTime}`
            }

            API.get(`findlogs?type=controller&stream_name=`+guard.id+url).then(response=>{
                setShowProgress(false)
                console.log("findlogsResponse",response.data.data)
                //if submit clicked again reset log values again
                if(from!=='submit' && logs && logs.length>0){
                    let serverLogs = response.data.data.data
                    let newData = _.unionBy(serverLogs,logs,'token')
                    newData = _.orderBy(newData,['timeStamp'],['asc'])
                    setLogs(newData)
                    console.log("newData",newData,logs)
                }else{
                    setLogs(response.data.data.data)
                    let min_obj = _.minBy(response.data.data.data, function(o) { return o.timeStamp; });
                    setOlderlogs({...olderlogs,
                        startDateTime:min_obj.timeStamp
                    })
                }
                if(response.data.data.data && response.data.data.data.length>0){
                    
                    let max_obj = _.maxBy(response.data.data.data, function(o) { return o.timeStamp; });
                    setIntervalRange({
                        startDateTime:max_obj.timeStamp
                    })
                }

            }).catch(error=>{
                setShowProgress(false)
            })
        }
        
    }
    useEffect(()=>{
        //if date range is selected , don't update logs in intervals
        if(!(dateRange.startDateTime && dateRange.endDateTime) && logs){
            const timer = setInterval(() => {
                    if(guard)
                        handleSubmit()
            }, 1000*30);
            return () => clearInterval(timer);
        }
        
    })
    //will be called when getolder logs btn is clicked from terminal
    useEffect(()=>{
        if(olderlogs.shouldFetch==true)
            handleSubmit()
    },[olderlogs.shouldFetch])
    const handleReset = () =>{
        console.log("handleReset")
        selectedGuard(null)
        setSelectedLoc(null)
        setDateRange({
            startDateTime : null,
            endDateTime :  null
        })
        setLogs(null)
        setIntervalRange({
            startDateTime:null
        })
        setShowProgress(false)
    }
    console.log("Controller-States",selectedLoc)
    return(
        <>
            <ContainerHeader title={'Controller Logs'} match={props.match}/>
            {
                loc && loc.length>0 &&
                <CardBox styleName="">
                    <div>
                        {/* 1st row */}
                        <div className={`row`} >
                            <div className="col-lg-5 col-xl-3">
                                <Autocomplete
                                    value = {selectedLoc?selectedLoc:null}
                                    id = "location"
                                    name = "location"
                                    style={{marginTop:0}}
                                    onChange={(event,value)=>{
                                        if(value)
                                        {
                                            setSelectedLoc(value)
                                            let location = _.find(loc,{id:value.id})
                                            setGuardList(location?location.guards:[])
                                            if(guard){
                                                selectedGuard(null)
                                            }

                                        }else{
                                            setSelectedLoc(null)
                                            selectedGuard(null)
                                            setGuardList([])
                                        }
                                        setLogs(null)
                                        setIntervalRange({
                                            startDateTime : null
                                        })
                                    }}
                                    options = {loc}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <React.Fragment>
                                            <InputLabel shrink={true} htmlFor="location" className={classes.label}>Location</InputLabel>
                                            <TextField name="location" {...params} margin="none" />
                                        </React.Fragment> 
                                    )}
                                />
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                <Autocomplete
                                    value = {guard}
                                    id = "guardId"
                                    name = "guardId"
                                    style={{marginTop:0}}
                                    onChange={(event,value)=>{
                                        if(value){
                                        selectedGuard(value)
                                        }else{
                                            selectedGuard(null)
                                        }
                                        setLogs(null)
                                        setIntervalRange({
                                            startDateTime : null
                                        })
                                    }}
                                    options = {(guardList.length>0?guardList:[])}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <React.Fragment>
                                                <InputLabel shrink={true} htmlFor="location" className={classes.label}>Guard Name</InputLabel>
                                                <TextField name="guardId" {...params} margin="none" />
                                        </React.Fragment> 
                                    )}
                                />
                            </div>
                            <div className="col-lg-5 col-xl-3">
                                    <FormHelperText className={classes.label}>{'Start Time'}</FormHelperText>
                                    <DateTimePicker
                                        fullWidth
                                        format="DD MMM YY - hh:mm a"
                                        value={dateRange.startDateTime} 
                                        onChange={(date)=>{
                                            setDateRange({...dateRange,startDateTime:date['_d']})
                                        }}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="zmdi zmdi-alarm"/>
                                            </InputAdornment>
                                        ),
                                        }}
                                        disableFuture
                                        minDate={new Date(moment(). subtract(15, 'days'))}
                                    />
                                </div>
                                <div className="col-lg-5 col-xl-3">
                                    <FormHelperText className={classes.label}>{'End Time'}</FormHelperText>
                                    <DateTimePicker
                                        fullWidth
                                        disableFuture
                                        format="DD MMM YY - hh:mm a"
                                        value={dateRange.endDateTime} 
                                        onChange={(date)=>{
                                            setDateRange({...dateRange,endDateTime:date['_d']})
                                        }}
                                        minDate={dateRange.startDateTime?dateRange.startDateTime:new Date()}
                                        InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="zmdi zmdi-alarm"/>
                                            </InputAdornment>
                                        ),
                                        }}
                                    />
                                </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-6 col-sm-12 col-12">
                                <Box mt="20px">
                                    <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{
                                        setIntervalRange({
                                            startDateTime:null
                                        })
                                        handleSubmit('submit')
                                        setShowProgress(true)
                                        setLogs(null)
                                    }}> Submit </Button></Box>
                                    <Box mr="10px" display="inline"><Button variant="contained" color="secondary" mr="5px" onClick={handleReset}> Reset </Button></Box>
                                </Box>
                            </div> 
                        </div>
                    </div>
                </CardBox>
            }
            {
                showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary"/>
                    <LinearProgress color="primary"/>
                </div>
            }
            {
                loc === null &&
                    <CircularProgress />
            }
            {
              logs && logs.length>0 &&
                <TerminalBox
                    logs={logs}
                    type={'Controller'}
                    name= {(selectedLoc && guard)?selectedLoc.name+' - '+guard.name:''}
                    handleRefresh={!(dateRange.startDateTime && dateRange.endDateTime)?handleSubmit:undefined}
                    getOlderLogs = {()=>{
                        setOlderlogs({
                            shouldFetch:true,
                            startDateTime:olderlogs.startDateTime-(15*60000),
                            endDateTime:olderlogs.startDateTime
                        })
                    }}
                    allowScroll={!olderlogs.shouldFetch}
                />
            }
            {
                logs && logs.length==0 &&
                <Typography style={{padding:15}} >{(selectedLoc &&guard)?'No Controller logs for '+selectedLoc.name+' - '+guard.name:''}</Typography>
            }
        </>
    )
}
export default ControllerLogs