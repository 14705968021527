import ContainerHeader from "components/ContainerHeader";
import CardBox from "components/CardBox";
import React, { useEffect, useState } from "react";
import { FormControl,FormHelperText,Select,MenuItem,Box,Button, Input, LinearProgress } from "@material-ui/core";
import { makeStyles} from '@material-ui/core/styles'
import API from "util/Api"
import SimpleTable from "components/ActionsTable/SimpleTable"


const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }
))

const DeploymentHistory = (props)=>{

    const classes = useStyles()
    const [inputs,setInputs] = useState({
        type:'',
        mac_address:null,
    })
    const [deviceList,setDeviceList] = useState(undefined)
    const [showProgress,setShowProgress] = useState(false)

    const handleChange = (e)=>{
        const {name,value} = e.target
        setInputs({...inputs,[name]:value})

    }
    const handleSubmit = ()=>{
        setDeviceList(undefined)
        setShowProgress(true)
        API.get(`deploymentHistory?data=${JSON.stringify(inputs)}`).then(res=>{
            console.log("deploymentHistory",res.data.data)
            setDeviceList(res.data.data)
            setShowProgress(false)
        })
    }
    return (
        <>
            <ContainerHeader title={'Deployment History'} match={props.match} />
            <CardBox styleName="">
            <div>
                {/* 1st row */}
                <div className={`row`} >
                    <div className="col-lg-5 col-xl-3">
                        <FormControl fullWidth>
                            <FormHelperText className={classes.label}>{'Controller Type'}</FormHelperText>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={inputs.type}
                                label="Type"
                                name={'type'}
                                onChange={handleChange}
                            >
                                <MenuItem value={'main'}>Main</MenuItem>
                                <MenuItem value={'rfid'}>RFID</MenuItem>
                                <MenuItem value={'evCharging'}>EV Charging</MenuItem>
                                <MenuItem value={'led'}>LED</MenuItem>
                                <MenuItem value={'nfc'}>NFC</MenuItem>
                                <MenuItem value={'loop'}>Loop</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-lg-5 col-xl-3">
                    <FormControl fullWidth>
                        <FormHelperText className={classes.label}>{'Mac address'}</FormHelperText>
                        <Input name='mac_address' value={inputs.mac_address} onChange={handleChange} />
                    </FormControl>   
                    </div>
                </div> 
                <div className="row mt-2">
                <div className="col-lg-6 col-sm-12 col-12">
                    <Box mt="20px">
                            <Box mr="10px" display="inline">
                                <Button variant="contained" color="primary" mr="5px" 
                                    onClick={handleSubmit}> Submit </Button>
                            </Box>
                    </Box>
                </div>
                </div>
            </div>
            </CardBox>
            {
              showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary"/>
                    <LinearProgress color="primary"/>
                </div>
            }
            {deviceList &&
                <div className="row">
                    <CardBox styleName="col-12">
                        <SimpleTable
                            type='deployment-history'
                            tableHeading={''}
                            table={{
                                headers:['MAC Address','Device Type','Device Status','Location','Action Date'],
                                tbody:deviceList
                            }}
                        />
                    </CardBox>
                </div>
            }
        </>
    )
}
export default DeploymentHistory