import React from 'react';
import {Box,Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Slide} from '@material-ui/core';
import LinearProgress from "@material-ui/core/LinearProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  console.log("AlertDialogSlide",props)
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={props.fullWidth}
        onClose={props.handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <LinearProgress style={{visibility:props.showProgress ? 'visible' : 'hidden'}} color="primary" />
        <DialogTitle id="alert-dialog-slide-title">{props.header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {props.content}
          </DialogContentText>
        </DialogContent>
        {!props.showMessage && <DialogActions>
          {props.disAgreeBtnTxt && <Button variant="outlined"  color="secondary" onClick={props.handleDialogClose}>
            {props.disAgreeBtnTxt}
          </Button>}
          {
            props.agreeBtnTxt && <Button variant="contained" color="primary" onClick={props.agreeClicked} disabled={props.isBtnDisabled}>
            {props.agreeBtnTxt}
            </Button>
          }
        </DialogActions>}
        {props.showMessage && <DialogActions>
          <Box display="inline-block">
              <Button onClick={props.handleDialogClose} variant="outlined">
                  OK
              </Button>                                     
          </Box>
        </DialogActions>}
      </Dialog>
    </div>
  );
}

export const DialogBox=(props)=>{
  return(
      <Dialog
          open={props.open}
          fullWidth
          maxWidth={'md'}
          disableBackdropClick
          onClose={props.onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">{props.header}
          </DialogTitle>
          <DialogContent>
              {props.children}
          </DialogContent>
          <DialogActions>
              <Button variant="outlined"   color="secondary" onClick={props.onClose}>
                  {'Close'}
              </Button>
              <Button variant="outlined" type="submit" onClick={props.onSubmit}  color="primary">
                  {'Submit'}
              </Button>
          </DialogActions>
    </Dialog>
  )
}
