import React from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"
const numFormatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 6 })
const maxBarSize = 40;

const StackedBar = ({ data, legends, xLabel, yLabel }) => {
    console.log('StackedBar props - ', { data, legends, xLabel, yLabel })
    return (
        <ResponsiveContainer width="95%" height={400}>
            <BarChart
                data={data}
                margin={{ top: 20, right: 0, left: 17, bottom: 0 }}
            >
                <XAxis dataKey={xLabel} />
                <YAxis angle={-45} textAnchor="end" label={{ value: yLabel, angle: 0, position: 'top' }}
                    tickFormatter={tick => {
                        return numFormatter.format(tick);
                    }} />
                <Tooltip formatter={(value) => numFormatter.format(value)} />
                <Legend margin={{ top: 100 }} iconType="circle" />
                {legends.map((n, index) => {
                    return (
                        <Bar dataKey={n.name} stackId="a" fill={n.color} maxBarSize={maxBarSize} />
                    )
                })}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default StackedBar;
