/*
 * File: index.js
 * Project: parkezdashboard
 * File Created: Thursday, 27th August 2020 9:55:52 am
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Friday, 28th August 2020 3:39:59 am
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import React, { useEffect, useState } from 'react'
import CUDetails from './CUDetails'
import API from 'util/Api'
import ActionsTable from 'components/ActionsTable/TableList'
import { Formik } from 'formik'
import PopUpDialog from 'components/PopUpDialog';
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import SnackBar from 'components/SnackBar';
import { Typography } from '@material-ui/core';

import * as Yup from "yup";

const validations = Yup.object().shape({
    fname: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    phone: Yup.string()
        .min(10, 'should be 10 digit!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),    
    location_ids: Yup.array()
        .required('Required'),
    permissions: Yup.array()
        .required('Required'),
    
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password')],"Passwords do not match")
  });
const ConsoleUsers = (props) => {

    const [config,setConfig] = useState(null)
    const [values,setValues] = useState(null)
    const [user,setUser] = useState(null)
    const [staffList,setStaffList] = useState(null)
    const [open,setDialogOpen] = useState(false)
    const [dialogContent,setDialogContent] = useState(false)
    const [guardAction,setGuardAction] = useState({isShowing:false,guard:0,status:false});
    const [snackBar,setSnackBar] = useState({open:false,msg:'',type:'error'});

    const saveStaff = (e,{setSubmitting}) => {
        console.log("save config",e)
        API.post("parking_console_staff", JSON.stringify(e))
        .then((res)=> {
            console.log("post config", res.data.data)
            setSubmitting(false)
            setUser(null)
            setSnackBar({open:true,msg:res.data.msg,type:'success'});
            setStaffList(res.data.data)

        })
        .catch((err)=>{
            console.log("post config error ", err)
            setSubmitting(false)
            setUser(null)
            try{
                setSnackBar({open:true,msg:err.response.data.msg,type:'error'});
            }catch{
                setSnackBar({open:true,msg:"Something went wrong",type:'error'});
            }
        })
    }

    const resetStaff = () => {
        // todo: do we need to do anything here?
        setUser(null)
        console.log("reset form ")        
    }
    
    useEffect(()=>{
        API.get("parking_console_staff")
        .then((res) => {
            console.log("parking_staff", res)
            setConfig(Object.values(res.data.data.config))
            setStaffList(res.data.data.consoleUsers)

            let data = {}
            for(let key in res.data.data.config){
                data[key]  = res.data.data.config[key].value
            }
            console.log('values',data);
            setValues(data)
        })
        .catch((err) => {
            // this.setState({appState:'active'})
        })
    },[])

   
    const handleStaffEdit = (clickedUser) => {
        console.log("edit ",clickedUser)
        setUser(clickedUser)
        // scroll to top 
        document.body.style.height = 'auto'            
            setTimeout(function(){
                document.body.style.height = '100%'
        },50)
    }
    const handleStaffStatus = (user) => {
        console.log(user)
        setGuardAction({guard: user.user_id.value, status: user.active.value, isShowing: true})
    }
    const handleClose = () => {
        setGuardAction({guard: 0, status: false, isShowing: false})
    }
    const onEnableConfirm = () => {
        API.post("consoleUserStatus",JSON.stringify({id:guardAction.guard,confirm: guardAction.status == 0 ? 1 : 0}))
        .then((res) => {
            console.log("res",res);
            // setStaffList([]);
            setSnackBar({open:true,msg:res.data.msg,type:'success'});
            setStaffList(res.data.data);
            setGuardAction({guard: 0, status: false, isShowing: false})
            //setUser(null);
        })
        .catch((err) => {
            setGuardAction({guard: 0, status: false, isShowing: false})
            try{
                setSnackBar({open:true,msg:err.response.data.msg,type:'error'});
            }catch{
                setSnackBar({open:true,msg:"Something went wrong",type:'error'});
            }
            
            console.log("catch",err);
        })
    }
    return(
        <>
        { config && values &&
        <Formik
            onSubmit={saveStaff}
            onReset={resetStaff}
            initialValues={values}
            validationSchema={validations}

        >
            <>
            <CUDetails
                config={config}
                user={user}
            />        
            {staffList &&
            <div className="row">
                <ActionsTable 
                    tableHeading = "Console Users" 
                    table={staffList} 
                    onEdit = {handleStaffEdit}
                    staffStatus={handleStaffStatus}
                    actions={true} 
                />
            </div>
            }
            </>
        </Formik>        
        }
        <SnackBar 
            open={snackBar.open} 
            handleClose={()=>{setSnackBar({open:false,msg:'',type:'error'})}}
            message={snackBar.msg}
            type={snackBar.type}
        />  
        <Dialog open={guardAction.isShowing} onClose={handleClose} 
            disableBackdropClick
            disableEscapeKeyDown
            >
                <DialogTitle disableTypography> 
                <Typography variant={"h6"} color={guardAction.status ? "error" : "secondary"}>
                    {guardAction.status == 1 ? "Deactivate" : "Activate"}
                </Typography>                                
                </DialogTitle>

                <DialogContent color="secondary">
                <Typography variant={"body1"}> 
                    Are you sure you want to {(guardAction.status == 1 ? 'deactivate' : 'activate')} user ?
                </Typography>                                                                                    
                </DialogContent>

                <DialogActions>
                    <Box display="inline-block">
                    <Button onClick={onEnableConfirm} variant="contained" color="primary">
                        Yes
                    </Button>                                     
                </Box>
                <Box display="inline-block">
                    <Button onClick={handleClose} variant="outlined"  color="secondary">
                        No
                    </Button>                                     
                </Box>
                </DialogActions>
            </Dialog>       
        </>
    )
}

export default ConsoleUsers