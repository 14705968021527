import React, { useEffect } from "react";
import { Form, connect } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import _ from "lodash";
import CardBox from "components/CardBox";

const EZField = connect(({ field, formik }) => {
  console.log("ezfield", field, formik);
  return !field.dependency ||
    (field.dependency &&
      formik.values[field.dependency].includes(field.dependencyValue)) ? (
    <div className="col-md-3 col-12">
      <FormField field={{ ...field, ...{ name: field.key } }} />
    </div>
  ) : null;
});

const ChargingPointSections = (props) => {
  console.log("charging point section", props);
  return (
    <CardBox styleName="col-12" heading={props.section.title}>
      <Form>
        {/* FIRST ROW */}
        <div className="row">
          {props.section.fields.map((field) => (
            <EZField field={field} key={`field-${field.key}`} />
          ))}
        </div>
        {/* BUTTONS */}
        <div className="row mt-2">
          <div className="col-lg-6 col-sm-12 col-12">
            <Box mt="20px">
              <Box mr="10px" display="inline">
                <Button
                  variant="contained"
                  color="primary"
                  mr="5px"
                  type="submit"
                >
                  {" "}
                  Submit{" "}
                </Button>
              </Box>
              <Box mr="10px" display="inline">
                <Button variant="outlined" color="secondary" type="reset">
                  Reset
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Form>
    </CardBox>
  );
};

const ChargingPointDetails = (props) => {
  console.log("config props", props);
  useEffect(() => {
    if (props.point) {
      let data = {};
      for (let key in props.point) {
        data[key] = props.point[key].value;
      }
      if (!_.isEqual(data, props.formik.values)) {
        props.formik.setValues(data);
      }
    }
    if (props.point === null) {
      props.formik.resetForm();
    }
  }, [props.point]);
  return (
    <>
      {props.config &&
        Object.keys(props.config).length > 0 &&
        props.config.map((section, index) => (
          <ChargingPointSections section={section} key={`section-${index}`} />
        ))}
    </>
  );
};
export default connect(ChargingPointDetails);
