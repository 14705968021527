import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from '@material-ui/core'
import AccessoryConfig from './AccessoryConfig'
import ControllerConfig from './ControllerConfig'
import EZTVConfig from './EZTVConfig'
import RFIDConfig from './RFIDConfig'

const LedPanelDetails = (props) => {
  const [message, setMessage] = useState('')
  console.log('LedPanelDetails-props', props)

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth={'md'}
      disableBackdropClick
      onClose={props.onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{'Config - ' + props.assignedLed.staffName}</DialogTitle>
      <Typography color='primary' style={{ marginLeft: 30 }}  >{message}</Typography>
      <DialogContent>
        <ControllerConfig
          setSnackBar={props.setSnackBar}
          assignedLed={props.assignedLed}
          controllerConfig={props.controllerConfig}
        />
        <RFIDConfig
          setSnackBar={props.setSnackBar}
          assignedLed={props.assignedLed}
          rfidConfig={props.rfidConfig}
          barrierConfig={props.barrierConfig}
          updateAssignedBarrier={props.updateAssignedBarrier}
          updateAssignedRFID={props.updateAssignedRFID}
        />
        <AccessoryConfig
          setSnackBar={props.setSnackBar}
          ledConfig={props.ledConfig}
          assignedLed={props.assignedLed}
          cameraConfig={props.cameraConfig}
          updateAssignedLed={props.updateAssignedLed}
          updateAssignedCamera={props.updateAssignedCamera}
        />
        <EZTVConfig
          staffId={props.assignedLed.staff_id}
          setSnackBar={props.setSnackBar}
          eztv={props.assignedLed.eztv}
          ezTvConfig={props.eztvConfig}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={props.onClose}>
          {"Close"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default LedPanelDetails