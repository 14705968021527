import React,{useEffect, useState} from 'react'
import {LinearProgress,FormControl,InputLabel,Select,TextField,Input,MenuItem,Box,Button,FormControlLabel,Checkbox} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import ReportPopover from 'components/ReportPopover'
import LineGraph from '../Reports/LineGraph'
import {getRoundedDate} from '../../../../../util/GenericFunc'
import ContainerHeader from 'components/ContainerHeader'
import IntlMessages from 'util/IntlMessages'
import Autocomplete from '@material-ui/lab/Autocomplete'
import axios from 'axios'
import API from 'util/Api';

const WeekDayDropdown = props =>{
    console.log("WeekDayDropdown",props)
    return(
            <div className='col-12' >
                <div className="col-md-3 col-sm-4" style={{float:'right'}}>
                <FormControl className="w-100 mb-2">
                    <InputLabel htmlFor="location">Week Day</InputLabel>
                    <Select
                    value={props.value}
                    onChange={props.handleChange}
                    input={<Input id="weekday"/>}
                    >
                        {props.weekDays.map( (weekday,index) =>(
                            <MenuItem key={`key`+weekday} value={index}>
                                {weekday}
                            </MenuItem>
                        ))}                                            
                    </Select>
                </FormControl>
            </div>
            </div>
    )
}
function getDay(){var now = new Date(); return now.getDay()}
const Predictions = (props) =>{
    const [inputs,setInputs] = useState({
        from:getRoundedDate(15,new Date()),
        to:getRoundedDate(15,new Date()),
        location:null,
        locations:[],
        vehicleTypes:[],
        selectedVehicleTypes:[],
        popoverID:'',
        selectedDay:getDay(),
        showDetails:false
    })
    const [data,setData] = useState({
        predictedUtilization:undefined,
        predictedLegends:undefined,
        weekDays:['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']

    })
    useEffect(()=>{
        API.get('location?prediction=1')
        .then((res) => {
            // received data        
            setInputs({ ...inputs, locations: res.data.data ? res.data.data : [] , errorMessage: "",vehicleTypes:res.data.data[0].vehicle_types,selectedVehicleTypes:res.data.data[0].vehicle_types,location:res.data.data[0]})
        })
        .catch((err) => {
            // got an error
        })
    },[])
    const togglePopover=(popoverID)=>{
        setInputs({...inputs,popoverID:inputs.popoverID == popoverID ? '' : popoverID});
    }
    const handleChange = name => event => {
        console.log("handleChange",name,event.target.value)
        setInputs({...inputs,selectedVehicleTypes:event.target.value})

    }
    const handleLocationChange=(event,value)=>{
        console.log(event,value)
        if(value!==null){
            setInputs({...inputs,location: value,vehicleTypes:value.vehicle_types,selectedVehicleTypes:value.vehicle_types})
        }else{
            setInputs({...inputs,location: '',vehicleTypes:[],selectedVehicleTypes:[]})
        }
            
    }
    const handleSubmit = (params)=>{
        console.log("handleSubmit",inputs)
        if(inputs.selectedVehicleTypes.length===0){
            console.log("vehicle type is required")
        }else if(!inputs.location){
            console.log("Location is required")
        }else{
            setData({...data,predictedUtilization:null})
            //get predection
            const token = localStorage.getItem('token');
            axios.defaults.headers.common = {'Authorization': JSON.parse(token)}; 
            axios.get(`${process.env.REACT_APP_VALETEZ_LINK}predict?pl_id=${inputs.location.id}&interval=0.5&details=${inputs.showDetails?1:0}&vehicleType=${inputs.selectedVehicleTypes.toString()}`).then(res=>{
                console.log("predict-res",res)
                setData({...data,predictedUtilization:res.data.data.prediction,predictedLegends:res.data.data.legends})
            }).catch(err=>{
                console.log("predict-err",err)
                setData({...data,predictedUtilization:[]})
            })
        }
        
    }
    return(
        <div>
            <ContainerHeader title={<IntlMessages id="Predicted Reports"/>} match={props.match}/>
          <div className="row">
            <div className="col-12">
                <div className="jr-card">
                    <div className="row jr-card-header d-flex align-items-center">
                        <h3 className="mb-0 col-md-12">
                            Predicted Utilization
                        </h3>
                        <ReportPopover onHover reference="Popover10" body="Number of active vehicles as per their types displayed over the chosen period." open={inputs.popoverID === 'Popover10'} togglePopover={togglePopover} />
                    </div>        
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <FormControl className="w-100">
                                <InputLabel shrink={true} htmlFor="location-input">Location</InputLabel>
                                <Autocomplete
                                    value={inputs.location}
                                    onChange={handleLocationChange}
                                    id="location"
                                    name="location"
                                    options={inputs.locations}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField id="location-input" {...params} margin="normal" />
                                    )}
                                >
                                </Autocomplete>
                                
                            </FormControl>
                        </div>
                        {/* VEHICLE TYPE */}
                        <div className="col-md-3 col-sm-6">
                            <FormControl className="w-100 mb-2">
                                <InputLabel htmlFor="location">Vehicle Types</InputLabel>
                                <Select
                                value={inputs.selectedVehicleTypes}
                                multiple={true}
                                onChange={handleChange('selectedVehicleTypes')}
                                input={<Input id="vehicleType"/>}
                                >
                                    {inputs.vehicleTypes.map( vehicle =>(
                                        <MenuItem key={`key`+vehicle} value={vehicle}>
                                            {vehicle}
                                        </MenuItem>
                                    ))}                                            
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <FormControl>
                                <FormControlLabel control={<Checkbox color='primary' checked={inputs.showDetails} onChange={e=>setInputs({...inputs,showDetails:!inputs.showDetails})} />} label="Show Details" />
                            </FormControl>
                        </div>
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">   
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={handleSubmit}> Get Details </Button></Box>
                            </Box>
                        </div> 
                    </div>
                    {
                        (data.predictedUtilization  && data.predictedUtilization.length>0) &&
                        <div className="row mb-4 mt-3">
                            <WeekDayDropdown value={inputs.selectedDay} handleChange={e=>setInputs({...inputs,selectedDay:e.target.value})} weekDays={data.weekDays}/> 
                            <LineGraph 
                                data={data.predictedUtilization[inputs.selectedDay].data} 
                                legends={data.predictedLegends} 
                                xLabel="name"
                                currencyFormat={false} 
                                yLabelProps={{ value: "no of parkings", angle: 270, position: 'middle' }}
                                min={0}
                                strokeWidth={2}
                            />
                        </div>
                    }
                    {
                        (data.predictedUtilization  && data.predictedUtilization.length===0)&&
                        <div className="mt-3">
                            No Data Found
                        </div>
                    } 
                    {
                        data.predictedUtilization===null &&
                        <div className="mt-3">
                            <LinearProgress color="primary"/><LinearProgress color="primary"/>
                        </div>
                    }
                </div>
            </div>
          </div> 
        </div>
    )
}
export default Predictions