import React from 'react'
import { Area, AreaChart, ResponsiveContainer } from "recharts"
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { DatePicker } from '@material-ui/pickers'
import ContainerHeader from 'components/ContainerHeader'
import IntlMessages from 'util/IntlMessages'
// import {Map,MapBox} from 'components/Map'
import ChartCard from '../MIS/ChartCard'
import StackedBar from './StackedBar'
import LineGraph from './LineGraph'
import TinyBarChart from './TinyBarChart'
import PieChart from './PieData'
import API from 'util/Api';
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'
import { Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReportPopover from 'components/ReportPopover'
import { Alert } from "reactstrap";
import moment from 'moment'

class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appState: 'active',
            loading: false,
            sectionLoading: false,
            location: {},
            locations: [],
            from: this.getRoundedDate(15, new Date()),
            to: this.getRoundedDate(15, new Date()),
            period: ["hourly", "daily", "monthly"],
            selectedPeriod: "hourly",
            selectedUtilizationPeriod: 'hourly',
            vehicleTypes: ['car', 'bike'],
            selectedVehicleTypes: ['car', 'bike'],
            popoverID: '',
            cumulative: false
        }
        this.handleLocationChange = this.handleLocationChange.bind(this)
        this.togglePopover = this.togglePopover.bind(this)

    }

    componentDidMount() {
        API.get('location')
            .then((res) => {
                // received data        
                this.setState({
                    locations: res.data.data ? res.data.data : [],
                    errorMessage: "",
                    // vehicleTypes: res.data.data[0].vehicle_types,
                    // selectedVehicleTypes: res.data.data[0].vehicle_types,
                    location: res.data.data[0]
                })
            })
            .catch((err) => {
                // got an error
                this.setState({ errorMessage: "Please try again" });
            })
    }
    handleChange = name => event => {
        console.log('handleChange - ', name, event)
        if (name === 'selectedVehicleTypes') {
            this.setState({ [name]: event.target.value });
        } else if (name === 'selectedPeriod') {
            this.setState({ [name]: event.target.value });
        } else if (name === 'selectedUtilizationPeriod') {
            this.setState({ [name]: event.target.value });
        } else {
            this.setState({ [name]: event });
        }
    }
    handleLocationChange(event, value) {
        console.log('handleLocationChange - ', event, value)
        if (value !== null) {
            this.setState({
                location: value,
                // vehicleTypes: value.vehicle_types,
                // selectedVehicleTypes: value.vehicle_types
            })
        } else {
            this.setState({
                location: '',
                // vehicleTypes: [],
                // selectedVehicleTypes: []
            })
        }
    }
    getRoundedDate(minutes, d = new Date(), low = true) {
        let ms = 1000 * 60 * minutes; // convert minutes to ms
        let roundedDate = new Date((Math.round(d.getTime() / ms) - (low ? 0 : -1)) * ms);

        return roundedDate
    }
    getAnalyticsData(params) {
        if (params.vehicleTypes.length === 0 ||
            params.location === null || params.location === '') {
            this.setState({ errorMsg: 'All fields are required' })

        } else {
            this.setState({ sectionLoading: true, selectedPeriod: "hourly", selectedUtilizationPeriod: 'hourly', errorMsg: '' });
            API.get('analyticDetails_v2?data=' + JSON.stringify(params))
                .then((res) => {
                    // rcvd reports data
                    let data = res.data.data;
                    // this.setState({
                    //     dailyTransactions: data.dailyTransactions,
                    //     lofsLegends: data.lengthOfStay.legends,
                    //     lofs: data.lengthOfStay, 
                    //     ps: data.priceSlabs,
                    //     ra: data.revenueSlabs,
                    //     ta: data.transactionSlabs,
                    //     sectionLoading: false,
                    //     utilsectionLoading: false,
                    //     raMin: data.min,
                    //     raMax: data.max,
                    //     pm: data.paymentModes,
                    //     pt: data.parkingTypes,
                    //     utilization: data.utilization,
                    //     utilizationMin: data.min_utilization,
                    //     utilizationMax: data.max_utilization,
                    //     pmLegends: data.paymentModeLegends,
                    //     taMin: data.transactionsMin,
                    //     taMax: data.transactionsMax,
                    //     exitTransactionSlabs: data.exitTransactionSlabs,
                    //     exitTransactionMax: data.max_transaction_exit,
                    //     exitTransactionMin: data.min_transaction_exit,
                    // })
                    this.setState({
                        data: res.data.data,
                        sectionLoading: false,
                        utilsectionLoading: false,
                    })
                })
                .catch((err) => {
                    this.setState({ errorMessage: "Please Try Again", sectionLoading: false });
                })
        }

    }
    togglePopover(popoverID) {
        this.setState({ popoverID: this.state.popoverID == popoverID ? '' : popoverID });
    }
    renderState(state) {
        switch (state) {
            case "loading":
                return (<div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}><CircularProgress color="secondary" size={50} /></div>)
            case "active":
            case "default":
                return (<div> {this.renderPage()} </div>)

        }
    }
    render() {
        return (<div> {this.renderState(this.state.appState)} </div>)
    }
    renderPage() {
        console.log('states - ', this.state)
        return (
            <div>
                <ContainerHeader title={<IntlMessages id="Analytic Reports" />} match={this.props.match} />
                {/* FILTERS */}
                <div className="row">
                    <div className="col-12">
                        <div className="jr-card">
                            <Typography variant={'h6'} >{this.state.errorMsg}</Typography>
                            <div className="row">
                                {/* LOCATION */}
                                <div className="col-md-3 col-sm-6">
                                    <FormControl className="w-100">
                                        <InputLabel shrink={true} htmlFor="location-input">Location</InputLabel>
                                        <Autocomplete
                                            value={this.state.location}
                                            onChange={this.handleLocationChange}
                                            id="location"
                                            name="location"
                                            options={this.state.locations}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField id="location-input" {...params} margin="normal" />
                                            )}
                                        >
                                        </Autocomplete>
                                    </FormControl>
                                </div>
                                {/* START DATE */}
                                <div className="col-md-3 col-sm-6">
                                    {/* <FormHelperText >Start Date</FormHelperText> */}
                                    <DatePicker
                                        fullWidth
                                        label="Start Date"
                                        format="DD MMM YY"
                                        name="from"
                                        minDate={new Date(moment().subtract(6, "months").format('YYYY-MM-01'))}
                                        maxDate={this.getRoundedDate(15, new Date())}
                                        value={this.state.from}
                                        onChange={this.handleChange('from')}
                                        animateYearScrolling
                                    />
                                </div>
                                {/* END DATE */}
                                <div className="col-md-3 col-sm-6">
                                    <DatePicker
                                        fullWidth
                                        label="End Date"
                                        format="DD MMM YY"
                                        name="to"
                                        value={this.state.to}
                                        minDate={this.state.from ? this.getRoundedDate(15, new Date(this.state.from)) : this.getRoundedDate(15, new Date())}
                                        maxDate={this.getRoundedDate(15, new Date())}
                                        onChange={this.handleChange('to')}
                                        animateYearScrolling
                                    />
                                </div>
                                {/* VEHICLE TYPE */}
                                <div className="col-md-3 col-sm-6">
                                    <FormControl className="w-100 mb-2">
                                        <InputLabel htmlFor="location">Vehicle Types</InputLabel>
                                        <Select
                                            value={this.state.selectedVehicleTypes}
                                            multiple={true}
                                            onChange={this.handleChange('selectedVehicleTypes')}
                                            input={<Input id="vehicleType" />}
                                        >
                                            {this.state.vehicleTypes.map(vehicle => (
                                                <MenuItem value={vehicle}>
                                                    {vehicle}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* BUTTONS */}
                            <div className="row mt-2">
                                <div className="col-lg-6 col-sm-12 col-12">
                                    <Box mt="20px">
                                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => this.getAnalyticsData({ 'location': this.state.location.id, 'from': this.state.from, 'to': this.state.to, 'vehicleTypes': this.state.selectedVehicleTypes, 'format': 'hourly' })}> Get Details </Button></Box>
                                        {/* <Box mr="10px" display="inline"><Button variant="contained"  name="requestReport"
                                        onClick={this.requestReportType}>Request Report</Button></Box> */}
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* HIGHLIGHTS */}
                {this.state.sectionLoading
                    ? <div><LinearProgress color="primary" /><LinearProgress color="primary" /></div>
                    : this.state.data
                        ? (this.state.data.dailyTxns?.data?.length && this.state.data.dailyRevenue?.data?.length
                            ? <div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <ReportPopover onHover reference="Popover1" body="Daily average transactions over the chosen period." open={this.state.popoverID === 'Popover1'} togglePopover={this.togglePopover} />
                                        <ChartCard prize={new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 6 }).format(this.state.data.dailyTxns.avg)} title={this.state.data.dailyTxns.percent} icon="bitcoin"
                                            children={<ResponsiveContainer className="card-img-bottom overflow-hidden" width="100%"
                                                height={75}>
                                                <AreaChart data={this.state.data.dailyTxns.data}
                                                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <defs>
                                                        <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                                                            <stop offset={this.state.data.dailyTxns.start} stopColor="#3f51b5" stopOpacity={1} />
                                                            <stop offset={this.state.data.dailyTxns.end} stopColor="#1fb6fc" stopOpacity={1} />
                                                        </linearGradient>
                                                    </defs>
                                                    <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#4D95F3'
                                                        fill="url(#color3)"
                                                        fillOpacity={1} />
                                                </AreaChart>
                                            </ResponsiveContainer>}
                                            styleName={this.state.data.dailyTxns.percent > 0 ? "up" : "down"} desc="Daily transactions" />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <ReportPopover onHover reference="Popover2" body="Daily average revenue over the chosen period." open={this.state.popoverID === 'Popover2'} togglePopover={this.togglePopover} />

                                        <ChartCard prize={'INR ' + new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(this.state.data.dailyRevenue.avg)} title={this.state.data.dailyRevenue.percent} icon="etherium"
                                            children={<ResponsiveContainer className="card-img-bottom overflow-hidden" width="100%"
                                                height={75}>
                                                <AreaChart data={this.state.data.dailyRevenue.data}
                                                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                                                    <defs>
                                                        <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                                                            <stop offset={this.state.data.dailyRevenue.start} stopColor="#aa3c6d" stopOpacity={1} />
                                                            <stop offset={this.state.data.dailyRevenue.end} stopColor="#ff9800" stopOpacity={1} />
                                                        </linearGradient>
                                                    </defs>
                                                    <Area dataKey='pv' type='monotone' strokeWidth={0} stackId="2" stroke='#4D95F3'
                                                        fill="url(#color4)"
                                                        fillOpacity={1} />
                                                </AreaChart>
                                            </ResponsiveContainer>}
                                            styleName={this.state.data.dailyRevenue.percent > 0 ? "up" : "down"} desc="Daily revenue" />
                                    </div>

                                </div>

                                <div className="row">
                                    {/* LEGNTH OF STAY CHART */}
                                    <div className="col-md-12 col-sm-12">
                                        <div className="jr-card">
                                            <div className="jr-card-header d-flex align-items-center">
                                                <h3 className="mb-0">
                                                    <ReportPopover onHover reference="Popover3" body="Duration(s) vehicles stay at the parking along with the counts for each." open={this.state.popoverID === 'Popover3'} togglePopover={this.togglePopover} />

                                                    Length Of Stay (upto 24 hours)
                                                </h3>
                                            </div>
                                            {/* <TinyBarChart data={this.state.data.lengthOfStay} legends={this.state.data.lengthOfStay.legends} xLabel="name" yLabel="No. of Vehicles" min='0' layout='horizontal' /> */}
                                            <StackedBar data={this.state.data.lengthOfStay.data} legends={this.state.data.lengthOfStay.legends} xLabel="hour" yLabel="No. of Vehicles" />
                                        </div>
                                    </div>
                                    {/* PRICE SLAB CHART */}
                                    {/* <div className="col-md-6 col-sm-12">
                                    <div className="jr-card">
                                        <ReportPopover onHover reference="Popover4" body="Number of vehicles under each rate model as per their types." open={this.state.popoverID === 'Popover4'} togglePopover={this.togglePopover} />

                                        <div className="jr-card-header d-flex align-items-center">
                                            <h3 className="mb-0">
                                                Vehicles By Rate Model
                                            </h3>
                                        </div>
                                        <TinyBarChart data={this.state.data.priceSlabs} legends={this.state.data.lengthOfStay.legends} xLabel="name" yLabel="No. of Vehicles" layout='horizontal' />
                                    </div>
                                </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="jr-card">
                                            <div className="row jr-card-header d-flex align-items-center">

                                                <h3 className="mb-0 col-md-9">
                                                    Total Revenue (in INR)
                                                </h3>
                                                <ReportPopover onHover reference="Popover5" body="Total revenue as per vehicle types displayed over period chosen." open={this.state.popoverID === 'Popover5'} togglePopover={this.togglePopover} />
                                                {/* <FormControl className="col-md-3" style={{}}>
                                                    <FormControlLabel
                                                        control={<Checkbox color="primary" checked={this.state.cumulative}
                                                            onChange={e => this.setState({ cumulative: e.target.checked })}
                                                            name="cumulative" />}
                                                        label="Group Year"
                                                    />
                                                </FormControl> */}
                                                <FormControl className="col-md-3" style={{}}>
                                                    <InputLabel htmlFor="location">PERIOD</InputLabel>
                                                    <Select
                                                        value={this.state.selectedPeriod}
                                                        onChange={this.handleChange('selectedPeriod')}
                                                        input={<Input id="ageSimple1" />}
                                                    >
                                                        {this.state.period.map(period => (
                                                            <MenuItem value={period}>
                                                                {period}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {this.state.loading
                                                ? <div><LinearProgress color="primary" /><LinearProgress color="primary" /></div>
                                                :
                                                <div>
                                                    <div className="row mb-4">
                                                        <LineGraph data={this.state.data.revenueSlabs[this.state.selectedPeriod].data} legends={this.state.data.lengthOfStay.legends} xLabel="name" yLabel="Amount(INR)" min={this.state.data.revenueSlabs[this.state.selectedPeriod].min} max={this.state.data.revenueSlabs[this.state.selectedPeriod].max} currencyFormat={true} />
                                                    </div>
                                                    <div className="row jr-card-header d-flex align-items-center">
                                                        <h3 className="mb-0 col-12">
                                                            <ReportPopover onHover reference="Popover6" body="Total transactions as per vehicle types displayed over period chosen." open={this.state.popoverID === 'Popover6'} togglePopover={this.togglePopover} />

                                                            Entry Trend
                                                        </h3>
                                                    </div>

                                                    <div className="row mb-4">
                                                        <LineGraph data={this.state.data.entryTransaction[this.state.selectedPeriod].data} legends={this.state.data.lengthOfStay.legends} xLabel="name" yLabel="No. of Vehicles" min={this.state.data.entryTransaction[this.state.selectedPeriod].min} max={this.state.data.entryTransaction[this.state.selectedPeriod].max} currencyFormat={false} />
                                                    </div>
                                                    <div className="row jr-card-header d-flex align-items-center">
                                                        <h3 className="mb-0 col-12">
                                                            <ReportPopover onHover reference="Popover7" body="Total transactions as per vehicle types displayed over period chosen." open={this.state.popoverID === 'Popover7'} togglePopover={this.togglePopover} />

                                                            Exit Trend
                                                        </h3>
                                                    </div>

                                                    <div className="row mb-4">
                                                        <LineGraph data={this.state.data.exitTransaction[this.state.selectedPeriod].data} legends={this.state.data.lengthOfStay.legends} xLabel="name" yLabel="No. of Vehicles" min={this.state.data.exitTransaction[this.state.selectedPeriod].min} max={this.state.data.exitTransaction[this.state.selectedPeriod].max} currencyFormat={false} />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div className="jr-card">
                                            <ReportPopover onHover reference="Popover8" body="Number of vehicles under each payment mode as per their types." open={this.state.popoverID === 'Popover8'} togglePopover={this.togglePopover} />

                                            <div className="jr-card-header d-flex align-items-center">
                                                <h3 className="mb-0">
                                                    Payment Modes
                                                </h3>
                                            </div>
                                            <StackedBar data={this.state.data.paymentModes.data} legends={this.state.data.paymentModes.legends} xLabel="name" yLabel="No. of Vehicles" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div className="jr-card">
                                            <ReportPopover onHover reference="Popover9" body="No. of vehicles through Pay and Park v/s Subscription based system." open={this.state.popoverID === 'Popover9'} togglePopover={this.togglePopover} />

                                            <div className="jr-card-header d-flex align-items-center">
                                                <h3 className="mb-0">
                                                    Parking Modes
                                                </h3>
                                            </div>
                                            <PieChart data={this.state.data.parkingTypes.data} legends={this.state.data.parkingTypes.legends} xLabel="name" yLabel="Count" />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="jr-card">

                                            <div className="row jr-card-header d-flex align-items-center">
                                                <h3 className="mb-0 col-md-4">
                                                    Utilization
                                                </h3>
                                                <ReportPopover onHover reference="Popover10" body="Number of active vehicles as per their types displayed over the chosen period." open={this.state.popoverID === 'Popover10'} togglePopover={this.togglePopover} />

                                                <FormControl className="col-md-3" style={{ marginLeft: "auto" }}>
                                                    <InputLabel >PERIOD</InputLabel>
                                                    <Select
                                                        value={this.state.selectedUtilizationPeriod}
                                                        onChange={this.handleChange('selectedUtilizationPeriod')}
                                                        input={<Input id="ageSimple1" />}
                                                    >
                                                        {this.state.period.map(period => (
                                                            <MenuItem value={period}>
                                                                {period}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {this.state.utilsectionLoading
                                                ? <div><LinearProgress color="primary" /><LinearProgress color="primary" /></div>
                                                :
                                                <div className="row mb-4">
                                                    <LineGraph data={this.state.data.utilization[this.state.selectedUtilizationPeriod].data} legends={this.state.data.lengthOfStay.legends} xLabel="name" yLabel="No. of Vehicles" min={this.state.data.utilization[this.state.selectedUtilizationPeriod].min} max={this.state.data.utilization[this.state.selectedUtilizationPeriod].max} currencyFormat={false} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div><Alert className="shadow-lg" color="primary">No data for this period !</Alert></div>)
                        : <></>
                }
            </div>
        )
    }
}

export default Reports