import React from 'react'
import { Button, IconButton, Typography } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { Formik, Form, connect, FieldArray } from 'formik'
import FormField from 'components/FormField'
import * as Yup from "yup"
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionActions } from "@material-ui/core";
import API from '../../../../../../util/Api'
import useStyles from './style'

const FormConfig = connect((props) => {
    const classes = useStyles();
    return (
        <Form>
            <Typography varient={"h5"} component={"h5"} className="mt-4">
                LED
            </Typography>
            <FieldArray
                name={"ledpanels"}
                render={({ insert, remove, push }) => {
                    return props.formik.values.ledpanels.map((value, i) => {
                        return (
                            <div className={"row"}>
                                {props.config.map((field, index) => {
                                    return (
                                        <div
                                            className={`col-md-${field.size} col-12`}
                                            key={`rowField-${field.key}`}
                                        >
                                            <FormField
                                                field={{
                                                    ...field,
                                                    ...{ name: `ledpanels.${i}.${field.key}` },
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="col-sm-1">
                                    {props.formik.values.ledpanels.length > 1 && (
                                        <IconButton
                                            aria-label="remove"
                                            onClick={(e) => {
                                                remove(i);
                                            }}
                                        >
                                            <RemoveIcon color="error" />
                                        </IconButton>
                                    )}
                                    {i === props.formik.values.ledpanels.length - 1 && (
                                        <IconButton
                                            aria-label="add"
                                            onClick={(e) =>
                                                push({
                                                    led_accessory_id: "",
                                                    command: "",
                                                    row: "",
                                                    type: "",
                                                })
                                            }
                                        >
                                            <AddIcon color="primary" />
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                        );
                    });
                }}
            />
            <Typography varient={"h5"} component={"h5"} className="mt-4">
                Camera
            </Typography>
            <FieldArray
                name={"cameras"}
                render={({ insert, remove, push }) => {
                    return props.formik.values.cameras.map((value, i) => {
                        return (
                            <div className={"row"}>
                                {props.cameraConfig.map((field, index) => {
                                    return (
                                        <div
                                            className={`col-md-${field.size} col-12`}
                                            key={`camera-${field.key}`}
                                        >
                                            <FormField
                                                field={{
                                                    ...field,
                                                    ...{ name: `cameras.${i}.${field.key}` },
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="col-sm-1">
                                    {props.formik.values.cameras.length > 1 && (
                                        <IconButton
                                            aria-label="remove"
                                            onClick={(e) => {
                                                remove(i);
                                            }}
                                        >
                                            <RemoveIcon color="error" />
                                        </IconButton>
                                    )}
                                    {i === props.formik.values.cameras.length - 1 && (
                                        <IconButton
                                            aria-label="add"
                                            onClick={(e) =>
                                                push({})
                                            }
                                        >
                                            <AddIcon color="primary" />
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                        );
                    });
                }}
            />
            <div className={`mt-4 ${classes.panelAction}`}>
                <AccordionActions>
                    <Button size="small" type="reset">Cancel</Button>
                    <Button size="small" color="primary" type="submit">Save</Button>
                </AccordionActions>
            </div>
        </Form>
    )
})

const AccessoryConfig = (props) => {
    console.log('AccessoryConfig-props', props)
    const classes = useStyles();
    const LedConfigSchema = () => {
        return Yup.lazy(values => {
            console.log('LedConfigSchema', values)
            return Yup.object().shape({
                // ledpanels: Yup.array().of(
                //     Yup.object().shape({
                //         led_accessory_id: Yup.string()
                //             .required("Name is requried"),
                //         command: Yup.string()
                //             .required("Command is requried"),
                //         row: Yup.string()
                //             .required("row is requried"),
                //         type: Yup.string()
                //             .required("Type is requried"),
                //     })
                // ).min(1, "Need at least a row"),
            });
        });
    }

    const saveAccessory = (e) => {
        console.log("saveAccessory", e)
        API.post('assignLed', JSON.stringify(e)).then(response => {
            // props.setMessage(response.data.msg)
            props.updateAssignedLed(response.data.data.leds)
            props.updateAssignedCamera(response.data.data.cameras)
            props.setSnackBar({ open: true, msg: response.data.msg, type: 'success' });
        }).catch(err => {
            // props.setMessage('Failed to save')
            console.log(err)
            props.setSnackBar({ open: true, msg: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong", type: 'error' });
        })
    }

    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ expanded: classes.expanded }}
            >
                <Typography className={classes.panelHeading}>
                    LED & Camera Config
                </Typography>
                <Typography className={classes.panelSubheading}></Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.panelRoot }}>
                <Formik
                    onSubmit={saveAccessory}
                    initialValues={{
                        "staff_id": props.assignedLed.staff_id,
                        "locationId": props.assignedLed.locationId,
                        "staffName": props.assignedLed.staffName,
                        "cameras": props.assignedLed.cameras,
                        "ledpanels": props.assignedLed.ledpanels
                    }}
                    validationSchema={LedConfigSchema}
                    resetInitalValue={true}
                >
                    <FormConfig
                        onClose={props.onClose}
                        config={props.ledConfig()}
                        cameraConfig={props.cameraConfig}
                    />
                </Formik>
            </AccordionDetails>
        </Accordion>
    )
}
export default AccessoryConfig;