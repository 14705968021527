import React from "react";
import {CircularProgress} from '@material-ui/core';

const Loader = (props) => 
<div  style={{
        position: "absolute", 
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent:"center"
      }}>
          <CircularProgress color="secondary" size={(props.size)?props.size:50}/>
    </div>;
export default Loader;
