import React, { useEffect, useState } from "react";
import API from "util/Api";
import ActionsTable from "components/ActionsTable/TableList";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect } from "formik";
import { Button, Box } from "@material-ui/core";
import FormField from "components/FormField";
import _ from "lodash";
import CardBox from "components/CardBox";
import ContainerHeader from "components/ContainerHeader";
import * as Yup from "yup";
// import dummyData from "./dummy";

const ParkingSearchConfig = (props) => {
  console.log("charging point section", props);
  return (
    <div className="row">
      <CardBox styleName="col-12">
      <Form>
        {/* FIRST ROW */}
        <div className="row">
          {props.section.fields.map((field) => (
            <div className="col-md-3 col-12" key={`field-${field.key}`}>
              <FormField field={{ ...field, ...{ name: field.key } }} />
            </div>
          ))}
        </div>
        {/* BUTTONS */}
        <div className="row mt-2">
          <div className="col-lg-6 col-sm-12 col-12">
            <Box mt="20px">
              <Box mr="10px" display="inline">
                <Button
                  variant="contained"
                  color="primary"
                  mr="5px"
                  type="submit"
                >
                  {" "}
                  Search{" "}
                </Button>
              </Box>
              <Box mr="10px" display="inline">
                <Button variant="outlined" color="secondary" type="reset">
                  Reset
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </Form>
    </CardBox>
    </div>
  );
};

const ParkingSearch = (props) => {
  const [config, setConfig] = useState(null);
  const [searchResult, setSearchResult] = useState();
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const validations = Yup.object().shape({
    parking_loc: Yup.object().required("Required"),
    vehicle_num: Yup.string().required("Required"),
  });

  const search = (data, form) => {
    API.post("parkingSearch", JSON.stringify(data))
      .then((res) => {
        setSearchResult(res.data.data);
        form.setSubmitting(false);
      })
      .catch((err) => {
        console.log("error", err);
        form.setSubmitting(false);
        setSnackBar({
          open: true,
          message: err?.response?.data?.msg?err.response.data.msg:"Something went wrong",
          type: "error",
        });
      });
  };
  useEffect(() => {
    API.get("parkingSearch")
      .then((res) => {
        // res.data=dummyData;
        setConfig(res.data.data);
      })
      .catch((err) => {
        setSnackBar({
          open: true,
          message: err?.response?.data?.msg?err.response.data.msg:"Something went wrong",
          type: "error",
        });
      });
  }, []);

  return (
    <>
      {config && <ContainerHeader
        title={`Search Parking Details`}
        match={props.match}
      />}
      {config && (
        <Formik
          onSubmit={search}
          initialValues={{parking_loc:'',vehicle_num:''}}
          validationSchema={validations}
        >
          <>
            {config.sections.map((section, index) => (
              <ParkingSearchConfig section={section} key={`ev-${index}`} />
            ))}
          </>
        </Formik>
      )}
      {searchResult &&
        searchResult.map((section, index) => (
          <ActionsTable
            disableSearch
            tableHeading="EV Search"
            table={section}
            key={`res-${index}`}
          />
        ))}
      <SnackBar
        {...snackBar}
        handleClose={() => {
          setSnackBar({ open: false, message: "", type: "error" });
        }}
      />
    </>
  );
};
export default ParkingSearch;
