import React, { useEffect, useState, useRef } from 'react'
import { Grid, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import API from 'util/Api'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts"
import logov from 'assets/images/logo_v.jpg'
import { Link } from "react-router-dom"
import ReactNumberTicker from 'react-animate-number-ticker'
import 'react-animate-number-ticker/dist/index.css'
import './index.css'
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


const useStyles = makeStyles((theme) => (
    {
        background: {
            backgroundImage: "url('/bg_TV-min.png')",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }
))
const graph = {
    "paymentGraph": [
        {
            "name": "Jul'22",
            "cash": 32.67,
            "fastag": 67.33
        },
        {
            "name": "Aug'22",
            "cash": 34.28,
            "fastag": 65.72
        },
        {
            "name": "Sep'22",
            "cash": 32.08,
            "fastag": 67.92
        },
        {
            "name": "Oct'22",
            "cash": 25.29,
            "fastag": 74.71
        }
    ]
}


const PaymentModeGraph = (props) => {
    return (
        <ResponsiveContainer width="100%" height="90%">
            <LineChart
                data={props.paymentGraph}
                margin={{
                    top: 30,
                    right: 20
                }}

            >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="name" strokeWidth={4} style={{ fontSize: '1vw' }} />
                <YAxis domain={[0, 100]} strokeWidth={4} style={{ fontSize: '1vw' }} />
                {/* <Tooltip /> */}
                <Legend verticalAlign={'top'} align={'right'} wrapperStyle={{ fontWeight: 'bold', fontSize: '1.1rem' }} />
                <Line
                    type="monotone"
                    dataKey="Cash"
                    strokeWidth={4}
                    stroke={"#1D2232"}
                    dot={false}
                />

                <Line type="monotone" dataKey="FASTag" strokeWidth={4} stroke="#f9a825"
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    )
}
const HeaderCard = (props) => {
    console.log("HeaderCard", props)
    const [deviceCount, setDeviceCount] = useState(props.deviceCount)
    useEffect(() => {
        const timer = setInterval(() => {
            let randomNo = Math.floor(Math.random() * (2730 - 2710)) + 2710
            setDeviceCount(randomNo)
        }, 1000 * 120)
        return () => clearInterval(timer);
    }, [])
    return (
        <Card variant="outlined" style={{ backgroundColor: '#f9a825', border: 'unset', maxHeight: '100%' }}>
            <Grid container direction='row' justifyContent='center' spacing={8} style={{ margin: 'auto', width: '100%' }}>
                <Grid item className="p-0" style={{ margin: 'auto', textAlign: 'center', padding: 'auto' }}>
                    <Typography variant='h5' style={{ fontSize: '2vw' }}>Parking transactions (10Cr+)</Typography>
                    <div className='number-container' >
                        <ReactNumberTicker number={props.bookingData.transactions.total.toLocaleString('en-IN')} />
                    </div>
                </Grid>
                <Grid item className='px-0'>
                    <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: '#1D2232', width: 3, height: 75, margin: 15 }} />
                </Grid>
                <Grid item className="p-0" style={{ textAlign: 'center', margin: 'auto' }} >
                    <Typography variant='h5' style={{ fontSize: '2vw' }}>Parking receipts (&#8377;~300Cr)</Typography>
                    <div className='number-container' >
                        <ReactNumberTicker number={props.bookingData.transactionsValue.total.toLocaleString('en-IN', { maximumFractionDigits: 0, style: 'currency', currency: 'INR' })} />
                    </div>
                </Grid>
                <Grid item className='px-0'><Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: '#1D2232', width: 3, height: 75, margin: 15 }} /></Grid>
                <Grid item className="p-0" style={{ margin: 'auto', textAlign: 'center', padding: 'auto' }}>
                    <Typography variant='h5' style={{ fontSize: '2vw' }}>Online IoT  devices</Typography>
                    {/* <Typography variant='h4' style={{color:'white',fontWeight:'bold',fontSize:'4vw'}} >{props.deviceCount.toLocaleString('en-IN')}</Typography> */}
                    <div className='number-container' >
                        <ReactNumberTicker number={deviceCount.toLocaleString('en-IN')} />
                    </div>
                </Grid>
            </Grid>
        </Card>
    )
}
const ParkingSummery = (props) => {
    const [bookingData, setBookingData] = useState(undefined)
    const [paymentGraph, setPaymentGraph] = useState(undefined)
    const [utilization, setUtilization] = useState(undefined)
    const [deviceCount, setDeviceCount] = useState(0)
    const handle = useFullScreenHandle();
    const [dotStyles, setDotStyles] = useState([]);

    const classes = useStyles()
    useEffect(() => {
        API.get('dashboardSummary?cached=1&utilizationData=1&devicesData=1&bookingData=1&paymentGraph=1')
            .then((res) => {
                console.log("dashboardSummary-cached", res.data.data)
                let resData = res.data.data
                setBookingData({ paymentModes: resData.paymentModes, transactions: resData.transactions, transactionsValue: resData.transactionsValue })
                setPaymentGraph(resData.paymentGraph)
                setUtilization(resData.utilizationData)
                setDeviceCount(resData.devicesData)
            })
            .catch((err) => {
                // got an error
            })
    }, [])

    useEffect(() => {
        let resCheck = true
        const timer = setInterval(() => {
            if (resCheck) {// allow calling api only if prv api call res rcved
                resCheck = false
                API.get('dashboardSummary?utilizationData=1')
                    .then((res) => {
                        console.log("from Interval -utilization- dashboardSummary", res.data.data)
                        // received data        
                        setUtilization(res.data.data.utilizationData)
                        resCheck = true

                    })
                    .catch((err) => {
                        // got an error
                        resCheck = true

                    })
            }

        }, 1000 * 15);
        return () => clearInterval(timer);

    }, [])

    useEffect(() => {
        let bookingResCheck = true
        const bookingTimer = setInterval(() => {
            if (bookingResCheck) {// allow calling api only if prv api call res rcved
                bookingResCheck = false
                console.log("bookingResCheck-interval", bookingResCheck)
                API.get('dashboardSummary?bookingData=1')
                    .then((res) => {
                        console.log("from Interval-bookingTimer-  dashboardSummary", res.data.data, bookingResCheck)
                        let resData = res.data.data
                        setBookingData(res.data.data)
                        bookingResCheck = true

                    })
                    .catch((err) => {
                        // got an error
                        bookingResCheck = true
                    })
            }

        }, 1000 * 2);
        return () => clearInterval(bookingTimer);

    }, [])

    const imageLoad = (data) => {
        const img = {
            width: data.target.clientWidth,
            height: data.target.clientHeight
        }
        const coords = {
            karnataka: { x: 295, y: 830 },
            telangana: { x: 405, y: 715 },
            maharashtra: { x: 320, y: 640 },
            gujrat: { x: 190, y: 510 },
            ap: { x: 410, y: 825 },
            tn: { x: 390, y: 950 },
            kerala: { x: 320, y: 989 },
            odisa: { x: 610, y: 610 },
            wb: { x: 710, y: 515 },
            up: { x: 480, y: 390 },
            assam: { x: 870, y: 390 },
            haryana: { x: 335, y: 310 },
            panjab: { x: 320, y: 236 },
            hp: { x: 374, y: 200 }
        };
        const values = Object.values(coords).map((item) => {
            return {
                left: img.width * (item.x * 100 / 1080) / 100,
                top: img.height * (item.y * 100 / 1116) / 100,
                position: 'absolute',
                width: '20px',
                display: 'block',
                animation:'fadeIn 1s'
            }
        })
        setDotStyles(values)
    }

    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (dotStyles.length) {
                const index = getRandomNumber(0, dotStyles.length - 1);
                const result = dotStyles.map((co, i) => {
                    return { ...co, display: (i === index) ? 'block' : 'none' };
                })
                setDotStyles(result);
            }
        }, 1500);

        return () => clearInterval(interval);
    }, [dotStyles]);

    console.log("summeryData", bookingData, utilization)

    return (
        <div>
            <Dialog
                fullScreen
                fullWidth
                open={true}
                classes={{ paper: classes.background }}
            >
                {
                    (bookingData && paymentGraph && utilization) &&
                    <>
                        <Grid container direction={'column'} style={{ padding: '32px 32px 20px 32px', zIndex: 2 }} spacing={2} >
                            <HeaderCard bookingData={bookingData} deviceCount={deviceCount} />
                        </Grid>
                        <Grid container direction='row' style={{ height: window.screen.height * 60 / 100, zIndex: 1, paddingTop: '10px' }}>
                            <Grid item xs={4} style={{ padding: '0px 32px 0px 32px' }}>
                                <Grid item xs={12} style={{ height: '40%' }}>
                                    <Typography variant='h5' style={{ fontSize: '2vw' }}>Payment methods</Typography>
                                    <Divider orientation="horizontal" variant="fullWidth" style={{ backgroundColor: '#f9a825', height: 2, width: 145, marginTop: 3 }} />
                                    <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }} >
                                        {
                                            Object.keys(bookingData.paymentModes).map((key, i) => {
                                                return (
                                                    <>
                                                        <div key={`paymentModes-${key}`}>
                                                            <Typography variant='h6' color={'primary'} style={{ fontSize: '2vw' }}>{key}</Typography>
                                                            <Typography variant='subtitle1' style={{ fontSize: '2.5vw', lineHeight: 1.4 }}><ReactNumberTicker number={bookingData.paymentModes[key].toLocaleString('en-IN')} /></Typography>
                                                        </div>
                                                        {(i < Object.keys(bookingData.paymentModes).length - 1) &&
                                                            <div key={`Divider${key}`}>
                                                                <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: '#1D2232', width: 2, height: '80%', margin: 12 }} />
                                                            </div>}
                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ padding: '0px 0px', height: '50%' }}>
                                    <Typography variant='h5' style={{ fontSize: '2vw' }}>FASTag vs Cash Trends</Typography>
                                    <Divider orientation="horizontal" variant="fullWidth" style={{ backgroundColor: '#f9a825', height: 2, width: 200, marginTop: 3 }} />
                                    <PaymentModeGraph paymentGraph={paymentGraph} />
                                </Grid>
                            </Grid>
                            <Grid item xs={4} style={{ padding: '0px 0px 0px 35px' }}>
                                <Typography variant='h5' style={{ fontSize: '2vw' }}>Live parking utilization</Typography>
                                <Divider orientation="horizontal" variant="fullWidth" style={{ backgroundColor: '#f9a825', height: 2, width: 170, marginTop: 3 }} />
                                <Grid container direction='row' style={{ marginTop: 10 }}>
                                    {
                                        Object.keys(utilization).map((key, i) => {
                                            return (
                                                <Grid item xs={6} key={`utilization-${key}`}>
                                                    <Typography variant='h6' color={'primary'} style={{ fontSize: '2vw' }} >{key === 'Railway/Metro Stn' ? 'Public transit' : key}</Typography>
                                                    <Typography variant='subtitle1' style={{ fontSize: '2.5vw', lineHeight: 1.4 }}><ReactNumberTicker number={utilization[key].toFixed(2) + '%'} /></Typography>
                                                </Grid>
                                            )
                                        })
                                    }
                                    <Grid item xs={12} style={{ padding: '20px 0px 0px 0px' }}>
                                        <Typography variant='h5' style={{ fontSize: '2vw' }}>ValetEZ enables</Typography>
                                        <Divider orientation="horizontal" variant="fullWidth" style={{ backgroundColor: '#f9a825', height: 2, width: 144, marginTop: 3, marginBottom: 10 }} />
                                        <Typography variant='subtitle1' style={{ fontSize: '1.4vw', lineHeight: 1.5 }}>- Seamless switch from cash to e-payments</Typography>
                                        <Typography variant='subtitle1' style={{ fontSize: '1.4vw', lineHeight: 1.5 }}>- Industry best FASTag detection at 99.7%</Typography>
                                        <Typography variant='subtitle1' style={{ fontSize: '1.4vw', lineHeight: 1.5 }}>- Automation of your parking within hours</Typography>
                                        <Typography variant='subtitle1' style={{ fontSize: '1.4vw', lineHeight: 1.5 }}>- Real time view of parking operations</Typography>
                                        <Typography variant='subtitle1' style={{ fontSize: '1.4vw', lineHeight: 1.5 }}>- ML based predictions on availability</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} style={{ padding: '0px 10px 0px 10px' }}>
                                <Typography variant='h5' style={{ fontSize: '1.9vw' }}>Our presence</Typography>
                                <Divider orientation="horizontal" variant="fullWidth" style={{ backgroundColor: '#f9a825', height: 2, width: 100, marginTop: 3 }} />
                                <Grid item style={{ textAlign: 'center', position: 'relative' }}>
                                    {dotStyles.map((style, i) => (
                                        <img src="/blink-dot.gif" style={style} key={`dot-${i}`} />
                                    ))}
                                    <img onLoad={imageLoad} src={'/black-map-min.png'} style={{ width: '100%' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>

                }
                <div style={{
                    height: window.screen.height * 20 / 100,
                    backgroundImage: "url('/bottom.jpg')",
                    backgroundPosition: 'bottom',
                    backgroundRepeat: 'repeat-x',
                    position: 'fixed',
                    textAlign: 'center',
                    bottom: 0,
                    left: 0,
                    zIndex: 0,
                    right: 0
                }}>
                    <img src={logov} style={{ height: '87px', position: 'fixed', bottom: 0, left: '45%' }} alt="ValetEZ" title="ValetEZ" />
                </div>
            </Dialog>
        </div>
    )
}
export default ParkingSummery