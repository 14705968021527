import React, {useState, useEffect} from 'react'
import {TextField,Grid} from '@material-ui/core';
 const SearchBox = (props)=>{
    const [searchValue,setSearchValue] = useState(null);
    return(
    <div className={'table-responsive-material'} style={(props.style)?props.style:{}} >
        <Grid container justifyContent={'flex-end'} >
            <TextField  value={searchValue} onChange={(e)=>{
                console.log("SearchBoxOnchange")
                setSearchValue(e.target.value);
                props.handleSearch(e.target.value);
            }}  label="Search" color="primary" />
        </Grid>
    </div>
    )
} 
export default SearchBox;