import React, { useEffect, useState } from "react";
import { Formik, Form, connect, FieldArray } from "formik";
import { Button, Box, LinearProgress, Typography } from "@material-ui/core";
import FormField from "components/FormField";
import CardBox from "components/CardBox";
import SnackBar from "components/SnackBar";
import _ from "lodash";
import ContainerHeader from "components/ContainerHeader";
import * as Yup from "yup";
import ReportTable from "components/ReportTable";
import API from "../../../../util/Api";
import { saveAs } from "file-saver";
import {Grid, makeStyles} from "@material-ui/core"
import {Area, AreaChart, ResponsiveContainer,BarChart,Bar,XAxis,YAxis,Tooltip,Legend,Label} from "recharts"
import NormalMap from 'components/Map/NormalMap'
import moment from "moment";

const useStyles = makeStyles( (theme) =>(
    {
        labelText:{
            margin: 'auto',
            marginLeft: 0,
            padding: 12,
            lineHeight:1.2,
            fontSize:15
        },
        text:{
            margin: 'auto',
            color: '#f9a825'
        }
    }
))

const FormConfig = (props) => {
  return (
    <div className="row">
      <CardBox styleName="col-12" heading="">
        <Form>
          <div className="row">
            {props.config.fields.map((field) => (
              <div className="col-md-3 col-12" key={`field-${field.key}`}>
                <FormField field={{ ...field, ...{ name: field.key } }} />
              </div>
            ))}
          </div>
          {/* BUTTONS */}
          <div className="row mt-2">
            <div className="col-lg-6 col-sm-12 col-12">
              <Box mt="20px">
                <Box mr="10px" display="inline">
                  <Button
                    variant="contained"
                    color="primary"
                    mr="5px"
                    type="submit"
                  >
                    {" "}
                    Get Details{" "}
                  </Button>
                </Box>
                <Box mr="10px" display="inline">
                  <Button variant="outlined" color="secondary" type="reset">
                    Reset
                  </Button>
                </Box>
              </Box>
            </div>
          </div>
        </Form>
      </CardBox>
    </div>
  );
};

const ProgressBar = connect(({ formik }) => {
  console.log("progress-bar-formik", formik);
  return formik.isSubmitting ? (
    <div className="col-12">
      <LinearProgress color="primary" />
      <LinearProgress color="primary" />
    </div>
  ) : (
    <></>
  );
});

const EVLocSummary = (props) => {

  const [config, setConfig] = useState();
  const [data, setData] = useState();
  const [inputs, setInputs]=useState();
  const validations = Yup.object().shape({
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
    period: Yup.object().required("Required"),
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    msg: "",
    type: "error",
  });
  const classes = useStyles()
  useEffect(() => {
    API.get("evLocSummary")
      .then((res) => {
        setConfig(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const downloadEVSummery = () => {
    API.post(`downloadEVLocSummery`, JSON.stringify(inputs))
      .then((res) => {
        saveAs(res.data.data);
      })
      .catch((err) => {
        console.log("downloadEVSummery-err", err);
      });
  };

  const handleSubmit = (e, { setSubmitting }) => {
    
    setSubmitting(true);
    let from = moment(e.from).startOf('day').format("X")
    let to = moment(e.to).endOf('day').format("X")
    let postData = {from:from,to:to,period:e.period}
    
    API.post("evLocSummary",postData ).then((res) => {
        setSubmitting(false);
        setInputs(postData);
        let lastRow = _.last(res.data.data.summary.table)
        let chargeHours = lastRow?.duration.title.split(":")
        chargeHours = chargeHours?chargeHours[0]+':'+chargeHours[1]:undefined 
        setData({...res.data.data,chargeHours:chargeHours})
      }).catch((err) => {
        setSnackBar({
          open: true,
          msg: err?.response?.data?.msg
            ? err.response.data.msg
            : "Something went wrong",
          type: "error",
        });
        setSubmitting(false);
        console.log("catch", err);
      });
  };

  return (
    <>
      <ContainerHeader title={`EV Locations Summary`} match={props.match} />
      {config && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={config.values}
          validationSchema={validations}
        >
          <>
            <FormConfig config={config} />
            <ProgressBar />
          </>
        </Formik>
      )}
      {data && (
        <>
        {/* 1st row */}
        <Grid container direction='row' spacing={4}>
            <Grid item xs={3}>
                <div className="jr-card" style={{padding:10}}> 
                    <Grid container direction='row' >
                        <Typography variant={'h6'} className={classes.labelText} >VEHICLES <br></br> CHARGED</Typography>
                        <Typography variant={'h4'} className={classes.text} >{data.fleet.vehiclesCharged}</Typography>
                    </Grid>
                </div> 
            </Grid>
            <Grid item xs={3}>
                <div className="jr-card" style={{padding:10}}> 
                    <Grid container direction='row' >
                        <Typography variant={'h6'} className={classes.labelText} >UNITS <br></br> CONSUMED</Typography>
                        <Typography variant={'h4'} className={classes.text} >{data.fleet.totalUnits}<span style={{fontSize:15}}> KWH</span></Typography>
                    </Grid>
                </div> 
            </Grid>
            <Grid item xs={3}>
                <div className="jr-card" style={{padding:10}}> 
                    <Grid container direction='row' >
                        <Typography variant={'h6'} className={classes.labelText} >FOSSIL FUEL <br></br>SAVINGS</Typography>
                        <Typography variant={'h4'} className={classes.text} >{`₹ `+data.fleet.fossilFuelSavings}</Typography>
                    </Grid>
                </div> 
            </Grid>
            <Grid item xs={3}>
                <div className="jr-card" style={{padding:10}}> 
                    <Grid container direction='row' >
                        <Typography variant={'h6'} className={classes.labelText} >Charge <br></br>Hours</Typography>
                        <Typography variant={'h4'} className={classes.text} >{data.chargeHours?data.chargeHours:0}<span style={{fontSize:15}}> Hr</span></Typography>
                    </Grid>
                </div> 
            </Grid>
        </Grid>
        {/* 2st row */}
        <Grid container direction='row' spacing={6}>
            <Grid item xs={6}>
                <div className="jr-card" style={{padding:10}}> 
                    <h5>UNITS CONSUMED</h5>
                    <ResponsiveContainer 
                    className="card-img-bottom overflow-hidden"          
                    width="100%"
                    height={150}>
                        <AreaChart 
                            data={data.fleet.unitConsumed.numData}
                            margin={{top: 0, right: 0, left: -20, bottom: 0}}>
                                <defs>
                            <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                                <stop offset={data.fleet.unitConsumed.numStart} stopColor="#3f51b5" stopOpacity={1}/>
                                <stop offset={data.fleet.unitConsumed.numEnd} stopColor="#1fb6fc" stopOpacity={1}/>
                            </linearGradient>
                            </defs>
                            <XAxis dataKey={'name'}  />
                            <YAxis tick={false} label={{ value: 'KWH', angle: -90, position: 'outsideRight' }} />
                            <Tooltip />
                            <Legend />
                            <Area 
                                name="units consumed"
                                dataKey='pv' 
                                strokeWidth={0} 
                                stackId="2" 
                                stroke='#f9a825'
                                fill="#f9a825"
                                fillOpacity={1}/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div> 
            </Grid>
            <Grid item xs={6}>
                <div className="jr-card" style={{padding:10}}>
                    <h5>BATTERY HEALTH STATUS</h5>
                    <ResponsiveContainer 
                    className="card-img-bottom overflow-hidden"          
                    width="100%"
                    height={150}>
                        <BarChart 
                            data={data.fleet.batteryHealthStatus.data}
                            margin={{top: 0, right: 0, left: -20, bottom: 0}}>
                            <XAxis dataKey={'name'}  />
                            <YAxis tick={false} />
                            <Tooltip />
                            <Legend  />
                            <Bar dataKey="uv" name="Service needed"  fill={data.fleet.batteryHealthStatus.color.uv} />
                            <Bar dataKey="pv" name="Good Health"  fill={data.fleet.batteryHealthStatus.color.pv} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Grid>
        </Grid>
        {/* 2nd Row Map */}
        <div className='row' >
            <div className="col-12" > 
                <div className='jr-card' style={{padding:20}}>
                    <h2>Charging Locations</h2>
                    <NormalMap data={data.fleet.chargingLocations} /> 
                </div>
            </div>
        </div>
        {/* 3rd row */}
        <div className="row">
          <div className="col-12">
            <ReportTable
              downloadIconBtn={downloadEVSummery}
              data={{ locationSummery: data.summary }}
            />
          </div>
        </div>
        </>
        
      )}
      <SnackBar
        open={snackBar.open}
        handleClose={() => {
          setSnackBar({ open: false });
        }}
        message={snackBar.msg}
        type={snackBar.type}
      />
    </>
  );
};
export default EVLocSummary;
