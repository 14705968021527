import React from 'react'

import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,Area, AreaChart, ResponsiveContainer} from "recharts"
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { DatePicker } from '@material-ui/pickers'

import SiteTrafficChart from "components/dashboard/Common/SiteTrafficChart"
import ContainerHeader from 'components/ContainerHeader'
import IntlMessages from 'util/IntlMessages'
// import {Map,MapBox} from 'components/Map'
import { Typography } from '@material-ui/core'
import ChartCard from '../MIS/ChartCard'
import StackedBar from './StackedBar'
import API from 'util/Api';
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'

class HistoricalAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appState:'loading',
            location: {},
            locations: [],
            period:["Hourly","Daily","Monthly"],
            vehicleTypes:[],
            selectedVehicleTypes:[],
            increamentData : [
                {name: 'Page A', pv: 200},
                {name: 'Page B', pv: 1200},
                {name: 'Page C', pv: 600},
                {name: 'Page D', pv: 1600},
                {name: 'Page D', pv: 1000},
                {name: 'Page H', pv: 2260},
                {name: 'Page K', pv: 800},
              ],
           lofs : [
                { name: '0-1', bike:50,car:20,lcv:1},
                {name: '1-2', bike:50,car:20,lcv:10},
                { name: '2-3', bike:50,car:20,lcv:10},
                { name: '3-4', bike:50,car:20,lcv:10},
                {name: '4-5', bike:50,car:20,lcv:10},
                {name: '5-6', bike:50,car:20,lcv:10},
                {  name: '6-7', bike:50,car:20,lcv:10}
              ],
            lofsLegends: [
                {name: 'bike', color:'#F8AB3D'},
                {name: 'car', color:'#00DD5E'},
                {name: 'lcv', color:'#0092DD'}
              ],
            ps : [
                { name: '0', bike:50,car:20,lcv:1},
                {name: '30', bike:50,car:20,lcv:10},
                { name: '60', bike:50,car:20,lcv:10},
                { name: '90', bike:50,car:20,lcv:10},
              ],
            psLegends: [
                {name: 'bike', color:'#F8AB3D'},
                {name: 'car', color:'#00DD5E'},
                {name: 'lcv', color:'#0092DD'}
              ],
            ra : [
                { name: '1', bike:50,car:20,lcv:1},
                {name: '2', bike:50,car:20,lcv:10},
                { name: '3', bike:50,car:20,lcv:10},
                { name: '4', bike:50,car:20,lcv:10},
                { name: '4', bike:50,car:20,lcv:10},
                { name: '5', bike:50,car:20,lcv:10},
                { name: '6', bike:50,car:20,lcv:10},
                { name: '7', bike:50,car:20,lcv:10},
                { name: '8', bike:50,car:20,lcv:10},
                { name: '9', bike:50,car:20,lcv:10},
                { name: '10', bike:50,car:20,lcv:10},
                { name: '11', bike:50,car:20,lcv:10},
                { name: '12', bike:50,car:20,lcv:10},
              ],  
            raLegends: [
                {name: 'bike', color:'#F8AB3D'},
                {name: 'car', color:'#00DD5E'},
                {name: 'lcv', color:'#0092DD'}
              ]       
        }
    }
    
    componentDidMount(){        
        API.get('location')
        .then((res) => {
            // received data        
            this.setState({ locations: res.data.data ? res.data.data : [] , errorMessage: "",vehicleTypes:res.data.data[0].vehicle_types,selectedVehicleTypes:res.data.data[0].vehicle_types})    
            this.setState({location:res.data.data[0]})
        })
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please try again"});
        })
    }
    handleChange = name => event => {
        console.log(name,event.target.value)
        this.setState({[name]: event.target.value});
    };
    handleLocationChange(event,value){
        // this.setState({location: value.id,vehicleTypes:value.vehicle_types,selectedVehicleTypes:value.vehicle_types})
    }
    render (){
        return (
            <div>
                <ContainerHeader title={<IntlMessages id="Analytic Reports"/>} match={this.props.match}/>          
                {/* FILTERS */}
                <div className="row">
                    <div className="col-12">
                        <div className="jr-card">
                            <div className="row">
                                {/* START DATE */}
                                <div className="col-md-3 col-sm-6">
                                    {/* <FormHelperText >Start Date</FormHelperText> */}
                                    <DatePicker
                                        fullWidth
                                        label="Start Date"
                                        format="DD MMM YY"
                                        name="from"
                                        value={this.state.from}
                                        onChange={this.handleChange('from')}
                                        animateYearScrolling
                                    />
                                </div>   
                                
                                {/* END DATE */}
                                <div className="col-md-3 col-sm-6">
                                    <DatePicker
                                        fullWidth
                                        label="End Date"
                                        format="DD MMM YY"
                                        name="to"
                                        value={this.state.to}
                                        onChange={this.handleChange('to')}
                                        animateYearScrolling
                                    />
                                </div>
                                {/* LOCATION */}
                                <div className="col-md-3 col-sm-6">
                                <FormControl className="w-100">
                                    <InputLabel shrink={true} htmlFor="location-input">Location</InputLabel>
                                    <Autocomplete
                                        value={this.state.location}
                                        onChange={this.handleLocationChange}
                                        id="location"
                                        name="location"
                                        options={this.state.locations}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField id="location-input" {...params} margin="normal" />
                                        )}
                                    >
                                    </Autocomplete>
                                    
                                </FormControl>
                                </div>
                                {/* VEHICLE TYPE */}
                                <div className="col-md-3 col-sm-6">
                                    <FormControl className="w-100 mb-2">
                                        <InputLabel htmlFor="location">Vehicle Types</InputLabel>
                                        <Select
                                        value={this.state.selectedVehicleTypes}
                                        multiple={true}
                                        onChange={this.handleChange('vehicleType')}
                                        input={<Input id="vehicleType"/>}
                                        >
                                            {this.state.vehicleTypes.map( vehicle =>(
                                                <MenuItem value={vehicle}>
                                                    {vehicle}
                                                </MenuItem>
                                            ))}                                            
                                        </Select>
                                    </FormControl>
                                </div>
                                
                            </div>
                        </div>    
                    </div>    
                </div>    
                {/* HIGHLIGHTS */}
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <ChartCard prize="81,345" title="23" icon="bitcoin"
                                children={<ResponsiveContainer className="card-img-bottom overflow-hidden" width="100%"
                                                                height={75}>
                                <AreaChart data={this.state.increamentData}
                                            margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                    <defs>
                                    <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                                        <stop offset="5%" stopColor="#3f51b5" stopOpacity={1}/>
                                        <stop offset="95%" stopColor="#1fb6fc" stopOpacity={1}/>
                                    </linearGradient>
                                    </defs>
                                    <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#4D95F3'
                                        fill="url(#color3)"
                                        fillOpacity={1}/>
                                </AreaChart>
                                </ResponsiveContainer>}
                        styleName="up" desc="Avg. daily transactions"/>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <ChartCard prize="2,32,678" title="23" icon="etherium"
                                children={<ResponsiveContainer className="card-img-bottom overflow-hidden" width="100%"
                                                                height={75}>
                                <AreaChart data={this.state.increamentData}
                                            margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                    <defs>
                                    <linearGradient id="color4" x1="0" y1="0" x2="1" y2="0">
                                        <stop offset="5%" stopColor="#aa3c6d" stopOpacity={1}/>
                                        <stop offset="95%" stopColor="#ff9800" stopOpacity={1}/>
                                    </linearGradient>
                                    </defs>
                                    <Area dataKey='pv' type='monotone' strokeWidth={0} stackId="2" stroke='#4D95F3'
                                        fill="url(#color4)"
                                        fillOpacity={1}/>
                                </AreaChart>
                                </ResponsiveContainer>}
                        styleName="up" desc="Daily revenue"/>
                    </div>
                
                </div>

                <div className="row">
                    {/* LEGNTH OF STAY CHART */}
                    <div className="col-md-6 col-sm-12">
                        <div className="jr-card">
                            <div className="jr-card-header d-flex align-items-center">
                                <h3 className="mb-0">
                                Length Of Stay
                                </h3>
                            </div>
                            <StackedBar data={this.state.lofs} legends={this.state.lofsLegends} xLabel="Hours" yLabel="Count" /> 
                        </div>
                                                
                    </div>
                    {/* PRICE SLAB CHART */}
                    <div className="col-md-6 col-sm-12">
                        <div className="jr-card">     
                            <div className="jr-card-header d-flex align-items-center">
                                <h3 className="mb-0">
                                Price Slab 
                                </h3>
                            </div>
                            <StackedBar data={this.state.lofs} legends={this.state.lofsLegends} xLabel="Count" yLabel="Amount(INR)" />
                        </div>                         
                    </div>                         
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="jr-card">
                            <div className="row jr-card-header d-flex align-items-center">
                                <h3 className="mb-0 col-md-4">
                                    Revenue Analytics
                                </h3>
                                <FormControl className="col-md-3" style={{marginLeft:"auto"}}>
                                        <InputLabel htmlFor="location">PERIOD</InputLabel>
                                        <Select
                                        value={this.state.location}
                                        onChange={this.handleChange('location')}
                                        input={<Input id="ageSimple1"/>}
                                        >
                                            {this.state.period.map( period =>(
                                                <MenuItem value={period}>
                                                    {period}
                                                </MenuItem>
                                            ))}                                            
                                        </Select>
                                    </FormControl>
                            </div>
                            
                            <div className="row mb-4"> 
                                <StackedBar data={this.state.ra} legends={this.state.raLegends} xLabel="" yLabel="Amount(INR)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        )
    }
}

export default HistoricalAnalytics