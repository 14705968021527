/*
 * File: LEDConfigDetails.jsx
 * Project: parkezdashboard
 * File Created: Tuesday, 25th March 2020 12:55:53 pm
 * Author: Binod Kumar Gope (binod@valetez.com)
 * -----
 * Last Modified: Thursday, 25th March 2020 4:26:02 pm
 * Modified By: Binod Kumar Gope (binod@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2021 , ValetEZ Services Pvt Ltd
 */

import React,{useEffect,useState,Fragment} from 'react'
import { Formik,Form,connect,FieldArray,Field } from 'formik'
import { Button,Box,Typography,IconButton } from '@material-ui/core'
import FormField from 'components/FormField'
import CardBox from 'components/CardBox';
import _ from "lodash";
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles( (theme) =>(
    {
        field:{
            paddingTop:5,
            paddingBottom:5
        },
        addButton:{
            margin:'10px 0'
        },
        title:{
            marginTop:15
        }
    }
))
const ConfigField = (props)=>{
    let field = _.cloneDeep(props.field)
    
    return(
        <div className={`col-md-${field.size} col-12`} key={`rowField-${field.key}`}>
            <FormField  
            field={{...field}}
            />
        </div>
    )
}
const EZField = connect(ConfigField)
const FieldArraySec = (props) =>{
    const classes = useStyles()
    console.log("FieldArraySec",props.formik.values)
    const addRow = (key)=>{
        return _.find(props.config,{key:key}).multiValues[0]
    }
    return(
        <FieldArray
            name={props.fieldKey}
            render={({ insert, remove, push }) =>{
                
                return(
                    <div className={`col-12`}>
                    {
                        <Typography className={`${classes.title}`} varient={'h5'} component={'h5'} >{props.title}</Typography>
                    }
                    {
                      props.formik.values[props.fieldKey].map((values,index)=>{
                        return(
                            <div className={`${classes.field} row`} >
                            {
                                props.fields.map((field,fieldIndex)=>{

                                    return(
                                        <EZField
                                            field={{
                                                    ...field,
                                                    ...{name:`${props.fieldKey}.${index}.${field.key}`},
                                                    ...{options: props.optionList[field.key] || field.options },
                                                    ...{disabled: (!props.optionList[field.key] || props.optionList[field.key].length) ? false : true }
                                                }}
                                                values={values}
                                        />
                                    )
                                })
                                }
                                {
                                props.addMultiValues &&
                                <div className="col-sm-2">
                                    {
                                        props.formik.values[props.fieldKey].length>1 &&
                                        <IconButton aria-label="remove" onClick={(e) => {remove(index)}}>
                                            <RemoveIcon color="error" />
                                        </IconButton>
                                    }
                                    
                                    {index === props.formik.values[props.fieldKey].length-1 &&
                                        <IconButton aria-label="add" onClick={(e) => {
                                            let obj = addRow(props.fieldKey)
                                            push(obj)
                                        }}>
                                            <AddIcon color="primary" />
                                        </IconButton>
                                    }
                                </div>
                                }
                            </div>     
                        )
                      }
                        )
                    }
                    </div>
                )
            }}
        />
    )
}

const ControllerDetails = (props) =>{
    const config = props.config
    const classes = useStyles()
    const [optionList,setOptionList] = useState({})
    const getLocOptions = (key) => {        
        var completeOptions = []
        var locID = props.formik.values.location_id
        if(locID){
            var allLocationData = _.find(config[0].fields,{key:"location_id"})
            var options = _.find(allLocationData.options,{value:locID.value})[key]
            console.log("getLocOpt",options)
            completeOptions = options
        }
        console.log('getLocOptions- ',key," - ",completeOptions,locID,config,allLocationData);
        return completeOptions;
    }
    useEffect(()=>{
        let keys = ["vehicle_type","led_id"]
        let newOptionList = optionList
        keys.map(key=>{
            newOptionList[key]= getLocOptions(key)
        })

    },[props.formik.values.location_id])
    useEffect(()=>{
        if(props.controllerConfig){  
            let isSame = _.isEqual(props.controllerConfig,props.formik.values)
            if(!isSame){
                props.formik.setValues(props.controllerConfig)                                
            }
        }                
    },[props.controllerConfig]);
    useEffect(()=>{
        if(props.resetFormik){
            props.formik.resetForm();
            props.setFalse();
        }
    },[props.resetFormik])
    console.log("ControllerDetails",props.formik.values,optionList)
    return(
    <>
    {
        config.length >0 &&
        <CardBox styleName="col-12" 
            //heading='Controller Details'
        >
            <Form>
            {/* FIRST ROW */}            
            <div>
            { config.map((conf) => {
                console.log("conf.key",conf.key)
                if(conf.type==='fields'){
                    return(
                    <div className={'row'} key={'key'+conf.type} >
                    {
                        <div className={'col-12'}>
                          <Typography className={`${classes.title}`} varient={'h5'} component={'h5'} >{conf.title}</Typography>
                        <div className={`${classes.field} row`}>
                        {
                        conf.fields.map(field=>
                            <div className="col-md-3 col-12" key={`rowField-${field.key}`}>
                                <FormField  
                                field={{
                                    ...field,
                                    ...{name:field.key}
                                }}/>
                            </div>)
                        }
                        </div>
                        </div>
                    }
                    </div>
                    )
                }else if(conf.type==='fieldArray'){
                    return(
                        <div className={'row'} key={'rowField-'+conf.key}>
                            <FieldArraySec config={config} {...conf} fieldKey={conf.key} optionList={optionList} formik={props.formik}/>
                        </div>
                    )
                }
            })
            }
            </div>
            {/* BUTTONS */}
            <div className="col-lg-6 col-sm-12 col-12">                    
                <Box mt="20px">
                    <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" > Submit </Button></Box>
                    <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset">Reset</Button></Box>
                </Box>
            </div>
            </Form>
        </CardBox>
    }
    </>
    )
}
export default connect(ControllerDetails)