import React, { useEffect, useState } from "react"
import { Button, Box, Chip, Typography, Card, CardContent,CardHeader, Select, MenuItem, InputLabel,Table,TableHead,TableRow,TableCell,TableBody, Dialog } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import _ from "lodash"
import API from "../../../../util/Api"
import {Grid, makeStyles} from "@material-ui/core"
import {Area, AreaChart, ResponsiveContainer,BarChart,Bar,XAxis,YAxis,Tooltip,Legend,Label} from "recharts"
import moment from "moment"
import FaceIcon from '@material-ui/icons/Face'
import "../EVEvent/evEvent.css"
import { BorderLinearProgress } from "./CustomizedProgress"
import DoneAll from "@material-ui/icons/DoneAll"
import { BatteryCharging20 } from "@material-ui/icons"
import LineGraphComp, { AreaChartComp, BarGraphComp } from "./LineGraph"
import DataUsageRoundedIcon from '@material-ui/icons/DataUsageRounded';
import DehazeRoundedIcon from '@material-ui/icons/DehazeRounded';
import FlashOnRoundedIcon from '@material-ui/icons/FlashOnRounded';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';
import EvStationRoundedIcon from '@material-ui/icons/EvStationRounded';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined';
import BatteryChargingFullOutlinedIcon from '@material-ui/icons/BatteryChargingFullOutlined';
import BatteryAlertOutlinedIcon from '@material-ui/icons/BatteryAlertOutlined';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import InsertInvitationRoundedIcon from '@material-ui/icons/InsertInvitationRounded';
import PhonelinkSetupRoundedIcon from '@material-ui/icons/PhonelinkSetupRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CloudOffRoundedIcon from '@material-ui/icons/CloudOffRounded';
const customdata = {
    "chargingPointSummary": {
      "total": 209,
      "available": 189,
      "charging": 20,
      "completed": 0,
      "maintenance": 0,
      "online": 88,
      "offline": 121,
      "preparing": 0,
      "stopped": 0,
      "reserved": 0,
      "error": 0
    },
    "chargingList": [
      {
        "locationId": "86",
        "locationName": "Hafeezpet PIQYU",
        "sessions": [
          {
            "vehicleNo": "1055",
            "chargeCompleted": 3,
            "remainingTime": 673,
            "status": "Charging",
            "departure": "01/11/23 08:21 pm"
          },
          {
            "vehicleNo": "1002",
            "chargeCompleted": 3,
            "remainingTime": 938,
            "status": "Charging",
            "departure": "02/11/23 12:46 am"
          },
          {
            "vehicleNo": "1002",
            "chargeCompleted": 0,
            "remainingTime": 1789,
            "status": "Stopped",
            "departure": "02/11/23 02:57 pm"
          },
          {
            "vehicleNo": "2310187",
            "chargeCompleted": 11,
            "remainingTime": 877,
            "status": "Charging",
            "departure": "01/11/23 11:45 pm"
          },
          {
            "vehicleNo": "1002",
            "chargeCompleted": 1,
            "remainingTime": 1580,
            "status": "Stopped",
            "departure": "02/11/23 11:28 am"
          }
        ]
      },
      {
        "locationId": "87",
        "locationName": "Laxmi Complex Piqyu ",
        "sessions": [
          {
            "vehicleNo": "1050",
            "chargeCompleted": 0,
            "remainingTime": 523,
            "status": "Stopped",
            "departure": "01/11/23 05:51 pm"
          },
          {
            "vehicleNo": "1050",
            "chargeCompleted": 0,
            "remainingTime": 523,
            "status": "Stopped",
            "departure": "01/11/23 05:51 pm"
          },
          {
            "vehicleNo": "1050",
            "chargeCompleted": 0,
            "remainingTime": 2657,
            "status": "Stopped",
            "departure": "03/11/23 05:25 am"
          },
          {
            "vehicleNo": "1051",
            "chargeCompleted": 39,
            "remainingTime": 495,
            "status": "Charging",
            "departure": "01/11/23 05:22 pm"
          },
          {
            "vehicleNo": "1050",
            "chargeCompleted": 1,
            "remainingTime": 902,
            "status": "Stopped",
            "departure": "02/11/23 12:10 am"
          }
        ]
      }
    ],
    "energyConsumption": {
      "totalThisWeek": 476,
      "totalLastWeek": 738,
      "data": [
        {
          "day": "Wed",
          "This week": 65,
          "Last week": 98
        },
        {
          "day": "Thu",
          "This week": 81,
          "Last week": 123
        },
        {
          "day": "Fri",
          "This week": 57,
          "Last week": 141
        },
        {
          "day": "Sat",
          "This week": 59,
          "Last week": 112
        },
        {
          "day": "Sun",
          "This week": 66,
          "Last week": 88
        },
        {
          "day": "Mon",
          "This week": 77,
          "Last week": 88
        },
        {
          "day": "Tue",
          "This week": 71,
          "Last week": 88
        }
      ]
    },
    "chargingSessions": {
      "totalThisWeek": 534,
      "totalLastWeek": 835,
      "data": [
        {
          "day": "Wed",
          "This week": "73",
          "Last week": "122"
        },
        {
          "day": "Thu",
          "This week": "77",
          "Last week": "139"
        },
        {
          "day": "Fri",
          "This week": "79",
          "Last week": "162"
        },
        {
          "day": "Sat",
          "This week": "71",
          "Last week": "137"
        },
        {
          "day": "Sun",
          "This week": "63",
          "Last week": "112"
        },
        {
          "day": "Mon",
          "This week": "85",
          "Last week": "79"
        },
        {
          "day": "Tue",
          "This week": "86",
          "Last week": "84"
        }
      ]
    },
    "avgChargingTime": {
      "totalThisWeek": 681,
      "totalLastWeek": 660,
      "data": [
        {
          "day": "Wed",
          "This week": 97,
          "Last week": 72
        },
        {
          "day": "Thu",
          "This week": 120,
          "Last week": 89
        },
        {
          "day": "Fri",
          "This week": 84,
          "Last week": 95
        },
        {
          "day": "Sat",
          "This week": 99,
          "Last week": 90
        },
        {
          "day": "Sun",
          "This week": 117,
          "Last week": 87
        },
        {
          "day": "Mon",
          "This week": 78,
          "Last week": 115
        },
        {
          "day": "Tue",
          "This week": 86,
          "Last week": 112
        }
      ]
    }
  }
const useStyles = makeStyles( (theme) =>(
    {
        labelText:{
            margin: 'auto',
            marginLeft: 0,
            padding: 12,
            lineHeight:1.2,
            fontSize:15
        },
        text:{
            margin: 'auto',
            color: '#f9a825'
        },
        tableCellCss:{
            padding:5
        },
        iconCss:{
            width:'0.9em',
            height:'0.9em'
        }
    }
))
const CustomChip = (props)=>{
    return(
        <Grid container direction="row" className="customChip" >
            <Grid item xs={3} style={{textAlign:'center',margin:'auto'}} >
                {props.item.flatIcon}
            </Grid>
            <Grid item xs={6} style={{fontSize:14,margin:'auto'}} >{props.item.name}</Grid>
            <Grid item xs={3} style={{margin:'auto',textAlign:'center',color:'#f58337'}}>{props.item.value}</Grid>
        </Grid>
    )
}
const ChargerStatus = (props)=>{
    const classes = useStyles()
    const chipData = [
        {name:"Available", value:props.summary.available, flatIcon:<CheckCircleOutlinedIcon classes={{root:classes.iconCss}} style={{color:'green'}}/>},
        {name:"Preparing", value:props.summary.preparing, flatIcon:<HourglassEmptyOutlinedIcon classes={{root:classes.iconCss}} style={{color:'#32a8a6'}} />},
        {name:"Charging", value:props.summary.charging, flatIcon:<BatteryChargingFullOutlinedIcon classes={{root:classes.iconCss}} style={{color:'#325da8'}} />},
        {name:"Stopped", value:props.summary.stopped, flatIcon:<BatteryAlertOutlinedIcon classes={{root:classes.iconCss}} style={{color:'#6481b5'}} />},
        {name:"Completed", value:props.summary.completed, flatIcon:<CheckCircleRoundedIcon classes={{root:classes.iconCss}} style={{color:'green'}} />},
        {name:"Reserved", value:props.summary.reserved, flatIcon:<InsertInvitationRoundedIcon classes={{root:classes.iconCss}} style={{color:'#c9442c'}} />},
        {name:"Maintenance", value:props.summary.maintenance, flatIcon:<PhonelinkSetupRoundedIcon classes={{root:classes.iconCss}} style={{color:'#c9442c'}} />},
        {name:"Error", value:props.summary.error, flatIcon:<ErrorRoundedIcon classes={{root:classes.iconCss}} style={{color:'red'}} />},
        {name:"Not in Service", value:props.summary.offline, flatIcon:<CloudOffRoundedIcon classes={{root:classes.iconCss}} style={{color:'grey'}} />}
    ]
    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar style={{ backgroundColor: '#f9a31b' }} aria-label="chagers">
                        <DataUsageRoundedIcon/>
                    </Avatar>
                }
                title="Charger Status"
                subheader="Number of chargers"
            >
                
            </CardHeader>
            <CardContent style={{paddingTop:0}}>
                <Grid container direction="row" >
                    <Grid item xs={12} style={{paddingBottom:5}} >
                        <Typography variant="h6" >Total Chargers: <span style={{fontSize:30}}>{props.summary.total}</span></Typography> 
                    </Grid> 
                </Grid>
                <Grid container direction="row" >
                    {
                        chipData.map(item=>(
                            <Grid item xs={6} key={`key-${item.name}`} style={{padding:2}}>
                                <CustomChip item={item}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}
const ChargingList = (props)=>{
    console.log("ChargingList",props)
    const [selected,setSelected] = useState(props.chargingList[0])
    var locs = []
    props.chargingList.map(i=>{
        locs.push({id:i.locationId,name:i.locationName})
    })
    const classes = useStyles()
    const tableHeader = ["Vehicle No","State of Charge","Remaining Time","Status","Scheduled Departure"]
    
    return(
        <Card>
            <CardHeader
                avatar={
                    <Avatar style={{ backgroundColor: '#4c89c8' }} aria-label="chagers">
                        <DehazeRoundedIcon/>
                    </Avatar>
                }
                title="Vehicle Charging List"
                subheader="The most recently"
            >
                
            </CardHeader>
            <CardContent style={{paddingTop:0}}>
                <Grid container direction="column">
                    <Grid item xs={4} style={{marginBottom:5}} >
                        <InputLabel id="demo-simple-select-label">Fleet</InputLabel>
                        <Select 
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selected.locationId}
                            onChange={(e,f)=>{
                                var res = _.find(props.chargingList,{locationId:e.target.value})
                                console.log("onChange",e.target.value,res)
                                setSelected(res)
                            }}>
                                {
                                    props.chargingList.map(loc=><MenuItem value={loc.locationId}>{loc.locationName}</MenuItem>)
                                }
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                    <Table className={''} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            {
                                tableHeader.map(i=><TableCell key={`${i}`} classes={{root:classes.tableCellCss}} align="left" >
                                <Typography variant="body1" >{i}</Typography>
                            </TableCell>)
                            }
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {selected.sessions.map((row,i) => (
                            <TableRow key={`key-${i}`}>
                                <TableCell classes={{root:classes.tableCellCss}} align="left">{row.vehicleNo}</TableCell>
                                <TableCell style={{padding:0}} align="left">
                                    <div style={{margin:'auto'}} >
                                        <BorderLinearProgress variant="determinate" value={row.chargeCompleted} />
                                        <Typography variant="body2" style={{fontSize:12,float:'right'}} >{row.chargeCompleted}%</Typography>
                                    </div>
                                </TableCell>
                                <TableCell classes={{root:classes.tableCellCss}} align="center">
                                    {(row.remainingTime=='-')?'-':convertToHrMin(row.remainingTime)}
                                </TableCell>
                                <TableCell classes={{root:classes.tableCellCss}} align="left">
                                    <Typography>{row.status=='Completed'?<DoneAll style={{color:'#389845'}} />:<BatteryCharging20 style={{color:'#f16022'}} />} {row.status}</Typography>
                                    
                                </TableCell>
                                <TableCell classes={{root:classes.tableCellCss}} align="left">
                                    {row.departure}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
const PerCount = (props) =>{
    var per = findPercentage(props?.totalLastWeek,props?.totalThisWeek)
    return(
        <span style={{fontSize:12,color:(per>0)?'green':'red'}}> {per}%</span>
    )
}
const EnergyConsuptionGraph = (props)=>{
    console.log("EnergyConsuptionGraph",props)
    return(
        <Card>
            <CardHeader
                avatar={
                    props.type=="energy_con"?
                    <Avatar style={{ backgroundColor: '#7f55a3' }} aria-label="chagers">
                        <OfflineBoltOutlinedIcon/>
                    </Avatar>
                    :<Avatar style={{ backgroundColor: '#f9a21e' }} aria-label="chagers">
                        <FlashOnRoundedIcon/>
                    </Avatar>
                }
                title={props?.title}
                subheader={props?.subheader}
            >
                
            </CardHeader>
            <CardContent style={{paddingTop:0}}>
                <Grid container direction="row" >
                    <Grid item xs={12} style={{paddingBottom:5,paddingLeft:10}} >
                        <Typography variant="h6" ><span style={{fontSize:30}}>{props?.data.totalThisWeek} </span>{props.type=="energy_con"?"kWh":"sessions"} <PerCount totalThisWeek={props?.data.totalThisWeek} totalLastWeek={props?.data.totalLastWeek} /></Typography> 
                    </Grid> 
                </Grid>
                <AreaChartComp data={props?.data?.data} type={props.type} key={`key-${props.type}`} colorCode={props.type=="energy_con"?"#9a489d":"#f36c24"} />
            </CardContent>
        </Card>
    )
}
const AvgVehicleCharge = (props) =>{
    console.log("AvgVehicleCharge",props)
    return(
        <Card>
            <CardHeader
                avatar={
                    <Avatar style={{ backgroundColor: '#ed1a7d' }} aria-label="chagers">
                        <EvStationRoundedIcon/>
                    </Avatar>
                }
                title="Average vehicle charging Time (weekly)"
                subheader="Weekly graph for energy consumption"
            >
                
            </CardHeader>
            <CardContent style={{paddingTop:0}}>
                <Grid container direction="row" >
                    <Grid item xs={12} style={{paddingBottom:5,paddingLeft:10}} >
                        <Typography variant="h6" ><span style={{fontSize:30}}>{props?.data.totalThisWeek} </span>{"mins "} <PerCount totalThisWeek={props?.data.totalThisWeek} totalLastWeek={props?.data.totalLastWeek} /> </Typography> 
                    </Grid> 
                </Grid>
                <BarGraphComp data={props?.data?.data} colorCode={{thisWeek:"#f286b6",lastWeek:"#deddd9"}} />
            </CardContent>
        </Card>
    )
}
const EVEvent = (props) => {

  const [data, setData] = useState(undefined)
  const classes = useStyles()
  const getEventData = ()=>{
    API.get("evEvent").then((res) => {
      setData(res.data.data);
      console.log("evEvent-data",res.data.data)
    }).catch((err) => {
        console.log(err)
    });
  } 
  useEffect(() => {
    // setData(customdata)
    getEventData()
    var interval = setInterval(getEventData,60000*1)//every 2 min
    return ()=> clearInterval(interval)
  }, []);

  return (
    <Dialog open={true} fullScreen
    fullWidth>
        {
              data ?
             <Grid container spacing={1} style={{padding:10}}>
                <Grid item xs={4}>
                    <ChargerStatus summary={data.chargingPointSummary} ></ChargerStatus>
                </Grid>
                <Grid item xs={8}>
                    <ChargingList chargingList={data.chargingList} ></ChargingList>
                </Grid>
                <Grid item xs={4} >
                    <EnergyConsuptionGraph data={data.energyConsumption} type="energy_con" title={'Energy Consumption'} subheader={'Weekly charging consumption'}  />
                </Grid>
                <Grid item xs={4} >
                    <EnergyConsuptionGraph data={data.chargingSessions} type="session_charge" title={'Charging Sessions'} subheader={'Weekly charging session'} />
                </Grid>
                <Grid item xs={4} >
                    <AvgVehicleCharge data={data.avgChargingTime} />
                </Grid>
            </Grid>:<></>
            }
    </Dialog>
  );
};
export default EVEvent;

function convertToHrMin(totalMinutes){
    console.log("totalMinutes",totalMinutes)
    var hours = Math.floor(totalMinutes / 60);          
    var minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2,'0')} hr ${minutes.toString().padStart(2,'0')} min`
}
function findPercentage(value, total) {
    return (100-((value/ total) * 100)).toFixed(2)
}
