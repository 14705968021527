
import React,{useEffect,useState} from 'react';
import { Button,InputLabel,Box,Select,MenuItem } from '@material-ui/core';
import API from 'util/Api';
import _ from "lodash";

const UploadSubscription = (props) => {
    console.log("upload-subsc-props", props);
    const [uploadType,setUploadType]=useState(null);
    const [file,setFile] = useState(undefined);
    const [value,setValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(()=>{
        setFile(undefined);
        setValue(null);
        setUploadType(null)
    },[props.selectedLoc])
    const uploadExcel = (e)=>{
        
        if(file){
            const data = new FormData();
            data.append('file', file);
            data.append('location_id', props.selectedLoc.id);
            data.append('subscription_type',uploadType);
            setIsSubmitting(true);
            API.post('subscriptionsCreate',data)
            .then((res) => {
                // received data        
                console.log('rcvd data',res.data)
                props.renderSnack({open:true,msg:res.data.msg,type:'success'});
                setFile(undefined);
                setValue(null);
                setUploadType(null)
                setIsSubmitting(false);
                props.updateList();
            })
            .catch((err) => {
                try{
                    props.renderSnack({open:true,msg:err.response.data.msg,type:'error'})
                }catch{
                    props.renderSnack({open:true,msg:"Something went wrong",type:'error'})
                }
                // got an error
                console.log('err',err.response)
                setIsSubmitting(false);
            })
        }else{
            props.renderSnack({open:true,msg:"Choose File",type:'error'})
        }
        console.log("uploadExcel",file)
    }
    return(
          <>
            <div className="row">
                <div className="col-12" style={{display:"flex",flexWrap:"wrap"}}>
                    <Box mr="10px" mb="10px"><Button variant="contained" color="primary" onClick={()=>{props.dwnloadSample('clients')}}> Download Client Sample  </Button></Box>
                    {props.selectedLoc.clients.length>0 && <>
                    <Box mr="10px" mb="10px"><Button variant="contained" color="primary" onClick={()=>{props.dwnloadSample('client_subscription')}}> Download Client Subscription Sample  </Button></Box>
                    <Box mr="10px" mb="10px"><Button variant="contained" color="primary" onClick={()=>{props.dwnloadSample('user_subscription')}}> Download User Subscription Sample  </Button></Box>
                    <Box mr="10px" mb="10px"><Button variant="contained" color="primary" onClick={()=>{props.dwnloadSample('user_client')}}> Download User's Client Sample  </Button></Box>
                    <Box mr="10px" mb="10px"><Button variant="contained" color="primary" onClick={()=>{props.dwnloadSample('client_code')}}> Download Client Code  </Button></Box>
                    </>}
                </div>
            </div>
            <div className="row" style={{marginTop:20}}>
                <div className={'col-md-3 col-12 '}  >
                <InputLabel id="demo-simple-select-helper-label">Select Upload Type</InputLabel>
                <Select
                    value={uploadType}
                    onChange={(e)=>{
                        setUploadType(e.target.value);
                        setFile(null);
                        setValue('');
                    }}
                    displayEmpty
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    >
                    <MenuItem value={"clients"}>{"New Clients"}</MenuItem>
                    {props.selectedLoc.clients.length>0 && <MenuItem value={"client_subscription"}>{"Clients Subscription"}</MenuItem>}
                    {props.selectedLoc.clients.length>0 && <MenuItem value={"user_subscription"}>{"User Subscription"}</MenuItem>}
                    {props.selectedLoc.clients.length>0 && <MenuItem value={"user_client"}>{"User's Client"}</MenuItem>}
                </Select>
                </div>
            </div>
            {
                uploadType &&
                <div className="row" style={{marginTop:20}}>
                    <div className={'col-md-3 col-12'} >
                        <input style={{display:'flex'}} value={value} type="file" accept=".xls,.xlsx" name="myfile" onChange={e=>{setValue(e.target.value);setFile(e.target.files[0])}}/>
                    </div>
                    <div className={'col-md-3 col-12'} >
                        <Button variant="contained" color="primary" mr="5px" disabled={isSubmitting} onClick={()=>{uploadExcel()}}> Upload</Button>
                    </div>
                </div>
            }
            
        </> 
    )
}

export default UploadSubscription;