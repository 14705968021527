import React, {useState,useEffect,useRef} from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { makeStyles,useTheme } from '@material-ui/core/styles'

import _ from 'lodash'

const useStyles = makeStyles( (theme) =>(
    {
        caption: {
            color: theme.palette.primary.main,
            lineHeight:'1em'
          },
        text: {
            color: theme.palette.secondary.main
          },
        label:{
            color: theme.palette.primary.main,
        },
        button:{
            backgroundColor:theme.palette.secondary.light,
            color:'white'
        },
        underline:{
            display:'inline-block',
            borderBottomColor: theme.palette.primary.main,
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
        },
        buttonProgress: {
            color: theme.palette.primary.main[500],
            position: 'fixed',
            marginLeft: -60,
            marginTop:6
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
            color: theme.palette.secondary.light
        },
        chipIcon:{
            color: theme.palette.primary.main,
            height: '10px',
            width: '10px'
        },
        md15:{marginBottom:"15px"},
        md20:{marginBottom:"20px"},
        md30:{marginBottom:"30px"},
      }
))

const RenewFilter =(props)=> {
    console.log("RenewFilter",props)

    const classes = useStyles();
    
    const [input, setInput] = useState({
        location:props.locations && props.locations.length > 0 ? props.locations[0].location_id : 0,
        client:null,
        subscription_type:props.subscription_type[0]
    })

    const handleInputChange = (e,val) => {        
        const {name, value} = e.target
        setInput({...input, [name]: value,client:null})
    } 

    const handleLocationChange = (e,value) => {
        if(value){
            console.log(" handleLocationChange value - ",value)
            setInput({...input, 'location': value.location_id, 'name':value.location_name,client:null})
        }
    }
    const handleClientChange =(e,value) =>{
        if(value){
            console.log("handleClientChange value - "+value)
            setInput({...input, client: value})
        }
    }
    return(
        <div>            
            {/* FIRST ROW */}            
            <div className="row">
                {/* LOCATION DROPDOWN */}
                <div className="col-lg-3 col-sm-6 col-12">
                        <FormControl className="w-100">
                            <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Location</InputLabel>
                            <Autocomplete
                                // size={props.flags.multiLocation ? "small" : ""}
                                value={_.find(props.locations,{location_id:input.location})
                                }
                                onChange={handleLocationChange}
                                id="location"
                                name="location"
                                style={{marginTop:0}}
                                options={props.locations}
                                getOptionLabel={(option) => option.location_name}
                                renderInput={(params) => (
                                    <TextField id="location-input" {...params} margin="normal" />
                                )}
                            >

                            </Autocomplete>
                            
                        </FormControl>
                </div>
                {/* SUBSCRIPTION TYPE DROPDOWN */}
                <div className="col-lg-3 col-sm-6 col-12">
                    <FormControl className="w-100" mr="50px">
                    <InputLabel htmlFor="subscription_type" className={classes.label}>Type</InputLabel>
                    <Select
                        value={input.subscription_type}
                        name='subscription_type'
                        onChange={handleInputChange}
                        input={<Input id="subscription_type"/>}                        
                    >
                        {props.subscription_type.map(subsc => (
                        <MenuItem
                            key={subsc}
                            value={subsc}
                            style={{
                                fontWeight: props.subscription_type.indexOf(subsc) !== -1 ? '500' : '400',
                            }}
                        >
                            {subsc}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </div>
                {/* CLIENTS DROPDOWN */}
                {
                    input.subscription_type=='clients'&&
                    <div className="col-lg-3 col-sm-6 col-12">
                    <FormControl className="w-100" mr="50px">
                        <InputLabel shrink={true} htmlFor="client-input" className={classes.label}>Clients</InputLabel>
                        <Autocomplete
                            // size={props.flags.multiLocation ? "small" : ""}
                            value={input.client}
                            onChange={handleClientChange}
                            id="client"
                            name="client"
                            style={{marginTop:0}}
                            options={_.find(props.locations,{location_id:input.location}).clients}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField id="location-input" {...params} margin="normal" />
                            )}
                        >

                        </Autocomplete>
                    </FormControl>
                    </div>
                }
                {/* BUTTONS */}
                {/* <div className="row mt-2">    */}
                <div className="col-lg-3 col-sm-12 col-12">
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => props.getReportData(input)}> Get Details </Button></Box>
                    </Box>
                </div> 
                {/* </div> */}
            </div>
        </div>    
    )
}


export default RenewFilter