import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../constants/ActionTypes";
import API from 'util/Api'
import { askForPermissioToReceiveNotifications} from 'Notifications/push-notification'

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignIn = ({email, password}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    API.post('login', {
        email: email,
        password: password,
      }
    ).then(({data}) => {
      console.log("userSignIn: ", data);
      if (data.msg) {
        localStorage.setItem("token", JSON.stringify(data.data.auth_token));
        localStorage.setItem('adminName', data.data.fullname);
        localStorage.setItem('user', JSON.stringify(data.data.permissions));
        API.defaults.headers.common['Authorization'] = data.data.auth_token;
        if(localStorage.getItem('permission')===null){
          askForPermissioToReceiveNotifications()
      }
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_TOKEN_SET, payload: data.data.auth_token});
        dispatch({type: USER_DATA, payload: data.data.permissions})        
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, message: error.response.data.msg})

    });
  }
};

export const userSignOut = () => {
  return (dispatch) => {
     try {
          localStorage.removeItem("token");
          localStorage.removeItem("permission");
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SIGNOUT_USER_SUCCESS});
          dispatch(setInitUrl("/"));
      } catch (error) {
          dispatch({type: FETCH_ERROR, payload: error.message});
          console.log("Error****:", error.message);
      }
  }
}
