import React from 'react'
import ConfigPage from '../ConfigPage'



/**
 * Parent component for the page
 * 
 * @param {*} props 
 */
const TariffConfiguration = (props) => {
    console.log()
    return(
        <ConfigPage url='ratemodel' title='tariff' {...props} configAdd='Rate Model'/>
    )    
}

export default TariffConfiguration