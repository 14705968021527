import React,{useState} from 'react'
import {LinearProgress,Box,Button, Grid,makeStyles,Typography} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import {getRoundedDate} from '../../../../../util/GenericFunc'
import ContainerHeader from 'components/ContainerHeader'
import API from 'util/Api'
import {Area, AreaChart, ResponsiveContainer,BarChart,Bar,XAxis,YAxis,Tooltip,Legend,Label} from "recharts"
import NormalMap from 'components/Map/NormalMap'
import moment from 'moment'
import ReportTable from 'components/ReportTable'
import _ from 'lodash'
import { saveAs } from 'file-saver'

const useStyles = makeStyles( (theme) =>(
    {
        labelText:{
            margin: 'auto',
            marginLeft: 0,
            padding: 12,
            lineHeight:1.2,
            fontSize:15
        },
        text:{
            margin: 'auto',
            color: '#f9a825'
        }
    }
))

const EVSummery = (props) =>{

    const classes = useStyles()
    const [inputs,setInputs] = useState({
        from:getRoundedDate(15,new Date()),
        to:getRoundedDate(15,new Date()),
        popoverID:''
    })
    const [data,setData] = useState(undefined)
    const togglePopover=(popoverID)=>{
        setInputs({...inputs,popoverID:inputs.popoverID == popoverID ? '' : popoverID});
    }
    const handleChange = name => event => {
        console.log("handleChange",name,event)
        setInputs({...inputs,[name]: event})
    }
    const handleSubmit = (params)=>{
        console.log("handleSubmit",inputs)
        if(!inputs.from || !inputs.to){
            console.log("from and to is required")
        }else{
            const from =moment(inputs.from).startOf('day').format("X")
            const to = moment(inputs.to).endOf('day').format("X")
            console.log("from",from,to)
            setData(null)
            API.get(`fleetData?from=${from}&to=${to}`).then(res=>{
                console.log("predict-res",res.data.data)
                let lastRow = _.last(res.data.data.locationSummery.table)
                let chargeHours = lastRow?.total_duration.title.split(":")
                chargeHours = chargeHours?chargeHours[0]+':'+chargeHours[1]:undefined 
                setData({...data,...res.data.data,chargeHours:chargeHours})
            }).catch(err=>{
                console.log("predict-err",err)
                setData({...data,data:{}})
            })
        }
        
    }
    const downloadEVSummery = (e)=>{
        const from =moment(inputs.from).startOf('day').format("X")
        const to = moment(inputs.to).endOf('day').format("X")
        API.get(`downloadEVSummery?from=${from}&to=${to}`).then(res=>{
            saveAs(res.data.data)
        }).catch(err=>{
            console.log("downloadEVSummery-err",err)
        })
    }
    const usbDeviceTest = ()=>{
        navigator.usb.requestDevice({
            filters: [{ vendorId: '1839' }]
        }).then(async device=>{
          console.log("device-details",device)
           device.open().then(async res=>{
            console.log("Open Status",res)
            await device.selectConfiguration(1);
            // await device.claimInterface(0);
           }).catch(err=>{
                console.log("Open Err",err)
           })

        })
    }
    console.log("dummy",data)
    return(
        <div>
            <ContainerHeader title={'EV Summary'} match={props.match}/>
          <div className="row">
            <div className="col-12">
                <div className="jr-card">
                    {
                        process.env.REACT_APP_ENV==='test'&&
                        <Button variant='outlined' onClick={usbDeviceTest} >Test Usb</Button>       
                    }
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-md-offset-2">
                            <DatePicker
                                fullWidth
                                label="Start Date"
                                format="DD MMM YY"
                                name="from"
                                maxDate = {getRoundedDate(15,new Date())}
                                value={inputs.from}
                                onChange={handleChange("from")}
                                animateYearScrolling
                            />
                        </div>        
                        {/* END DATE */}
                        <div className="col-md-4 col-sm-6">
                            <DatePicker
                                fullWidth
                                label="End Date"
                                format="DD MMM YY"
                                name="to"
                                value={inputs.to}
                                minDate = {inputs.from ? getRoundedDate(15,new Date(inputs.from)) : getRoundedDate(15,new Date())}
                                maxDate = {getRoundedDate(15,new Date())}
                                onChange={handleChange("to")}
                                animateYearScrolling
                            />
                        </div>
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">   
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={handleSubmit}> Get Details </Button></Box>
                            </Box>
                        </div> 
                    </div>
                </div>
                {
                    data===null &&
                    <div className="mt-3">
                        <LinearProgress color="primary"/><LinearProgress color="primary"/>
                    </div>
                }
                {
                 (data && Object.keys(data).length>0) &&
                  <>
                  <Grid container direction='row' spacing={4}>
                    <Grid item xs={3}>
                        <div className="jr-card" style={{padding:10}}> 
                            <Grid container direction='row' >
                                <Typography variant={'h6'} className={classes.labelText} >VEHICLES <br></br> CHARGED</Typography>
                                <Typography variant={'h4'} className={classes.text} >{data.vehiclesCharged}</Typography>
                            </Grid>
                        </div> 
                    </Grid>
                    <Grid item xs={3}>
                        <div className="jr-card" style={{padding:10}}> 
                            <Grid container direction='row' >
                                <Typography variant={'h6'} className={classes.labelText} >UNITS <br></br> CONSUMED</Typography>
                                <Typography variant={'h4'} className={classes.text} >{data.totalUnits}<span style={{fontSize:15}}> KWH</span></Typography>
                            </Grid>
                        </div> 
                    </Grid>
                    <Grid item xs={3}>
                        <div className="jr-card" style={{padding:10}}> 
                            <Grid container direction='row' >
                                <Typography variant={'h6'} className={classes.labelText} >FOSSIL FUEL <br></br>SAVINGS</Typography>
                                <Typography variant={'h4'} className={classes.text} >{`₹ `+data.fossilFuelSavings}</Typography>
                            </Grid>
                        </div> 
                    </Grid>
                    <Grid item xs={3}>
                        <div className="jr-card" style={{padding:10}}> 
                            <Grid container direction='row' >
                                <Typography variant={'h6'} className={classes.labelText} >Charge <br></br>Hours</Typography>
                                <Typography variant={'h4'} className={classes.text} >{data.chargeHours?data.chargeHours:0}<span style={{fontSize:15}}> Hr</span></Typography>
                            </Grid>
                        </div> 
                    </Grid>
                  </Grid>
                    {/* 1st row */}
                    <Grid container direction='row' spacing={6}>
                        <Grid item xs={6}>
                            <div className="jr-card" style={{padding:10}}> 
                                <h5>UNITS CONSUMED</h5>
                                <ResponsiveContainer 
                                className="card-img-bottom overflow-hidden"          
                                width="100%"
                                height={150}>
                                    <AreaChart 
                                        data={data.unitConsumed.numData}
                                        margin={{top: 0, right: 0, left: -20, bottom: 0}}>
                                            <defs>
                                        <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                                            <stop offset={data.unitConsumed.numStart} stopColor="#3f51b5" stopOpacity={1}/>
                                            <stop offset={data.unitConsumed.numEnd} stopColor="#1fb6fc" stopOpacity={1}/>
                                        </linearGradient>
                                        </defs>
                                        <XAxis dataKey={'name'}  />
                                        <YAxis tick={false} label={{ value: 'KWH', angle: -90, position: 'outsideRight' }} />
                                        <Tooltip />
                                        <Legend />
                                        <Area 
                                            name="units consumed"
                                            dataKey='pv' 
                                            strokeWidth={0} 
                                            stackId="2" 
                                            stroke='#f9a825'
                                            fill="#f9a825"
                                            fillOpacity={1}/>
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div> 
                        </Grid>
                        <Grid item xs={6}>
                            <div className="jr-card" style={{padding:10}}>
                                <h5>BATTERY HEALTH STATUS</h5>
                                <ResponsiveContainer 
                                className="card-img-bottom overflow-hidden"          
                                width="100%"
                                height={150}>
                                    <BarChart 
                                        data={data.batteryHealthStatus.data}
                                        margin={{top: 0, right: 0, left: -20, bottom: 0}}>
                                        <XAxis dataKey={'name'}  />
                                        <YAxis tick={false} />
                                        <Tooltip />
                                        <Legend  />
                                        <Bar dataKey="uv" name="Service needed"  fill={data.batteryHealthStatus.color.uv} />
                                        <Bar dataKey="pv" name="Good Health"  fill={data.batteryHealthStatus.color.pv} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </Grid>
                    </Grid>
                    {/* 2nd Row Map */}
                    <div className='row' >
                        <div className="col-12" > 
                            <div className='jr-card' style={{padding:20}}>
                                <h2>Charging Locations</h2>
                                <NormalMap data={data.chargingLocations} /> 
                            </div>
                        </div>
                    </div>
                    {/* 3rd Row Location Summery */}
                    <div className='row' >
                        <div className="col-12" > 
                            <ReportTable
                                downloadIconBtn={downloadEVSummery}
                                data={{locationSummery:data.locationSummery}}
                            />
                        </div>
                    </div>
                    {/* 4th Row - Top 10 Locations by Sessions in Last 10 days */}
                    <div className='row' >
                        <div className="col-6" > 
                            <ReportTable
                                data={{topLocationsSessions10:data.topLocationsSessions10}}
                            />
                        </div>
                        <div className="col-6" > 
                            <ReportTable
                                data={{topLocationsSessions30:data.topLocationsSessions30}}
                            />
                        </div>
                    </div>
                    {/* 6th Row - Top 10 Locations by Units Consumed in Last 10 days */}
                    <div className='row' >
                        <div className="col-6" > 
                            <ReportTable
                                data={{topLocationsUnits10:data.topLocationsUnits10}}
                            />
                        </div>
                        <div className="col-6" > 
                            <ReportTable
                                data={{topLocationsUnits30:data.topLocationsUnits30}}
                            />
                        </div>
                    </div>
                  </>  
                }
            </div>
          </div> 
        </div>
    )
}
export default EVSummery