import React from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import API from 'util/Api';
import ActionsTable from 'components/ActionsTable'
import CardBox from 'components/CardBox';
import Printer from './Printer'
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackBar from 'components/SnackBar';

class Printers extends React.Component {
  constructor(props) {
        super(props);
        this.state = {
            match: props.match,
            printersTable: [],
            errorMessage:'',
            selectedPrinter: null,
            locations: [],
            appState:'loading',
            snackOpen:false,
            snackMessage:'',
            snackType:'error',
            refreshForm:false
        }
        this.onEdit = this.onEdit.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
  }

  componentDidMount(){
    API.get('locations').then((res) => {
            console.log("locations", res.data.data)
            this.setState({ locations: res.data.data, appState : 'active', errorMessage: ""})
        })
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please Try Again"});
    })

    API.get("printerByUser")
        .then((res) => {
            console.log("printers", res.data.data)
            this.setState({printersTable: res.data.data.printers})
        })
        .catch((err) => {
            // got an error
            console.log("configuration api error")
    })
  }

  onEdit = (loc) => {
    console.log("edit clicked", loc)
    this.setState({selectedPrinter: loc})

    document.body.style.height = 'auto'            
        setTimeout(function(){
            document.body.style.height = '100%'
    },50)
  }
  onSubmit = (printer) => {
    console.log("Submit clicked",printer)
    API.post("printers", JSON.stringify(printer))
        .then((res)=> {
            console.log("printersonsubmit",res.data)
            this.setSnackState(true,res.data.msg,"success");
            this.setState({
              selectedPrinter:null,
              printersTable:res.data.data.printers,
              refreshForm:true})
            this.onReset();
            console.log("post config", res.data)
        })
        .catch((err)=>{
            this.setSnackState(true,err.response.data.msg,"error");
            console.log("post config error ", err)
    })
     
  }

  onReset = () => {
        console.log("reset clicked");
        this.setState({selectedPrinter:null});
  }
  setSnackState=(open,msg,type)=>{
    this.setState({snackOpen:open,snackMessage:msg,snackType:type})
  }
  handleSnackClose = ()=>{
    this.setState({
      snackOpen:false
    });
  }
  renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<React.Fragment> {this.renderPage()} </React.Fragment>)                
        }
  }

  render() {
        return (
        <React.Fragment> 
            {this.renderState(this.state.appState)}
            <SnackBar 
              open={this.state.snackOpen} 
              handleClose={this.handleSnackClose.bind(this)}
              message={this.state.snackMessage}
              type={this.state.snackType}
             />
        </React.Fragment>)
  }

  renderPage() {
    return (
      <div>
        <ContainerHeader title="Printers" match={this.props.match}/>
        <div className="row">
           <CardBox styleName="col-12" heading="Printer Details">
             {this.state.locations.length >0 &&
             <Printer
               options = {this.state.locations}
               printer = {this.state.selectedPrinter}
               onReset={()=> this.setState({selectedPrinter:null})}
               onSubmit={this.onSubmit}
               setRefresh={()=>this.setState({refreshForm:false})}
               refreshForm ={this.state.refreshForm}
             /> }
           </CardBox> 
        </div>
        <div className="row">
          {this.state.printersTable.length !==0 &&  <ActionsTable tableHeading ="Printers" table={this.state.printersTable} onEdit = {this.onEdit} actions={true}/>}
        </div>
      </div>
    );
  }
}

export default Printers;