import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ApproveBills from "./routes/ApproveBills";

const Ezcredits = ({match}) => (
	  <div className="app-wrapper">
	    <Switch>
	        <Route path={`${match.url}/bills`} component={ApproveBills}/> 
	    </Switch>

	     </div>
);

export default Ezcredits;