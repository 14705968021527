import React, {useState,useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles,useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import { Typography } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const useStyles = makeStyles( (theme) =>(
    {
        caption: {
            color: theme.palette.primary.main,
            lineHeight:'1em'
          },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        right10: {
            right:'10%'
        },  
        text: {
            color: theme.palette.secondary.main
          },
        label:{
            color: theme.palette.primary.main,
        },
        button:{
            backgroundColor:theme.palette.secondary.light,
            color:'white'
        },
        underline:{
            display:'inline-block',
            borderBottomColor: theme.palette.primary.main,
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
        },
        buttonProgress: {
            color: theme.palette.primary.main[500],
            position: 'fixed',
            marginLeft: -60,
            marginTop:6
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
            color: theme.palette.secondary.light
        },
        chipIcon:{
            color: theme.palette.primary.main,
            height: '10px',
            width: '10px'
        },
        mt5:{marginBottom:"5px"},
        md15:{marginBottom:"15px"},
        md20:{marginBottom:"20px"},
        md30:{marginBottom:"30px"},
        dispBlock:{display:'block'},
        acceptColor:{background:'green',color:'white'},
        rejectColor:{background:'red',color:'white'},
        pickupColor:{background:'orange',color:'white'},
        completeColor:{background:'green',color:'white'}
      }

))
const EditProducts =(props)=> {
    const emptyProduct = [{'product':'','quantity':0}]
    const [products, setProducts] = useState(emptyProduct)
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
  
    const handleProductChange = (e,index,val) => {   
        var product = _.cloneDeep(products);
        product[index].product = val
        setProducts(product)  
    }
    const handleQuantityChange = (e,index) => {                          
        var product = _.cloneDeep(products);
        product[index].quantity = e.target.value
        setProducts(product)   
    }
    const removeItem = (index) => {
        var product = _.cloneDeep(products);
        product.splice(index,1)
        setProducts(product) 
    }
    const addItem = (index) => {
        
        var product = _.cloneDeep(products);
        product.push({'product':'','quantity':0})
        setProducts(product) 
    }
    return(
        <div>
        { props.isShowing && 
        <Dialog open={props.isShowing} onClose={props.handleClose} 
            fullWidth={true}
            fullScreen={fullScreen}
        >
        <DialogTitle color="secondary"> 
            <Box>
                Add Products

                <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
            </Box>                   
        </DialogTitle>
        <DialogContent>
            <Box className={classes.mt5}>
                <Table>
                    {products.map((n,index) => {
                    return (
                    <TableRow key={index}>
                        <TableCell>
                            <Autocomplete                        
                                value={products[index].product != '' ? products[index].product : null }
                                onChange={(event,val) => handleProductChange(event,index,val)}
                                id="product"
                                name="product"
                                style={{marginTop:0}}
                                options={props.products}
                                getOptionLabel={(option) => option.name}                        
                                renderInput={(params) => (
                                    <React.Fragment>
                                        <InputLabel shrink={true} htmlFor="name" className={classes.label}>Choose Product</InputLabel>
                                        <TextField {...params} margin="none" />
                                    </React.Fragment>                            
                                    )}
                            >
                            </Autocomplete>
                        </TableCell>
                        <TableCell>
                            <InputLabel shrink={true} htmlFor="quantity" className={classes.label}>Quantity</InputLabel>
                            <TextField name="quantity" margin="none" onChange={(event) =>  handleQuantityChange(event,index)} value={n.quantity} fullWidth/>
                        </TableCell>
                        <TableCell>
                            {index !== 0 &&
                            
                            <IconButton aria-label="remove" onClick={(e) => {removeItem(index)}}>
                                            <RemoveIcon color="error" />
                            </IconButton>                                    
                            }
                            <IconButton aria-label="add" onClick={(e) => addItem(index)}>
                                <AddIcon color="primary" />
                            </IconButton>
                        </TableCell>
                    </TableRow>)
                    })    
                }         
                </Table>
            </Box>
    </DialogContent>
            
            <DialogActions>
            
                <Box display="inline-block">
                    <Button className={classes.right10} variant="contained" color="primary" onClick={() => props.updateProduct(products)}>
                        Update
                    </Button>                                     
                </Box>
            </DialogActions>
        </Dialog>
    }
    </div>
    )

}
export default EditProducts

