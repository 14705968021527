import React from 'react';
import {Route, Switch} from 'react-router-dom';
import FastTag from './FastTag'
import ControllerLogs from './Controller'
import LedLogs from './Led'
import LiteController from './LiteController'

const Logs = ({match}) => (
  <div className="app-wrapper">
    <Switch>
        <Route path={`${match.url}/fastag`} component={FastTag}/>
        <Route path={`${match.url}/controller`} component={ControllerLogs}/>
        <Route path={`${match.url}/lite-controller`} component={LiteController}/>
        <Route path={`${match.url}/led`} component={LedLogs}/>
    </Switch>
  </div>
);

export default Logs;
