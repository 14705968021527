import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import API from "util/Api";
import ContainerHeader from "components/ContainerHeader";
import SnackBar from "components/SnackBar";
import ActionsTable from "components/ActionsTable/TableList";
import EZForm, { getFieldValues } from "components/EZForm";

const ValetLocations = (props) => {
    console.log('ValetLocations props - ', props);
    const [fields, setFields] = useState();
    const [values, setValues] = useState();
    const [table, setTable] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        msg: "",
        type: "error",
    });
    const validations = Yup.object().shape({
        name: Yup.string().required("Required"),
        display_name: Yup.string().required("Required"),
        opening_time: Yup.string().required("Required"),
        closing_time: Yup.string().required("Required"),
        city: Yup.object().required("Required"),
        map: Yup.object().required("Required"),
    });

    const handleSubmit = (data, form) => {
        API.post("valetLocations", JSON.stringify(data))
            .then((res) => {
                setTable(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    };

    const handleEdit = (loc) => {
        const fieldValues = getFieldValues(Object.values(loc));
        console.log('fieldValues', fieldValues)
        setValues(fieldValues);
        // scroll to top
        document.body.style.height = "auto";
        setTimeout(function () {
            document.body.style.height = "100%";
        }, 50);
    }


    const rateModel = (clickedLoc) => {
        console.log('rateModel-clicked', clickedLoc)
        props.history.push({
            pathname: `${props.match.url}/rate-model/${clickedLoc.name.value.replace(/\s+/g, "-").toLowerCase()}`,
            state: clickedLoc,
        });
    };

    useEffect(() => {
        API.get("valetLocations")
            .then((res) => {
                setFields(res.data.data.config?.fields);
                setTable(res.data.data.table);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    return (
        <>
            <ContainerHeader title={"Valet Locations"} match={props.match} />
            {fields && <EZForm
                fields={fields}
                values={values}
                onSubmit={handleSubmit}
                validationSchema={validations}
            />}
            {table && <ActionsTable
                tableHeading={table?.title}
                table={table}
                onEdit={handleEdit}
                rateModel={rateModel}
                actions={true}
            />}
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar({ open: false });
                }}
            />
        </>
    )
}
export default ValetLocations;