import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AttendantStatus from "./routes/AttendantStatus";

const Attendants = ({match}) => (
  <div className="app-wrapper">
    <Switch>
     <Route path={`${match.url}/online-status`} component={AttendantStatus}/>
     {/* <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
    </Switch>

     </div>
);

export default Attendants;
