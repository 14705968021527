import React, { useEffect, useState } from "react";
import API from "util/Api";
import ContainerHeader from "components/ContainerHeader";
import ReportTable from "components/ReportTable/index";
import { saveAs } from "file-saver";

const Requests = (props) => {
    const [requestList, setRequestList] = useState([]);

    const downloadReport = (clicked) => {
        console.log('clicked-data', clicked)
        saveAs(clicked.link_hidden);
    }

    useEffect(() => {
        API.get("reportRequests")
            .then((res) => {
                setRequestList(res.data.data)
            })
            .catch((err) => {
                console.log('error', err)
            });
    }, []);

    return (
        <>
            <ContainerHeader
                title={`Report Requests`}
                match={props.match}
            />

            <ReportTable
                data={requestList}
                downloadReport={downloadReport}
            />
        </>
    );
};
export default Requests;