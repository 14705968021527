
import React, { useState, useEffect } from 'react'
import _ from "lodash"
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, makeStyles, Grid } from '@material-ui/core'
import ContainerHeader from 'components/ContainerHeader'
import logov from 'assets/images/logo_v.jpg'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer,Label, Text } from 'recharts';
import API from 'util/Api'
import ReactNumberTicker from 'react-animate-number-ticker'

const useStyles = makeStyles( (theme) =>(
    {
        labelText:{
            margin: 'auto',
            marginLeft: 0,
            padding: 5,
            lineHeight:1,
            fontSize:18
        },
        text:{
            margin: 'auto',
            color: '#f9a825'
        },
        rightAlign:{
            textAlign:"right"
        },
        statusTitle:{
            lineHeight:1
        },
        pdText:{
            color:'#252525bf',
            padding:'5px 0px'
        },
        background:{
            backgroundImage: "url('/bg_TV-min.png')",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        numberTicker:{
            margin: 'auto',
            fontSize: '3vw',
            fontWeight: 'bold',
            color:'#f9a825'
        }
    }
))
const COLORS = [ "#f9a825","#1D2232", "#FFBB28"];
  
const PragatiMaidan = (props) => {

    const classes = useStyles()
    const [values,setValues] = useState(undefined)
    const getDataService = ()=>{
        API.get("pragatiMaidanDetails").then(response=>{
            let data = response.data.data
            let collection = (data.summary[0]?.bike_count*20)+data.summary[0]?.car_count*50
            const parkingSummary = [
                {title:"Transactions",value:data.summary[0]?.transactions},
                {title:"Collection",value:"₹"+collection},
                {title:"FASTag Bookings",value:data.summary[0]?.fastag_count},
                {title:"POS Bookings",value:data.summary[0]?.cash_count},
                {title:"NFC Bookings",value:data.summary[0]?.nfc_count}
            ]
            
            setValues({summary:parkingSummary,parkingData:data.booking_data})
        }).catch(error=>{

        })
    }
    useEffect(()=>{
        getDataService()
        const fetchData = setInterval(getDataService,1000*10)
        return ()=>{
            clearInterval(fetchData)
        }
    },[])
    
    const deviceStatus = [
        {"title":"Controler Unit",status:"Online"},
        {"title":"KIOSK",status:"Online"},
        {"title":"RFID Reader",status:"Online"},
        {"title":"LED",status:"Online"},
        {"title":"Boom Barrier",status:"Online"},
        {"title":"NFC",status:"Online"},

    ]

    const utilization = [
        {name:"Space Utilization",text:'67%',data:[
                { name: "Space Utilization", value: 67 },
                { name: "Cash Percentage", value: 43 }
            ]
        },
        {name:"Cash Percentage",text:'15%',data:[
            { name: "Space Utilization", value: 15 },
            { name: "Cash Percentage", value: 85 }
        
          ]
        },
        {name:"Avg parking Time",text:'1 Hr',data:[
            { name: "Space Utilization", value: 27 },
            { name: "Cash Percentage", value: 73 }
        
          ]
        }
    ]

    return (
        <Dialog
        fullScreen
        fullWidth
        open={true}
        classes={{paper:classes.background}}>
            <Grid style={{zIndex:2,padding:'32px 32px 20px 32px'}}>
                <ContainerHeader title={'Pragati Maidan'} match={''} style={{backgroundColor:'#f9a825'}} textStyle={{color:'white'}} subText={'Smart Parking Dashboard'} />
                <Grid container >
                { 
                    values?.summary.map(item=>{
                        return(
                            <Grid item xs={2} key={`key-${item.title}`} style={{margin:'auto'}} >
                                <div className="jr-card" style={{padding:10}}> 
                                    <Grid container direction='column' >
                                        <Typography variant={'h6'} className={classes.labelText} >{item.title}</Typography>
                                        <div className={`${classes.numberTicker}`} >
                                            <ReactNumberTicker number={item.value?.toLocaleString('en-IN')}/>
                                        </div>
                                    </Grid>
                                </div> 
                            </Grid>
                        )
                    })
                }    
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <div className="jr-card" style={{padding:15}}>
                            <Grid container direction='row'>
                                <Grid item xs={7}><Typography variant='subtitle1' className={``} >Device</Typography></Grid>
                                <Grid item xs={5} className={`${classes.rightAlign}`}><Typography variant='subtitle1' >Status</Typography></Grid>
                            </Grid>
                            {
                                deviceStatus.map(item=>(
                                    <Grid container direction='row'>
                                        <Grid item xs={7} style={{margin:'auto'}} ><Typography variant='subtitle1' style={{color:'#252525bf'}} className={`${classes.statusTitle}`} >{item.title}</Typography></Grid>
                                        <Grid item xs={5} className={`${classes.rightAlign +" "+ classes.statusTitle}`}><Typography variant='subtitle1' style={{color:'green',margin:'auto'}} >{item.status}</Typography></Grid>
                                    </Grid>
                                ))
                            }
                        </div>
                        <Grid item xs={12}>
                                <Typography variant={'h5'} style={{textAlign:'center',marginTop:60}} >Real-Time Parking Data</Typography>
                            </Grid>
                    </Grid>
                    <Grid item xs={5} direction=''>
                        <div className="jr-card" style={{padding:15}}>
                            <Grid container direction='row'>
                                <Grid item xs={2}><Typography variant='subtitle1' >Type</Typography></Grid>
                                <Grid item xs={3} className={``}><Typography variant='subtitle1' >Vehicle No</Typography></Grid>
                                <Grid item xs={4} className={``}><Typography variant='subtitle1' >Time</Typography></Grid>
                                <Grid item xs={3} style={{textAlign:'center'}}><Typography variant='subtitle1' >Charges</Typography></Grid>
                                {
                                    values?.parkingData?.map(item=>(
                                    <>
                                        <Grid item xs={2} style={{margin:'auto'}} ><Typography variant='subtitle1' style={{textTransform:'capitalize'}}  className={`${classes.statusTitle} ${classes.pdText}`} >{item.type}</Typography></Grid>
                                        <Grid item xs={3} style={{margin:'auto'}} className={``}><Typography variant='subtitle1'  className={`${classes.statusTitle} ${classes.pdText}`} >{item.vehicle_no}</Typography></Grid>
                                        <Grid item style={{margin:'auto'}} xs={4}><Typography variant='subtitle1' className={`${classes.statusTitle}  ${classes.pdText}`}>{item.entry_time}</Typography></Grid>
                                        <Grid item style={{margin:'auto',textAlign:'center'}} xs={3} ><Typography variant='subtitle1' className={`${classes.statusTitle} ${classes.pdText}`} >{item.charges}</Typography></Grid>
                                    </>   
                                    ))
                                }

                            </Grid>
                        </div>
                        {/* Paicart start here */}
                        <Grid container direction='row' style={{textAlign:'center'}} justifyContent='center' >
                            {
                                utilization.map(item=>(
                                    <Grid item xs={4}>
                                        <Grid container direction='column' justifyContent='center'>
                                            <PieChart width={135} height={135} style={{margin:'auto'}} >
                                                <Pie
                                                    data={item.data}
                                                    cx={60}
                                                    cy={60}
                                                    innerRadius={35}
                                                    outerRadius={55}
                                                    fill="#8884d8"
                                                    paddingAngle={0}
                                                    dataKey="value"
                                                >
                                                    <Cell key={`cell-${0}`} fill={COLORS[0 % COLORS.length]} />
                                                    <Cell key={`cell-${1}`} fill={COLORS[1 % COLORS.length]} />
                                                    <Label style={{fontSize:'1.4rem', fontWeight:'bold'}} value={item.text} position="center" />
                                                </Pie>
                                            </PieChart>
                                            <Typography variant='subtitle2' style={{fontSize:'1.1rem'}}>{item.name}</Typography>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            
                        </Grid>
                        {/* Paicart end here */}
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item style={{textAlign:'center'}}>
                            <img src={'/pragati_loc_img.png'} style={{width:'100%'}} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>    
            <div style={{
                height:window.screen.height*20/100,
                backgroundImage: "url('/bottom.jpg')",
                backgroundPosition: 'bottom',
                backgroundRepeat: 'repeat-x',
                position: 'fixed',
                textAlign:'center',
                bottom: 0,
                left: 0,
                zIndex: 0,
                right: 0}}>
                <img src={logov} style={{height:'87px',position:'fixed',bottom:0,left:'45%'}} alt="ValetEZ" title="ValetEZ"/>
            </div>
        </Dialog>
    )
}

export default PragatiMaidan