import React from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const numFormatter = new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 6 })
const amountFormatter = new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

const LineGraph = ({ data, legends, xLabel, yLabel, min, max, currencyFormat, yLabelProps, strokeWidth }) => {
  console.log('LineGraph props - ', { data, legends, xLabel, yLabel, min, max, currencyFormat, yLabelProps, strokeWidth })
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={data} margin={{ top: 20, right: 0, left: 17, bottom: 0 }}>
        <XAxis dataKey={xLabel} padding={{ left: 0, right: 20 }} />
        {max != 0 && <YAxis angle={-45} textAnchor="end" domain={[min, max]} label={yLabelProps ? yLabelProps : { value: yLabel, angle: 0, position: 'top' }}
          tickFormatter={tick => {
            return numFormatter.format(tick);
          }} />}
        {max == 0 && <YAxis angle={-45} textAnchor="end" label={yLabelProps ? yLabelProps : { value: yLabel, angle: 0, position: 'top' }}
          tickFormatter={tick => {
            return numFormatter.format(tick);
          }} />}
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={(value) => currencyFormat ? 'INR ' + amountFormatter.format(value) : numFormatter.format(value)} />
        <Legend />
        {legends.map((n, index) => {
          return (
            <Line type="monotone" dataKey={n.name} stroke={n.color} strokeWidth={strokeWidth} />
          )
        })}
      </LineChart>
    </ResponsiveContainer>
  )
};

export default LineGraph