/*
 * File: EditLocation.js
 * Project: parkezdashboard
 * File Created: Thursday, 21st May 2020 2:50:01 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Friday, 5th June 2020 4:07:20 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * component to edit the location
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import Box from '@material-ui/core/Box'

import Button from '@material-ui/core/Button'

import PinDropIcon from '@material-ui/icons/PinDrop';
import CloseIcon from '@material-ui/icons/Close'
import { CloudUpload } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles'
import { Dialog, AppBar, IconButton, Toolbar, Select, MenuItem } from '@material-ui/core';
import API from 'util/Api';

import _ from 'lodash'

import { MapBox, MapAPI } from 'components/Map/MapBox'
import GMap from 'components/Map/GMap'
import Axios from 'axios';

const useStyles = makeStyles((theme) => (
    {
        mapButton: {
            marginTop: '32px',
            color: theme.palette.secondary.light,
        },
        label: {
            color: theme.palette.primary.main,
            marginTop: '16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        imgErr: {
            color: 'red'
        }
    }
))

const EditLocation = (props) => {

    const classes = useStyles();
    const emptyLocation = {
        id: null,
        name: "",
        printName: "",
        time_offset: "",
        opening_time: "",
        closing_time: "",
        gst: "",
        city: null,
        tag: null,
        plt_id: null,
        vehicle: [],
        long: 77.5946,
        lat: 12.9716,
        locality: "",
        logo: "",
        plazaId: null,
        orgId: null,
        lanePrefix: null,
        agencyCode: null,
        acquirerId: null,
        bank: null,
        isFastTagLoc: false,
        fastag_consent: false,
        admin_email: "",
        fastag_plaza_type: "",
        price_mode: "",
        location_bypass_id: "",
        landmark: ""
    }

    const [location, setLocation] = useState(emptyLocation)
    const [showMap, setShowMap] = useState(false)
    const [viewport, setViewport] = useState({
        latitude: 12.9716,
        longitude: 77.5946,
        zoom: 12
    })
    const [imgErr, setImgErr] = useState(null);
    const plazaTypes = [{ title: 'Parking', value: 'parking' }, { title: 'Toll', value: 'toll' }];
    const priceModes = [{ title: 'Custom', value: 'custom' }, { title: 'Point', value: 'point' }, { title: 'Distance', value: 'distance' }];

    useEffect(() => {
        if (props.location) {
            if (props.location.id !== location.id) {
                // setup form for location edit
                console.log('props.location', props.location)

                setLocation({
                    name: props.location.identifier,
                    id: props.location.id,
                    time_offset: (props.location.time_offset ? props.location.time_offset : ""),
                    opening_time: props.location.opening_time,
                    closing_time: props.location.closing_time,
                    gst: (props.location.gst ? props.location.gst : ""),
                    city: props.location.city.id,
                    tag: props.location.pl_tag.tag_id,
                    plt_id: props.location.pl_tag.id,
                    printName: (props.location.name ? props.location.name : ""),
                    vehicle: props.location.vehicle_types.map((o) => o.name),
                    long: parseFloat(props.location.long),
                    lat: parseFloat(props.location.lat),
                    locality: props.location.locality,
                    logo: props.location.logo,
                    plazaId: props.location.plazaId,
                    orgId: props.location.orgId,
                    lanePrefix: props.location.lanePrefix,
                    acquirerId: props.location.acquirerId,
                    agencyCode: props.location.agencyCode,
                    bank: props.location.bank,
                    isFastTagLoc: (props.location.plazaId && props.location.orgId) ? true : false,
                    fastag_consent: props.location.fastag_consent,
                    admin_email: props.location.admin_email,
                    landmark: props.location.landmark,
                    fastag_plaza_type: props.location.fastag_plaza_type,
                    price_mode: props.location.price_mode,
                    location_bypass_id: props.location.location_bypass_id
                })
                setViewport({ ...viewport, 'longitude': parseFloat(props.location.long), 'latitude': parseFloat(props.location.lat) })
            }
        } else if (null !== location.id) {
            // reset the location edit form
            setLocation(emptyLocation)
        }
    }, [props.location, location, emptyLocation, viewport, imgErr])

    useEffect(() => {
        if (props.refreshForm === true) {
            setLocation(emptyLocation)
            props.setRefresh();
        }
    }, [props.refreshForm])

    const handleInputChange = (e, val, field=undefined) => {
        const { name, value } = e.target

        if (name) {
            console.log('handleInputChange', name, value)
            if ('vehicle' === name) {
                let vehicle = location.vehicle

                if (e.target.checked) vehicle.push(e.target.value)
                else _.remove(vehicle, (o) => {
                    return o === e.target.value
                })
                setLocation({ ...location, 'vehicle': vehicle })
            } else if ('fastTagLocation' === name) {

                setLocation({
                    ...location,
                    'isFastTagLoc': e.target.checked,
                    plazaId: e.target.checked ? location.plazaId : null,
                    orgId: e.target.checked ? location.orgId : null,
                    lanePrefix: e.target.checked ? location.lanePrefix : null,
                    bank: e.target.checked ? location.bank : null
                })

            } else if ('fastag_consent' === name) {

                setLocation({
                    ...location,
                    'fastag_consent': e.target.checked,
                })

            } else {
                setLocation({ ...location, [name]: value })
            }

        } else { // if  no name then city autocomplete
            console.log('autocomplete-change', e)
            if (field == 'city' && val) {
                setLocation({ ...location, 'city': val.id })
            } else if(field == 'tag' && val) {
                setLocation({ ...location, 'tag': val.id })
            }
        }
    }

    const onReset = () => {
        setLocation(emptyLocation)
        props.onReset()
    }

    const onMapClose = () => {
        setShowMap(false)
        if (location?.id) {
            setLocation({ ...location, 'long': parseFloat(props.location.long), 'lat': parseFloat(props.location.lat) })
            setViewport({ ...viewport, 'longitude': parseFloat(props.location.long), 'latitude': parseFloat(props.location.lat) })
        }
    }

    // set value of isGoogle to change the map usage
    const onMapSave = (isGoogle = true) => {

        // console.log("save map",location.long,location.lat)

        if (isGoogle) {
            const keyList = ["neighborhood", "sublocality_level_1", "sublocality_level_2", "locality", "route"]
            let url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='
            url += location.lat + ',' + location.long + '&key=' + process.env.REACT_APP_GMAP_KEY
            Axios.get(url).then((res) => {
                // console.log(res.data.results)    
                let add = null
                for (let i = 0; i < keyList.length; i++) {
                    let address = _.filter(res.data.results, (o) => {
                        return _.includes(o.types, keyList[i])
                    })
                    if (address.length) {
                        add = address[0].address_components
                        break
                    }
                }
                if (add) {
                    let locality = add[0].short_name + ', ' + add[1].short_name
                    setLocation({ ...location, 'locality': locality })
                    setShowMap(false)
                } else {
                    // show an alert to user 
                    console.log('unable to find locality ')
                }
            })
                .catch((err) => {
                    // got an error
                    console.log("error from google", err)
                })

        } else {
            const keyList = ["locality", "place"]
            // get the data for this marker
            MapAPI.get('mapbox.places/' + location.long + "," + location.lat +
                ".json?access_token=" + process.env.REACT_APP_MAPBOX_KEY).then((res) => {
                    console.log('rcvd', res.data.features)

                    let locality = _.filter(res.data.features, (o) => {
                        return _.some(keyList, el => _.includes(o.id, el))
                    }).map(el => el.text).join(', ')

                    console.log(locality)
                    setLocation({ ...location, 'locality': locality })
                    console.log(location)
                    setShowMap(false)

                })
                .catch((err) => {
                    // got an error
                    console.log("error from mapbox")
                })
        }

    }

    const onMapMarkerChange = (marker) => {
        setLocation({ ...location, 'long': marker[0], 'lat': marker[1] })
    }

    const onPlacesChanged = (marker) => {
        setLocation({ ...location, 'long': marker[0], 'lat': marker[1] })
        setViewport({ ...viewport, 'longitude': marker[0], 'latitude': marker[1] })
    }

    const onLatChanged = (e) => {
        setLocation({ ...location, ['lat']: e.target.value });
        setViewport({ ...viewport, ['latitude']: e.target.value });
    };

    const onLongChanged = (e) => {
        setLocation({ ...location, ['long']: e.target.value });
        setViewport({ ...viewport, ['longitude']: e.target.value });
    };

    const onMapViewChange = (center) => {
        setViewport({ ...viewport, 'longitude': center[0], 'latitude': center[1] })
    }
    const onMapViewportChange = (viewport) => {
        setViewport(viewport)
    }
    const uploadLogo = (e) => {

        if (e.target.files) {
            var f = e.target.files[0];
            var len = f.name.split('.');
            if ((len[len.length - 1] != 'jpg' && len[len.length - 1] != 'png' && len[len.length - 1] != 'jpeg') || f.size >= 2048000) {
                setImgErr("Image format should be png/jpg/jpeg and size less than 2MB");
            }
            else {
                setImgErr(null);
                var fd = new FormData();
                fd.append('logo', e.target.files[0]);
                //console.log("uploadLogo",fd);
                API.post("upload_logo", fd).then((res) => {
                    setLocation({ ...location, 'logo': res.data.data.logo });
                }).catch((err) => {
                    console.log("error", err);
                })
            }

        }
    }
    return (
        <div>
            {/* FIRST ROW */}
            <div className="row">
                <div className="col-md-4 col-12">
                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Location Name</InputLabel>
                    <TextField name="name" margin="none" onChange={handleInputChange} value={location.name} fullWidth />
                </div>
                <div className="col-md-4 col-12">
                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Print Name</InputLabel>
                    <TextField name="printName" margin="none" onChange={handleInputChange} value={location.printName} fullWidth />
                </div>
                <div className="col-md-4 col-12">
                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>GMT Offset (seconds)</InputLabel>
                    <TextField name="time_offset" margin="none" onChange={handleInputChange} value={location.time_offset} fullWidth />
                </div>
                <div className="col-md-4 col-12">
                    <InputLabel shrink={true} htmlFor="opening_time" className={classes.label}>Opening Time(24 hr format)</InputLabel>
                    <TextField name="opening_time" margin="none" onChange={handleInputChange} value={location.opening_time} fullWidth />
                </div>
                <div className="col-md-4 col-12">
                    <InputLabel shrink={true} htmlFor="closing_time" className={classes.label}>Closing Time(24 hr format)</InputLabel>
                    <TextField name="closing_time" margin="none" onChange={handleInputChange} value={location.closing_time} fullWidth />
                </div>
                <div className="col-md-4 col-12">
                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>GST no.</InputLabel>
                    <TextField name="gst" margin="none" onChange={handleInputChange} value={location.gst} fullWidth />
                </div>
            </div>
            {/* SECOND ROW */}
            <div className='row'>
                {/* CITIES DROPDOWN */}
                <div className="col-md-4 col-12">
                    <Autocomplete
                        value={location.city ? _.find(props.options.cities, { id: location.city }) : null}
                        onChange={(event, value) => handleInputChange(event, value, 'city')}
                        id="city"
                        name="city"
                        style={{ marginTop: 0 }}
                        options={props.options.cities}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <React.Fragment>
                                <InputLabel shrink={true} htmlFor="name" className={classes.label}>City</InputLabel>
                                <TextField name="locationCity" {...params} margin="none" />
                            </React.Fragment>
                        )}
                    >
                    </Autocomplete>
                </div>
                <div className="col-md-4 col-12">
                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Admin Email</InputLabel>
                    <TextField name="admin_email" margin="none" onChange={handleInputChange} value={location.admin_email} fullWidth />
                </div>
                <div className="col-md-4 col-12">
                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Landmark</InputLabel>
                    <TextField name="landmark" margin="none" onChange={handleInputChange} value={location.landmark} fullWidth />
                </div>
            </div>

            <div className='row'>
                <div className="col-md-4 col-12">
                    <Autocomplete
                        value={location.tag == null ? null : _.find(props.options.locationTags, { id: location.tag })}
                        onChange={(event, value) => handleInputChange(event, value, 'tag')}
                        id="tag"
                        name="tag"
                        style={{ marginTop: 0 }}
                        options={props.options.locationTags}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <React.Fragment>
                                <InputLabel shrink={true} htmlFor="name" className={classes.label}>Tag</InputLabel>
                                <TextField name="tag" {...params} multiple={true} margin="none" />
                            </React.Fragment>
                        )}                    
                    >
                    </Autocomplete>
                </div>
                <div className="col-sm-4 col-12">
                    <FormControl className="w-100">
                        <FormHelperText className={classes.label}>Fastag Consent</FormHelperText>
                        <FormControlLabel
                            key={"fastag_consent"}
                            control={
                                <Checkbox color="primary"
                                    checked={location.fastag_consent}
                                    onChange={handleInputChange}
                                    name="fastag_consent"
                                    value={location.fastag_consent}
                                />
                            }
                            label={'Fastag Consent'}
                        />
                    </FormControl>

                </div>
                {/* SET PARKING LOCATION */}
                <div className="col-md-4 col-12">
                    {/* <FormControl> */}
                    <Button
                        variant="text"
                        color="secondary"
                        size='large'
                        className={classes.mapButton}
                        onClick={() => setShowMap(true)}
                        startIcon={<PinDropIcon />}
                    >
                        {location.locality && location.locality.length > 0 ? location.locality : "Select Location in Map"}

                    </Button>
                    {/* </FormControl> */}

                </div>
                <div className="col-sm-4 col-12">
                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Upload Logo</InputLabel>
                    <CloudUpload
                        onClick={() => {
                            const i = document.getElementById('photoFile');
                            if (i) {
                                i.click();
                            }
                        }} />
                    <input
                        type='file'
                        name='photoFile'
                        id={'photoFile'}
                        style={{ display: "none" }}
                        onChange={uploadLogo}
                        accept="image/jpeg,image/jpg,image/png" />
                    <Typography variant={"body1"}>{location.logo}</Typography>
                    <Typography variant={"body1"} className={classes.imgErr} >{imgErr}</Typography>
                </div>
            </div>

            {/*  THIRD ROW */}
            <div className='row'>
                <div className="col-sm-12 col-12">
                    <FormControl className="w-100">
                        <FormHelperText className={classes.label}>Vehicle Type</FormHelperText>
                        <FormGroup className="d-flex flex-row">
                            {props.options.vehicleTypes.map(vehicle => (
                                <FormControlLabel
                                    key={vehicle.id}
                                    control={
                                        <Checkbox color="primary"
                                            checked={location.vehicle.indexOf(vehicle.name) !== -1}
                                            onChange={handleInputChange}
                                            name="vehicle"
                                            value={vehicle.name}
                                        />
                                    }
                                    label={vehicle.name}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </div>

            </div>
            {/*  FORTH ROW */}
            <div className='row'>
                <div className="col-md-4 col-12">
                    <FormControl className="w-100">
                        <FormHelperText className={classes.label}>Fasttag Location</FormHelperText>
                        <FormGroup className="d-flex flex-row">
                            <FormControlLabel
                                key={'fastTagLocation'}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={location.isFastTagLoc}
                                        onChange={handleInputChange}
                                        name="fastTagLocation"
                                        value={location.isFastTagLoc}
                                    />
                                }
                                label={'FastTag Location'}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
            {
                location.isFastTagLoc &&
                //FIFTH ROW 
                <div className='row'>
                    <div className="col-sm-3 col-12">
                        <InputLabel shrink={true} htmlFor="plazaId" className={classes.label}>Plaza Id</InputLabel>
                        <TextField name="plazaId" margin="none" onChange={handleInputChange} value={location.plazaId} fullWidth />
                    </div>
                    <div className="col-sm-3 col-12">
                        <InputLabel shrink={true} htmlFor="orgId" className={classes.label}>Org Id</InputLabel>
                        <TextField name="orgId" margin="none" onChange={handleInputChange} value={location.orgId} fullWidth />
                    </div>
                    <div className="col-sm-3 col-12">
                        <InputLabel shrink={true} htmlFor="bank" className={classes.label}>Bank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={location.bank}
                            name={'bank'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {
                                props.options.fastTagBanks.map(option =>
                                    <MenuItem value={option.value}>{option.title}</MenuItem>
                                )
                            }
                        </Select>
                    </div>
                    <div className="col-sm-3 col-12">
                        <InputLabel shrink={true} htmlFor="lanePrefix" className={classes.label}>Lane Prefix</InputLabel>
                        <TextField name="lanePrefix" margin="none" onChange={handleInputChange} value={location.lanePrefix} fullWidth />
                    </div>
                    <div className="col-sm-3 col-12">
                        <InputLabel shrink={true} htmlFor="lanePrefix" className={classes.label}>Plaza Acquirer ID</InputLabel>
                        <TextField name="acquirerId" margin="none" onChange={handleInputChange} value={location.acquirerId} fullWidth />
                    </div>
                    <div className="col-sm-3 col-12">
                        <InputLabel shrink={true} htmlFor="lanePrefix" className={classes.label}>Plaza Agency Code</InputLabel>
                        <TextField name="agencyCode" margin="none" onChange={handleInputChange} value={location.agencyCode} fullWidth />
                    </div>
                    <div className="col-sm-3 col-12">
                        <InputLabel shrink={true} htmlFor="bank" className={classes.label}>Plaza Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={location.fastag_plaza_type}
                            name={'fastag_plaza_type'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {
                                plazaTypes.map(option =>
                                    <MenuItem value={option.value}>{option.title}</MenuItem>
                                )
                            }
                        </Select>
                    </div>
                    <div className="col-sm-3 col-12">
                        <InputLabel shrink={true} htmlFor="bank" className={classes.label}>Price Mode</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={location.price_mode}
                            name={'price_mode'}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            {
                                priceModes.map(option =>
                                    <MenuItem value={option.value}>{option.title}</MenuItem>
                                )
                            }
                        </Select>
                    </div>
                    <div className="col-sm-3 col-12">
                        <Autocomplete
                            value={location.location_bypass_id ? _.find(props.options.fastagLocations, { id: location.location_bypass_id }) : null}
                            onChange={(e, val) => {
                                handleInputChange({ target: { name: 'location_bypass_id', value: val?.id } })
                            }}
                            id="bypassLocation"
                            style={{ marginTop: 0 }}
                            options={props.options.fastagLocations}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <React.Fragment>
                                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Bypass Location</InputLabel>
                                    <TextField {...params} margin="none" />
                                </React.Fragment>
                            )}
                        >
                        </Autocomplete>
                    </div>
                </div>
            }
            {/* BUTTONS */}
            <div className="row mt-2">
                <div className="col-lg-6 col-sm-12 col-12">
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => props.onSubmit(location)}> Submit </Button></Box>
                        <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" onClick={onReset}>Reset</Button></Box>
                    </Box>
                </div>
            </div>
            <Dialog open={showMap}
                // onClose={closeDialog} 
                fullScreen={true}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={onMapClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Mark location for <span style={{ textDecoration: "underline" }}>{location.name ? " " + location.name : " <New Location>"}</span>
                        </Typography>
                        <Button autoFocus variant="contained" color="secondary" onClick={onMapSave}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <GMap
                    viewport={viewport}
                    marker={[location.long, location.lat]}
                    onMarkerChange={onMapMarkerChange}
                    onPlacesChanged={onPlacesChanged}
                    onViewChange={onMapViewChange}
                    onLatChanged={onLatChanged}
                    onLongChanged={onLongChanged}
                />
                {/* <DialogContent>                    
                    <MapBox
                        viewport={viewport}
                        marker = {[location.long,location.lat]}
                        onViewChange = {onMapViewportChange}
                        onMarkerChange = {onMapMarkerChange}
                    />
                </DialogContent> */}
            </Dialog>
        </div>
    )
}

export default EditLocation