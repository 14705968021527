
import React,{Component} from 'react';
import { withStyles} from '@material-ui/core/styles'
import { Table,TableBody,TableHead,TableRow } from '@material-ui/core';
import MuiTableCell from "@material-ui/core/TableCell";

const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);
const amountFormatter = new Intl.NumberFormat('en-IN', {minimumFractionDigits: 2,maximumFractionDigits: 2 })
export default class PrintReceipt extends Component {
    constructor(props){
        super(props)
        console.log("PrintReceipt",props);
    }
    
    render(){
        return(
            <>
            <div style={{padding:30}}>
            <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Order Details</TableCell>
                            <TableCell>Customer Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell >ORDER ID: {this.props.orderDetails.order_id}</TableCell>
                            <TableCell >NAME: {this.props.orderDetails.name}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >ORDER PLACED AT: {this.props.orderDetails.placed_at}</TableCell>
                            <TableCell >MOBILE: {this.props.orderDetails.mobile}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >ORDER CONFIRMED AT: {this.props.orderDetails.confirmed_at}</TableCell>
                            <TableCell ></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
               <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { this.props.orderDetails.products.map((product, index)  => {
                            return (
                            <TableRow key={index}>
                                <TableCell>{product.name}</TableCell>
                                <TableCell>{product.quantity}</TableCell>
                                <TableCell>{amountFormatter.format(product.amount/product.quantity)}</TableCell>
                                <TableCell>{amountFormatter.format(product.amount)}</TableCell>
                            </TableRow>
                            );
                            })}
                            <TableRow>
                            <TableCell colSpan={3}>Total</TableCell>
                            <TableCell >{amountFormatter.format(this.props.orderDetails.order_total)}</TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
                </div>
            </>
        )
    }
}