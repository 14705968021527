import { Grid,Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import "../ParkingOverview/overview.css"
import { EZDoughnut } from "components/Chart"; 
import CardBox from "components/CardBox";

const useStyles = makeStyles( (theme) =>(
    {
        labelText:{
            margin: 'auto',
            marginLeft: 0,
            padding: 12,
            lineHeight:1.2,
            fontSize:20
        },
        text:{
            margin: 'auto',
            color: '#f9a825'
        }
    }
))
const summary = [
    {
        key : "entry",
        title : "Total Entry",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    },
    {
        key : "exit",
        title : "Total Exit",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    },
    {
        key : "active",
        title : "Total Active",
        totalCount : 2000,
        data : [
            {type:"bike",count:1000},
            {type:"car",count:100},
            {type:"lcv",count:100},
            {type:"hcv",count:50},
        ]
    }
]

const paymentMode = [
    {
        title:"Bike",
        key:"bike", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    },
    {
        title:"Car",
        key:"car", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    },
    {
        title:"Truck",
        key:"truck", 
        data:{
            labels: [
              'UPI',
              'CASH'
            ],
            datasets: [{
              label: '',
              data: [50, 200],
              backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
              ],
              hoverOffset: 4
            }]
        }
    }
]

const parkingAvailibity=[
    {
        client_name:"Doninos",
        avalibility:[
            {
                title:"Bike",
                key:"bike", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            },
            {
                title:"Car",
                totleSlot:"100",
                key:"car", 
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            }
        ]
    },
    {
        client_name:"Trends",
        avalibility:[
            {
                title:"Bike",
                key:"bike", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            },
            {
                title:"Car",
                key:"car", 
                totleSlot:"100",
                data:{
                    labels: [
                      'Occupied',
                      'Available'
                    ],
                    datasets: [{
                      label: '',
                      data: [50, 200],
                      backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)'
                      ],
                      hoverOffset: 4
                    }]
                }
            }
        ]
    }
] 
const ParkingAvailibity = (props)=>{
    
    return(
        <CardBox styleName="col-12" heading={"Parking Availability"}>
            <Grid container  direction="row" spacing={8}>
                {
                    props.clientSlots.map(item=>(
                    <Grid item xs={6} key={`key=${item.client_name}`}>
                        <Typography variant="h5" style={{textAlign:'center',padding:10}}>{item.name}</Typography>
                        <Grid container direction="row" spacing={2}>
                            {
                                item.avalibility.map(val=>(
                                <Grid item xs={6} key={`item-${val.title}`}>
                                    <div className="jr-card" style={{padding:5,display:'flex'}}>
                                        <Grid item xs={8}>
                                            <EZDoughnut data={val.data} style={{padding:5}}/>
                                        </Grid>
                                        <Grid item xs={4} style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                                            <div>
                                                {'Total '+val.title}-{val.totalSlots}
                                            </div>
                                            <div>
                                                {val.data.label.map((label, index)=>(
                                                    <div style={{display:'flex'}}>
                                                        <div style={{width: '12px',height: '12px',backgroundColor: val.data.datasets[0].backgroundColor[index], alignSelf:'center', marginRight:'5px'}}></div>
                                                        {label}-{val.data.datasets[0].data[index]}
                                                    </div>
                                                ))}
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                                ))
                            }
                             
                        </Grid>     
                        
                    </Grid>
                    ))
                }
                
            </Grid>
        </CardBox>
    )
}
const PaymentModeCard =(props)=>{
    console.log("PaymentModeCard-",props)
    return(
        <CardBox styleName="col-12" heading={"Payment Mode"}>
            <Grid container  direction="row" spacing={4}>
                {
                    props.paymentMode?.map(item=>{
                        const total = item.data.datasets[0].data.reduce((acc, value) => Number(acc) + Number(value), 0);
                        return(
                            <Grid item xs={4}>
                                <div className="jr-card" style={{padding:5}}>
                                    <Grid container direction="row">
                                        <Grid item xs={8}>
                                            <EZDoughnut data={item.data} style={{padding:10}}/>
                                        </Grid>
                                        <Grid item xs={4} style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                                            <div>
                                                <Typography variant="h5" style={{textAlign:'right'}} >{item.title}</Typography>
                                            </div>
                                            <div>
                                                {item.data.labels.map((label, i)=>(
                                                    <div style={{display:'flex'}}>
                                                        <div style={{width: '12px', height: '12px', backgroundColor: item.data.datasets[0].backgroundColor[i], alignSelf:'center', fontSize:'1rem', marginRight:'5px'}}></div>
                                                        {label}-{item.data.datasets[0].data[i]}
                                                    </div>
                                                ))}
                                                <div style={{display:'flex'}}>
                                                    <div style={{width: '12px', height: '12px', backgroundColor:'#999999', alignSelf:'center', fontSize:'1rem', marginRight:'5px'}}></div>
                                                    {'Total'}-{total}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div> 
                            </Grid>
                        )
                    })
                }
                
            </Grid>
        </CardBox>
    )
}
const OverviewDetails = (props) =>{
    const classes = useStyles()
    console.log("OverviewDetails-",props.data)
    return (
        <>
            <Grid container direction='row' spacing={4}>
                {
                    props.data?.summary?.map(item=>(
                    <Grid item xs={12} sm={4} key={`key-${item.key}`}>
                        <div className="jr-card" style={{padding:10}}> 
                            <Grid container direction='row' style={{marginBottom:10}} >
                                <Typography variant={'h3'} className={classes.labelText} >{item.title} </Typography>
                                <Typography variant={'h4'} className={classes.text} >{item.totalCount}</Typography>
                            </Grid>
                            <Grid container direction='row' >
                                {
                                    item.data.map(value=>(
                                        <Grid item xs={6} key={`type-${value.type}`} className="vehicle-card">
                                            {(value.type=='bike')?<MotorcycleIcon className="type-icon" />:(value.type=='car')?<DriveEtaIcon className="type-icon"/>:<LocalShippingIcon className="type-icon"/>}
                                            <Typography className="type-text">{value.type.toUpperCase()} {value.count}</Typography>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </div> 
                    </Grid>
                    ))
                }
            </Grid>
            <PaymentModeCard paymentMode={props.data?.paymentMode} ></PaymentModeCard>
            {
                props.data?.clientSlots&&
                <ParkingAvailibity clientSlots={props.data.clientSlots} ></ParkingAvailibity>
            }
        </>
    )
    
}

export default OverviewDetails