import React, { useState,useEffect,useRef } from 'react';
import CardBox from 'components/CardBox'
import {Typography,Grid,IconButton,GridList,Switch,FormControlLabel,TextField,Button} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles'
import Refresh from '@material-ui/icons/Refresh'
import CropFreeIcon from '@material-ui/icons/CropFree'
import _ from 'lodash'
import { FullScreen, useFullScreenHandle } from "react-full-screen"

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        headerStyle:{
            textAlign:'end',
            marginBottom:0
        },
        terminal:{
            background: '#263647',
            boxShadow: 'inset 0px 0px 5px black',
        },
        terminalText:{
            color:'#fff'
        },
        cardStyle:{
            padding:0
        },
        preTag:{
            padding:10,
            marginBottom:0,
            width:'100%',
            margin:'0px !important'
        },
        btnOlder:{
            position:'absolute',
            left:'50%',
            transform:'translateX(-50%)',
            margin: 5,
            borderRadius: 25,
            fontSize: 10,
            marginTop:'-16px'
        }
    }
))
const TerminalBox = (props) =>{
    const classes = useStyles()
    const containerRef =  useRef()
    const [isScrolledTop,setIsScrolledTop] = useState(false)
    const [logs,setLogs] = useState(props.logs)

    useEffect(()=>{
        setLogs(props.logs)
    },[props.logs])

    const handleScroll = (e) => {
        //if terminal scrolled to top
            if(e.target.scrollTop===0)
                setIsScrolledTop(true)
            else
                setIsScrolledTop(false)
        
    };
    useEffect(() => {
        const currentElement = containerRef.current
        if (currentElement) {
            currentElement.addEventListener('scroll', handleScroll);
        }
        return () => {
            if(currentElement)currentElement.removeEventListener('scroll', handleScroll)
        };
    }, [containerRef]);

    function gotoBottom(id){
        var element = document.getElementById(id);
        element.scrollTop = element.scrollHeight - element.clientHeight;
    }
    //scroll to bottom 
    useEffect(()=>{
        //scroll down when auto scroll is checked
        if(logs && logs.length>0 && props.autoScroll){
            gotoBottom('terminal'+props.type)
        }
    },[logs])
    
    return(
        <div className={`row ${classes.terminal}`}>
            {
                (props.getOlderLogs ) &&
                    <Button style={isScrolledTop?{}:{display:'none'}} onClick={()=>props.getOlderLogs()} className={classes.btnOlder} variant="contained">get Older</Button>
            }
            <pre style={{marginBottom:'0px'}}>
            <GridList ref={containerRef} id={'terminal'+props.type} style={props.isFullScreen?{height:'77vh'}:{height:400}} classes={{root:classes.preTag}}>
               {logs?.map((log,index)=>{
                    return(
                        <Grid item container xs={12} key={'key'+index} className={`${classes.terminalText}`} style={{display:'contents', textWrap: 'pretty'}}>
                            {typeof log === 'object' ? 
                            <>
                            <Grid item xs={2} style={{paddingBottom:10}}>
                                <Typography>{log.time}</Typography>
                            </Grid>
                            {
                                log.msgTime &&
                                <Grid item xs={2} style={{paddingLeft:10,paddingBottom:10}}>
                                    <Typography>{log.msgTime}</Typography>
                                </Grid>
                            }
                            <Grid item xs={log.msgTime?8:10} style={{paddingBottom:10,paddingLeft:10,wordBreak:'break-word'}}>
                                <Typography>{log.message}</Typography>
                            </Grid>
                            </>:
                            <Grid item xs={12} style={{paddingBottom:10,paddingLeft:10,wordBreak:'break-word'}}>
                                <Typography>{log}</Typography>
                            </Grid>}
                        </Grid>
                    )
                })}
            </GridList>
            </pre>
        </div>
    )
}
const TerminalCard = (props) =>{
    const classes = useStyles()
    const handle = useFullScreenHandle()
    const [autoScroll,setAutoScroll] = useState(props.allowScroll === false ? false : true)
    const [searchValue,setSearchValue] = useState('')
    const [logs,setLogs] = useState(props.logs)

    useEffect(()=>{
        setLogs(props.logs)
    },[props.logs])

    useEffect(()=>{
        if(props.allowScroll==false){
            setAutoScroll(props.allowScroll)
        }
    },[props.allowScroll])
    const handleSearch = (e) =>{
        setSearchValue(e.target.value)
        let value = e.target.value
        if(value){
            var filter = [];
            props.logs.map(o=>{
                for(var key in o){
                    if( !key.includes('hidden') && o[key] !=null && !Array.isArray(o[key]) && o[key].toString().toLowerCase().includes(value.toLowerCase())){
                        filter.push(o);
                        break;
                    }
                }
            });
            console.log("filterdedArray",filter)
            setLogs(filter);
        }else{
            setLogs(props.logs);
        }
    }
    console.log("TerminalBox",logs,handle)
    return(
        <FullScreen handle={handle} >
            <div 
                style={{ width: '100%' }}
            >
            {
                logs && 
                <CardBox styleName={` ${handle.active?classes.cardStyle:''}`} headingStyle={classes.headerStyle} heading={
                    <React.Fragment>
                        <Typography style={{float:'left',textAlign:'left'}} variant={'h6'} component={'h6'}>{`${props.type+' logs - '} ${props.name}`}</Typography>
                        <FormControlLabel
                            control={<TextField autoComplete={'off'} value={searchValue} onChange={(e)=>handleSearch(e)}  label="Search" color="primary" />}
                        />
                        {
                            props.handleRefresh &&
                            <FormControlLabel
                            control={<Switch color={'primary'} checked={autoScroll} onChange={()=>setAutoScroll(!autoScroll)} />}
                            label="Auto Scroll"
                        />
                        }
                        {document.fullscreenEnabled&&<IconButton 
                            onClick={() => {
                                console.log("handleEntry",handle)
                                if(!handle.active){
                                    handle.enter()
                                }else{
                                    handle.exit()
                                }
                            }} 
                            color="primary">
                            <CropFreeIcon/>
                        </IconButton>}
                        {
                            props.handleRefresh &&
                            <IconButton onClick={()=>props.handleRefresh()} color="primary">
                                <Refresh/>
                            </IconButton>
                        }
                    </React.Fragment>}>
                    {
                        <TerminalBox
                            isFullScreen = {handle.active}
                            logs={logs}
                            type={props.type}
                            autoScroll={autoScroll}
                            getOlderLogs = {props.getOlderLogs}
                        />
                    }
                </CardBox>
            }
            </div>
        </FullScreen>
    )
}
export default TerminalCard