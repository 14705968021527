import React,{Fragment,Component} from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import { Formik } from 'formik'
import API from 'util/Api';
import ActionsTable from 'components/ActionsTable/TableList'
import CardBox from 'components/CardBox';
import CircularProgress from 'components/Loader';
import HolidayDetail from './HolidayDetail';
import SnackBar from 'components/SnackBar';
import _ from 'lodash';
import * as Yup from "yup";

const SignupSchema =()=> {
  return Yup.lazy(values=>{
      return Yup.object().shape({
          name: Yup.string()
              .min(2, 'Too Short!')
              .required('Required'),
          holiday_date: Yup.string()
              .nullable()
              .required('Required'),
      });
  });
  
}

class Holidays extends Component {

  
  constructor(props) {
        super(props);
        const initialSnack = {
            snackOpen :false,
            snackMessage : '',
            snackType : '',
        }
        this.state = {
            appState:'waiting',
            holidays:[],
            selectedHoliday:null,
            errmsg:null,
            initialConfig:null,
            initialValues:null,
            snackBar:initialSnack,
            resetFormik:false
        }
  }

componentDidMount(){
    
    this.getHoildaylist();
}
getHoildaylist = () =>{
    API.get("holidays").then((res) => {

        var initialValues = {};
        Object.values(res.data.data.config).map(item => {
          initialValues[item.key] = item.type==='input' ? "" : item.type==='select' ? null : item.type==='datepicker' ? null : false
        });
        initialValues['id'] ='';

        this.setState({
          holidays:res.data.data.holidays,
          initialConfig:Object.values(res.data.data.config),
          initialValues:initialValues,
          appState:'active'
        })
    })
    .catch((err) => {
        // got an error
        console.log("configuration api error")
});
}
saveHoliday = (e,{setSubmitting}) => {
  setSubmitting(true)
        
  console.log("save config",e);
  API.post("holidays",JSON.stringify(e)).then((res) => {

      this.setSnackState(true,res.data.msg,'success');
      this.setState({holidays:res.data.data})
      this.resetHoliday();
      setSubmitting(false)


  })
  .catch((err) => {
      try{
          this.setSnackState(true,err.response.data.msg,'error');
      }catch{
          this.setSnackState(true,"Something went wrong",'error');
      }
      // got an error
      console.log("configuration api error",err.response)
      setSubmitting(false)

  })
}
resetHoliday = () => {
    this.setState({selectedHoliday:null,resetFormik:true});
}
onEdit = (item) => {
    console.log("edit clicked", item);
    this.setState({selectedHoliday:item})
    document.body.style.height = 'auto'            
        setTimeout(function(){
            document.body.style.height = '100%'
    },50)
    
  }
scrollTop = ()=>{
      document.body.style.height = 'auto'            
      setTimeout(function(){
          document.body.style.height = '100%'
  },50)
  }
setSnackState=(open,msg,type)=>{
    this.setState({snackBar:{'snackOpen':open,'snackMessage':msg,'snackType':type}})
  }
  renderPage() {
    return (
      <div>
        <ContainerHeader title="Holiday" match={this.props.match}/>
        <div className="row">
           <CardBox styleName="col-12" heading='Holiday'>
              <Formik
                onSubmit={this.saveHoliday.bind(this)}
                onReset={this.resetHoliday.bind(this)}
                initialValues={this.state.initialValues}
                enableReinitialize={true}
                validationSchema={SignupSchema}
            >
                  <HolidayDetail 
                    holiday={this.state.selectedHoliday}
                    initialConfig={this.state.initialConfig}
                    refresh={this.state.resetFormik}
                    resetRefresh={()=>{this.setState({resetFormik:false})}}
                  />
              </Formik>
           </CardBox> 
        </div>
        <div className="row">
            <ActionsTable 
                tableHeading = "Holiday" 
                table={this.state.holidays} 
                onEdit = {this.onEdit.bind(this)} 
                actions={true} 
            />
        </div>
        <SnackBar 
            open={this.state.snackBar.snackOpen} 
            handleClose={this.setSnackState.bind(this,false,'','')}
            message={this.state.snackBar.snackMessage}
            type={this.state.snackBar.snackType}
        />
      </div>
    );
  }
  renderState(state){
        switch(state){
            case "waiting" :
            default:
                return(
                    <CircularProgress />
                )
            case "active" :
                return(
                <Fragment> 
                    {this.renderPage()} 
                </Fragment>)                
        }
  }
  render() {
        return (<Fragment>{this.renderState(this.state.appState)} </Fragment>)
  }
}

export default Holidays;