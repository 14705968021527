/*
 * File: index.js
 * Project: parkezdashboard
 * File Created: Friday, 28th August 2020 10:53:15 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Thursday, 3rd September 2020 4:27:22 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import PSDetails1 from './PSDetails1'
import ActionsTable from 'components/ActionsTable/TableList'
import CircularProgress from 'components/Loader'
import SnackBar from 'components/SnackBar'
import API from 'util/Api'
import * as Yup from "yup"
import _ from "lodash"
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core'
import GuardConfig from './Config'

const checkVehicleType = (values, config) => {
    var flag = true;
    if (values.rate_model.length > 0) {
        var conigRateModels = _.find(config, { key: "rate_model" }).options;
        var selectedRatemodels = _.filter(conigRateModels, o => values.rate_model.includes(o.value));
        var vehicleType = values.vehicle_type;
        var is_ratemodel = [];
        for (var w in vehicleType) {
            for (var x in selectedRatemodels) {
                if (vehicleType[w] == selectedRatemodels[x].vehicle_type_id) {
                    if (is_ratemodel.indexOf(selectedRatemodels[x].vehicle_type_id) == -1) {
                        is_ratemodel.push(selectedRatemodels[x].vehicle_type_id);
                    }
                }
            }
        }
        if (is_ratemodel.length != vehicleType.length) {
            flag = false;
        }
        console.log("validationrate_model", vehicleType, is_ratemodel, flag);
    }
    console.log("validationrate_model", flag);
    return flag;
}

const nameRegExp = /^[a-zA-Z0-9\s]*$/;

const SignupSchema = (config) => {
    return Yup.lazy(values => {
        console.log("SignupSchema", config, values);
        return Yup.object().shape({
            fname: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .matches(nameRegExp, 'Special characters are not allowed.')
                .required('Required'),
            lname: Yup.string()
                .matches(nameRegExp, 'Special characters are not allowed.'),
            location_id: Yup.object()
                .required('Required'),
            guard_type: Yup.array()
                .required('Required'),
            vehicle_type: Yup.array()
                .required('Required'),
            rate_model: Yup.array()
                .required("Required")
                .test('global-ok', 'should have all vehicle type',
                    function () {
                        return checkVehicleType(values, config);
                    }),
            default_view: Yup.string()
                .required('Required'),
            email: Yup.string()
                .email('Invalid email')
                .required('Required'),
            password: ('id' in values && values.password === undefined) ?
                Yup.string()
                    .notRequired() : Yup.string()
                        .required('Password is required'),
            confirm_password: ('id' in values && values.password === undefined) ? Yup.string()
                .oneOf([Yup.ref('password')], "Passwords do not match") :
                Yup.string()
                    .oneOf([Yup.ref('password')], "Passwords do not match")
                    .required('Confirm password is required'),
            lane_direction: (values.fasttag_guard === true ?
                Yup.mixed().required("Required") :
                Yup.mixed().notRequired()),
            lane_subtype: (values.fasttag_guard === true ?
                Yup.mixed().required("Required") :
                Yup.mixed().notRequired()),
            gate: (values.fasttag_guard === true ?
                Yup.mixed().required("Required") :
                Yup.mixed().notRequired()),
        });
    });

}
//fixme: currently a copy of console users , if no major changes required please use a single component
const ParkingStaff = (props) => {

    const [config, setConfig] = useState(null)
    const [values, setValues] = useState(null)
    const [user, setUser] = useState(null)
    const [staffList, setStaffList] = useState(null)
    const [snackBar, setSnackBar] = useState({ open: false, msg: '', type: 'error' });
    const [resetF, setResetF] = useState(false);
    const [guardAction, setGuardAction] = useState({ isShowing: false, type:'', headingVariant:'', heading:'', text:'', user:{} });
    const [assignedPrinter, setAssignedPrinter] = useState([])
    const [assignedLed, setAssignedLed] = useState(null)
    const [parkingGuardLed, setParkingGuardLed] = useState({})
    const [parkingGuardCamera, setParkingGuardCamera] = useState({})
    const [parkingGuardBarrier, setParkingGuardBarrier] = useState({})
    const [parkingGuardController, setParkingGuardController] = useState({})
    const [parkingGuardRFID, setParkingGuardRFID] = useState({})
    const [parkingGuardEZTv,setParkingGuardEZtv] = useState({})

    const getAssignedPrinter = (staff) => {
        let assignedPrinterArray = []
        for (let key in staff) {

            if (staff[key].printer.value != null) {
                assignedPrinterArray.push({ id: parseInt(staff[key].printer.value), assignedTo: staff[key].uname.value })
            }

        }
        return assignedPrinterArray
    }
    const saveStaff = (e, { setSubmitting }) => {
        console.log("save config", e)
        API.post("parkingStaff", JSON.stringify(e))
            .then((res) => {
                console.log("parkingStaffRes", res);
                setSnackBar({ open: true, msg: res.data.msg, type: 'success' });

                setStaffList(res.data.data);
                resetStaff()
                setAssignedPrinter(getAssignedPrinter(res.data.data.body))
                //setUser(null);
                setSubmitting(false)
            })
            .catch((err) => {
                try {
                    setSnackBar({ open: true, msg: err.response.data.msg, type: 'error' });
                } catch {
                    setSnackBar({ open: true, msg: "Something went wrong", type: 'error' });
                }
                console.log("catch", err);
                setSubmitting(false)
            })

    }

    const resetStaff = () => {
        // todo: do we need to do anything here?
        setUser(null);
        setResetF(true);
        console.log("reset form ")
    }

    useEffect(() => {
        API.get("parkingStaff")
            .then((res) => {
                console.log("parking_staff", res, res.data.data.config)
                setConfig(Object.values(res.data.data.config));
                setStaffList(res.data.data.staff);
                setParkingGuardLed(res.data.data.parkingGuardLed)
                setParkingGuardCamera(res.data.data.parkingGuardCamera)
                setParkingGuardBarrier(res.data.data.parkingGuardBarrier)
                setParkingGuardController(res.data.data.parkingGuardController)
                setParkingGuardRFID(res.data.data.parkingGuardRFID)
                setParkingGuardEZtv(res.data.data.parkingGuardEzTv)
                let data = {}
                var fields = ["vehicle_type", "rate_model", "printer", "boom_relay", "default_view"];
                for (let key in res.data.data.config) {
                    data[key] = res.data.data.config[key].value
                }
                console.log('values', data);
                setValues(data);
                setAssignedPrinter(getAssignedPrinter(res.data.data.staff.body))
            })
            .catch((err) => {
                console.log("catch", err);
            })
    }, [])


    const handleStaffEdit = (clickedUser) => {
        console.log("edit ", clickedUser)
        setUser(clickedUser)
        // scroll to top 
        document.body.style.height = 'auto'
        setTimeout(function () {
            document.body.style.height = '100%'
        }, 50)
    }
    const handleStaffStatus = (user) => {
        console.log(user)
        let headingVariant = user.active.value == 1? 'error':'secondary';
        let heading = `${user.active.value ==1 ? 'Deactivate' : 'Activate'} Parking Guard`;
        let text = `Are you sure you want to ${(user.active.value == 1 ? 'deactivate' : 'activate')} ${user.fname.value} ${user.lname.value}?`;
        setGuardAction({ isShowing: true, type:'guardStatus', headingVariant, heading, text, user })
    }
    const handleResetMobile = (user) => {
        let headingVariant = 'error';
        let heading = 'Reset Guard';
        let text = `Are you sure you want to reset ${user.fname.value} ${user.lname.value}?`;
        setGuardAction({ isShowing: true, type:'resetMobile', headingVariant, heading, text, user })
    }

    const confirmAction = (type, user) =>{
        if(type === 'guardStatus'){
            onEnableConfirm(user)
        } else if(type === 'resetMobile'){
            resetMobile(user)
        }
    }

    const handleClose = () => {
        setGuardAction({ isShowing: false, type:'', headingVariant:'', heading:'', text:'', user:{} })
    }
    const onEnableConfirm = (user) => {
        let guardActive = user.active.value == 0 ? 1 : 0;
        API.post("deactivate", JSON.stringify({ id: user.id.value, confirm: guardActive }))
            .then((res) => {
                console.log("res", res);
                // setStaffList([]);
                setSnackBar({ open: true, msg: res.data.msg, type: 'success' });
                setStaffList(res.data.data);
                handleClose()
                //setUser(null);
            })
            .catch((err) => {
                handleClose()
                try {
                    setSnackBar({ open: true, msg: err.response.data.msg, type: 'error' });
                } catch {
                    setSnackBar({ open: true, msg: "Something went wrong", type: 'error' });
                }

                console.log("catch", err);
            })
    }
    const resetMobile = (user) => {
        API.post("resetMobile", JSON.stringify({ 'guard_id': user.id.value }))
            .then((res) => {
                console.log("res", res);
                handleClose()
                setSnackBar({ open: true, msg: res.data.msg, type: 'success' });
                resetStaff()
                //setUser(null);
            })
            .catch((err) => {
                handleClose()
                try {
                    setSnackBar({ open: true, msg: err.response.data.msg, type: 'error' });
                } catch {
                    setSnackBar({ open: true, msg: "Something went wrong", type: 'error' });
                }
                console.log("catch", err);
            })
    }
    const editLed = (n) => {
        let staffId = n.id.value
        console.log("editLed", staffId, parkingGuardLed)
        let pAcc = _.cloneDeep(parkingGuardLed.data)
        let assignLed = {
            "staff_id": staffId,
            "locationId": n.location_id.value,
            "staffName": n.uname.value,
            "cameras": [],
            "ledpanels": [],
            "barriers": {},
            "rfid": {},
            "controller": {
                controller_version: n.controller_version.value,
                device_id: n.device_id.value
            },
            "eztv":{}
        }
        _.forEach(pAcc, acc => {
            if (staffId == acc.user_id) {
                assignLed.ledpanels.push({ id: acc.id, led_accessory_id: acc.led_accessory_id, command: acc.command, row: acc.row, type: acc.type, port: acc.port })
            }
        })
        if (assignLed.ledpanels.length === 0)
            assignLed.ledpanels.push({})

        _.forEach(parkingGuardCamera.data, camera => {
            if (staffId == camera.user_id) {
                assignLed.cameras.push(camera)
            }
        })
        if (assignLed.cameras.length === 0)
            assignLed.cameras.push({})

        const assignedRfid = _.find(parkingGuardRFID.data, { 'user_id': staffId });
        const rfidInitValue = {};
        _.forEach(parkingGuardRFID.config, config => {
            rfidInitValue[config.key] = config.value;
        })
        assignLed.rfid = assignedRfid ? assignedRfid : rfidInitValue;

        const assignedBarriers = _.find(parkingGuardBarrier.data, { 'user_id': staffId });
        const barrierInitValue = {};
        _.forEach(parkingGuardBarrier.config, config => {
            barrierInitValue[config.key] = config.value;
        })
        assignLed.barriers = assignedBarriers ? assignedBarriers : barrierInitValue;
        
        const assignedEZTv = _.find(parkingGuardEZTv.data, { 'user_id': staffId });
        const eztvInitValue = {};
        _.forEach(parkingGuardEZTv.config, config => {
            eztvInitValue[config.key] = config.value;
        })
        assignLed.eztv = assignedEZTv ? assignedEZTv : eztvInitValue;
        console.log("assignLed.eztv",assignLed.eztv,parkingGuardEZTv.config)
        setAssignedLed(assignLed)
    }
    const updateAssignedLed = data => {
        let pAcc = _.cloneDeep(parkingGuardLed.data)
        let newData = _.unionBy(data, pAcc, 'id')
        setParkingGuardLed({ config: parkingGuardLed.config, data: newData })
    }
    const updateAssignedCamera = data => {
        let pAcc = _.cloneDeep(parkingGuardCamera.data)
        let newData = _.unionBy(data, pAcc, 'id')
        setParkingGuardCamera({ config: parkingGuardCamera.config, data: newData })
    }
    const updateAssignedRFID = data => {
        let pAcc = _.cloneDeep(parkingGuardRFID.data)
        let newData = _.unionBy(data, pAcc, 'id')
        setParkingGuardRFID({ config: parkingGuardRFID.config, data: newData })
    }
    const updateAssignedBarrier = data => {
        let pAcc = _.cloneDeep(parkingGuardBarrier.data)
        let newData = _.unionBy(data, pAcc, 'user_id')
        setParkingGuardBarrier({ config: parkingGuardBarrier.config, data: newData })
    }
    return (
        <>
            {config && values &&
                <Formik
                    onSubmit={saveStaff}
                    onReset={resetStaff}
                    initialValues={values}
                    validationSchema={SignupSchema(config)}
                    resetInitalValue={true}
                >
                    <>
                        <PSDetails1
                            config={config}
                            user={user}
                            resetFormik={resetF}
                            setFalse={() => { console.log('setResetF'); setResetF(false) }}
                            assignedPrinter={assignedPrinter}
                        />
                        {staffList &&
                            <div className="row">
                                <ActionsTable
                                    tableHeading="Staff"
                                    table={staffList}
                                    onEdit={handleStaffEdit}
                                    staffStatus={handleStaffStatus}
                                    resetMobile={handleResetMobile}
                                    editLed={editLed}
                                    actions={true}
                                />
                            </div>
                        }
                    </>
                </Formik>
            }
            {
                !config && !values && <CircularProgress />
            }
            <SnackBar
                open={snackBar.open}
                handleClose={() => { setSnackBar({ open: false, msg: '', type: 'error' }) }}
                message={snackBar.msg}
                type={snackBar.type}
            />
            <Dialog open={guardAction.isShowing} onClose={handleClose}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle disableTypography>
                    <Typography variant={"h6"} color={guardAction.headingVariant}>
                        {guardAction.heading}
                    </Typography>
                </DialogTitle>

                <DialogContent color="secondary">
                    <Typography variant={"body1"}>
                        {guardAction.text}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Box display="inline-block">
                        <Button onClick={()=>confirmAction(guardAction.type, guardAction.user)} variant="contained" color="primary">
                            Yes
                        </Button>
                    </Box>
                    <Box display="inline-block">
                        <Button onClick={handleClose} variant="outlined" color="secondary">
                            No
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            {
                assignedLed && Object.keys(assignedLed).length > 0 && parkingGuardLed &&
                <GuardConfig
                    setSnackBar={setSnackBar}
                    open={assignedLed && Object.keys(assignedLed).length > 0 ? true : false}
                    assignedLed={assignedLed}
                    ledConfig={
                        () => {
                            let ledCon = []
                            _.forEach(_.cloneDeep(parkingGuardLed.config), con => {
                                // fillter led options according to location
                                if (con.key === "led_accessory_id") {
                                    con.options = _.filter(con.options, option => option.location_id == assignedLed.locationId.value)
                                }
                                ledCon.push(con)
                            })
                            return ledCon
                        }
                    }
                    onClose={() => setAssignedLed(null)}
                    updateAssignedLed={(data) => updateAssignedLed(data)}
                    cameraConfig={parkingGuardCamera.config}
                    updateAssignedCamera={data => updateAssignedCamera(data)}
                    barrierConfig={parkingGuardBarrier.config}
                    updateAssignedBarrier={data => updateAssignedBarrier(data)}
                    rfidConfig={parkingGuardRFID.config}
                    updateAssignedRFID={data => updateAssignedRFID(data)}
                    controllerConfig={parkingGuardController.config}
                    eztvConfig={
                        () => {
                            
                            let ezTvCon = []
                            _.forEach(_.cloneDeep(parkingGuardEZTv.config), con => {
                                
                                // fillter eztv options according to location
                                if (con.key === "eztv_accessory_id") {
                                    console.log("assignedLed.locationId.value",assignedLed.locationId.value,con.key,con.options)
                                    con.options = _.filter(con.options, option => parseInt(option.pl_id) == parseInt(assignedLed.locationId.value))
                                }
                                ezTvCon.push(con)
                            })
                            return ezTvCon
                        }
                    }
                />
            }
        </>
    )
}

export default ParkingStaff