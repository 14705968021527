import React, {useState,useEffect} from 'react';
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles,useTheme } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'


const useStyles = makeStyles( (theme) =>(
    {
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        right10: {
            right:'10%'
        },  
       }

))
const UpdatePass =(props)=> {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(props.loading);
    const [error,setErrorMessage] = useState(props.errorMessage)
    const [message,setMessage] = useState(props.message)
    const [input, setInput] = useState(props.formValues)

    useEffect(() => { 
        console.log('use state',props)
        setErrorMessage(props.errorMessage)
        setMessage(props.message)
        setIsLoading(props.loading)
        setInput(props.formValues)
    
     }, [props]);
     const handleInputChange = (e,val) => { 

        const {name, value} = e.target
        console.log("usdatee state name - "+name+ " value - "+value+"-")
            setInput({...input, [name]: value})
        console.log('input',input)
     }
    return(
            <Dialog open={props.open} onClose={props.handleClose} 
                    fullWidth={true}
                    fullScreen={fullScreen}
                    aria-labelledby="max-width-dialog-title"
                >
                <DialogTitle id="max-width-dialog-title" color="secondary"> 
                    <Box>
                        {props.dialogTitle}
                        <IconButton aria-label="close" className={classes.closeButton} onClick={props.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>                   
                </DialogTitle>
                <DialogContent>
                {error !='' && <Typography paragraph={true} variant="h5"  color="secondary">{error}</Typography>}
                {message !='' && <Typography paragraph={true} variant="h5"  color="primary">{message}</Typography>}
                <div>{isLoading && <LinearProgress size={24}  />}</div>
                {!isLoading && 
                    <div>            
                        <div className="row">
                        {
                            <>
                                <div className="col-lg-6 col-sm-12 col-12"> 
                                    <FormHelperText className={classes.label}>Vehicle No</FormHelperText>
                                    <TextField name="vehicleNo" margin="none" onChange={handleInputChange} value={input.vehicleNo} fullWidth/> 
                                </div>
                            </>
                        }
                        </div>
                    </div>
                } 
            </DialogContent>
            <DialogActions>
                    {!isLoading && <div><Button onClick={props.handleClose} name="cancel" variant="outlined"  color="secondary" className={classes.right10}>
                        Cancel    
                    </Button>
                        <Button onClick={()=>{
                            setIsLoading(true) ;setErrorMessage('');setMessage('');
                            props.updateApi(input)
                        }} variant="contained" color="primary" disabled={isLoading}>
                                {'Update'}
                        </Button>
                    </div>}
                    <Box display="inline-block">
                        
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Box>
                </DialogActions>
        </Dialog>     
     )

}
export default UpdatePass