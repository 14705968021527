import React, {useEffect} from "react";
import {createTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Redirect, Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {IntlProvider} from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import AppLocale from "../lngProvider";
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from "constants/ThemeColors";
import SignIn from "./SignIn";
import {setInitUrl} from "../actions/Auth";
import RTL from "util/RTL";
import {setDarkTheme, setThemeColor} from "../actions/Setting";
import AppLayout from "./AppLayout";

const RestrictedRoute = ({component: Component, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

const App = (props) => {
  const dispatch = useDispatch();
  const {themeColor, darkTheme, locale, isDirectionRTL} = useSelector(({settings}) => settings);
  const {token, initURL} = useSelector(({auth}) => auth);
  const isDarkTheme = darkTheme;
  const {match, location} = props;
  const reports = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.state?.from?props.history.location.state.from:props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has("theme-name")) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has("dark-theme")) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);


  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createTheme({...indigoTheme, direction: 'rtl'});
        break;
      }
      case DARK_CYAN: {
        applyTheme = createTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      default :
        createTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createTheme(amberTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme)
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }
  if (location.pathname === '/') {
    if (token === null) {
      return (<Redirect to={'/signin'}/>);
    }else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      if (reports.reports && reports.reports.length>0){
        return (<Redirect to={'app/reports/'+reports.reports[0].name}/>);
      } else if (reports.historicaldata && reports.historicaldata.length>0) {
        return (<Redirect to={'app/historical/'+reports.historicaldata[0].name}/>);
      } else if (reports.resources && reports.resources.length>0) {
        return (<Redirect to={'/app/resources/'+reports.resources[0].name}/>);
      } else if(reports.ezcredits && reports.ezcredits.length>0) {
        return (<Redirect to={'/app/Ezcredits/'+reports.ezcredits[0].name}/>);
      } else if(reports.manageorders && reports.manageorders.length>0) {
        return (<Redirect to={'/app/ManageOrders/'+reports.manageorders[0].name}/>);
      } else if(reports.freerequests && reports.freerequests.length>0) {
        return (<Redirect to={'/app/FreeRequests/'+reports.freerequests[0].name}/>);
      } else if(reports.valet && reports.valet.length>0){
        return (<Redirect to={'app/reports/'+reports.valet[0].name}/>);                    
      } else if(reports.ezcharge && reports.ezcharge.length>0){
        return (<Redirect to={'app/ezcharge/'+reports.ezcharge[0].name}/>);                    
      } else if(reports.access && reports.access.length>0){
        return (<Redirect to={'app/access/'+reports.access[0].name}/>);  
      } else if(reports.valetreimbursements && reports.valetreimbursements.length>0){
        return (<Redirect to={'app/Valet/'+reports.valetreimbursements[0].name}/>);                    
      } else{
        const key = Object.keys(reports)[0];
        return (<Redirect to={`app/${key}/${reports[key][0].name}`}/>); 
      }
    } else {
      return (<Redirect to={initURL}/>);
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <RTL>
            <div className="app-main">
              <Switch>
                <RestrictedRoute path={`${match.url}app`} token={token}
                                 component={AppLayout}/>
                <Route path='/signin' component={SignIn}/>                
                {/*<Route*/}
                {/*  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>*/}
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};


export default App;
