import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './store';
import App from './containers/App';
import AppUpdate from './workbox'

export const store = configureStore();

const MainApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <Switch>
            <Route path="/" component={App}/>
        </Switch>
        <AppUpdate />
    </ConnectedRouter>
  </Provider>;

export default MainApp;