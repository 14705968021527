import React,{useState,useEffect} from 'react';
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import InputLabel from '@material-ui/core/InputLabel'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles} from '@material-ui/core/styles'
import _ from 'lodash'


const useStyles = makeStyles( (theme) =>(
    {
        mapButton:{
            marginTop:'32px',
            color:theme.palette.secondary.light,
        },
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }
))

const Relay = (props)=> {
    console.log("Relay props are", props)
    const classes = useStyles();
    //const vendors = ["Epson", "NGX", "EP802-TM", "MobiOcean", "Z91", "T1-mini"]
    const initialRelay = {
        id: null,
        mac_address: "",
        name: "",
        vendor: "",
        location: {},
        parking_guard: {}
    }

    const [relay, setRelay] = useState(initialRelay)

    useEffect(() => {
        if(props.relay) {
            if(props.relay.id!==relay.id) {
               console.log("relay", props.relay)
               const location = _.find(props.options.locations,{id:props.relay.hidden_parking_id});
               setRelay({
                 name: props.relay.name,
                 id: props.relay.hidden_id,
                 mac_address: props.relay.mac_address,
                 pbrc_id: props.relay.hidden_pbrc_id,
                 vendor: _.find(props.options.relays,{vendor:props.relay.vendor}),
                 location: location,
                 parking_guard: _.find(location.guards,{id:props.relay.hidden_guard_id}),
               }); 
            } else if (null != relay.id) {
                setRelay(initialRelay)
            }
        }
    },[props.relay])
    
    useEffect(()=>{
        if(props.refreshForm===true){
            setRelay(initialRelay);
            props.setRefresh();
        }
    },[props.refreshForm])

    const handleInputChange = (e,val) => {              
        const {name, value} = e.target
        console.log("handleInputChange",e,val)
        setRelay({ ...relay, [name]: val ? val : value });  
    }

    const onReset = () => {
        setRelay(initialRelay)
        props.onReset()
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-3 col-xl-2"> 
                   <InputLabel shrink={true} htmlFor="name" className={classes.label}>Mac Address</InputLabel> 
                   <TextField name="mac_address" margin="none" onChange={handleInputChange} value={relay.mac_address} fullWidth/> 
                </div>
                <div className="col-lg-3 col-xl-2"> 
                   <InputLabel shrink={true} htmlFor="name" className={classes.label}>Name</InputLabel> 
                   <TextField name="name" margin="none" onChange={handleInputChange} value={relay.name} fullWidth/> 
                </div>

                <div className="col-lg-3 col-xl-3">
                    <Autocomplete
                        value = {relay.vendor}
                        id = "vendor"
                        style={{marginTop:0}}
                        onChange={(event, value)=>{
                            event.target.name = "vendor";
                            handleInputChange(event, value);
                        }}
                        options = {props.options.relays}
                        getOptionLabel={(option) => option.vendor}
                        renderInput={(params) => (
                            <React.Fragment>
                                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Vendor</InputLabel>
                                    <TextField {...params} margin="none" />
                            </React.Fragment> 
                        )}
                    >

                    </Autocomplete>
                </div>

                <div className="col-lg-3 col-xl-3">
                    <Autocomplete
                        value = {relay.location}
                        id = "location"
                        style={{marginTop:0}}
                        onChange={(event, value)=>{
                            event.target.name = "location";
                            handleInputChange(event, value);
                        }}
                        options = {props.options.locations}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <React.Fragment>
                                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Location</InputLabel>
                                    <TextField {...params} margin="none" />
                            </React.Fragment> 
                        )}
                    >

                    </Autocomplete>
                </div>
                <div className="col-lg-3 col-xl-3">
                    <Autocomplete
                        value = {relay.parking_guard}
                        id = "pg"
                        style={{marginTop:0}}
                        onChange={(event, value)=>{
                            event.target.name = "parking_guard";
                            handleInputChange(event, value);
                        }}
                        options = {relay.location.guards ? relay.location.guards : []}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <React.Fragment>
                                    <InputLabel shrink={true} htmlFor="name" className={classes.label}>Parking Guard</InputLabel>
                                    <TextField {...params} margin="none" />
                            </React.Fragment> 
                        )}
                    >

                    </Autocomplete>
                </div>

            </div>

            <div className="row mt-2">   
                <div className="col-lg-6 col-sm-12 col-12">                    
                    <Box mt="20px">
                        <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => props.onSubmit(relay)}> Submit </Button></Box>
                        <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" onClick={onReset}>Reset</Button></Box>
                    </Box>
                </div> 
            </div>  
        </div>
    )
}

export default Relay