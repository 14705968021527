import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


export default function MISTable(props) {
    console.log(props)
    const [tabelHeading, setTableHeading] = useState(props.table.headers);
    const [tableBody, setTabelBody] = useState(props.table.body);
    const [isState, setIsState] = useState(props.isState);
    var getTotalCount = (stateData)=>{
      var sum = 0
      stateData.map((data)=>{
        sum+=parseInt(data.active)
      })
      return sum;
    }
    return (
      <>
        { !isState ?
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                  <TableRow>
                  {
                      tabelHeading.map((heading,index)=>(
                              <TableCell  style={{
                                  backgroundColor: "#1d2232",
                                  color: "white",
                                  fontFamily: "Roboto",    
                                  textAlign: index === tabelHeading.length - 1 ? 'right' : 'left', // Align right if it's the last column

                                }}
                                size="small" >{heading}</TableCell>
                      ))
                  }
                  </TableRow>
              </TableHead>
              <TableBody>
                {tableBody.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell>{row.identifier?row.identifier : row.name}</TableCell>
                    <TableCell >{ new Intl.NumberFormat('en-IN').format((row.txn ? row.txn :row.txns))}</TableCell>
                    <TableCell align="right" >₹{ new Intl.NumberFormat('en-IN').format((row.gmv))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          :
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                  <TableRow>
                  {
                      tabelHeading.map((heading,index)=>(
                              <TableCell  style={{
                                  backgroundColor: "#1d2232",
                                  color: "white",
                                  fontFamily: "Roboto",
                                  textAlign: index === tabelHeading.length - 1 ? 'right' : 'left', // Align right if it's the last column
                                }}
                                size="small" >{heading}</TableCell>
                      ))
                  }
                  </TableRow>
              </TableHead>
              <TableBody>
                {tableBody.map((row) => (
                  <TableRow key={row.state}>
                    <TableCell className='font-weight-bold'>{row.state}</TableCell>
                    <TableCell align="right">{row.active}</TableCell>
                  </TableRow>
                ))}
                <TableRow key='total'>
                    <TableCell className='font-weight-bold'>Total</TableCell>
                    <TableCell className='font-weight-bold' align="right">{getTotalCount(tableBody)}</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
      </>
  );
}
