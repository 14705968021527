import React, {Component} from 'react';
import ContainerHeader from 'components/ContainerHeader';
import API from 'util/Api';
import _ from 'lodash'
import DiningTables from './DiningTables'
import CircularProgress from '@material-ui/core/CircularProgress'
import OrderDetails from './OrderDetails.js'
import AddProduct from './AddProduct.js'
import {AudioUrl} from 'constants/AppConstants';

class ManageDineIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            table: [],
            appState:'loading',
            isQR: false,
            isShowing:false,
            showProduct:false,
            currentOrder:{'status':'','prodcuts':[],'amount':0,'tax':0},
            additionalProducts:[],
            products:[],
            selectedTable:'',
            productDetails:{},
            reloadMessage:false
        }
        this.updateOrderStatus = this.updateOrderStatus.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleProductClose = this.handleProductClose.bind(this)
        this.completeOrder = this.completeOrder.bind(this)
        this.updateProduct = this.updateProduct.bind(this)
        this.addProducts = this.addProducts.bind(this)

    }

    componentDidMount(){
        this.getOrders()
        this.getProducts()
        this.notificationHandler()

    }
    handleClose = () => {
        this.setState({isShowing: false})

    }
    handleProductClose = () => {
        this.setState({showProduct: false})
    }
    getProducts(){
        API.get('products')
        .then((res) => {
            this.setState({ products: res.data.data.products.body})
        })
        .catch((err) => {
            this.setState({errorMessage: "Please try again"});
        })
    }
    getOrders() {
        this.setState({appState:'loading'})
        API.get('dineInOrders')
       .then((res) => {
           // received data        
           console.log('rcvd data', res.data)
           this.setState({ table: res.data.data ? res.data.data : []  ,appState : 'active', errorMessage: "",reloadMessage:false})
           console.log('table',this.state.table)
       })
       .catch((err) => {
           // got an error
           this.setState({errorMessage: "Please try again"});
       })
    }
    calculateServiceCharge(products,rate){
        let total = 0
        for(let i=0;i<products.length;i++){
            total +=parseInt(products[i].price_hidden)
        }
        console.log("calculateServiceCharge",total)
        return (total>0)?total*rate:0
    }
    updateOrderStatus(status,data,table){
        switch(status){
            case 'order_complete': 
                    var orderProducts = _.cloneDeep(data.body);
                    var similarProducts = [];  
                    var products = [];  
                    for(var i=0;i<orderProducts.length;i++){
                        if(similarProducts[orderProducts[i].name] !== undefined){
                            products[similarProducts[orderProducts[i].name]].quantity = parseInt(orderProducts[i].quantity) + parseInt(products[similarProducts[orderProducts[i].name]].quantity);
                            products[similarProducts[orderProducts[i].name]].price_hidden = (parseFloat(orderProducts[i].price_hidden) + parseFloat(products[similarProducts[orderProducts[i].name]].price_hidden)).toFixed(2);
                        } else {
                            products.push(orderProducts[i]);
                            similarProducts[orderProducts[i].name] = products.length-1;
                        }
                    }
                    var currentOrder = {'orderID':data.details.orderID,'status':data.details.status,'products':products,'amount':data.details.amount,'tax':this.calculateServiceCharge(products,0.05).toFixed(2)} 

                    //note: currently service charge is hard coded for specific vendor 
                    if('vendorID' in data.details && data.details.vendorID==58)
                        currentOrder.serviceCharge = this.calculateServiceCharge(products,0.05).toFixed(2) //note: 0.05 (5 %) is the service charge rate , later it should be dynamic

                    this.setState({isShowing:true,currentOrder:currentOrder}); 
                            break;
            default:       
                    this.setState({appState:'loading',selectedTable:table})
                    
                    API.post('dineInOrders',{'status':status,'data':JSON.stringify(data)})
                    .then((res) => {
                        // received data        
                      this.getOrders()
                    })
                    .catch((err) => {
                        // got an error
                        this.setState({errorMessage: "Please try again",appState:'active'});
                    })          
        }

    }
    completeOrder(verifiedBy,orderID){
        this.setState({appState:'loading','loading':true})
                    
        API.post('dineInOrders',{'status':'order_complete','data':JSON.stringify({'orderID':orderID,'paymentMode':verifiedBy})})
        .then((res) => {
            // received data        
            this.setState({'loading':false,isShowing:false})
          
            this.getOrders()

        })
        .catch((err) => {
            // got an error
            this.setState({errorMessage: "Please try again",appState:'active','loading':false});
        })  
    }
    addProducts(data,table){
        this.setState({showProduct:true,productDetails:data,selectedTable:table});
    }
    updateProduct(product){
        this.setState({appState:'loading'})

        API.post('updateOrderProduct',{'orderID':this.state.productDetails,'products':JSON.stringify(product)})
            .then((res) => {
                // received data    
                this.setState({showProduct:false,productDetails:[]});

                this.getOrders()
            })
            .catch((err) => {
                // got an error
                this.setState({errorMessage: "Please try again",appState:'active'});
            })
    }
    notificationHandler(){
        var self = this;

        navigator.serviceWorker.addEventListener("message", (message) => { 
            var notificationMessage = message.data['firebase-messaging-msg-data'].data;  
            // var orderID = message.data['firebase-messaging-msg-data'].data.orderID;  
            // var tableID = message.data['firebase-messaging-msg-data'].data.tableID;  

            navigator.serviceWorker.ready.then(function(registration) {
            registration.showNotification(notificationMessage.title, {
                body: notificationMessage.body,
                icon: 'https://valetez.app/favicon.ico'
              }); 
              let audio = new Audio(AudioUrl);
              audio.play()  
              self.updateNewItems(notificationMessage.body)
                
            });
          });
    }
    updateNewItems(message){
        API.get('dineInOrders')
       .then((res) => {
           // received data        
           this.setState({ table: res.data.data ? res.data.data : [] ,reloadMessage:message})
           var self = this;
           setTimeout(function(){
            self.setState({ reloadMessage:false})
           },60000)
       })
       .catch((err) => {
           // got an error
           this.setState({errorMessage: "Please try again"});
       })
    }
    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<div> {this.renderPage()} </div>)                
        }
    }

    render() {
        return (<div> {this.renderState(this.state.appState)} </div>)
    }

    renderPage() {
        return(
            <div>
                <ContainerHeader title="Manage Orders" match={this.props.match}/>  
                    { this.state.errorMessage!=='' && <h3 className="error"> { this.state.errorMessage } </h3> }                  
                    {this.state.reloadMessage && <h4 className=" alert-dark">{this.state.reloadMessage}</h4>}
                    {this.state.table.length !==0 && 
                    <DiningTables selectedTable={this.state.selectedTable} table = {this.state.table} addProducts={this.addProducts} updateOrderStatus={this.updateOrderStatus} />}
                {this.state.isShowing  && 
                    <OrderDetails dineIn = {true} loading = {this.state.loading} message = {this.state.message} errorMessage = {this.state.errorMessage} isShowing={this.state.isShowing} orderDetails={this.state.currentOrder} completeOrder = {this.completeOrder} handleClose={this.handleClose} />                                         
                }  
                {this.state.showProduct  && 
                    <AddProduct isShowing={this.state.showProduct} products = {this.state.products} updateProduct = {this.updateProduct} handleClose={this.handleProductClose} />                                         
                }                 
            </div>
        )
    }
}
  
export default ManageDineIn;