import React from "react";
import { Route, Switch } from "react-router-dom";

import CreateEVSubscriptions from "../Subscriptions/routes/CreateEVSubscriptions";
import EVChargingPoints from "../Resources/routes/EVPoints"
import EVChargingLocations from "../Resources/routes/EVLocations"
import BasicReports from "../dashboard/routes/BasicReports";
import EVSummery from '../Analytics/routes/EVSummery';
import ChargingTariff from "../Resources/routes/EVLocations/EVRateModel"
import OCPPCharger from "./OCPPCharger"
import EVLocSummary from "./EVLocSummary";
import ClientEVLocSummary from "./ClientEVLocationSummery";
import EVEvent from "./EVEvent";

const EZcharge = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
        <Route path={`${match.url}/charging-points`} component={EVChargingPoints}/>
        <Route path={`${match.url}/charging-locations/tariffs/:location`} component={ChargingTariff}/>
        <Route path={`${match.url}/charging-locations`} component={EVChargingLocations}/>
        <Route path={`${match.url}/create-ev-subscription/`} component={CreateEVSubscriptions}/>
        <Route path={`${match.url}/ev-summary`} component={EVSummery}/>
        <Route path={`${match.url}/ocpp-chargers`} component={OCPPCharger}/>
        <Route path={`${match.url}/ev-loc-summary`} component={EVLocSummary}/>
        <Route path={`${match.url}/loc-summary`} component={ClientEVLocSummary}/>
        <Route path={`${match.url}/ev-event`} component={EVEvent}/>
        <Route path={`${match.url}/:report`} component={BasicReports}/>
    </Switch>
  </div>
);

export default EZcharge;
