/*
 * File: index.js
 * Project: parkezdashboard
 * File Created: Tuesday, 14th April 2020 10:35:09 am
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Tuesday, 14th April 2020 10:37:16 am
 * Modified By: Smit (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

import React from 'react';
import { Popover,Typography,Link,makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));
  
function MsgPopover(props){
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        if(props.timeout && parseInt(props.timeout)>0){
            setTimeout(function(){
                handleClose()
            },parseInt(props.timeout))
        }
        
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <Link  onClick={handleClick}>
            {props.children}
            </Link>
            
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            >
            <Typography className={classes.typography}>{props.msg}</Typography>
            </Popover>
        </React.Fragment>
    );
}


export default MsgPopover