export const clientConfig = [

    {
        "value": "",
        "title": "Client Name",
        "key": "client_name",
        "help": "",
        "type": "input",
        "size": 2
    },
    {
        "value": '',
        "title": "Start Date",
        "key": "start",
        "help": "",
        "type": "datepicker",
        "size": 3,
        "format":"DD MMM, YYYY"
    },
    {
        "value": '',
        "title": "End Date",
        "key": "end",
        "help": "",
        "type": "datepicker",
        "size": 3,
        "format":"DD MMM, YYYY"
    }
]
export const editClientConfig = [
    {
        "value": "",
        "title": "Client Name",
        "key": "name",
        "help": "",
        "type": "input",
        "size": 3
    }
]