import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    section: {
      flexBasis: "100%",
      flexShrink: 0,
      flexWrap: "wrap",
      borderBottomWidth: 1,
      borderBottomColor: "rgba(0, 0, 0, 0.2)",
      borderBottomStyle: "solid",
      paddingBottom: 15,
      paddingTop: 15,
      "&:nth-last-child(1)": {
        borderBottomWidth: 0,
      },
    },
    field: {
      paddingTop: 10,
      paddingBottom: 10,
    },
    panelHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.secondary.main,
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    panelSubheading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      textAlign: "right",
    },
    expanded: {
      backgroundColor: "#efefef",
    },
    panelRoot: {
      flexWrap: "wrap",
      display: "block!important",
    },
    panelAction: {
      flex: 1,
      justifyContent: "flex-end",
    },
    addButton: {
      margin: "10px 0",
    },
  }));
  export default useStyles;