import React from "react";
import { DatePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import moment from "moment";

const CustomDatePicker = (props) => {
  return (
    <div key="datetime_custom" className="picker">
      <DatePicker
          fullWidth
          format="DD MMM YY"
          minDate={props.minDate}
          maxDate={props.maxDate}
          showTabs={false}
          value={props.selectedDate}
          onAccept={handleOnAccept}
          onChange={(date) => {
            props.handleDateChange({
              target: { name: props.name, value: date },
            });
          }}
          leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
          rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i className="zmdi zmdi-alarm" />
              </InputAdornment>
            ),
          }}
        />
    </div>
  );
};

const handleOnAccept = (date) => {
  console.log("inside on accept", date);
};

export default CustomDatePicker;
