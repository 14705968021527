
import React,{useEffect,useState} from 'react';
import { makeStyles,useTheme } from '@material-ui/core/styles'
import { Button,TextField,InputLabel,Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from "lodash";

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
        },
    }

))

const GetClientsByLocation = (props) => {

    const [loc,setLoc] = useState(null);
    const classes = useStyles();
    const handleChange = (e,value) =>{
        if(value != undefined)
            setLoc(value);
        else
            setLoc(null)
    }
    return(
        <>
            <div className={'row'}>
                <div className="col-md-3 col-12">                    
                <Autocomplete                        
                    value={(loc)?loc:null}
                    onChange={handleChange}
                    id="location"
                    name="location"
                    options={props.data}
                    getOptionLabel={(option) => option.location_name}                        
                    renderInput={(params) => (
                        <React.Fragment>
                            <InputLabel shrink={true} htmlFor="name" className={classes.label}>Select Location</InputLabel>
                            <TextField {...params} margin="none" />
                        </React.Fragment>                            
                        )}
                >
                </Autocomplete>
                </div>
                
            </div>
            
            {/* BUTTONS */}
            <div className="row" style={{marginTop:20}}>
                <div className="col-12">
                    <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => props.onSubmit(loc)}> Submit </Button></Box>
                    <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" mr="5px" onClick={() => {props.reset();setLoc(null)}}> Reset </Button></Box>
                </div>
            </div>
        
        </>
    )
}

export default GetClientsByLocation;