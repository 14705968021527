import {initializeApp} from 'firebase/app';
import { getMessaging,getToken } from "firebase/messaging";
import API from 'util/Api';

export const initializeFirebase = () => {
	initializeApp({
		apiKey: "AIzaSyA9rXo8scaDgAHqrpbrFVZAWqwODnt3BKE",
		authDomain: "valetez.firebaseapp.com",
		projectId: "valetez",
		storageBucket: "valetez.appspot.com",
		messagingSenderId: "828415983902",
		appId: "1:828415983902:web:e6293b37fcfca75d4a490f",
		measurementId: "G-JWE91L174T"
	});
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
		var userToken = localStorage.getItem('fcmToken');
		API.get('device?token='+userToken).then((res) => {
			if(res.data.data.fcm_token===false){
				getPermission()
			} 
		})
		.catch((err) => {
			console.log(err)
		})

  } catch(e){
    console.log('error',e)
  }  
}
const getPermission = async () =>{
	const messaging = getMessaging()
	getToken(messaging, { vapidKey: 'BAc-8OnhBVzDpTOQWnn9B-mxrP6iVp4RMmOT7N_aHOwmUedb6iTKWtsnNq6GOCRATCDb-qDwAyJ-lGdoiGMy-xo' }).then((currentToken) => {
		if (currentToken) {
			console.log("currentToken",currentToken)
		  // Send the token to your server and update the UI if necessary
		  API.post('device', JSON.stringify(currentToken)).then((res)=> {
				localStorage.setItem('fcmToken', currentToken);
				localStorage.setItem('permission', 1);
				console.log(res)
			}).catch((err)=> {
				console.log("Please Try Again")
			})
		} else {
		  // Show permission request UI
		  	console.log('No registration token available. Request permission to generate one.');
		  // ...
		}
	  }).catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
	  });
	
}