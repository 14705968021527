import React, { useEffect, useState } from "react";
import API from "util/Api";
import { Formik } from "formik";
import SnackBar from "components/SnackBar";
import { Form, connect } from "formik";
import FormField from "components/FormField";
import ContainerHeader from "components/ContainerHeader";
import _ from "lodash";
import CardBox from "components/CardBox";
import * as Yup from "yup";
import ActionsTable from "components/ActionsTable/TableList";
import { Button, Box, Typography } from '@material-ui/core'
import PopUpDialog from 'components/PopUpDialog';

const getFieldValues = (fields) => {
    const values = Object.values(fields)?.reduce((obj, item) => {
        return { ...obj, [item.key]: item.value };
    }, {});
    return values;
}

const ValetForm = connect((props) => {
    console.log('valet-ValetForm-props', props);
    useEffect(() => {
        if (props.valet) {
            const values = getFieldValues(Object.values(props.valet));
            if (!_.isEqual(values, props.formik.values)) {
                props.formik.setValues(values);
            }
        }
    }, [props.valet]);

    useEffect(() => {
        if (props.formik.values.valet_location_id) {
            const values = _.cloneDeep(props.formik.values);
            let configs = _.cloneDeep(props.config);
            props.setConfig(configs);
        }
    }, [props.formik.values.valet_location_id])

    return (
        <div className="row">
            <CardBox styleName="col-12">
                <Form>
                    {/* FIRST ROW */}
                    <div className="row">
                        {Object.values(props.config.fields)?.map((field) => (
                            field && <div className="col-md-3 col-12" key={`field-${field.key}`}>
                                <FormField field={{ ...field, ...{ name: field.key } }} />
                            </div>
                        ))}
                    </div>
                    {/* BUTTONS */}
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        mr="5px"
                                        type="submit"
                                    >
                                        {` Submit `}
                                    </Button>
                                </Box>
                                <Box mr="10px" display="inline">
                                    <Button variant="outlined" color="secondary" type="reset">
                                        Reset
                                    </Button>
                                </Box>
                            </Box>
                        </div>
                    </div>
                </Form>
            </CardBox>
        </div>
    );
});

const ValetStaff = (props) => {
    const [config, setConfig] = useState();
    const [initialValues, setInitialValues] = useState();
    const [valets, setValets] = useState();
    const [valet, setValet] = useState();
    const [snackBar, setSnackBar] = useState({
        open: false,
        msg: "",
        type: "error",
    });
    const [dialog, setDialog] = useState({open:false, type:'', heading:'', text:'', agreeBtnTxt:'',disAgreeBtnTxt:'', valet:{}});
    const validations = Yup.object().shape({
        first_name: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        phone: Yup.string().required("Required"),
        valet_location_id: Yup.object().required("Required"),
    });

    useEffect(() => {
        API.get("valetStaffV2")
            .then((res) => {
                const values = getFieldValues(res.data.data.config.fields);
                setInitialValues(values);
                setConfig(res.data.data.config);
                setValets(res.data.data.valets);
            })
            .catch((err) => {
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong",
                    type: "error",
                });
            });
    }, []);

    const handleSubmit = (data, form) => {
        API.post("valetStaffV2", JSON.stringify({...data, role_id:'14'}))
            .then((res) => {
                setValets(res.data.data);
                form.setSubmitting(false);
                form.resetForm();
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log("error", err);
                form.setSubmitting(false);
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong  11",
                    type: "error",
                });
            });
    };

    const handleEdit = (clickedLoc) => {
        setValet(clickedLoc);
        document.body.style.height = "auto";
        setTimeout(function () {
            document.body.style.height = "100%";
        }, 50);
    };

    const handleStaffStatus = (valet) => {
        console.log("valetStatus", valet)
        let heading= `${valet.active.value ==1 ? 'Deactivate' : 'Activate'} Valet`;
        let text = `Are you sure you want to ${(valet.active.value == 1 ? 'deactivate' : 'activate')} ${valet.first_name.value}  ${valet.last_name.value}?`;
        setDialog({ open: true, type:'valetStaffStatus', headingVariant:valet.active.value == 1? 'error':'secondary', heading, text, agreeBtnTxt:'Confirm', disAgreeBtnTxt:'Cancel', valet })
    }

    const handleResetMobile = (valet) => {
        let heading='Reset Valet';
        let text = `Are you sure you want to reset ${valet.first_name.value} ${valet.last_name.value}?`;
        setDialog({ open: true, type:'resetMobile', headingVariant:'error', heading, text, agreeBtnTxt:'Confirm', disAgreeBtnTxt:'Cancel', valet })
    }


    const confirmDialogSubmit = (type) =>{
        if(type === 'valetStaffStatus'){
            onEnableConfirm(dialog.valet)
        } else if(type === 'resetMobile'){
            resetMobile(dialog.valet)
        }
    }

    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            type:'',
            heading:'',
            text:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            valet:null
        });
    }
    const onEnableConfirm = (valet) => {
        console.log("valetStatus", valet)

        let valetActive = valet.active.value == 0 ? 1 : 0;
        API.put("valetStatusV2", JSON.stringify({ id: valet.id.value, active: valetActive }))
            .then((res) => {
                let tempValet = valets;

                const index = tempValet.body.findIndex(tempValet => tempValet.id.value === valet.id.value);
                if (index !== -1) {
                    const selectedValet = tempValet.body[index];
                    tempValet.body[index] = { ...selectedValet, active:{...selectedValet.active, value: valetActive}};
                    setValets(tempValet);
                }
                handleDialogClose()
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                handleDialogClose()
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong 22",
                    type: "error",
                });
            })
    }

    const resetMobile = (valet) => {
        API.post("resetMobile", JSON.stringify({ 'guard_id': valet.user_id.value }))
            .then((res) => {
                handleDialogClose()
                setSnackBar({
                    open: true,
                    message: res.data.msg,
                    type: "success",
                });
            })
            .catch((err) => {
                handleDialogClose()
                setSnackBar({
                    open: true,
                    message: err?.response?.data?.msg ? err.response.data.msg : "Something went wrong 33",
                    type: "error",
                });
            })
    }

    return (
        config && initialValues &&
        <>
            <ContainerHeader title={config?.title} match={props.match} />
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={validations}
            >
                <ValetForm config={config} setConfig={setConfig} valet={valet} />
            </Formik>
            {valets && (
                <ActionsTable
                    tableHeading={valets.title}
                    table={valets}
                    onEdit={handleEdit}
                    staffStatus={handleStaffStatus}
                    resetMobile={handleResetMobile}
                    actions={true}
                />
            )}
            <SnackBar
                {...snackBar}
                handleClose={() => {
                    setSnackBar(prevState => ({...prevState, open: false}));
                }}
            />
            <PopUpDialog
                fullWidth
                open={dialog.open}
                header={dialog.heading}
                content ={dialog.text}
                agreeBtnTxt ={dialog.agreeBtnTxt}
                disAgreeBtnTxt={dialog.disAgreeBtnTxt}
                agreeClicked ={() =>confirmDialogSubmit(dialog.type)}
                handleDialogClose={handleDialogClose}
            />
        </>
    )
}
export default ValetStaff;  