import React, {Component} from 'react';
import ContainerHeader from 'components/ContainerHeader';
import CardBox from 'components/CardBox';
import API from 'util/Api';
import _ from 'lodash'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ActionsTable from 'components/ActionsTable'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core';

class ManageProducts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendors: [],
            vendor: [],
            table: [],
            appState:'loading',
            isShowing: false,
            disableProduct: null,
            disable: ''
        }
        this.onEnable = this.onEnable.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.getProducts = this.getProducts.bind(this)
    }

    componentDidMount(){
      API.get('vendors')
      .then((res) => {
          // received data        
          console.log('vendors',res.data.data[0])
          this.getProducts(res.data.data[0].id)
          this.setState({vendors: res.data.data, vendor: res.data.data[0], appState : 'active', errorMessage: ""})
      })
      .catch((err) => {
          // got an error
          this.setState({errorMessage: "Please try again"});
      })
        
    }

    getProducts(id) {
       this.setState({appState:'waiting'})
       console.log("product api", id)
       API.get('products', {
          params: {
              id: id //this.state.vendor===undefined?this.state.vendors[0].id:this.state.vendor.id
          }
      })
      .then((res) => {
          // received data        
          console.log('rcvd data', res.data)
          this.setState({ table: res.data.data.products ,appState : 'active', errorMessage: ""})
      })
      .catch((err) => {
          // got an error
          this.setState({errorMessage: "Please try again"});
      })
    }

    renderState(state){
        switch(state){
            case "loading" :
            default:
                return(<div  style={{
                    position: "absolute", 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent:"center"
                  }}><CircularProgress color="secondary" size={50}/></div>)
            case "active" :
            case "waiting" :
                return(<div> {this.renderPage()} </div>)                
        }
    }

    handleInputChange = (e,val) => {
        console.log("val is", val)
        if(val!=null) {
            this.getProducts(val.id)
            this.setState({vendor: val})
        }
    }

    onEnable = (product, checked) => {
        console.log("enable clicked",product, checked)
        this.setState({disableProduct: product, disable: !checked, isShowing: true})
    }

    onEnableConfirm = () => {
        let params = {
            id: this.state.disableProduct.hidden_id,
            confirm: this.state.disable===true ? 0 :1
        }

        API.post('productStatus', JSON.stringify(params))
        .then((res)=> {
            
            let products = _.cloneDeep(this.state.table)
            let index = _.findIndex(this.state.table.body,{hidden_id:params.id})
            products.body[index].hidden_status = params.confirm
            this.setState({isShowing: false, appState:'active', errorMessage: "",table:products,disableProduct:null})
        })
        .catch((err)=> {
            this.setState({errorMessage: "Please Try Again"});
        })        
    }

    handleClickOpen = () => {
       this.setState({isShowing: true})
    }

    handleClose = () => {
        this.setState({isShowing: false})
    }

    render() {
        return (<div> {this.renderState(this.state.appState)} </div>)
    }

    renderPage() {
        return(
            <div>
                <ContainerHeader title="Manage Orders" match={this.props.match}/>  
                <div className="row">
                    <CardBox styleName="col-12">
                        { Object.entries(this.state.vendors).length > 0 &&
                           <div className="col-md-4 col-12">
                               <Autocomplete
                                  value = {this.state.vendor ? _.find(this.state.vendors,{name: this.state.vendor.name}): null}
                                  id="combo-box-demo"
                                  options={this.state.vendors}
                                  getOptionLabel={(option) => option.name}
                                  style={{ width: 300 }}
                                  onChange = {this.handleInputChange}
                                  renderInput={(params) => <TextField {...params} margin="normal" />}
                                /> 
                           </div> 
                        }
                    </CardBox>
                </div>
                <div className="row">
                    { this.state.errorMessage!=='' &&
                  <h3 className="error"> { this.state.errorMessage } </h3> }
                  
                    { this.state.disableProduct &&
                        <Dialog open={this.state.isShowing} onClose={this.handleClose}>
                            <DialogTitle disableTypography>
                                <Typography variant={"h6"} color={this.state.disable ? "error" : "secondary"}> 
                                    {this.state.disable ? "Disable" : "Enable"}
                                </Typography>
                            </DialogTitle>

                            <DialogContent color="secondary">
                                <Typography variant={"body1"}>
                                    Are you sure you want to {(this.state.disable ? 'disable' : 'enable')} : {this.state.disableProduct.name} ?
                                </Typography>
                            </DialogContent>

                            <DialogActions>
                                <Box display="inline-block">
                                    <Button onClick={this.onEnableConfirm} variant="contained" color="primary">
                                        Yes
                                    </Button>                                     
                                </Box>
                                <Box display="inline-block">
                                    <Button onClick={this.handleClose} variant="outlined"  color="secondary">
                                        No
                                    </Button>                                     
                                </Box>
                            </DialogActions>
                        </Dialog> 
                    }
                </div>
                <div className="row">
                    {this.state.table.length !==0 &&
                       <ActionsTable tableHeading ="Products" table={this.state.table} onEnable = {this.onEnable} actions={true}/> 
                    }
                </div>                    
            </div>
        )
    }
}
  
export default ManageProducts;