import React, { useEffect, useState } from 'react';
import ContainerHeader from 'components/ContainerHeader/index';
import API from 'util/Api';
import ActionsTable from 'components/ActionsTable'
import CardBox from 'components/CardBox';
import Relay from './Relay'
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackBar from 'components/SnackBar';

const BoomRelays = (props) => {
    
    const [boom, setBoom] = useState({
        match: props.match,
        errorMessage:'',
        locations: [],
        relays: [],
        appState:'loading',
        refreshForm:false
    })
    const [relayTable,setRelayTable] = useState(null)
    const [selectedRelay,setSelectedRelay] = useState(null)
    const [snack,setSnack] = useState({snackOpen:false,snackMessage:'',snackType:'error'})
    const [reRenderList,setRerenderList] = useState(true)
    useEffect(()=>{
        
        API.get("location", { params: { guard :true} })
        .then((res) => {
            console.log("locations", res.data.data);
            API.get('getRelayTypes').then((respose) => {
                console.log("relays", res.data.data)
                setBoom({ ...boom,relays: respose.data.data,locations: res.data.data, errorMessage: "", appState : 'active'})
            }).catch((err) => {
                // got an error
                setBoom({...boom,errorMessage: "Please Try Again"});
            })
            
        }).catch((err) => {
            // got an error
            setBoom({...boom, errorMessage: "Please Try Again" })
        });

        API.get("boomrelaysByUser").then((res) => {
            console.log("boomrelaysByUser", res.data.data)
            setRelayTable(res.data.data.BoomRelay)
        }).catch((err) => {
            // got an error
            console.log("configuration api error")
        })

    },[])
    useEffect(()=>{
        setRerenderList(true)
    },[relayTable])
    const  onEdit = (loc) => {
        console.log("edit clicked", loc)
        setSelectedRelay(loc)

        document.body.style.height = 'auto'            
            setTimeout(function(){
                document.body.style.height = '100%'
        },50)
    }
    const  onSubmit = (loc) => {
        console.log("Submit clicked",loc)
        
        API.post("boomrelay", JSON.stringify(loc)).then((res)=> {
            console.log("onSubmitboomrelay",res.data)
            setSnackState(true,res.data.msg,"success")
            setBoom({
                ...boom,
                refreshForm:true
            })
            setSelectedRelay(null)
            setRerenderList(false)
            setRelayTable(res.data.data.BoomRelay)
            console.log("post config", res.data)
        }).catch((err)=>{
            console.log("post config error ", err)
            setSnackState(true,err?.response?.data?.msg?err.response.data.msg:"Something went wrong","error");
        })
    }
    const setSnackState=(open,msg,type)=>{
        setSnack({snackOpen:open,snackMessage:msg,snackType:type})
    }
    const  handleSnackClose = ()=>{
        setSnack({
            ...snack,
            snackMessage:'',
            snackOpen:false
        });
    }
    return(
        <>
            <ContainerHeader title="Boom Relay" match={props.match}/>
            {
            (boom.appState==='loading')?<div  style={{
                        position: "absolute", 
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent:"center"
                    }}><CircularProgress color="secondary" size={50}/></div>:
                <>
                <div className="row">
                    <CardBox styleName="col-12" heading='Boom Relay Details'>
                    {boom.locations.length >0 &&
                        <Relay
                            options = {{relays: boom.relays, locations: boom.locations}}
                            relay = {selectedRelay}
                            onReset={()=> setSelectedRelay(null)}
                            onSubmit={onSubmit}
                            setRefresh={()=>setBoom({...boom,refreshForm:false})}
                            refreshForm ={boom.refreshForm}
                        />}
                    </CardBox> 
                </div>
                <div className="row">
                    {(relayTable && reRenderList) &&  
                    <ActionsTable tableHeading ="Printers" table={relayTable} onEdit = {onEdit} actions={true}/>}
                </div>
                </>
            }
            <SnackBar 
              open={snack.snackOpen} 
              handleClose={handleSnackClose}
              message={snack.snackMessage}
              type={snack.snackType}
             />
        </>
    )
}

export default BoomRelays;