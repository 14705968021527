import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ViewRequests from "./routes/ViewRequests";
import AddFreeParking from "./routes/AddFreeParking";

const FreeRequests = ({match}) => (
	<div className="app-wrapper">
		<Switch>
			<Route path={`${match.url}/view-free-requests`} component={ViewRequests}/> 
			<Route path={`${match.url}/add-free-requests`} component={AddFreeParking}/>
		</Switch>
	</div>
);

export default FreeRequests;