/*
 * File: LEDConfigDetails.jsx
 * Project: parkezdashboard
 * File Created: Tuesday, 25th August 2020 12:55:53 pm
 * Author: Binod Kumar Gope (binod@valetez.com)
 * -----
 * Last Modified: Thursday, 15th February 2020 4:26:02 pm
 * Modified By: Binod Kumar Gope (smit@valetez.com)
 * -----
 * <file description>
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */

import React,{useEffect,useState,Fragment} from 'react'
import { Formik,Form,connect,FieldArray,Field } from 'formik'
import { Button,Box,Typography,IconButton } from '@material-ui/core'
import FormField from 'components/FormField'
import CardBox from 'components/CardBox';
import _ from "lodash";
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles( (theme) =>(
    {
        field:{
            paddingTop:5,
            paddingBottom:5
        },
        addButton:{
            margin:'10px 0'
        },
        title:{
            marginTop:25
        }
    }
))
const ConfigField = (props)=>{
    let field = _.cloneDeep(props.field)
    let values = props.values
    console.log("ConfigField",props.field,' ----- ',props.field.key,' ----- ',props.formik.values,values)
    //when type is selected total  or (type is location and vehcile tyoe is message) then disable field desc
    if((values.type==='Total' || (values.type==='location' && values.vehicle_type=='0')) && field.key==='desc'){

        field.disabled=true
        let resetValue = field.value
        let currentValue = values[field.key]
        //reset the value if dependency do not match
        if(currentValue != resetValue){
            props.formik.setFieldValue(field.name,resetValue)
        }
    }
    //when type is selected  location add option message in vehicle type
    if(values.type==='location' && field.key==='vehicle_type'){
        field.options.push({title: "Message", value: "0"})
    }

    if(field.dependency){
        let fieldValue = field.dependency ? values[field.dependency]:null
        console.log("dependencyValue",fieldValue)
        //condition when not to render the field
        if(!fieldValue || fieldValue=='text' ||  !field.dependencyValue.includes(fieldValue) ){
            console.log("fieldMap--",field.value,'--',values,'--',field.key,'--',values[field.key],'--')
            let resetValue = field.value
            let currentValue = values[field.key]
            
            //reset the value if dependency do not match
            if(currentValue != resetValue){
                console.log("currentValue--",currentValue,'---',resetValue,'--condition-',currentValue != resetValue,'--key-- ',field.key)
                props.formik.setFieldValue(field.name,resetValue)
            } // check so that we donot enter a infinite loop
            
            
            return(null)
        }
    }
    return(
        <div className={`col-md-${field.size} col-12`} key={`rowField-${field.key}`}>
            <FormField  
            field={{...field}}
            />
        </div>
    )
}
const EZField = connect(ConfigField)
const FieldArraySec = (props) =>{
    const classes = useStyles()
    console.log("FieldArraySec",props,props.formik.values)
    return(
        <FieldArray
            name={props.name}
            render={({ insert, remove, push }) =>{
                
                return(
                    <div className={`col-12`}>
                    {
                        <Typography className={`${classes.title}`} varient={'h5'} component={'h5'} >{props.title}</Typography>
                    }
                    {
                      props.formik.values[props.name].map((values,index)=>{
                        return(
                            <div className={`${classes.field} row`} >
                                {
                                props.fields.map((field,fieldIndex)=>{

                                    return(
                                        <EZField
                                            field={{
                                                    ...field,
                                                    ...{name:`${props.name}.${index}.${field.key}`},
                                                    ...{options: props.optionList[field.key] || field.options },
                                                    ...{disabled: (!props.optionList[field.key] || props.optionList[field.key].length) ? false : true }
                                                }}
                                                values={values}
                                        />
                                    )
                                })
                                }
                                <div className="col-sm-2">
                                    {
                                        props.formik.values[props.name].length>1 &&
                                        <IconButton aria-label="remove" onClick={(e) => {remove(index)}}>
                                            <RemoveIcon color="error" />
                                        </IconButton>
                                    }
                                    
                                    {index === props.formik.values[props.name].length-1 &&
                                        <IconButton aria-label="add" onClick={(e) => {
                                            if(props.name==='config'){
                                                push({"desc":'',"type":'',"vehicle_type":'',"sub_location":''})
                                            }
                                        }}>
                                            <AddIcon color="primary" />
                                        </IconButton>
                                    }
                                </div>
                            </div>     
                        )
                      }
                        )
                    }
                    </div>
                )
            }}
        />
    )
}
const LEDConfigDetails = (props) => {
    var config = _.cloneDeep(props.config)
    console.log('LEDConfigDetails',config)
    const [optionList,setOptionList] = useState({})
    const getLocOptions = (key) => {        
        var completeOptions = []
        var locID = props.formik.values.location_id
        if(locID){
            let ledDetails = _.find(config, {key:'ledConfig'});
            var allLocationData = _.find(ledDetails.fields,{key:"location_id"})
            var options = _.find(allLocationData.options,{value:locID.value})[key]
            let ledConfig = _.find(config, {key:'config'});
            var allOptions = _.find(ledConfig.fields,{key:key})
            completeOptions = allOptions.options.filter( o => options?.includes(o.value))
        }
        console.log('getLocOptions- ',key," - ",completeOptions);
        return completeOptions;
    }
    useEffect(()=>{
        let keys = ["vehicle_type","sub_location","client", "floors", "areas"]
        let newOptionList = optionList
        keys.map(key=>{
            newOptionList[key]= getLocOptions(key)
        })

    },[props.formik.values.location_id])
    useEffect(()=>{
        if(props.ledConfig){  
            let isSame = _.isEqual(props.ledConfig,props.formik.values)
            if(!isSame){
                props.formik.setValues(props.ledConfig)                                
            }
        }                
    },[props.ledConfig]);
    useEffect(()=>{
        if(props.resetFormik){
            props.formik.resetForm();
            props.setFalse();
        }
    },[props.resetFormik])
    console.log("configLedConfig",config,props.formik.values)
    return(
        <>
        { config.length >0 &&
            <CardBox styleName="col-12" heading='LED Config Details'>
                <Form>
                    {/* FIRST ROW */}            
                    <div>
                        { config.map((conf) => {
                                console.log("conf",conf)
                                if(conf.type==='fields'){
                                    return(
                                    <div className={'row'} key={'key'+conf.type} >
                                    {
                                        conf.fields.map(field=>{
                                              
                                            //show data fetch interval field only when fetch data is selected
                                            if(field.key==="default_fetch_interval" && props.formik.values.fetch_data==false){
                                                let default_fetch_interval = props.formik.values.default_fetch_interval?.toString()
                                                //make empty if any value is set
                                                if(default_fetch_interval){
                                                    
                                                    props.formik.setFieldValue('default_fetch_interval',null)
                                                }
                                                    
                
                                                return
                                            }
                                            return(
                                                (!field.dependency || props.formik.values[field.dependency] == field.dependencyValue) &&
                                                <div className="col-md-3 col-12" key={`rowField-${field.key}`}>
                                                    <FormField
                                                    field={{
                                                        ...field,
                                                        ...{name:field.key}
                                                    }}/>
                                                </div>
                                              )
                                        })
                                    }
                                    </div>
                                    )
                                }else if(conf.type==='fieldArray'){
                                    return(
                                        <div className={'row'} key={'rowField-'+conf.type}>
                                            <FieldArraySec name={conf.key} {...conf} optionList={optionList} formik={props.formik}/>
                                        </div>
                                    )
                                }
                            })
                        }                 
                    </div>
                    {/* BUTTONS */}
                    <div className="col-lg-6 col-sm-12 col-12">                    
                        <Box mt="20px">
                            <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" type="submit" > Submit </Button></Box>
                            <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" type="reset">Reset</Button></Box>
                        </Box>
                    </div>
                </Form>
            </CardBox>
        }
        </>
    )
}
export default connect(LEDConfigDetails)