// import deepOrange from '@material-ui/core/colors/deepOrange';
import amber from '@material-ui/core/colors/amber';

export default {
  palette: {
    primary: {
      light: '#fbb847',
      main: '#faa719',
      dark: '#af7411',
      contrastText: '#fff'
    },
    secondary: {
      light: '#4a4e5b',
      main: '#1d2232',
      dark: '#141723',
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },

  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
    fontFamily: '"NoirPro", serif'
    // fontFamily:'"Poppins", serif'
  }
};
