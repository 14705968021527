import React, { useState,useEffect } from 'react'
import ContainerHeader from 'components/ContainerHeader'
import CardBox from 'components/CardBox'
import {Box,Button,InputLabel,TextField, Typography,LinearProgress} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles} from '@material-ui/core/styles'
import API from '../../../../../util/Api'
import _ from 'lodash'
import JsonView from 'components/JsonViewer'

const useStyles = makeStyles( (theme) =>(
    {
        label:{
            color: theme.palette.primary.main,
            marginTop:'16px'
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        }
    }
))

const DeviceData = (props)=>{
    const classes = useStyles()
    const [loc,setLoc] = useState(null)
    const [guardList,setGuardList] = useState([])
    const [selectedLoc,setSelectedLoc] = useState(null)
    const [guard,selectedGuard] = useState(null)
    const [showProgress,setShowProgress] = useState(false)
    const [jsonData,setJsonData] = useState({
        'config':null,
        'accessList':null
    })

    useEffect(()=>{
        API.get("location?guard=true").then(response=>{
            setLoc(response.data.data)
        }).catch(error=>{})
    },[])
    const handleSubmit = () =>{
        
        if(selectedLoc && guard){
            API.get("getDeviceData?guradId="+guard.id+"&locationId="+selectedLoc.id).then(response=>{
                console.log("getDeviceData-response",response.data.data)
                setShowProgress(false)
                setJsonData({
                    config:response.data.data.locConfig,
                    accessList:response.data.data.accesslist
                })
            }).catch(error=>{
                setShowProgress(false)
            })
        }
    }
    const handleReset = ()=>{
        selectedGuard(null)
        setSelectedLoc(null)
        setJsonData({
            'config':null,
            'accessList':null
        })
    }
    return(
        <>
            <ContainerHeader title={'Device Data'} match={props.match}/>
            {
            loc && loc.length>0 &&
                <CardBox styleName="">
                <div>
                    {/* 1st row */}
                    <div className={`row`} >
                        <div className="col-lg-5 col-xl-3">
                            <Autocomplete
                                value = {selectedLoc?selectedLoc:null}
                                id = "location"
                                name = "location"
                                style={{marginTop:0}}
                                onChange={(event,value)=>{
                                    if(value)
                                    {
                                        setSelectedLoc(value)
                                        let location = _.find(loc,{id:value.id})
                                        setGuardList(location?location.guards:[])
                                        if(guard){
                                            selectedGuard(null)
                                        }

                                    }else{
                                        setSelectedLoc(null)
                                        selectedGuard(null)
                                        setGuardList([])
                                    }
                                    setJsonData({
                                        'config':null,
                                        'accessList':null
                                    })
                                }}
                                options = {loc}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <React.Fragment>
                                        <InputLabel shrink={true} htmlFor="location" className={classes.label}>Location</InputLabel>
                                        <TextField name="location" {...params} margin="none" />
                                    </React.Fragment> 
                                )}
                            />
                        </div>
                        <div className="col-lg-5 col-xl-3">
                            <Autocomplete
                                value = {guard}
                                id = "guardId"
                                name = "guardId"
                                style={{marginTop:0}}
                                onChange={(event,value)=>{
                                    if(value){
                                    selectedGuard(value)
                                    }else{
                                        selectedGuard(null)
                                    }
                                    setJsonData({
                                        'config':null,
                                        'accessList':null
                                    })
                                }}
                                options = {(guardList.length>0?guardList:[])}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <React.Fragment>
                                            <InputLabel shrink={true} htmlFor="location" className={classes.label}>Guard Name</InputLabel>
                                            <TextField name="guardId" {...params} margin="none" />
                                    </React.Fragment> 
                                )}
                            />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 col-12">
                            <Box mt="20px">
                                <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={()=>{
                                    handleSubmit()
                                    setJsonData({
                                        'config':null,
                                        'accessList':null
                                    })
                                    setShowProgress(true)
                                }}> Submit </Button></Box>
                                <Box mr="10px" display="inline"><Button variant="contained" color="secondary" mr="5px" onClick={handleReset}> Reset </Button></Box>
                            </Box>
                        </div> 
                    </div>
                </div>
            </CardBox>
            }
            {
                showProgress &&
                <div className="col-12">
                    <LinearProgress color="primary"/>
                    <LinearProgress color="primary"/>
                </div>
            }
            {   jsonData.config&&
                <JsonView name={'Guard Config'} jsonObject={jsonData.config}/>
            }
            {
                jsonData.config && jsonData.config.length==0 &&
                <Typography style={{padding:15}} >{(selectedLoc &&guard)?'No Config data for '+selectedLoc.name:''}</Typography>
            }
            {
                jsonData.accessList&&
                <JsonView name={'Guard Access List'} jsonObject={jsonData.accessList}/>
            }
            {
                jsonData.accessList && jsonData.accessList.length==0 &&
                <Typography style={{padding:15}} >{(selectedLoc &&guard)?'No Access List data for '+selectedLoc.name+' - '+guard.name:''}</Typography>
            }
        </>
    )
}
export default DeviceData