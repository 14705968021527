/*
 * File: ReportFilter.js
 * Project: parkezdashboard
 * File Created: Saturday, 18th April 2020 12:32:42 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Thursday, 21st May 2020 10:57:52 am
 * Modified By: Smit (smit@valetez.com)
 * -----
 * component for filtering the different reports
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import React, { useState, useEffect, useRef } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { makeStyles, useTheme } from '@material-ui/core/styles'

import _ from 'lodash'
import moment from 'moment'
import CustomDateTimePicker from 'components/customDateTimePicker/CustomDateTimePicker'

const useStyles = makeStyles((theme) => (
    {
        caption: {
            color: theme.palette.primary.main,
            lineHeight: '1em'
        },
        text: {
            color: theme.palette.secondary.main
        },
        label: {
            color: theme.palette.primary.main,
        },
        button: {
            backgroundColor: theme.palette.secondary.light,
            color: 'white'
        },
        underline: {
            display: 'inline-block',
            borderBottomColor: theme.palette.primary.main,
            borderBottomWidth: '2px',
            borderBottomStyle: 'solid',
        },
        buttonProgress: {
            color: theme.palette.primary.main[500],
            position: 'fixed',
            marginLeft: -60,
            marginTop: 6
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
            color: theme.palette.secondary.light
        },
        chipIcon: {
            color: theme.palette.primary.main,
            height: '10px',
            width: '10px'
        },
        md15: { marginBottom: "15px" },
        md20: { marginBottom: "20px" },
        md30: { marginBottom: "30px" },
    }
))

const ReportFilter = (props) => {
    console.log("ReportFilter", props)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();

    const didMountRef = useRef(false)

    const [isShowing, setIsShowing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [formSubmit, setFormSubmit] = useState(false);
    const [accessLocations, setAccessLocations] = useState(props.accessLocations ? props.accessLocations : []);
    const [accessClients, setAccessClients] = useState(props.accessLocations && props.accessLocations.length > 0 ? props.accessLocations[0].clients : []);
    const [accessSubLocations, setAccessSubLocations] = useState(props.accessLocations && props.accessLocations.length > 0 ? props.accessLocations[0].sub_locations : []);
    const [clientSubLocations, setClientSubLocations] = useState(accessClients.length > 0 ? _.filter(accessSubLocations, (subLoc) => subLoc.client_id.includes(accessClients[0].id)) : []);

    const [input, setInput] = useState({
        location: props.locations && props.locations.length > 0 ? (props.flags.multiLocation ? [props.locations[0].id] : props.locations[0].id) : 0,
        valetLocation: props.valetLocations && props.valetLocations.length > 0 ? (props.flags.multiLocation ? [props.valetLocations[0].id] : props.valetLocations[0].id) : 0,
        period: props.period && props.period.length>0?props.period[0]:[],
        subReport: props.reportType[0].toLowerCase(),
        details_show: false,
        summary_show: true,
        version: 'v2',
        from: props.maxToDate ? new Date(moment(props.maxToDate).subtract(1, 'days').format('YYYY-MM-DD 00:00:00')) : new Date(moment().format('YYYY-MM-DD 00:00:00')),
        to: props.maxToDate ? new Date(moment(props.maxToDate).format('YYYY-MM-DD 00:00:00')) : new Date(moment().add(1, 'days').format('YYYY-MM-DD 00:00:00')),
        vehicle: props.locations && props.locations.length > 0 ? _.cloneDeep(props.locations[0].vehicle_types) : [],
        report_type: 'pdf',
        location_tag: props.tags && props.tags.length > 0 ? props.tags[0].id : 0,
        vendor: props.vendors.length > 0 ? (props.flags.multiVendor ? [props.vendors[0].id] : props.vendors[0].id) : 0,
        chargingLocation: props.charginglocations?.length > 0 ? (props.flags.multiChargingLoc ? [props.charginglocations[0].id] : props.charginglocations[0].id) : 0,
        subscription_type: props.subscription_type && props.subscription_type.length > 0 ? props.subscription_type[0] : '',
        vsa: props.valetServiceAreas && props.valetServiceAreas.length > 0 ? props.valetServiceAreas[0].id : 0,
        noMinDate: props.flags.noMinDate,
        accessLocation: props.accessLocations && props.accessLocations.length > 0 ? props.accessLocations[0].location_id : 0,
        accessClient: 0,
        accessSubLocation: 0,
    })

    useEffect(() => {
        setAccessLocations(props.accessLocations)
    }, [props.accessLocations])

    useEffect(() => {
        if (input.accessLocation) {
            const accessLocation = _.find(accessLocations, { 'location_id': input.accessLocation });
            const accessClients = accessLocation.clients;
            const accessClientID = accessClients.length > 0 ? accessClients[0].id : 0;

            const accessSubLocations = accessLocation.sub_locations;
            const clientSubLocations = _.filter(accessSubLocations, (subLoc) => subLoc.client_id.includes(accessClientID));
            // const accessSubLocationID = clientSubLocations.length > 0 ? clientSubLocations[0].id : 0;

            setAccessClients(accessClients);
            setAccessSubLocations(accessSubLocations)
            setClientSubLocations(clientSubLocations)
        }
        setInput({ ...input, 'accessClient': 0 })
    }, [input.accessLocation])

    useEffect(() => {
        if (input.accessClient) {
            const clientSubLocations = _.filter(accessSubLocations, (subLoc) => subLoc.client_id.includes(input.accessClient));
            // const accessSubLocationID = clientSubLocations.length > 0 ? clientSubLocations[0].id : 0;
            setClientSubLocations(clientSubLocations)
        }
        setInput({ ...input, 'accessSubLocation': 0 })
    }, [input.accessClient])

    useEffect(() => {
        setInput({
            ...input,
            ["location"]: props.locations && props.locations.length > 0 ? props.flags.multiLocation ? [props.locations[0].id] : props.locations[0].id : 0,
            period: props.period && props.period.length>0 ?props.period[0]:[]
        });
    }, [props.locations])


    useEffect(() => {
        /**
         * Need to change the input location from string to array
         * depending on multiLocation flag
         */
        const setupLocation = () => {
            console.log('setupLocation', props.flags, input.location)
            if (!props.flags.multiLocation && Array.isArray(input.location)) {
                setInput({ ...input, 'location': input.location[0] })
            } else if (props.flags.multiLocation && !Array.isArray(input.location)) {
                setInput({ ...input, 'location': [input.location] })
            }

        }
        const setupVendor = () => {
            if (!props.flags.multiVendor && Array.isArray(input.vendor)) {
                setInput({ ...input, 'vendor': input.vendor[0] })
            } else if (props.flags.multiVendor && !Array.isArray(input.vendor)) {
                setInput({ ...input, 'vendor': [input.vendor] })
            }

        }

        if (didMountRef.current) {
            setupLocation()
            setupVendor()
        } else {
            console.log("ignore userEffect")
            didMountRef.current = true
        }
    }, [props.flags, input])

    //on change of flags
    useEffect(() => {
        console.log("props.flags", props.flags)
        setInput({ ...input, noMinDate: props.flags.noMinDate })
    }, [props.flags])


    const closeDialog = () => {
        setIsShowing(false)
        setIsLoading(false)
        if (props.onDialogClose) props.onDialogClose()
    }

    const handleInputChange = (e, val) => {
        const { name, value } = e.target

        console.log("name - " + name + " value - " + value)
        // select manages the state internally 
        // we are doing this to store the status for the api calls
        if ('details_show' === name) {
            setInput({ ...input, 'details_show': e.target.checked })
        } else if ("summary_show" === name) {
            setInput({ ...input, summary_show: e.target.checked });
        } else if ("historical" === name) {
            if (e.target.checked) {
                setInput({
                    ...input,
                    ['version']: 'v3',
                    ['from']: moment(Date.now()).subtract(7, "months"),
                    ['to']: moment(Date.now()).subtract(7, "months")
                })
            } else {
                setInput({
                    ...input,
                    ['version']: 'v2',
                    ['from']: props.maxToDate ? getRoundedDate(15, new Date(props.maxToDate)) : getRoundedDate(15, new Date()),
                    ['to']: props.maxToDate ? getRoundedDate(15, new Date(props.maxToDate), false) : getRoundedDate(15, new Date(), false),
                })
            }
        } else if ("vehicle" === name) {
            let vehicle = input.vehicle;

            if (e.target.checked) vehicle.push(e.target.value);
            else
                _.remove(vehicle, (o) => {
                    return o === e.target.value;
                });
            setInput({ ...input, vehicle: vehicle });
        } else if ("location_tag" === name && props.flags.multiLocation) {
            let locations = _.map(
                _.filter(props.locations, (o) => {
                    if (o.tag) return _.includes(o.tag.split(","), value);
                }),
                "id"
            );
            setInput({ ...input, location: locations, [name]: value });
        } else {
            setInput({ ...input, [name]: value });
        }
    }

    const handleLocationChange = (e, value) => {
        console.log('location-value', value)
        // set values if value is not null
        if (value) {
            if (!props.flags.multiLocation)
                setInput({ ...input, 'location': value.id, "vehicle": _.cloneDeep(value.vehicle_types) })
            else {
                let locations = value.map(a => a.id)
                console.log('locations', locations)
                setInput({ ...input, 'location': value.map(a => a.id) })
            }
        }
    }
    const handleValetLocationChange = (e, value) => {
        if (value) {
            if (!props.flags.multiLocation)
                setInput({ ...input, 'valetLocation': value.id})
            else {
                setInput({ ...input, 'valetLocation': value.map(a => a.id) })
            }
        }
    }
    const handleVendorChange = (e, value) => {
        if (value) {
            if (!props.flags.multiVendor)
                setInput({ ...input, 'vendor': value.id })
            else {
                let vendors = value.map(a => a.id)
                setInput({ ...input, 'vendor': value.map(a => a.id) })
            }
        }
    }
    const handleChargingChange = (e, value) => {
        if (value) {
            if (!props.flags.multiChargingLoc)
                setInput({ ...input, 'chargingLocation': value.id })
            else {
                setInput({ ...input, 'chargingLocation': value.map(a => a.id) })
            }
        }
    }
    const handleVsaChange = (e, value) => {
        if (value) {
            setInput({ ...input, 'vsa': value.id })
        }
    }
    const handleAccessChange = (e, value) => {
        setInput({ ...input, 'accessLocation': value ? value.location_id : 0 })
    }
    const handleClientChange = (e, value) => {
        setInput({ ...input, 'accessClient': value ? value.id : 0 })
    }

    const handleSubAccessChange = (e, value) => {
        setInput({ ...input, 'accessSubLocation': value ? value.id : 0 })
    }

    //removing min date check, if subsc type is revenue
    useEffect(() => {

        if (input.subscription_type) {
            setInput({ ...input, 'noMinDate': input.subscription_type === 'revenue' ? true : props.flags.noMinDate })
        }
    }, [input.subscription_type])

    return (
        <div>
            {/* FIRST ROW */}
            <div className="row">
                {/* LOCATION DROPDOWN */}
                {props.flags.location && props.locations.length > 0 &&
                    <div className={"col-sm-6 col-12 " + (props.flags.multiLocation ? "col-lg-8" : "col-lg-4")}>
                        <FormControl className="w-100">
                            <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Location</InputLabel>
                            <Autocomplete
                                // size={props.flags.multiLocation ? "small" : ""}
                                value={props.flags.multiLocation
                                    ? (Array.isArray(input.location)
                                        ? _.filter(props.locations, ((o) => {
                                            return _.includes(input.location, o.id);
                                        }))    // find the locations which have id in input.location array
                                        : [])
                                    : (input.location ? _.find(props.locations, { 'id': input.location }) : {})
                                }
                                multiple={props.flags.multiLocation}
                                onChange={handleLocationChange}
                                id="location"
                                name="location"
                                style={{ marginTop: 0 }}
                                options={props.locations}
                                getOptionLabel={(option) => option.name}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" size="small" color="primary" key={value}
                                            className={classes.chip}
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField id="location-input" {...params} margin="normal" />
                                )}
                            >

                            </Autocomplete>

                        </FormControl>
                    </div>
                }
                 {/* VALET LOCATION DROPDOWN */}
                 {props.flags.valetLocations && props.valetLocations.length > 0 &&
                    <div className={"col-sm-6 col-12 " + (props.flags.multiLocation ? "col-lg-8" : "col-lg-4")}>
                        <FormControl className="w-100">
                            <InputLabel shrink={true} htmlFor="valetlocation-input" className={classes.label}>Valet Location</InputLabel>
                            <Autocomplete
                                // size={props.flags.multiLocation ? "small" : ""}
                                value={(input.valetLocation ? _.find(props.valetLocations, { 'id': input.valetLocation }) : {})}
                                onChange={handleValetLocationChange}
                                id="valetlocation"
                                name="valetlocation"
                                style={{ marginTop: 0 }}
                                options={props.valetLocations}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField id="valetlocation-input" {...params} margin="normal" />
                                )}
                            >
                            </Autocomplete>

                        </FormControl>
                    </div>
                }
                {/* Charging Locations DROPDOWN */}
                {props.flags.chargingLoc && props.charginglocations.length > 0 &&
                    <div className={"col-sm-6 col-12 " + (props.flags.multiChargingLoc ? "col-lg-8" : "col-lg-4")}>
                        <FormControl className="w-100">
                            <InputLabel shrink={true} htmlFor="chargingLoc-input" className={classes.label}>Charging Location</InputLabel>
                            <Autocomplete
                                // size={props.flags.multiLocation ? "small" : ""}
                                value={props.flags.multiChargingLoc
                                    ? (Array.isArray(input.chargingLocation)
                                        ? _.filter(props.charginglocations, ((o) => {
                                            return _.includes(input.chargingLocation, o.id);
                                        }))    // find the locations which have id in input.location array
                                        : [])
                                    : _.find(props.charginglocations, { 'id': input.chargingLocation })
                                }
                                multiple={props.flags.multiChargingLoc}
                                onChange={handleChargingChange}
                                id="chargingLoc"
                                name="chargingLoc"
                                style={{ marginTop: 0 }}
                                options={props.charginglocations}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField id="chargingLoc-input" {...params} margin="normal" />
                                )}
                            >

                            </Autocomplete>

                        </FormControl>
                    </div>
                }
                {/* VENDOR DROPDOWN */}
                {props.flags.vendor && props.vendors.length > 0 &&
                    <div className={"col-sm-6 col-12 " + (props.flags.multiVendor ? "col-lg-8" : "col-lg-4")}>
                        <FormControl className="w-100">
                            <InputLabel shrink={true} htmlFor="location-input" className={classes.label}>Vendor</InputLabel>
                            <Autocomplete
                                // size={props.flags.multiLocation ? "small" : ""}
                                value={props.flags.multiVendor
                                    ? (Array.isArray(input.vendor)
                                        ? _.filter(props.vendors, ((o) => {
                                            return _.includes(input.vendor, o.id);
                                        }))    // find the locations which have id in input.location array
                                        : [])
                                    : _.find(props.vendors, { 'id': input.vendor })
                                }
                                multiple={props.flags.multiVendor}
                                onChange={handleVendorChange}
                                id="vendor"
                                name="vendor"
                                style={{ marginTop: 0 }}
                                options={props.vendors}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField id="vendor-input" {...params} margin="normal" />
                                )}
                            >

                            </Autocomplete>

                        </FormControl>
                    </div>
                }
                {/* VALET SERVICE AREAS DROPDOWN */}
                {props.flags.valetServiceAreas && props.valetServiceAreas.length > 0 &&
                    <div className={"col-sm-6 col-12 col-lg-4"}>
                        <FormControl className="w-100">
                            <InputLabel shrink={true} htmlFor="vsa-input" className={classes.label}>Service Area</InputLabel>
                            <Autocomplete
                                // size={props.flags.multiLocation ? "small" : ""}
                                value={_.find(props.valetServiceAreas, { 'id': input.vsa })
                                }
                                onChange={handleVsaChange}
                                id="vsa"
                                name="vsa"
                                style={{ marginTop: 0 }}
                                options={props.valetServiceAreas}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField id="vsa-input" {...params} margin="normal" />
                                )}
                            >

                            </Autocomplete>

                        </FormControl>
                    </div>
                }
                {/* Access Locations DROPDOWN */}
                {props.flags.accessLoc && props.accessLocations.length > 0 &&
                    <>
                        <div className={"col-sm-6 col-12 col-lg-4"}>
                            <FormControl className="w-100">
                                <InputLabel shrink={true} htmlFor="accessLoc-input" className={classes.label}>Access Location</InputLabel>
                                <Autocomplete
                                    // size={props.flags.multiLocation ? "small" : ""}
                                    value={input.accessLocation ? _.find(accessLocations, { 'location_id': input.accessLocation }) : ""}
                                    multiple={props.flags.multiLocation}
                                    onChange={handleAccessChange}
                                    id="accessLoc"
                                    name="accessLoc"
                                    style={{ marginTop: 0 }}
                                    options={accessLocations}
                                    getOptionLabel={(option) => option.location_name}
                                    renderInput={(params) => (
                                        <TextField id="accessLoc-input" {...params} margin="normal" />
                                    )}
                                >
                                </Autocomplete>
                            </FormControl>
                        </div>
                        <div className={"col-sm-6 col-12 col-lg-4"}>
                            <FormControl className="w-100">
                                <InputLabel shrink={true} htmlFor="accessLoc-input" className={classes.label}>Access Client</InputLabel>
                                <Autocomplete
                                    // size={props.flags.multiLocation ? "small" : ""}
                                    value={input.accessClient ? _.find(accessClients, { 'id': input.accessClient }) : ""}
                                    // multiple={props.flags.multiLocation}
                                    onChange={handleClientChange}
                                    id="accessLoc"
                                    name="accessLoc"
                                    style={{ marginTop: 0 }}
                                    options={accessClients}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField id="accessLoc-input" {...params} margin="normal" />
                                    )}
                                >
                                </Autocomplete>
                            </FormControl>
                        </div>
                        <div className={"col-sm-6 col-12 col-lg-4"}>
                            <FormControl className="w-100">
                                <InputLabel shrink={true} htmlFor="accessLoc-input" className={classes.label}>Sub Location</InputLabel>
                                <Autocomplete
                                    // size={props.flags.multiLocation ? "small" : ""}
                                    value={input.accessSubLocation ? _.find(clientSubLocations, { 'id': input.accessSubLocation }) : ""}
                                    // multiple={props.flags.multiLocation}
                                    onChange={handleSubAccessChange}
                                    id="accessLoc"
                                    name="accessLoc"
                                    style={{ marginTop: 0 }}
                                    options={clientSubLocations}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField id="accessLoc-input" {...params} margin="normal" />
                                    )}
                                >
                                </Autocomplete>
                            </FormControl>
                        </div>
                    </>
                }
                {/* PERIOD DROPDOWN */}
                {props.flags?.period &&
                    <div className="col-lg-3 col-sm-6 col-12">
                        <FormControl className="w-100" mr="50px">
                            <InputLabel htmlFor="period" className={classes.label}>Period</InputLabel>
                            <Select
                                value={input.period}
                                name='period'
                                onChange={handleInputChange}
                                input={<Input id="period" />}
                            >
                                {props.period?.map(period => (
                                    <MenuItem
                                        key={period}
                                        value={period}
                                        style={{
                                            fontWeight: props.period.indexOf(period) !== -1 ? '500' : '400',
                                        }}
                                    >
                                        {period}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>}

                {/* SUBSCRIPTION TYPE DROPDOWN */}
                {props.flags.subscription_type &&
                    <div className="col-lg-3 col-sm-6 col-12">
                        <FormControl className="w-100" mr="50px">
                            <InputLabel htmlFor="subscription_type" className={classes.label}>Type</InputLabel>
                            <Select
                                value={input.subscription_type}
                                name='subscription_type'
                                onChange={handleInputChange}
                                input={<Input id="subscription_type" />}
                            >
                                {props.subscription_type.map(subsc => (
                                    <MenuItem
                                        key={subsc}
                                        value={subsc}
                                        style={{
                                            fontWeight: props.subscription_type.indexOf(subsc) !== -1 ? '500' : '400',
                                        }}
                                    >
                                        {subsc}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>}

                {/* REPORT TYPE DROPDOWN */}
                {props.flags.type &&
                    <div className="col-lg-3 col-sm-6 col-12">
                        <FormControl className="w-100">
                            <InputLabel htmlFor="subReport" className={classes.label}>Type</InputLabel>
                            <Select
                                value={input.subReport}
                                name='subReport'
                                onChange={handleInputChange}
                                input={<Input id="subReport" />}
                            // renderValue={(selected) => (selecte)}
                            >
                                {props.reportType.map(type => (
                                    <MenuItem
                                        key={type}
                                        value={type.toLowerCase()}
                                        style={{
                                            fontWeight: props.reportType.indexOf(type) !== -1 ? '500' : '400',
                                        }}
                                    >
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>}

                {/* TAGS */}
                {props.flags.multiLocation &&
                    <div className="col-lg-4 col-sm-6 col-12">
                        <FormControl className="w-100">
                            <InputLabel htmlFor="location_tag" className={classes.label} >Location Tags</InputLabel>
                            <Select
                                value={input.location_tag}
                                onChange={handleInputChange}
                                name="location_tag"
                                input={<Input id="location_tag" />}
                            >
                                {props.tags.map((item, i) => (
                                    <MenuItem
                                        key={"location_tag-" + item.id}
                                        value={item.id}
                                        data-id={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>}

            </div>

            {/* SECOND ROW */}
            {(props.flags.details || props.flags.summary) &&
                <div className="row">

                    {/* DETAILS CHECKBOX */}
                    <div className={"col-sm-12 " + (props.flags.type || props.flags.subscription_type ? "col-lg-10" : "col-lg-7")} align="right">
                        <FormControl className="w-100">
                            <FormGroup className="d-flex flex-row">
                                {props.flags.summary && <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name='summary_show'
                                            checked={input.summary_show}
                                            onChange={handleInputChange}
                                        />
                                    }
                                    label="Show Summary"
                                />}
                                {props.flags.details && <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name='details_show'
                                            checked={input.details_show}
                                            onChange={handleInputChange}
                                        />
                                    }
                                    label="Show Details"
                                />}
                            </FormGroup>
                        </FormControl>
                    </div>
                </div>}

            {/* THIRD ROW */}

            <div className={"row" + (props.flags.details ? " mt-2" : " mt-5")}>
                {props.flags.showDate &&
                    <>
                        {/* START DATE */}
                        <div className="col-lg-4 col-sm-12 col-12">
                            <FormHelperText className={classes.label}>Start Date</FormHelperText>
                            <CustomDateTimePicker selectedDate={props.minFromDate && !input.noMinDate && moment(input.from).unix() < moment(props.minFromDate).unix() && input.version!='v3' ? props.minFromDate : input.from} minDate={props.minFromDate && !input.noMinDate && input.version != 'v3' ? props.minFromDate : new Date('2016-01-01')} maxDate={props.maxToDate} handleDateChange={handleInputChange} name='from' minutes={props.minutesStep} version={input.version} enableFuture={true} />
                        </div>

                        {/* END DATE */}
                        <div className="col-lg-4 col-sm-12 col-12">
                            <FormHelperText className={classes.label}>End Date</FormHelperText>
                            <CustomDateTimePicker selectedDate={input.to} minDate={input.from ? input.from : props.minFromDate} handleDateChange={handleInputChange} name='to' minutes={props.minutesStep} maxDate={props.maxToDate ? props.maxToDate : moment(input.from).add(90, 'days')} version={input.version} enableFuture={true} />
                        </div>
                        {props.flags.historical &&
                            <div className="col-lg-4 col-sm-12 col-12 mt-4">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name="historical"
                                            checked={input.version === 'v3' ? true : false}
                                            onChange={handleInputChange}
                                        />
                                    }
                                    label="Data older than 6 months"
                                />
                            </div>}
                    </>
                }
            </div>

            {/* FOURTH ROW */}

            {/* VEHICLE TYPES */}
            {props.flags.vehicleType &&
                <div className="row mt-2">
                    <div className="col-sm-12 col-12">
                        <FormControl className="w-100">
                            <FormHelperText className={classes.label}>Vehicle Type</FormHelperText>
                            <FormGroup className="d-flex flex-row">
                                {_.findIndex(props.locations, { id: input.location }) > -1 &&
                                    (_.find(props.locations, { id: input.location }).vehicle_types).map((item, i) => (
                                        <FormControlLabel
                                            key={'vehicleType-' + item}
                                            control={
                                                <Checkbox color="primary"
                                                    checked={input.vehicle.indexOf(item) !== -1}
                                                    onChange={handleInputChange}
                                                    name="vehicle"
                                                    value={item}
                                                />
                                            }
                                            label={item}
                                        />
                                    ))}
                            </FormGroup>
                        </FormControl>
                    </div>
                </div>}

            {/* BUTTONS */}
            <div className="row mt-2">
                <div className="col-lg-6 col-sm-12 col-12">
                    <Box mt="20px">
                        {!props.isDataRequest && <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => {
                            setFormSubmit(true)
                            props.getReportData(input, () => {
                                setFormSubmit(false)
                            })
                        }} disabled={formSubmit}> Get Details </Button></Box>}
                        {<Box mr="10px" display="inline"><Button variant="contained" className={classes.button} name="requestReport"
                            onClick={() => setIsShowing(true)}>Request Report</Button></Box>}
                    </Box>
                </div>
            </div>

            {/* DIALOG */}
            <Dialog open={isShowing} onClose={closeDialog}
                fullScreen={fullScreen}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle color="secondary">
                    <Box className={classes.underline}>Please confirm</Box><Box display="inline"> report details</Box>
                </DialogTitle>
                <DialogContent>
                    {props.flags.location && <div><Typography variant="overline" className={classes.caption}>Location</Typography>
                        <Typography variant="h6" className={classes.md20} color="secondary">
                            {!props.flags.multiLocation
                                ? <React.Fragment>{_.findIndex(props.locations, { id: input.location }) > -1 ? _.find(props.locations, { id: input.location }).name : ""}</React.Fragment>
                                : <React.Fragment>{
                                    _.filter(props.locations, ((o) => {
                                        return _.includes(input.location, o.id);
                                    })).map((el) => {
                                        return (el.name)
                                    }).join(', ')
                                }</React.Fragment>
                            }
                        </Typography></div>
                    }
                    {props.flags.vendor && <div><Typography variant="overline" className={classes.caption}>Vendor</Typography>
                        <Typography variant="h6" className={classes.md20} color="secondary">
                            {!props.flags.multiVendor
                                ? <React.Fragment>{_.findIndex(props.vendors, { id: input.vendor }) > -1 ? _.find(props.vendors, { id: input.vendor }).name : ""}</React.Fragment>
                                : <React.Fragment>{
                                    _.filter(props.vendors, ((o) => {
                                        return _.includes(input.vendor, o.id);
                                    })).map((el) => {
                                        return (el.name)
                                    }).join(', ')
                                }</React.Fragment>
                            }
                        </Typography></div>
                    }
                    <Typography variant="overline" className={classes.caption}>From</Typography>
                    <Typography variant="h6" color="secondary">{input.version === 'v3' ? moment(input.from).format('MM/YYYY') : moment(input.from).format('DD/MM/YY hh:mm a')}</Typography>
                    <Typography variant="overline" className={classes.caption}>To</Typography>
                    <Typography variant="h6" color="secondary" className={classes.md20}>{input.version === 'v3' ? moment(input.to).format('MM/YYYY') : moment(input.to).format('DD/MM/YY hh:mm a')}</Typography>

                    <FormControl>
                        <Typography variant="overline" className={classes.caption}>Choose Report Type</Typography>
                        <RadioGroup
                            className="d-flex flex-row"
                            aria-label="report_type"
                            name="report_type"
                            value={input.report_type}
                            onChange={handleInputChange}
                        >
                            {
                                !props.flags.valetServiceAreas && <FormControlLabel value="pdf" control={<Radio color="primary" />} label="PDF" />
                            }
                            <FormControlLabel value="excel" control={<Radio color="primary" />} label="Excel" />
                        </RadioGroup>
                    </FormControl>
                    {props.flags.details && !props.isReportRequest ?
                        <FormControl fullWidth>
                            <FormControlLabel control={<Checkbox color="primary" name='details_show' checked={input.details_show} onChange={handleInputChange} />} label="Show Details" />
                        </FormControl> : ''}

                </DialogContent>

                {props.requestStatus.msg.length > 0 &&
                    <DialogContent>
                        <Typography style={{ whiteSpace: 'pre-line' }} color={(props.requestStatus.success) ? "primary" : "error"} gutterbottom>
                            {props.requestStatus.msg}
                        </Typography>
                    </DialogContent>}
                <DialogActions>
                    {props.requestStatus.msg.length > 0 ?
                        <>
                            {props.requestStatus.status === 'duplicate_request' ?
                                <>
                                    <Button onClick={() => { setIsLoading(true); props.sendReportData({ ...input, force: true }, () => { setIsLoading(false) }) }} name="close" variant="outlined" color="secondary" disabled={isLoading}>
                                        Yes
                                    </Button>
                                    <Button onClick={closeDialog} name="close" variant="contained" color="primary" disabled={isLoading}>
                                        No
                                    </Button>
                                </>
                                : <Button onClick={closeDialog} name="close" variant="outlined" color="secondary" disabled={isLoading}>
                                    Close
                                </Button>}
                        </>
                        : <>
                            <Button onClick={closeDialog} name="cancel" variant="outlined" color="secondary" disabled={isLoading}>
                                Cancel
                            </Button>
                            <Button onClick={() => { setIsLoading(true); props.sendReportData(input, () => { setIsLoading(false) }) }} variant="contained" color="primary" disabled={isLoading}>
                                Continue
                            </Button>
                        </>}
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </DialogActions>
            </Dialog>
        </div>
    )
}

const getRoundedDate = (minutes, d = new Date(), low = true) => {
    let ms = 1000 * 60 * minutes; // convert minutes to ms
    let roundedDate = new Date((Math.round(d.getTime() / ms) - (low ? 0 : -1)) * ms);

    return roundedDate
}

export default ReportFilter