import React from 'react';
import {Route, Switch} from 'react-router-dom';
import orders from './routes/ManageOrders';
import ReadyForPickUp from "./routes/ManageOrders/ReadyForPickup";
import ManageProducts from "./routes/ManageOrders/ManageProducts";
import ManageDineIn from "./routes/ManageOrders/ManageDineIn";

const ManageOrders = ({match}) => (
          <div className="app-wrapper">
            <Switch>
            <Route path={`${match.url}/view-kerb-side-orders`} component={orders}/>
            <Route path={`${match.url}/complete-kerb-side-orders`} component={ReadyForPickUp}/>
            <Route path={`${match.url}/manage-products`} component={ManageProducts}/>
            <Route path={`${match.url}/manage-dine-in-orders`} component={ManageDineIn}/>
             {/* <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
            </Switch>

             </div>
);

export default ManageOrders;
