import React from 'react';
import {Route, Switch} from 'react-router-dom';

import historical from "./routes/historical";
import { useLocation } from 'react-router-dom';
import Error404 from 'components/404/index.js';

const Historical = ({match}) => {
	const loc = useLocation()
	return(
	  <div className="app-wrapper">
	    <Switch>
		{!(loc.search=='?t')&& <Route path={[`${match.url}/parking`,`${match.url}/parking_v2`]} component={Error404}/> }
		{/* {!(loc.search=='?t')&& <Route path={[`${match.url}/parking-detail`,`${match.url}/parking_v2`]} component={Error404}/> } */}
	     <Route path={`${match.url}/:report`} component={historical}/>
	     {/* <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
	    </Switch>

	  </div>
	)
};

export default Historical;
