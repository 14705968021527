/*
 * File: GMap.js
 * Project: parkezdashboard
 * File Created: Tuesday, 2nd June 2020 6:06:02 pm
 * Author: Smit (smit@valetez.com)
 * -----
 * Last Modified: Friday, 5th June 2020 4:06:24 pm
 * Modified By: Smit (smit@valetez.com)
 * -----
 * component for google maps
 * -----
 * Copyright - 2020 , ValetEZ Services Pvt Ltd
 */
import React, { useEffect } from "react"
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react'
import { makeStyles } from "@material-ui/core"
import './gmap.css'
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles( (theme) =>(
    {
        searchBox:{
            backgroundColor:'white',
            position: 'relative',
            top: "2%",
            marginRight: "2.5%",
            float: "right",
            width: "400px",
            border:'1px solid #ccc',
            borderRadius:'3px',
            padding:'3px 8px'
        }
    }
))

const SearchBox = (props) => {

    const inputRef = React.createRef();
    const classes = useStyles()
    let autoComplete
    useEffect(()=>{        
        autoComplete = new props.google.maps.places.Autocomplete(inputRef.current,
            {types: [],componentRestrictions:{country:["in","my"]}});
        props.google.maps.event.addListener(autoComplete,'place_changed',onPlacesChanged)
    })        
    
    const onPlacesChanged = () => {
        let places = autoComplete.getPlace()
        var lat = places.geometry.location.lat();
        var lng = places.geometry.location.lng();
        console.log(lat,lng)

        // console.log("places",places)
        props.onPlacesChanged([lng,lat])
    }    
    
    
    return (
      <div style={{ marginRight: "2.5%", marginLeft: "2.5%" }}>
        <TextField
          name="lat"
          margin="none"
          className="mr-3"
          placeholder="Lattitude"
          onChange={props.onLatChanged}
          value={props.marker[1]}
        />
        <TextField
          name="long"
          margin="none"
          className="mr-3"
          placeholder="Longitude"
          onChange={props.onLongChanged}
          value={props.marker[0]}
        />
        <TextField
          inputRef={inputRef}
          style={{ width: "400px", float: "right" }}
        />
      </div>
    );
}

/**
 * component for google maps
 * @param {*} props 
 */
const GMap = (props) => {

    const classes = useStyles()

    const onDragend = (mapProps,map) => {
        // console.log("center changed",map.center.lat(),map.center.lng())
        props.onViewChange([map.center.lng(),map.center.lat()])
    }
    const onMarkerChange = (t,map,coord) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        // console.log("marker changed",lat,lng)
        props.onMarkerChange([lng,lat])
    }    

    return (
      <Map
        google={props.google}
        zoom={14}
        streetViewControl={false}
        fullscreenControl={false}
        style={{
          margin: "3% auto",
          width: "95%",
          height: "85%",
        }}
        onDragend={onDragend}
        onZoomChanged={onDragend}
        center={{ lng: props.viewport.longitude, lat: props.viewport.latitude }}
        initialCenter={{
          lng: props.viewport.longitude,
          lat: props.viewport.latitude,
        }}
      >
        <SearchBox
          google={props.google}
          onPlacesChanged={props.onPlacesChanged}
          marker={props.marker}
          onLatChanged={props.onLatChanged}
          onLongChanged={props.onLongChanged}
        />
        <Marker
          position={{ lat: props.marker[1], lng: props.marker[0] }}
          draggable={true}
          onDragend={onMarkerChange}
        />
      </Map>
    );
};

export default GoogleApiWrapper({apiKey: process.env.REACT_APP_GMAP_KEY})(GMap)