import React,{useState} from 'react'
import ContainerHeader from 'components/ContainerHeader'
import CardBox from 'components/CardBox'
import API from 'util/Api'
import SnackBar from 'components/SnackBar'
import {Box,TextField,FormHelperText,Button,Select,MenuItem,FormControl,InputLabel,Grid} from '@material-ui/core'
import SimpleTable from 'components/ActionsTable/SimpleTable' 
import PopUpDialog from 'components/PopUpDialog'
import _ from 'lodash'

const ContentBox = (props) =>{
    console.log("ContentBox",props)
    return(
        <Grid item xs={6}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tags</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="tag"
                    name={'tag'}
                    onChange={props.setSelectedValue}
                    
                >
                    {
                        props.fastagsList?.map(item=><MenuItem key={`key-${item.id}`} value={item.tag}>{`${item.tag} (${item.updatedOn})`}</MenuItem>)
                    }
                    
                </Select>
            </FormControl>
        </Grid>
    )
}
const  UpdateFasTag =(props)=> {
    
    const [dialog,setDialog] = useState({open:false,content:'',header:'',agreeBtnTxt:'',disAgreeBtnTxt:'',reset:null});
    const [data,setData] = useState({
        'autoTags':[],
        'fasTag':[]
    })
    const [snackBar,setSnackBar] =  useState({
        open:false,
        message:'',
        type:''
    })
    const [inputs,setInputs] = useState({
        vehicleNo:'',
        tag:''
    })
    const handleChange = (e,v) =>{
        console.log("handleChange",e.target.name,v)
        setInputs({...inputs,[e.target.name]:e.target.value})
    }
    const onSubmit = ()=>{
        API.get(`getFastagDetails?vehicleNo=${inputs.vehicleNo}`).then(response=>{
                setData({...data,autoTags:response.data.data.autoTags,fasTag:response.data.data.fasTags})
        }).catch((error=>{
            setSnackBar({open:true,message:error?.response?.data?.msg?error.response.data.msg:'something went wrong',type:'error'})
        }))
    }
    const onEditClick = (row) =>{
        setDialog({
            open:true,
            header:"Update FasTag tag",
            content:<ContentBox fastagsList={data.fasTag} selectedTag={inputs.tag} setSelectedValue={e=>{setInputs({...inputs,[e.target.name]:e.target.value})}} />,
            agreeBtnTxt:'Update',
            disAgreeBtnTxt:'Cancel',
            row:row
            
        })
    }
    const agreeClicked = () =>{
        console.log("agreeClicked",inputs.tag)
        if(inputs.tag && inputs.tag.length>0){
            let postData={
                user_id:dialog.row.user_id,
                autoId:dialog.row.id,
                tag:inputs.tag.toLowerCase()
            }
            API.post(`updateFasTagInAutoTag`,postData).then(response=>{
                let rowData = dialog.row
                rowData['tag'] = postData.tag
                let updatedRows = _.unionBy(data.autoTags,[rowData])
                console.log("updateFasTagInAutoTag",updatedRows)
                handleDialogClose()
                setInputs({...inputs,tag:''})
                setSnackBar({open:true,message:response.data.msg,type:'success'})
            }).catch(error=>{
                setSnackBar({open:true,message:error?.response?.data?.msg?error.response.data.msg:'something went wrong',type:'error'})
            })
        }else{
            setSnackBar({open:true,message:'please select tag',type:'error'})
        }
    }
    const handleDialogClose = ()=>{
        setDialog({
            open:false,
            content:'',
            header:'',
            agreeBtnTxt:'',
            disAgreeBtnTxt:'',
            row:null});
    }
    const onResetClick=()=>{
        setData({
            'autoTags':[],
            'fasTag':[]
        })
        setInputs({
            vehicleNo:'',
            tag:'',
            error:''
        })
    }
    return(
        <div>
            <ContainerHeader title="Update FasTag" match={props.match} />    
            <div className="row">
                <CardBox styleName="col-12">
                    <>
                    <div className={'row'}>
                        <div className="col-md-3 col-12">
                            <FormHelperText >Vehicle No</FormHelperText>
                            <TextField
                                name={'vehicleNo'}
                                value={inputs.vehicleNo}
                                variant={'standard'}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>
                        <Box mt="20px" pl="10px">
                            <Box mr="10px" display="inline"><Button variant="contained" color="primary" mr="5px" onClick={() => onSubmit()}> Search </Button></Box>
                            <Box mr="10px" display="inline"><Button variant="outlined" color="secondary" mr="5px" onClick={onResetClick}> Reset </Button></Box>
                        </Box>
                    </div>
                    {
                        (data.autoTags && data.autoTags.length>0)?
                        <SimpleTable
                            table={{
                                headers:['Vehicle No','Tag','User Name','Action'],
                                tbody:data.autoTags
                            }}
                            onUpdate={onEditClick}
                            type={'updateFasTag'}
                        />:<></>
                    } 
                    </>
                </CardBox>
                <PopUpDialog 
                    fullWidth
                    open={dialog.open}
                    header={dialog.header}
                    content ={dialog.content}
                    agreeBtnTxt ={dialog.agreeBtnTxt}
                    disAgreeBtnTxt={dialog.disAgreeBtnTxt}
                    agreeClicked ={agreeClicked}
                    handleDialogClose={handleDialogClose}
                />
                <SnackBar 
                    {...snackBar}
                    handleClose={()=>setSnackBar({open:false,msg:'',type:''})}
                />
            </div>   
        </div>
    )
}
export default UpdateFasTag;
